import React, { useRef } from 'react'
import PrintPage from './monogram/PrintPage'
import { useReactToPrint } from 'react-to-print';

const PrintTest = () => {
  const packingSlipRef = useRef();
  const printLabels = useReactToPrint({
    content: () => packingSlipRef.current,
  });
  const handlePrintLabel = () => {
    printLabels()
  }
  
  return <div onClick={handlePrintLabel}>
    <PrintPage 
      ref={packingSlipRef}
      monogram={true}
      monogramRecords={[
        {
          monogramId: '#GETJULY3998866-0',
          location: 'TOP',
          letters: 'L🐓achi🤬🐓',
          font: 'modern',
          mainCol: 'Grey',
          // backCol: 'White',
          created_at: new Date(),
          shopifyId: 'TESTID007',
          sku: 'COPB01-NAV',
          quantity: 1,
          lineItems: ['COPB01-NAV'],
          shippingType: 'express'
        },
      ]} 
    />
  </div>
} 

export default PrintTest