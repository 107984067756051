import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { Select, Nav, CardSubtitle, CardTitle, CardText, TabContent, TabPane, Collapse, NavLink, NavItem, Container, Input, Button, Table, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "./datatables.scss";

import useQuery from '../../helpers/JIFI/useQuery';

import { useHistory } from "react-router-dom";
import superagent from 'superagent'
import {backendUrl} from '../../helpers/consts.js'

import LocationSelector from './shared/LocationSelector'


const StockInput = ({stock, setStock, productData}) => {
  const handleAddRow = () => {
    const prefillProduct = stock[stock.length - 1]?.product
    let prefillVariant
    if (prefillProduct) {
      prefillVariant = productData.find(val => prefillProduct === val._id)['variants'][0]._id
    }
    const item = {
      product: prefillProduct || productData[0]._id,
      variant: prefillVariant || productData[0]['variants'][0]._id,
      quantity: 0
    };
    setStock(prev => [...prev, item])
  };

  const editProductRow = (id, productId) => {
    setStock(prev => {
      let newList = [...prev]
      newList[id]['product'] = productId
      const variant = productData.find(val => `${val._id}` === `${productId}`)
      newList[id]['variant'] = variant.variants[0]?._id
      return newList
    })
  };

  const editVariantRow = (id, val) => {
    setStock(prev => {
      let newList = [...prev]
      newList[id]['variant'] = val
      return newList
    })
  };

  const editQuantityRow = (id, val) => {
    setStock(prev => {
      let newList = [...prev]
      newList[id]['quantity'] = parseInt(val)
      return newList
    })
  };

  const removeRow = (id) => {
    setStock(prev => {
      let newList = prev.filter((item, key) => key !== id)
      return newList
    })
  };

  useEffect(() => {
    if(productData){
      // editProductRow(0, productData[0]._id)
      // editVariantRow(0, productData[0]['variants'][0]._id)
    }
  }, [productData])


  const products = productData ? productData.map(product => {
    const variants = product.variants.map(variant => {
      return {
        _id: variant._id,
        name: variant.name,
        selected: stock.findIndex(val => val.variant === variant._id)
      }
    })

    return {
      name: product.name,
      _id: product._id,
      variants
    }
  }) : []

  return <>
    <Row>
      <Col xs="4">
        Product
      </Col>
      <Col xs="4">
        Variant
      </Col>
      <Col xs="4">
        Quantity
      </Col>
    </Row>
    <br/>
    {
      productData && stock.map((line, lineKey) => {
        const product = products.find(val => {
          return val._id === line.product
        })
        const variant = product && product.variants.find(val => val._id === line.variant)
        return (
          <Row style={{padding: '12px 0'}}>
            <Col xs="4">
              <Input type="select" style={{width: '100%', height: '100%'}} onChange={(e) => editProductRow(lineKey, e.target.value)} name="products" id="products">
                {products.map((val, key) => <option selected={line.product === val._id} value={val._id}>{val.name}</option> )}
              </Input>
            </Col>
            <Col xs="4">
              <Input type="select" style={{width: '100%', height: '100%'}} onChange={(e) => editVariantRow(lineKey, e.target.value)} name="products" id="products">
                {
                  productData && 
                  stock[lineKey]['product'] && 
                  products.find(val => val._id === stock[lineKey]['product']).variants.map((val, key) => 
                    <option selected={line.variant === val._id} disabled={val.selected !== -1 && val.selected !== lineKey} value={val._id}>{val.name}</option> 
                  )}
              </Input>
              {/* {productData && productData.find(val => val._id === stock[lineKey]['product']).variants.find((val) => val._id === stock[lineKey]['variant']).name} */}
            </Col>
            <Col xs="3">
              <Input style={{width: '100%', height: '100%'}} type="number" value={stock[lineKey]['quantity']} onChange={(e) => editQuantityRow(lineKey, e.target.value)}/>
            </Col>
            <Col xs="1" >
              <Button onClick={() => removeRow(lineKey)} color="danger">
                X
              </Button>{" "}
            </Col>
          </Row>
        )
      })
    }
    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 12}}>
      <Button onClick={handleAddRow} color="primary">
        Add{" "}
      </Button>{" "}
      <span />
    </div>
  </>
}


const PullPage =  () => {
  const history = useHistory();
  const [refresh, setRefresh] = useState(true)

  const [stockPush, setStockPush] = useState([])
  const [stockPull, setStockPull] = useState([])
  const [col1, setCol1] = useState(false)
  const [col2, setCol2] = useState(false)
  const [modal, setModal] = useState(false)
  const togglemodal =  () => setModal(prev => !prev)
  const [stockType, setStockType] = useState('on_hand') 

  const [loading, setlLoading] = useState(false)

  const [data, state] = useQuery({
    url: `/product`,
    refresh
  });
  const productData = data && data.data


  const handleSubmit = () => {
    setlLoading(true)
    const exportData = {
      location: 'HQ2',
      pushStock: stockPush,
      pullStock: stockPull,
      stockType: stockType
    }
    
    superagent.post(`${backendUrl}/inventory/alter`)
      .send(exportData)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
          const code = response.status
          history.replace(history.location.pathname, {
              errorStatusCode: code
          });
      })
      .then(response => {
      const code = response.status
      if (code > 400) {
          history.replace(history.location.pathname, {
          errorStatusCode: code
          });
      } else {
        history.go(0)
      }
      }).catch(err => {
      console.log(err)
      });
  } 

  return <div className="page-content">
    <Container fluid>
      <Breadcrumbs title="Inventory" breadcrumbItem={'Alter Stock'} />
      <Row className="mb-2">
        <Col  xs="12" m="8" lg="8" xl="8" >
          <Card>
            <CardBody>
              <div> Location: </div>
              <Input type="select" style={{width: '100%'}}  name="location" id="location">
                <option selected="true" value={'HQ2'}>HQ2</option>
              </Input>
              <br/>
              <div> Stock Type: </div>
              <Input type="select" style={{width: '100%'}} onChange={e => setStockType(e.target.value)} name="location" id="location">
                <option selected="true" value={'on_hand'}>On Hand</option>
                <option value={'dead'}>Dead</option>
                <option value={'display'}>Display</option>
              </Input>
              <br/><br/>
              <div id="accordion">
                  <Card className="mb-1">
                    <CardHeader className="p-3" id="headingOne">
                      <h6 className="m-0 font-14">
                        <span onClick={() => setCol1(prev => !prev)}
                          style={{ cursor: "pointer" }} className="text-dark">
                          Take stock <i className="bx bx-layer-minus text-danger ml-3" />
                        </span>
                      </h6>
                    </CardHeader>
                    <Collapse isOpen={col1}>
                      <Card>
                        <CardBody>
                          <StockInput stock={stockPull} setStock={setStockPull} productData={productData} />
                        </CardBody>
                      </Card>
                    </Collapse>
                  </Card>
                  <Card className="mb-1">
                    <CardHeader className="p-3" id="headingTwo">
                      <h6 className="m-0 font-14">
                        <span  onClick={() => setCol2(prev => !prev)}
                          style={{ cursor: "pointer" }} className="text-dark">
                          Put stock back <i className="bx bx-add-to-queue text-success ml-3" />
                        </span>
                      </h6>
                    </CardHeader>
                    <Collapse isOpen={col2}>
                      <Card>
                        <CardBody>
                          <StockInput stock={stockPush} setStock={setStockPush} productData={productData} />
                        </CardBody>
                      </Card>
                    </Collapse>
                  </Card>
                </div>
              <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 12}}>
                <span />
                {(productData && (stockPull.length > 0 || stockPush.length > 0)) && (
                  <Button onClick={togglemodal} color="primary" className="btn-lg btn-rounded">
                    Submit
                  </Button>
                ) }
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" m="4" lg="4" xl="4">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">STOCK SUMMARY</h4>
              <br />
              {
                productData && stockPull.length > 0 && 'Removing:'
              }
              {
                productData && stockPull.map((line, lineKey) => {
                  return <div>  <span className="text-danger">-{line['quantity']}</span> x {line['product'] && line['variant'] && productData.find(val => val._id === line['product']).variants.find(val => val._id === line['variant'])?.sku} </div>
                })
              }
              <br />
              {
                productData && stockPush.length > 0 && 'Adding:'
              }
              {
                productData && stockPush.map((line, lineKey) => {
                  return <div> <span className="text-success">+{line['quantity']}</span> x {line['product'] && line['variant'] && productData.find(val => val._id === line['product']).variants.find(val => val._id === line['variant'])?.sku} </div>
                })
              }
            </CardBody>
          </Card> 
        </Col>
      </Row>
    </Container>
    <Modal isOpen={modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" toggle={togglemodal}>
      <CardBody>
        <h4 className="card-title mb-4">STOCK SUMMARY</h4>
        <br />
        {
          productData && stockPull.length > 0 && 'Removing:'
        }
        {
          productData && stockPull.map((line, lineKey) => {
            return <div>  <span className="text-danger">-{line['quantity']}</span> x {line['product'] && line['variant'] && productData.find(val => val._id === line['product']).variants.find(val => val._id === line['variant'])?.sku} </div>
          })
        }
        <br />
        {
          productData && stockPush.length > 0 && 'Adding:'
        }
        {
          productData && stockPush.map((line, lineKey) => {
            return <div> <span className="text-success">+{line['quantity']}</span> x {line['product'] && line['variant'] && productData.find(val => val._id === line['product']).variants.find(val => val._id === line['variant'])?.sku} </div>
          })
        }
      </CardBody>
      <ModalFooter>
        {
          loading ? (
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <Button onClick={handleSubmit} color="primary" className="btn-lg btn-rounded">
              Confirm
            </Button>
          )
        }
      </ModalFooter>
    </Modal>
  </div>
}

export default PullPage