import React from "react";
import { Route, Redirect } from "react-router-dom";

const AppRoute = ({
	history,
	component: Component,
	layout: Layout,
	isAuthProtected,
	...rest
}) => (
		<Route
			{...rest}
			render={props => {

				if (isAuthProtected && !localStorage.getItem("authJWT")) {
					return (
						<Redirect
							to={{
								pathname: "/login",
								state: {
									from: rest.location.pathname
								}
							}}
						/>
					);
				}

				return (
					<Layout history={history}>
						<Component {...props} />
					</Layout>
				);
			}}
		/>
	);

export default AppRoute;

