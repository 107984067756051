import React, { useState, useEffect } from 'react';
import { ModalBody, ModalFooter, Input, Button } from 'reactstrap'; // Assuming you are using Reactstrap for styling
import WizardNav from './WizardNav'; // Import WizardNav component if it exists
import superagent from 'superagent'; // Make sure to import superagent or replace it with your preferred HTTP client
import { backendUrl } from '../../../../helpers/consts';

const OrderWizardFlaggedModalContent = ({
  options,
  loading,
  selectedFlagType,
  handleFlagLineItemChanged,
  updateLineItem,
  order,
  handleFlag,
}) => (
  <div>
    <WizardNav steps={['Select a flag type']} activeTabProgress={1} />
    {loading ? (
      <div>
        <div className="modal-content">
          <ModalBody>
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </ModalBody>
        </div>
      </div>
    ) : (
      <div>
        <div>
          <ModalBody>
            {order.flagged === false ? (
              <Input
                onChange={(e) => handleFlagLineItemChanged(e)}
                value={selectedFlagType}
                type="select"
                name="select"
                id="exampleSelect"
              >
                {options.map((option) => (
                  <option
                    key={option?._id}
                    value={option?._id === undefined ? options[0]?._id : option?._id}
                  >
                    {option?.name}
                  </option>
                ))}
              </Input>
            ) : (
              <Input
                onChange={(e) => handleFlagLineItemChanged(e)}
                value={selectedFlagType}
                type="select"
                name="select"
                id="exampleSelect"
                disabled
              >
                {options.map((option) => (
                  <option
                    key={option?._id}
                    value={option?._id === undefined ? options[0]?._id : option?._id}
                  >
                    {option?.name}
                  </option>
                ))}
              </Input>
            )}
          </ModalBody>
          <ModalFooter>
            {order.flagged === true ? (
              <Button color="success" onClick={(e) => handleFlag(e)}>
                Unflag
              </Button>
            ) : (
              <Button color="success" onClick={updateLineItem}>
                Add
              </Button>
            )}
            <br />
          </ModalFooter>
        </div>
      </div>
    )}
  </div>
);

const OrderWizardFlaggedModal = ({ togglemodal, refresh, orderID, order }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFlagType, setSelectedFlagType] = useState();

  const handleFlag = (e) => {
    e.preventDefault();
    setLoading(true);
    superagent.get(`${backendUrl}/orders/${orderID}/getFlagged`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then(response => {
        const code = response.status;
        if (code > 400) {
          // Handle error
        } else {
          refresh();
          setLoading(false);
          togglemodal();
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    superagent.get(`${backendUrl}/orders/getFlagTypes`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then(response => {
        const code = response.status;
        if (code > 400) {
          // Handle error
        } else {
          setOptions(response.body);
          setSelectedFlagType(response.body.data._id);
          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleFlagLineItemChanged = (e) => {
    const option = e.target.value;
    setSelectedFlagType(option);
  };

  const updateLineItem = () => {
    let newLineItem = {
      orderID: orderID,
      flagTypeId: selectedFlagType !== undefined ? selectedFlagType : options[0]?._id,
    };

    setLoading(true);
    superagent.post(`${backendUrl}/orders/${orderID}/setFlagged`)
      .send(newLineItem)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then(response => {
        const code = response.status;
        if (code > 400) {
          // Handle error
        } else {
          refresh();
          togglemodal();
          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <OrderWizardFlaggedModalContent
      options={options}
      loading={loading}
      selectedFlagType={selectedFlagType}
      handleFlagLineItemChanged={handleFlagLineItemChanged}
      updateLineItem={updateLineItem}
      order={order}
      handleFlag={handleFlag}
    />
  );
};

export default OrderWizardFlaggedModal;
