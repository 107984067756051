import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { FormGroup, Container, Input, Button, Table, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink } from "reactstrap";

import { CSVLink, CSVDownload } from "react-csv";

import moment from 'moment';
import Select from 'react-select';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "./datatables.scss";
import useQuery from '../../helpers/JIFI/useQuery';

const perPageOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
]

const fulfillmentToBadge = (fulfillment) => {
  if (fulfillment === 'unfulfilled') return 'warning'
  const conversion = {
    'fulfilled': 'success',
    'partially_fulfilled': 'error',
  }
  return conversion[fulfillment]
} 

const pickToBadge = (pick) => {
  const conversion = {
    'none': 'warning',
    'monogram': 'info',
    'fulfillment': 'success',
  }
  return conversion[pick] || 'success'
}

const pickToName = (pick) => {
  const conversion = {
    'none': 'UNPICKED',
    'monogram': 'TO-MONOGRAM',
    'fulfillment': 'TO-SHIP',
  }
  return conversion[pick] || pick
}


const paginate = function (array, index) {
  return [
    array[index - 2],
    array[index - 1],
    array[index],
    array[index + 1],
    array[index + 2]
  ].filter(function (el) {
    return el != null;
  });
}

const TablePagination = ({ count, page, setPage, perPage, setPerPage }) => {
  const pageCount = Math.ceil(count / perPage)
  const pages = paginate(Array.from({ length: pageCount }, (v, i) => i + 1), page)
  const handleNumberClicked = (key) => {
    setPage(key)
  }
  return (
    <Pagination className="pagination pagination-rounded justify-content-end mb-2">
      <div style={{ height: '100%', width: 140 }}>
        <Select
          options={perPageOptions}
          onChange={newPerPage => setPerPage(newPerPage.value)}
          value={perPageOptions.find(val => val.value === perPage)}
        />
      </div>
      <PaginationItem disabled>
        <PaginationLink previous />
      </PaginationItem>
      {
        pages.map(val => (
          <PaginationItem onClick={() => handleNumberClicked(val)} active={val === page}>
            <PaginationLink>
              {val}
            </PaginationLink>
          </PaginationItem>
        ))
      }
      <PaginationItem>
        <PaginationLink next />
      </PaginationItem>
    </Pagination>
  )
}

const OrdersPage = () => {
  const initialState = {
    orderStartDate: '',
    filterStartDate: '',
    orderEndDate: '',
    filterEndDate: '',
  }
  const [stateData, setStateData] = useState(initialState);

  const handleChange = (e) => {
    e.persist();
    setStateData((prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        filterStartDate: e.target.name === 'orderStartDate' ? moment(e.target.value).format('YYYY-MM-DD') : prevState.filterStartDate,
        filterEndDate: e.target.name === 'orderEndDate' ? moment(e.target.value).format('YYYY-MM-DD') : prevState.filterEndDate
      }
    }))
  };

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
      setRefresh(prev => !prev)
  }

  const [data, state] = useQuery({ refresh, url: '/orders/embroidery-summary/', queryData: { page: page, perPage: perPage, filterStartDate: stateData.filterStartDate, filterEndDate: stateData.filterEndDate }});
  const summary = data && data.summary;
  const count = 0

  const { orderStartDate, orderEndDate } = stateData;

  const dateString = `${orderStartDate}-${orderEndDate}`

  let merged = []
  if (summary) {
    merged = summary.map((sum, key) => {
      return [
        sum.order?.name,
        sum.lineItem?.sku,
        sum.lineItem.monogram_text,
        sum.lineItem.monogram_font,
        sum.lineItem.monogram_color,
        (new Date(sum.lineItem.monogram_state_updated)).toDateString(),
        sum.lineItem.fulfillment_status
      ]
    })
  }

  const csvData = [
    ['Order ID', 'SKU', 'Monogram Text', 'Monogram Font', 'Monogram Color', 'Date', 'State'],
    ...merged
]

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Stock" breadcrumbItem="Embroidery Summary" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Col>
                    <Row>
                      <Col sm="5">
                        <FormGroup className="select2-container">
                          <Label className="control-label">STARTING</Label>
                          <input className="form-control" name="orderStartDate" value={orderStartDate} onChange={handleChange} type="date" defaultValue="2019-08-19" id="example-date-input" />
                        </FormGroup>
                      </Col>
                      <Col sm="5">
                        <FormGroup className="select2-container">
                          <Label className="control-label">ENDING</Label>
                          <input className="form-control" name="orderEndDate" value={orderEndDate} onChange={handleChange} type="date" defaultValue="2019-08-19" id="example-date-input" />
                        </FormGroup>
                      </Col>
                      <Col sm="2" style={{display:'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 8}}>
                        <CSVLink 
                          data={csvData}
                          filename={`EMBROIDERY_${dateString}.csv`}
                        >
                          <Button type="button" color="secondary" className="btn-lg btn-rounded">
                          CSV
                          </Button>
                        </CSVLink>
                      </Col>
                    </Row>
                  </Col>
                  <div className="table-responsive">
                    <Table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th>Order ID</th>
                          <th>SKU</th>
                          <th>Monogram Text</th>
                          <th>Monogram Font</th>
                          <th>Monogram Color</th>
                          <th>Date</th>
                          <th>State</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          state === 'success' && (
                            summary && summary.map((sum, key) => (
                              <tr key={"_embroidery_" + key}>
                                <td>
                                  <Link to={`/orders/${sum.order?._id}`}>{sum.order?.name}</Link>
                                </td>
                                <td>
                                  {sum.lineItem.sku}
                                </td>
                                <td>
                                  {sum.lineItem.monogram_text}
                                </td>
                                <td>
                                  {sum.lineItem.monogram_font}
                                </td>
                                <td>
                                  {sum.lineItem.monogram_color}
                                </td>
                                <td>
                                  {(new Date(sum.lineItem.monogram_state_updated)).toDateString()}
                                </td>
                                <td>
                                  <Badge className={"font-size-12 badge-soft-" + fulfillmentToBadge(sum.lineItem.fulfillment_status)} color={fulfillmentToBadge(sum.lineItem.fulfillment_status)} pill>
                                    {sum.lineItem.fulfillment_status ? sum.lineItem.fulfillment_status.replace('_', ' ').toUpperCase() : 'UN-FULFILLED'}
                                  </Badge>
                                </td>
                              </tr>
                            ))
                          )
                        }
                      </tbody>
                    </Table>
                  </div>
                  {
                    (summary && state === 'success') ? (
                      <TablePagination count={count} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
                    ) : (
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                          <div className="spinner-grow text-primary m-1" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>   
    </>
  );
}


export default OrdersPage;


