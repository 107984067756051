import React, {useState, useEffect} from 'react'
import superagent from 'superagent'
import {backendUrl} from '../../../helpers/consts.js'

// const SkuToName = (sku) => {
//   return productData.find(val => val.sku === sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-'))?.name + (sku.includes('-P') ? ' Personalised' : '')
// }

const useProductData = () => {
  const [variantData, setVariantData] = useState()
  useEffect(() => {
    superagent.get(`${backendUrl}/product/variantList`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          const variantObject = {}
          response.body.map(val => variantObject[val.sku] = val)
          setVariantData(variantObject)
        }
      }).catch(err => {
        console.log(err)
      });
  }, [])

  const getVariant = (sku = '') => {
    const updatedSKU = sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')
    if(variantData === undefined || variantData[updatedSKU] === undefined){
      return {}
    }
    let variant = {...variantData[updatedSKU]}
    const rootSku = sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')
    if(variant !== undefined) {
      variant['name'] = variant['name'] + (rootSku !== sku ? ' Personalised' : '')
    }
    
    return variant
  }

  return getVariant
}

export default useProductData