import React from 'react'
import { skuToProduct, caseColors, newColors, colors, fonts, lineHeights, locations, skuToColor } from '../constants'
import Word from '../../../components/Personalisation/font'
import Logo from '../svgs/july_black.svg'

var QRCode = require('qrcode.react');

const displaySize = {
    height: 96,
    width: 400,
    padding: {
        top: 8,
        bottom: 8,
        left: 0,
        right: 0
    }
}

Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        this.getFullYear(),
    ].join(' / ');
};

class PrintPage extends React.Component {
    render() {
        return (
            <div className="Labels">
                <PrintPageFunc monogramRecord={this.props.monogramRecord} />
            </div>
        )
    }
}

const PrintPageFunc = ({ monogramRecord }) => {
    // console.log(monogramRecord)
    let printLabelID = ''
    return (
        <div className="PrintCanvas">
            <div className="PrintLine"> {monogramRecord.batchID} </div>

            <div className="PrintTable" >
                {
                    monogramRecord && monogramRecord.lineItems.map(lineItem => {
                        // console.log("lineItem", lineItem)
                        if(lineItem.orderName.includes('#GETJULY')) {
                            printLabelID = lineItem.monogramID.replace('#GETJULY', '');
                        } else if(lineItem.orderName.includes('#JULYNZ')) {
                            printLabelID =lineItem.monogramID.replace('#JULYNZ', '');
                        } else if(lineItem.orderName.includes('#JULY')) {
                            printLabelID = lineItem.monogramID.replace('#JULY', '');
                        }
                        return (
                            <div className="PrintTableRowFlex">
                                <div style={{ fontSize: 22, padding: '8px 12px' }} className="PrintTableRowHalfBlack"> {printLabelID} </div>
                                <div style={{ fontSize: 22, padding: '8px 12px' }} className="PrintTableRowHalfBlack"> {(new Date(lineItem.orderSent)).yyyymmdd()} </div>
                                <div style={{ fontSize: 22, padding: '8px 12px' }} className="PrintTableRowHalfBlack"> {lineItem.sku} </div>
                                <div style={{ fontSize: 22, padding: '8px 12px' }} className="PrintTableRowHalf"> {lineItem.monogram_text} </div>
                            </div>
                        )
                    })
                }
            </div>

            <img style={{ right: 'calc(32px + 20mm)', bottom: 0, position: 'absolute', width: '20%' }} src='/logo.svg' />
        </div>
    )
}


export default PrintPage