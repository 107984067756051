import axios from 'axios';
import superagent from 'superagent'

import {backendUrl} from '../../helpers/consts.js'
// import {backendUrl} from '../../helpers/consts.js'

// Gets the logged in user data from local session 
const getLoggedInUser = () => {
    const user = localStorage.getItem('user');
    if (user)
        return JSON.parse(user);
    return null;
}

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
}

// Register Method
const postRegister = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        var message;
        if (err.response && err.response.status ) {
            switch (err.response.status) {
                case 404: message = "Sorry! the page you are looking for could not be found"; break;
                case 500: message = "Sorry! something went wrong, please contact our support team"; break;
                case 401: message = "Invalid credentials"; break;
                default: message = err[1]; break;
            }
        }
        throw message;
    });

}

// Login Method
const postLogin = (data) => {
  return superagent.post(`${backendUrl}/auth/`)
    .send(data)
    .set('accept', 'json')
    .then(response => {
      if (response.status === 400 || response.status === 401 || response.status === 500) {
        throw response.body;
      }
        
      return response.body;
    }).catch(err => {
        return {
          error: err
        }
    });

  // return axios.post(`${backendUrl}/auth/`, data).then(response => {
    
  //   if (response.status === 400 || response.status === 500)
  //     throw response.data;
  //   return response.data;
  // }).catch(err => {
  //     throw err[1];
  // });
}

const refreshLogin = async () => {    
    const user = JSON.parse(localStorage.getItem('authUser'))
    if (!user || !localStorage.getItem("refreshJWT")) return 

    return new Promise((res, rej) => {
        superagent.post(`${backendUrl}/auth/refresh`)
            .send({
                token: localStorage.getItem("refreshJWT"),
                email: user?.email
            })
            .set('Authorization', `Bearer ${localStorage.getItem("refreshJWT")}`)
            .set('accept', 'json')
            .then(response => {
                if (response.status === 400 || response.status === 500)
                    rej(response.body)
        
                localStorage.setItem("authJWT", response.body.token);
                localStorage.setItem("authUser", JSON.stringify(response.body.user));
                res()
            }).catch(err => {
                rej(err)
            });
    })
  }

// postForgetPwd 
const postForgetPwd = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}

export { getLoggedInUser, isUserAuthenticated, postRegister, postLogin, postForgetPwd, refreshLogin }