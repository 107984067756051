export const skuToProduct = (sku) => {
    if(sku) {
      if (sku.includes('CAR-WKDP')){
        return 'Weekender Plus'
      }

      if(sku.includes('COV-TRK')) {
        const skuSub = sku.substring(0,8)
        const longNames = {
          'COV-TRKS': 'Carry On Checked Cover',
          'COV-TRKL': 'Trunk Checked Cover',
        }
        return longNames[skuSub]
      } else if(sku.includes('DBK') || sku.includes('CAR') || sku.includes('TRK') || sku.includes('BOT') || sku.includes('SET')) {
        const skuSub = sku.substring(0,7)
        const longNames = {
          'CAR-POC': 'Pocket',
          'CAR-SLI': 'Sling',
          'CAR-DPK': 'Daypack',
          'CAR-TOT': 'Tote',
          'CAR-BPK': 'Backpack',
          'CAR-WKD': 'Weekender',
          'DBK-TOT': 'Daybreak Tote',
          'DBK-BPK': 'Daybreak Backpack',
          'DBK-WKD': 'Daybreak Weekender',

          'TRK-COP': 'Carry On Trunk',
          'TRK-CHK': 'Checked Trunk',
          'BOT-LAR': 'Bottle 750ml',
          'BOT-SML': 'Bottle 500ml',

          'SET-CHK': 'Carry on + Checked Set',
          'SET-PLS': 'Carry on + Plus Set',
          'SET-FML': 'Family Set',
          'SET-TRK': 'Trunk Set',
        }
        return longNames[skuSub]
      } else {
        var start = sku.split("-")[0].slice(0,3);
        const names = {
          'IPC': 'iPhone Case',
          'COP': 'Carry On',
          'PRO': 'Carry On Pro',
          'CHK': 'Checked',
          'PLS': 'Checked Plus',
          'CME': 'Carry ME',
          'TOT': 'Tote',
          'WKD': 'Weekender',
          'BPK': 'Backpack',
          '8PC': '8 Packing Cells',
          '4PC': '4 Packing Cells',
          'PB0': 'Power Bank',
          'LGT': 'Carry On Light',
          'NBK': 'Notebook',
          'TEC': 'Tech Kit',
        }
        return names[start]
      }
    }
    return ''
}

const isCarryAll = (sku) => {
  if(sku) {
    var start = sku.split("-")[0].slice(0,3);
    const names = {
      'IPC': false,
      'LGT': false,
      'COP': false,
      'CHK': false,
      'PLS': false,
      'CME': false,
      'TOT': true,
      'WKD': true,
      'BPK': true,
      '8PC': false,
      '4PC': false,
    }
    return names[start]
  }
  return false
}

export const caseColors = {
    'NAV': '#002551',
    'BUR': '#d71f27',
    'NUD': '#d8a68b',
    'CHA': '#000000',
    'FOR': '#084221',
    'MON': '#000000'
}

export const newColors = {
  'Lavender': '#90759B',
  'Lilac': '#DCC5DF',
  'Ballet-Pink': '#F1C9DE',
  'Rose-Pink': '#DC75A4',
  'Grenaine': '#9E5360',
  'Blush-Pink': '#E77472',
  'Magenta': '#B12D52',
  'Maroon': '#731A29',
  'Red': '#C92327',
  'Burnt-Orange': '#DC5527',
  'Mango': '#EF7E2E',
  'Rust': '#9B4827',
  'Toffee': '#705037',
  'Taupe': '#D3A289',
  'Butter-Yellow': '#FBF3BE',
  'Yellow': '#E8B629',
  'Lime': '#C6B937',
  'Green': '#4A813C',
  'Forest-Green': '#034123',
  'Mint': '#78AB93',
  'Ice-Blue': '#BEE3E9',
  'Denim': '#75A6B3',
  'Ocean-Blue': '#3E8EA1',
  'Blue': '#1466B2',
  'Grey': '#95979E',
  'Slate-Grey': '#63727E',
  'Black': '#000000',
  White: '#FFFFFF',
}


export const colors = {
    'Cream':    '#FAD6BC', //CREAM
    'Pink':     '#BC2C55', //Pink
    'Coral':    '#F4827A', //Coral
    'Red':      '#DA372B', //Red
    'Yellow':   '#feed67', //Yellow
    'Lime':     '#959D38', //Lime
    'Blue':     '#118AA4', //Blue
    'White':    '#FFFFFF', //White
    'Grey':     '#A6A9AE', //Grey
    'Black':    '#000000', //Black
}
export const fonts = {
  'heritage': 'Heritage',
  'bold-new': 'Bold (new)',
  'bubble': 'Bubble',
  'retro-serif': 'Retro Serif',
  'graphic': 'Graphic',
  'sans-serif': 'Sans Serif',
  block:  'Block',
  signature: 'Signature',
  retro: 'Retro',
  serif: 'Serif',
  decorative: 'Decorative',

  painted: 'Painted',
  college: 'College',
  script: 'Script',
  casual: 'Casual',
  wide: 'Wide',
  bold: 'Block Serif',
  cursive: 'Modern Script',
  modern: 'Modern Serif',
  'zh-ser': 'Chinese Serif',
  'zh-san': 'Chinese Sans Serif',
  'zh-block': 'Chinese Block',
  'zh-script': 'Chinese Script',
  'zh-casual': 'Chinese Casual',
}

export const lineHeights = {
    block: 105,
    script: 184,
    casual: 184,
    wide: 184,
    serif: 184,
    'zh-ser': 184,
    'zh-san': 184,
}
export const locations = [
    'Top',
    'Side',
    'Back'
]

export const skuToColor = (sku) => {
  if (!sku) return ''
  const colorAbreviation = sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-').split("-").pop().replace(/[0-9]/g, '')
  const names = {
    'NAV': 'NAVY',
    'CHA': 'CHARCOAL',
    'FOR': 'FOREST',
    'NUD': isCarryAll(sku) ? 'CHALK' : 'SAND',
    'BUR': 'BURGUNDY',
    'MON': 'MONOCHROME',
    'CLY': 'CLAY',
    'CLA': 'CLAY',
    'SKY': 'SKY',
    'SHW': 'SHADOW',
    'SHA': 'SHADOW',
    'BLA': 'Midnight Black',
    'TAU': 'Natural Taupe',
    'CHK': 'Chalk White',
    'SNO': 'Snow',
    'SAN': 'Sand',
    'LGR': 'Light Grey',

    'GRY': 'Stone',
    'OLI': 'Moss',
    'PIN': 'Rose',

    'SAP': 'Sapphire Blue',
    'HYD': 'Hydrangea Blue',
    'RED': 'Scarlet Red',
    'GRE': 'Myrtle Green',
    
    
  }
  return names[colorAbreviation]
}