import React from 'react'
import FontFile from '../shared/font-file'


const Word = (props) => { 
  return <FontFile 
            font={'bubble'} 
            topOffset='0.15em'
            multiplier={1}
            {...props}
            borderColor={props.backCol} 
          />
}

export default Word