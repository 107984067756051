import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Col, Card, CardBody, UncontrolledTooltip, Media, Badge } from "reactstrap";


const ProductCard = ({product, key}) => {
  return (
    <Col xl="4" sm="6" key={"_product_" + key} >
      <Link to={`/product/edit/${product._id}`}>
        <Card>
          <CardBody>
            <Media>
              <div className="avatar-md mr-4">
                  <span className="rounded-circle d-block shadow-sm overflow-hidden p-3">
                      <img className="w-100 h-100" src={product.image} alt="" height="30" />
                  </span>
              </div>

              <Media className="overflow-hidden" body>
                <h5 className="text-truncate font-size-15"><Link to={`/product/edit/${product._id}`} className="text-dark">{product.name}</Link></h5>
                <p className="text-muted mb-4">{product.description}</p>

                <div className="team">
                  {
                    product.variants.map((variant, key) =>
                      variant.image !== "Null"
                        ?
                        <Link to={`/product/edit/${product._id}/variant/edit/${variant._id}`} className="team-member d-inline-block" id={"member" + variant._id} key={"_team_" + key}>
                          <img src={variant.image} className="rounded-circle avatar-sm m-1" alt="" />
                          <UncontrolledTooltip placement="top" target={"member" + variant._id}>
                            {variant.sku}
                          </UncontrolledTooltip>
                        </Link>
                        :
                        <Link to={`/product/edit/${product._id}/variant/edit/${variant._id}`} className="team-member d-inline-block" id={"member" + variant._id} key={"_team_" + key}>
                          <div className="avatar-sm m-1">
                              <span className={"avatar-title rounded-circle bg-soft-" + variant.color + " text-" + variant.color + " font-size-16"}>
                                {variant.name.charAt(0)}
                              </span>
                              <UncontrolledTooltip placement="top" target={"member" + variant._id}>
                                {variant.sku}
                              </UncontrolledTooltip>
                          </div>
                        </Link>
                    )
                  }
                </div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Link>
    </Col>
  )
}


export default ProductCard;