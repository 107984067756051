import React, { useState, useEffect } from 'react';
import { Card, CardBody, Media, Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import { arrayNotNull } from "../../../utilities/utilities";
import AddComment from './AddCommentPending'
import { MentionText } from './CommentHistory';


const CommentHostory = ({initCommentType, initOrderName, title, comments, setComments}) => {
    const [orderName, setOrderName] = useState(initOrderName ? initOrderName.replace('#', '') : '');

    useEffect(() => {
        setOrderName(initOrderName ? initOrderName.replace('#', '') : '')
    }, [initOrderName])

    const [refr, setRefresh] = useState(false);
    const refresh = () => setRefresh(prev => !prev)

    const deleteComment = (id) => {
        setComments(prev => prev.filter((comment, key) => key !== id))
    }
    const addComment = (comment) => {
        setComments(prev => [...prev, comment])
    }

    const Body = () => {
        return (
            <div className="mt-4">
                <h4 className="card-title mb-4">{title ? title : 'Notifications'}</h4>
                <SimpleBar style={{ maxHeight: "700px" }}>
                    <ul className="list-group">
                        {(arrayNotNull(comments) && comments.map((comment, key) => {
                            
                            let isMe = true
                            return (
                                <li key={`_comment_${key}`} className="list-group-item">
                                    <Media>
                                        {/* <div className="avatar-xs mr-3">
                                            <span className="avatar-title rounded-circle bg-light">
                                                <img src={comment.user_model?.profile_image || '/logo-j.svg'} alt="" height="32" width="32" style={comment.user_model?.profile_image ? {objectFit: 'cover', width: 32, height: 32}: {width: 16, height: 16, margin: 8}} className="rounded-circle"/>
                                            </span>
                                        </div> */}
                                        <Media body style={{whiteSpace: 'pre-wrap'}}>
                                            <h5 className="font-size-14"></h5>
                                            {/* {
                                                comment.description.split('\n').map((item, i) => (<p className="text-muted" key={i}>{item}</p>))
                                            } */}
                                            {
                                                (arrayNotNull(comment.files) &&  (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(comment.files[0]) === false) ? (
                                                    <p className="text-muted"><a target="blank" href={comment.files[0]}> {comment.description || 'Attached file'} </a> </p>
                                                ) : (
                                                    <MentionText text={comment.description} />
                                                )
                                            }
                                            
                                            {arrayNotNull(comment.files) ?
                                                <div>
                                                    {
                                                        comment.files.map((img, index) => {
                                                            return (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(img) ? <img src={img} alt="" width="100%" key={index} /> : <></>
                                                        })
                                                    }
                                                </div> : null}
                                            {/* <div className="float-right">
                                                <p className="text-muted mb-0"> {getTimeInterval(new Date(comment.comment_date))}  </p>
                                            </div> */}
                                            {/* <p className="text-muted mb-0"><i className="mdi mdi-account mr-1"></i>{comment.user_model?.userName || comment.userName}</p> */}
                                        </Media>
                                        {
                                            isMe && <Button onClick={() => deleteComment(key)} style={{marginLeft: 12}} close />
                                        }
                                    </Media>
                                </li>
                            )
                        }))
                        }
                    </ul>
                </SimpleBar>
                <br/>
                 <AddComment orderName={orderName} addComment={addComment} refresh={refresh}/>
            </div>
        )
    }
    if (initCommentType === 'Dashboard') {
        return <Body />
    }
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Body />
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default CommentHostory;
