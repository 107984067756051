import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { FormGroup, Container, Input, Button, Table, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useUrlState } from "../../../helpers/useUrlState";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import moment from 'moment';
import useQuery from "../../../helpers/JIFI/useQuery";
import ReactSelect from "react-select";
import { CSVLink, CSVDownload } from "react-csv";


function calculateStandardDeviation(values) {
  // Calculate the mean
  const mean = values.reduce((acc, val) => acc + val, 0) / values.length;

  // Calculate the variance
  const variance = values.reduce((acc, val) => acc + (val - mean) ** 2, 0) / values.length;

  // Calculate the standard deviation
  return Math.sqrt(variance);
}

function generateMonthYearStrings(startYear, endYear) {
  const startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
  const endDate = moment().add(1, 'day').format('YYYY-MM-DD');;

  const startOfYear = startDate ? moment.tz(`${startDate} 00:00`, "Australia/Melbourne").startOf('day').tz("Etc/GMT") : null
  const endOfYear = endDate ? moment.tz(`${endDate} 12:00`, "Australia/Melbourne").endOf('day').tz("Etc/GMT") : null

  const months = [];

  while (startOfYear.isBefore(endOfYear)) {
    months.push(startOfYear.format('YYYY-MM'));
    startOfYear.add(1, 'month');
  }

  // Include the last month of the range
  months.push(endOfYear.format('YYYY-MM'));

  return [...new Set(months)];
}


const StockStatus = () => {
  const initialState = {
    region: 'OnlineAU'
  }
  const [stateData, setStateData] = useUrlState(initialState);
  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
    setRefresh(prev => !prev)
  }

  const { orderStartDate, orderEndDate, region } = stateData;

  const [stockStatus, stockStatusState] = useQuery({
    url: `/demand/search-stock-status-month`,
    refresh,
    queryData: {
      region
    }
  })

  const [velocityType, setVelocityType] = useState('Daily Velocity')


  const [searchTerm, setSearchTerm] = useState('')
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [parseProducts, setParsedProducts] = useState([])
  const fullSelectedProduct = parseProducts.find(product => product.id === selectedProduct?.value)
  const productOptions = parseProducts.map(product => ({ value: product.id, label: product.name }))
  const [products, productState] = useQuery({
    url: `/product/findAll`,
    refresh,
  });

  useEffect(() => {
    if (products && productState === 'success') {
      let tempProd = products?.data?.map(product => {
        let variants = []
        product.variants.map(variant => {
          variants.push(variant.sku)
        })
        return {
          id: product._id,
          name: product.name,
          variants,
          variantsFull: product.variants,
          category: product.category
        }
      }) || []

      tempProd = tempProd.sort((a, b) => {
        // Compare by category
        if (a.category < b.category) return -1;
        if (a.category > b.category) return 1;

        // If categories are equal, compare by name
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        return 0;
      });

      setParsedProducts(tempProd)
    }
  }, [products])

  const loadingState = stockStatusState === 'success' && productState === 'success'

  const regeionOptions = [
    'OnlineAU',
    'OnlineUS',
    'OnlineUK',
    'AllRetail',
    'RetailPacfair',
    'RetailChadstone',
    'RetailCollingwood',
    'RetailGaleries',
    'RetailNy',
    'RetailQv',
    'Retail80Collins',
    'RetailBondi',
    'RetailPitt',
    'RetailJames'
  
  ]
  const setRegion = (val) => {
    setStateData(prev => ({
      ...prev,
      region: val
    }))
  }

  const allMonthStrings = generateMonthYearStrings()

  const parsedLines = stockStatus?.sort((a, b) => a._id - b._id).map(status => {
    if (status._id === '' || status._id === '.') {
      return undefined
    }
    if (fullSelectedProduct && !fullSelectedProduct.variants.includes(status._id)) {
      return undefined
    }
    if (searchTerm !== '' && !status._id.toUpperCase().includes(searchTerm.toUpperCase())) {
      return undefined
    }
    if (status.salesVelocity === undefined || status.salesVelocity.filter(val => val).length === 0) {
      return undefined
    }
    
    const product = parseProducts?.find(product => product.variants.includes(status._id))
    const fullVariant = product?.variantsFull.find(variant => variant.sku === status._id)

    console.log(status)

    const groupedMonthCounts = status.salesVelocity.map((val, i) => {
      if (velocityType === 'Daily Velocity') {
        return {
          count: Math.round(val / status.numDays[i] * 100) / 100,
          month: status.monthYear[i]
        }
      } else {
        return {
          count: Math.round(val),
          month: status.monthYear[i]
        }
      }
      
    })

    const allMonths = allMonthStrings.map(val => {
      const month = groupedMonthCounts.find(month => month.month === val)
      return {
        count: month?.count || 0,
        month: val
      }
    })

    return {
      sku: status._id,
      region: status.region,
      monthCounts: allMonths,
      stdDev: calculateStandardDeviation(status.salesVelocityRaw.flat()),
      productCategory: product?.category,
      productName: product?.name, 
      price: (fullVariant?.shopifyData || {})[region.replace('Online', '')]?.price,
    }
  }).flat().filter(val => val).sort((a, b) => {
    // First compare by productCategory
    const categoryComparison = a?.productCategory?.localeCompare(b?.productCategory);
    if (categoryComparison !== 0) {
      return categoryComparison;
    }
  
    // If productCategory is the same, then compare by sku
    return a?.sku?.localeCompare(b?.sku);
  });


  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Fulfillment" breadcrumbItem="Stock Status" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="12">
                      <Col>
                        <Row>
                          <Col sm="10" style={{paddingBottom: 8}}>
                            <div className="app-search d-none d-lg-block">
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..." 
                                  value={searchTerm} 
                                  onChange={(e) => setSearchTerm(e.target.value)} 
                                />
                                <span className="bx bx-search-alt"></span>
                              </div>
                            </div>
                          </Col>
                          {
                            loadingState && (
                              <Col sm="2" style={{padding: 16}}>
                                <CSVLink 
                                  style={{float: 'right'}}
                                  data={parsedLines.map(line => ({
                                    Category: line.productCategory,
                                    Product: line.productName,
                                    SKU: line.sku,
                                    Price: line.price,
                                    Store: line.region,
                                    StdDev: line.stdDev,
                                    ...line.monthCounts.reduce((acc, month) => ({...acc, [month.month]: month.count}), {})
                                  }))}
                                  filename={`STOCK_STATUS_${moment().format('YYYY-MM-DD')}.csv`}
                                >
                                  <Button type="button" color="secondary" className="btn-lg btn-rounded">
                                    Export CSV
                                  </Button>
                                </CSVLink>
                              </Col>
                            )
                          }

                        </Row>
                        <Row>
                          <Col sm="3">
                            <FormGroup className="select2-container">
                              <Label className="control-label">Region</Label>
                              <ReactSelect
                                options={regeionOptions.map(val => ({ value: val, label: val }))}
                                onChange={val => setRegion(val.value)}
                                value={{ value: region, label: region }}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="3">
                            <FormGroup className="select2-container">
                              <Label className="control-label">Product</Label>
                              <ReactSelect
                                options={productOptions}
                                onChange={setSelectedProduct}
                                value={selectedProduct}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="3">
                            <FormGroup className="select2-container">
                              <Label className="control-label">Velocity Type</Label>
                              <ReactSelect
                                options={['Daily Velocity', 'Total Count'].map(val => ({value: val, label: val}))}
                                onChange={(e) => setVelocityType(e.value)}
                                value={{value: velocityType, label: velocityType}}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <Table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th>Category</th>
                          <th>Product</th>
                          <th>SKU</th>
                          <th>Price</th>
                          <th>Store</th>
                          <th>Std Dev</th>
                          {
                            allMonthStrings.map((month, i) => (
                              <th key={"_month_" + i}>{month}</th>
                            ))
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          loadingState ? (
                            parsedLines?.map((status, i) => {
                              return <tr className="py-8" style={{ padding: '16px 0' }} key={"_line_" + `${i}_` + `${status.sku}`}>
                                <td> {status.productCategory} </td>
                                <td> {status.productName} </td>
                                <td> {status.sku} </td>
                                <td> {status.price} </td>
                                <td> {status.region} </td>
                                <td> {status.stdDev} </td>
                                {
                                  status.monthCounts.map((month, i) => {
                                    return (
                                      <td key={"_month_" + `${i}_` + `${status.sku}`}>
                                        {month.count === 0 ? '' : month.count}
                                      </td>
                                    )
                                  })
                                }
                              </tr>
                            })
                          ) : (
                            (
                              [...Array(10)].map((_, i) => (
                                <tr className="py-8" style={{ padding: '16px 0' }} key={"_line_loading_" + i}>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                </tr>
                              ))
                            )
                          )
                        }
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default StockStatus;