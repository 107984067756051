import React from 'react';
import { Col, Card, CardHeader, CardBody, CardTitle, CardText } from 'reactstrap'; // Assuming you are using Reactstrap for styling

const OptionTypeCard = ({ name, description, available, onClick }) => {
    const cardStyle = {
        cursor: 'pointer',
    };

    const getCardHeaderProps = () => {
        const baseProps = {
            className: 'bg-transparent',
        };

        if (available) {
            return {
                ...baseProps,
                text: name,
                color: 'primary',
                iconClassName: 'mdi mdi-bullseye-arrow',
                disable: false
            };
        } else {
            return {
                ...baseProps,
                text: name,
                color: 'danger',
                iconClassName: 'mdi mdi-block-helper',
                disable: true
            };
        }
    };

    const { text, color, iconClassName, disable } = getCardHeaderProps();

    return (
        <Col lg={6} onClick={onClick} style={cardStyle}>
            <Card outline color={color} className="border"  disable={disable}>
                <CardHeader {...getCardHeaderProps()}>
                    <h5 className={`my-0 text-${color}`}>
                        <i className={`${iconClassName} mr-3`}></i>
                        {text}
                    </h5>
                </CardHeader>
                <CardBody>
                    <CardText>{description}</CardText>
                </CardBody>
            </Card>
        </Col>
    );
};

export default OptionTypeCard;
