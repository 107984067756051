import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { FormGroup, Container, Input, Button, Table, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Progress, TabContent, TabPane, NavLink, NavItem} from "reactstrap";
import { CSVLink, CSVDownload } from "react-csv";

import { useUrlState } from "../../../helpers/useUrlState";

import PrintPage from '../monogram/PrintPage'
import PickList from './PickList'

import {backendUrl} from '../../../helpers/consts.js'

import { useReactToPrint } from 'react-to-print';

import '../../Skote/Dashboard/skelton.css'

import Select from 'react-select';
import img4 from "../../../assets/images/product/img-4.png";
import img7 from "../../../assets/images/product/img-7.png";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import "../datatables.scss";
import useQuery from '../../../helpers/JIFI/useQuery';
import { effectTypes, select } from "redux-saga/effects";
import moment from 'moment';
import classnames from "classnames";

import superagent from 'superagent'
import { useHistory } from "react-router-dom";
import SKUToolTip from "../helpers/SKUToolTip.js";
import useSKULevels from "../helpers/useSKULevels.js";
import useUpcomingStock from "../helpers/useUpcomingStock.js";
import useSKUStatus from "../helpers/useSKUStatus.js";
import useUserStore from "../../../zustand/useUserStore.js";

const perPageOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500 - (WARNING SLOW)' },
  { value: 1000, label: '1000 - (WARNING SLOW)' },
]

const fulfillmentToBadge = (fulfillment) => {
  if (fulfillment === 'unfulfilled') return 'warning'
  if (fulfillment === 'awaiting-stock') return 'secondary'
  const conversion = {
    'fulfilled': 'success',
    'partially_fulfilled': 'error',
  }
  return conversion[fulfillment]
}

const pickToName = (pick) => {
  const conversion = {
    'none': 'UNPICKED',
    'monogram': 'TO-MONOGRAM',
    'fulfillment': 'TO-SHIP',
    'EWE': 'EWE',
    'QV': 'QV',
    'EWESYD': 'EWE Sydney',
    'B360': 'B360',
    'UNIS': 'UNIS',
    'EWE-MONO': 'EWE-MONO',
    'AIRBOX': 'Airbox',
    'AIRBOX-MONO': 'Airbox Monogram',
  }
  return conversion[pick] || pick
}

const pickToBadge = (pick) => {
  const conversion = {
    'none': 'warning',
    'monogram': 'info',
    'fulfillment': 'success',
    'EWE': 'success',
    'QV': 'success',
    'B360': 'success',
    'EWESYD': 'success',
    'UNIS': 'success',
    'EWE-MONO': 'info',
    'AIRBOX': 'success',
    'AIRBOX-MONO': 'info',
  }
  return conversion[pick] || 'success'
}

const paginate = function (array, index) {
  return [
    array[index - 2],
    array[index - 1],
    array[index],
    array[index + 1],
    array[index + 2]
  ].filter(function (el) {
    return el != null;
  });
}

const SKUDropdown = ({skus}) => {
  const [skuArray, setSkuArray] = useState(skus.split(' '))
  const [dropdownOpen, setDropdownOpen] = useState(false);
  useEffect(() => {
    setSkuArray(skus.split(' '))
  }, [skus])

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown direction='left' isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>
        ▼
      </DropdownToggle>
      <DropdownMenu>
        {
          skuArray.map((sku) => <DropdownItem>{sku}</DropdownItem>)
        }
      </DropdownMenu>
    </Dropdown>
  );

}

const TablePagination = ({ count, page, setPage, perPage, setPerPage }) => {
  const pageCount = Math.ceil(count / perPage)
  const pages = paginate(Array.from({ length: pageCount }, (v, i) => i + 1), page)
  const handleNumberClicked = (key) => {
    setPage(key)
  }
  return (
    <Pagination className="pagination pagination-rounded justify-content-end mb-2">
      <div style={{ height: '100%', width: 140 }}>
        <Select
          options={perPageOptions}
          onChange={newPerPage => setPerPage(newPerPage.value)}
          value={perPageOptions.find(val => val.value === perPage)}
        />
      </div>
      <PaginationItem disabled>
        <PaginationLink previous />
      </PaginationItem>
      {
        pages.map(val => (
          <PaginationItem onClick={() => handleNumberClicked(val)} active={val === page}>
            <PaginationLink>
              {val}
            </PaginationLink>
          </PaginationItem>
        ))
      }
      <PaginationItem>
        <PaginationLink next />
      </PaginationItem>
    </Pagination>
  )
}

const OrdersPage = ({ url, initValues, location }) => {
  const initialState = {
    selectedRegion: null,
    filterRegion: null,
    selectedSource: null,
    filterSource: null,
    selectedPickStatus: null,
    filterPickStatus: null,
    selectedFlagType: null,
    filterFlagType: null,
    selectedAssignedUser: null,
    filterAssignedUser: null,
    selectedState: null,
    filterState: null,
    selectedStatus: null,
    filterStatus: null,
    selectedOrder: null,
    filterOrder: null,
    selectedShipping: null,
    filterShipping: null,
    searchTerm: '',
    orderStartDate: '',
    filterStartDate: '',
    orderEndDate: '',
    filterEndDate: '',
    regionOptions: [{ label: 'Australia', value: 'Australia' }, { label: 'China', value: 'China' }, { label: 'International', value: 'International' }],
    orderSourceOptions: [{ label: 'AU Shopify', value: 'shopify_au' }, { label: 'TMALL', value: 'tmall' }, { label: 'US Shopify', value: 'shopify_us' }, { label: 'NZ Shopify', value: 'shopify_nz' }, { label: 'UK Shopify', value: 'shopify_uk' }, { label: 'QV Store', value: 'qv_store' }, { label: 'Greene St', value: 'greene' }, { label: 'Galeries Store', value: 'galeries_store' }, { label: 'Bondi Store', value: 'bondi' }, { label: '80 Collins', value: '80_collins' }, { label: 'HQ Store', value: 'hq_store' }, { label: 'Pitt St', value: 'pitt_st' }, { label: 'PacFair', value: 'pacfair' }, { label: 'Goop', value: 'goop' },  { label: 'Custom', value: 'custom' }],
    stateOptions: [{ label: 'KY', value: 'KY' }, { label: 'VIC', value: 'Victoria' }, { label: 'NSW', value: 'New South Wales' }, { label: 'ACT', value: 'Australian Capital Territory' }, { label: 'QLD', value: 'Queensland' }, { label: 'WA', value: 'Western Australia' }, { label: 'SA', value: 'South Australia' }, { label: 'NT', value: 'Northern Territory' }, { label: 'TAS', value: 'Tasmania' }],
    stepOptions: [{ label: 'Unfulfilled', value: 'unfulfilled' }, { label: 'Fulfilled', value: 'fulfilled' }, { label: 'Partially fulfilled', value: 'partially-fulfilled' }, { label: 'Cancelled', value: 'cancelled' }, ],
    orderOptions: [{ label: 'Has personalisation', value: 'Has-personalisation' }, { label: 'Flagged', value: 'Flagged' }, { label: 'POS', value: 'POS' }, { label: 'Ready to ship', value: 'ready-to-ship' }, { label: 'Partially fulfilled', value: 'partially-fulfilled'}, {label: 'Contains Pets', value: 'contains-pets'}, {label: 'Art Ready', value: 'art-ready'}, {label: 'Art Not Ready', value: 'art-not-ready'}],
    shippingOptions: [{ label: 'Standard', value: 'standard' }, { label: 'Express', value: 'express' }, { label: 'Same Day', value: 'same-day' }, { label: 'Next Day', value: 'next-day' }, { label: 'QV Pickup', value: 'pickup' }, { label: 'HQ Pickup', value: 'pickup_hq' }, { label: 'Uber', value: 'Uber' }, { label: 'Overnight', value: 'overnight' }],
    pickStatusOptions: [{ label: 'Unpicked', value: 'none' }, { label: 'To Ship', value: 'fulfillment' }, { label: 'To Monogram', value: 'monogram' }, { label: 'EWE Monogram', value: 'EWE-MONO' }, { label: 'EWE', value: 'EWE' }, { label: 'EWE Sydney', value: 'EWESYD'}, { label: 'B360', value: 'B360' }, { label: 'UNIS', value: 'UNIS' }],
    flagTypesOptions: [{}],
  }

  const [stateData, setStateData] = useUrlState(initValues || initialState);
  const history = useHistory()
  const [modal, setModal] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  
  const userList = useUserStore((state) => state.userList);
  


  const [flagTypesFilters] = useQuery({
    url: `/orders/getFlagTypes`
  });

  useEffect(() => {
    if (flagTypesFilters) {
      let flagFiltersOptions = flagTypesFilters.map((e) => ({label: e.name, value: e._id}))
      setStateData((prevState => {
        return {
          ...prevState,
          flagTypesOptions: flagFiltersOptions
        }
      }))
    }
  }, [flagTypesFilters])

  const [exportOpen, setExportModal] = useState();
  const toggleExportOpen = () => setExportModal(prev => !prev)

  let [localSearchTerm, setLocalSearchTerm] = useState(stateData.searchTerm)
  useEffect(() => {
    if (localSearchTerm !== stateData.searchTerm) {
      setLocalSearchTerm(stateData.searchTerm)
    }
  }, [stateData.searchTerm])

  const handleTermChange = (e) => {
    const newTerm = e.currentTarget.value.toUpperCase().replace('#', '')
    setLocalSearchTerm(newTerm)

    console.log(e)

  }


  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      setStateData((prevState => {
        return {
          ...prevState,
          searchTerm: localSearchTerm
        }
      }))
    }
  }

  const handleSubmitSearchTerm = () => {
    setStateData((prevState => {
      return {
        ...prevState,
        searchTerm: localSearchTerm
      }
    }))
  }

  const handleSelectRegion = selectedRegion => {
    setStateData((prevState => {
      return {
        ...prevState,
        selectedRegion: selectedRegion,
        filterRegion: selectedRegion && selectedRegion.value
      }
    }))
  };

  const handleSelectFlagType = selectedFlagType => {
    console.log(selectedFlagType)
    setStateData((prevState => {
      return {
        ...prevState,
        selectedFlagType: selectedFlagType,
        filterFlagType: Array.isArray(selectedFlagType) && selectedFlagType.map(a => a.value)
      }
    }))
  };

  const handleSelectAssignedUser = selectedAssignedUser => {
    
    setStateData((prevState => {
      return {
        ...prevState,
        selectedAssignedUser: selectedAssignedUser,
        filterAssignedUser: Array.isArray(selectedAssignedUser) && selectedAssignedUser.map(a => a.value)
      }
    }))
  };

  const handleSelectPickStatus = selectedPickStatus => {
    setStateData((prevState => {
      return {
        ...prevState,
        selectedPickStatus: selectedPickStatus,
        filterPickStatus: Array.isArray(selectedPickStatus) && selectedPickStatus.map(a => a.value)
      }
    }))
  };

  const handleSelectSource = selectedSource => {
    setStateData((prevState => {
      return {
        ...prevState,
        selectedSource: selectedSource,
        filterSource: Array.isArray(selectedSource) && selectedSource.map(a => a.value)
      }
    }))
  };

  const handleSelectStatus = selectedStatus => {
    setStateData((prevState => {
      return {
        ...prevState,
        selectedStatus: selectedStatus || [],
        filterStatus: Array.isArray(selectedStatus) ? selectedStatus.map(a => a.value) : []
      }
    }))
  };

  const handleSelectOrder = selectedOrder => {
    setStateData((prevState => {
      return {
        ...prevState,
        selectedOrder: selectedOrder,
        filterOrder: Array.isArray(selectedOrder) && selectedOrder.map(a => a.value)
      }
    }))
  };

  const handleSelectShipping = selectedShipping => {
    setStateData((prevState => {
      return {
        ...prevState,
        selectedShipping: selectedShipping,
        filterShipping: Array.isArray(selectedShipping) && selectedShipping.map(a => a.value)
      }
    }))
  };

  const ResetField = e => {
    e.preventDefault();
    history.push('/orders/')
  };

  const handleChange = (e) => {
    e.persist();
    setStateData((prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        filterStartDate: e.target.name === 'orderStartDate' ? moment(e.target.value).format('YYYY-MM-DD') : prevState.filterStartDate,
        filterEndDate: e.target.name === 'orderEndDate' ? moment(e.target.value).format('YYYY-MM-DD') : prevState.filterEndDate
      }
    }))
  };

  const togglemodal = () => {
    setModal(prev => !prev)
    // printLabels()
  }

  const [radioState, changeRadioState] = useState({
    radio1: true,
    radio2: false,
    radio3: false
  })
  const setRadioState = (value) => {
    if (value.radio2 == true || value.radio3 == true) {
      handleSelectStatus([{ label: 'Unfulfilled', value: 'unfulfilled' }])
    }
    changeRadioState(value)
  }
  const pickFilter = radioState.radio2 ? 'monogram': (radioState.radio3 ? 'fulfillment' : undefined)

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
      setRefresh(prev => !prev)
  }

  const [data, state] = useQuery({ refresh, url, queryData: { ...(pickFilter ? {pickCategory: pickFilter} : {}) ,page: page, perPage: perPage, searchTerm: stateData.searchTerm, filterStatus: stateData.filterStatus, filterStartDate: stateData.filterStartDate, filterEndDate: stateData.filterEndDate, filterOrder: stateData.filterOrder, filterShipping: stateData.filterShipping, filterState: stateData.filterState, filterRegion: stateData.filterRegion, filterPickStatus: stateData.filterPickStatus, filterSource: stateData.filterSource, filterFlagType: stateData.filterFlagType, filterAssignedUser: stateData.filterAssignedUser }});

  const orders = data && data.data;
  const count = data && data.count;

  const { orderStartDate, orderEndDate, selectedRegion, selectedState, selectedStatus, selectedOrder, selectedShipping, searchTerm, regionOptions, stepOptions, orderOptions, shippingOptions, flagTypesOptions, pickStatusOptions, selectedPickStatus, orderSourceOptions, selectedSource, selectedFlagType} = stateData;


  const [selected, setSelected] = useState([])
  useEffect(() => {
    orders ? setSelected(orders.map(() => !radioState.radio1)) : setSelected([])
  }, [orders, radioState])
  const selectedIds = orders && orders.filter((val, key) => selected[key] === true)


  const savedUrls = localStorage.getItem('savedUrls')? JSON.parse(localStorage.getItem('savedUrls')) : [];
  const [isSaveDropdownOpen, setIsSaveDropdownOpen] = useState(false)
  const [saveModal, setSaveModal] = useState(false)
  const [filterName, setFilterName] = useState('')
  const saveCurrentUrl = () => {
    const url = location.pathname + location.search;
    const name = filterName
    localStorage.setItem('savedUrls', JSON.stringify([...savedUrls, {url, name}]))
    setSaveModal(false)
    setFilterName('')
  }

  const handleDelete = (deleteKey) => {
    const newSavedUrls = savedUrls.filter((savedUrl, key) => key !== deleteKey)
    localStorage.setItem('savedUrls', JSON.stringify(newSavedUrls))
  }


  const addOn = (
    <>
      <Dropdown
        isOpen={isSaveDropdownOpen}
        direction='left'
        toggle={() =>
          setIsSaveDropdownOpen(prev => !prev)
        }
      >
        <DropdownToggle className="btn btn-success" caret>
          {" "}
          <i className="bx bx-save"></i>
        </DropdownToggle>
        <DropdownMenu>
          {
            savedUrls.map((savedUrl, key) => (
              <DropdownItem>
                <div className="d-flex align-items-center justify-content-between">
                  <Link key={key} to={savedUrl.url} >{savedUrl.name}</Link>
                  <div className="text-danger" onClick={() => handleDelete(key)}> x </div>
                </div>
              </DropdownItem>
            ))
          }
          <DropdownItem className="text-success" onClick={() => setSaveModal(true)}>+ Save current</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Modal
        size='lg'
        isOpen={saveModal}
        toggle={() => setSaveModal(prev => !prev)}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Save Filter</h5>
          <button
            type="button"
            onClick={() => setSaveModal(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <FormGroup>
            <Label> Filter Name </Label>
            <Input value={filterName} onChange={e => setFilterName(e.target.value)}/>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={saveCurrentUrl} >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )

  const findSKULevel = useSKULevels()
  const findUpcomingStock = useUpcomingStock()
  const findSKUStatus = useSKUStatus()

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Fulfillment" addOn={addOn} breadcrumbItem="All Orders" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="11">
                      {
                        perPage <= 20 && (
                          <>
                            <Label className="control-label">PICK CATEGORY:</Label>
                            <br/>
                            <div
                              className="btn-group btn-group-toggle"
                              data-toggle="buttons"
                            >
                              <label
                                className={
                                  radioState.radio1
                                    ? "btn btn-info active"
                                    : "btn btn-info"
                                }
                              >
                                <input
                                  type="radio"
                                  name="options"
                                  id="option1"
                                  onClick={() =>
                                    setRadioState({
                                      radio1: true,
                                      radio2: false,
                                      radio3: false
                                    })
                                  }
                                />{" "}
                                None
                              </label>
                              <label
                                className={
                                  radioState.radio2
                                    ? "btn btn-info active"
                                    : "btn btn-info"
                                }
                              >
                                <input
                                  type="radio"
                                  name="options"
                                  id="option2"
                                  onClick={() =>
                                    setRadioState({
                                      radio2: true,
                                      radio1: false,
                                      radio3: false
                                    })
                                  }
                                />{" "}
                                Monogram
                              </label>
                              <label
                                className={
                                  radioState.radio3
                                    ? "btn btn-info active"
                                    : "btn btn-info"
                                }
                              >
                                <input
                                  type="radio"
                                  name="options"
                                  id="option3"
                                  onClick={() =>
                                    setRadioState({
                                      radio3: true,
                                      radio2: false,
                                      radio1: false
                                    })
                                  }
                                />{" "}
                                Fulfillment
                              </label>
                            </div>

                          </>
                        )
                      }
                    </Col>
                    <Col sm="1">
                      <Button onClick={toggleExportOpen}> Export </Button>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm="3">
                      <Row>
                        <Col sm="9">
                          <FormGroup className="select2-container">
                            <Label className="control-label">FILTER</Label>
                            <div className="search-box mb-2 d-block">
                              <div className="position-relative">
                                <Input type="text" className="form-control" placeholder="Search..." value={localSearchTerm} onChange={handleTermChange} onKeyDown={handleKeyDown}/>
                                <i className="bx bx-search-alt search-icon"></i>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                     
                        <Col sm="2" className="d-flex justify-content-end align-items-end">
                          <FormGroup className="select2-container">
                            <Label className="control-label">{' '}</Label>
                            <Button type="button" color="success" className="btn-rounded waves-effect waves-light" onClick={handleSubmitSearchTerm}> Search </Button>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="6">
                          <FormGroup className="select2-container">
                            <Label className="control-label">STARTING</Label>
                            <input className="form-control" name="orderStartDate" value={orderStartDate || ''} onChange={handleChange} type="date" defaultValue="2019-08-19" id="example-date-input" />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="select2-container">
                            <Label className="control-label">ENDING</Label>
                            <input className="form-control" name="orderEndDate" value={orderEndDate || ''} onChange={handleChange} type="date" defaultValue="2019-08-19" id="example-date-input" />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="4">
                      <Row>
                        <Col sm="6">
                          <FormGroup className="select2-container">
                            <Label className="control-label">REGION</Label>
                            <Select classNamePrefix="select2-selection" value={selectedRegion || []} onChange={handleSelectRegion} placeholder="All" title="Country" options={regionOptions} />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="select2-container">
                            <Label className="control-label">ORDER SOURCE</Label>
                            <Select classNamePrefix="select2-selection" value={selectedSource || []} onChange={handleSelectSource} placeholder="All" title="Source" options={orderSourceOptions} isMulti />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <FormGroup className="select2-container">
                            <Label className="control-label">SHIPPING</Label>
                            <Select classNamePrefix="select2-selection" value={selectedShipping || []} onChange={handleSelectShipping} placeholder="All" title="Country" options={shippingOptions} isMulti />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="select2-container">
                            <Label className="control-label">STEP</Label>
                            <Select classNamePrefix="select2-selection" value={selectedStatus || []} onChange={handleSelectStatus} placeholder="All" title="Country" options={stepOptions} isMulti />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="4">
                      <Row>
                        <Col sm="6">
                          <FormGroup className="select2-container">
                            <Label className="control-label">ORDER</Label>
                            <Select classNamePrefix="select2-selection" value={selectedOrder || []} onChange={handleSelectOrder} placeholder="All" title="Country" options={orderOptions} isMulti />
                          </FormGroup>
                        </Col>
                        <Col>
                        <FormGroup className="select2-container">
                          <Label className="control-label">Flag Type</Label>
                          <Select classNamePrefix="select2-selection" value={selectedFlagType || []} onChange={handleSelectFlagType} placeholder="All" title="Status" options={flagTypesOptions} isMulti />
                        </FormGroup>
                        </Col>

                        
                      </Row>
                      <Row>
                      <Col>
                        <FormGroup className="select2-container">
                          <Label className="control-label">PICK STATUS</Label>
                          <Select classNamePrefix="select2-selection" value={selectedPickStatus || []} onChange={handleSelectPickStatus} placeholder="All" title="Status" options={pickStatusOptions} isMulti />
                        </FormGroup>
                        </Col>
                        <Col sm="6">
                        <FormGroup className="select2-container">
                          <Label className="control-label">USER</Label>
                          <Select classNamePrefix="select2-selection" onChange={handleSelectAssignedUser} placeholder="All" title="Assigned User" options={userList?.length > 0 ? userList?.map((val) => ({ label: val.userName, value: val._id })) : []} isMulti />
                        </FormGroup>
                        </Col>
                      </Row>
                      
                    </Col>
                    

                    <Col sm="1" style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                      <FormGroup className="select2-container">
                        <Button type="button" color="info" className="btn-rounded waves-effect waves-light" onClick={ResetField} > Reset</Button>
                      </FormGroup>
                      {/* <FormGroup className="select2-container mt-4">
                        <Button type="button" color="success" className="btn-rounded waves-effect waves-light mb-2 mr-2"><i className="mdi mdi-plus mr-1"></i> Add New Order</Button>
                      </FormGroup> */}
                    </Col>
                  </Row>
                  {
                    perPage <= 20 && radioState.radio1 === false && selectedIds.length > 0 &&(
                      <Row className="mb-4">
                        <Col sm="12">
                          <Button color="primary" onClick={togglemodal}> Generate Picklist </Button>
                        </Col>
                      </Row>
                    )
                  }
                  <div className="table-responsive">
                    <Table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: "20px" }}>
                            <div className="custom-control custom-checkbox">
                              <Input onChange={e => (orders ? setSelected(orders.map(() => e.target.checked)) : setSelected([])) } type="checkbox" className="custom-control-input" id="customCheck1" />
                              <Label className="custom-control-label" htmlFor="customCheck1">&nbsp;</Label>
                            </div>
                          </th>
                          <th>Order ID</th>
                          <th>Pick Status</th>
                          <th>Fulfillment</th>
                          <th>Source</th>
                          <th>Shipping</th>
                          <th>Region</th>
                          <th>Billing Name</th>
                          <th>Date</th>
                          <th>SKUs</th>
                          <th>Total</th>
                          <th>Payment</th>
                          <th>Flag Type</th>
                          <th>Assigned User</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          state === 'success' ? (
                            orders && orders.map((order, key) => {
                              let skuCount = {}
                              order.line_items.map(val => {
                                if (skuCount[val.sku] === undefined) {
                                  skuCount[val.sku] = val.quantity
                                } else {
                                  skuCount[val.sku] += val.quantity
                                }
                              })

                              const lineSum = Object.keys(skuCount).map(sku => {
                                return {
                                  sku: sku,
                                  quantity: skuCount[sku]
                                }
                              })

                              return lineSum.map((line, keyLine) => keyLine === 0 ? (
                                <tr style={order.flagged ? {background: "#fde1e1"} : {}} key={"_order_"+ `${keyLine}` + key}>
                                <td>
                                  <div
                                    className="custom-control custom-checkbox"
                                    onClick={() => setSelected(prev => {
                                      let newSelected = [...prev]
                                      newSelected[key] = !newSelected[key]
                                      return newSelected
                                    })}
                                  >
                                    <Input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={order.id}
                                      checked={selected[key]} />
                                    <Label className="custom-control-label" htmlFor={order.id}>&nbsp;</Label>
                                  </div>
                                </td>
                                <td><Link to={`/orders/${order['_id']}`} className="text-body font-weight-bold">{order['name']}</Link></td>
                                <td>
                                  <Badge className={"font-size-12 badge-soft-" + pickToBadge(order.pick_status)} color={pickToBadge(order.pick_status)} pill>
                                    <i className={"fab " + order.methodIcon + " mr-1 font-size-12 badge-soft-primary"}></i> {pickToName(order.pick_status)}
                                  </Badge>
                                </td>
                                <td>
                                  <Badge className={"font-size-12 badge-soft-" + fulfillmentToBadge(order.fulfillment_status)} color={fulfillmentToBadge(order.fulfillment_status)} pill>
                                    <i className={"fab " + order.methodIcon + " mr-1 font-size-12 badge-soft-primary"}></i> {order.fulfillment_status ? order?.fulfillment_status?.replace('_', ' ').toUpperCase() : 'UN-FULFILLED'}
                                  </Badge>
                                </td>
                                <td>{order?.order_source}</td>
                                <td>
                                  {order?.shipping_type}
                                </td>
                                <td>
                                  {order?.shipping_address?.province}, {order?.shipping_address?.country}
                                </td>
                                <td>{order?.shipping_address?.name}</td>
                                <td>
                                  {(new Date(order.orderCreated)).toDateString()}
                                </td>
                                <td>
                                  <Badge className={"font-size-12 badge-soft-primary"} color={'primary'} pill>
                                    <SKUToolTip sku={line.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} />
                                  </Badge> x{line.quantity}
                                </td>
                                <td>
                                  ${order.total_price}
                                </td>
                                <td>
                                  <Badge className={"font-size-12 badge-soft-" + order.badgeclass} color={order.badgeClass} pill>{order?.financial_status?.toUpperCase()}</Badge>
                                </td>
                                <td>{order.flagtype?.name}</td>
                                <td>{order?.assigned_user?.userName ?? ""}</td>
                                </tr>
                              ) : (
                                  <tr style={order.flagged ? {background: "#fde1e1"} : {}} key={"_order_"+ `${keyLine}` + key}>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <Badge className={"font-size-12 badge-soft-primary"} color={'primary'} pill>
                                      <SKUToolTip sku={line.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} />
                                    </Badge> x{line.quantity}
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              )).flat()
                            })
                          ) : (
                            perPage && (
                              [...Array(perPage)].map((_, i) => (
                                <tr className="py-8" style={{padding: '16px 0'}} key={"_order_" + i}>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                  <td> <div className="placeholder" /> </td>
                                </tr>

                              ))
                            )
                          )
                        }
                      </tbody>
                    </Table>
                  </div>
                  <TablePagination count={count} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal isOpen={modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" toggle={togglemodal}>
        <PickModal refresh={refreshData} monogram={radioState.radio2 ? true: false} togglemodal={togglemodal} selectedIds={selectedIds}/>
      </Modal>


      <Modal isOpen={exportOpen} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" toggle={toggleExportOpen}>
        <ExportModal toggleModal={toggleExportOpen} stateData={stateData}/>
      </Modal>

    </>
  );
}

const ExportModal = ({toggleModal, stateData}) => {
  const { orderStartDate, orderEndDate, selectedRegion, selectedState, selectedStatus, selectedOrder, selectedShipping, searchTerm, regionOptions, stateOptions, stepOptions, orderOptions, shippingOptions, pickStatusOptions, selectedPickStatus, filterPickStatus, orderSourceOptions, selectedSource, filterSource} = stateData;

  const [data, state] = useQuery({ url: '/orders/export', queryData: { searchTerm: stateData.searchTerm, filterStatus: stateData.filterStatus, filterStartDate: stateData.filterStartDate, filterEndDate: stateData.filterEndDate, filterOrder: stateData.filterOrder, filterShipping: stateData.filterShipping, filterState: stateData.filterState, filterRegion: stateData.filterRegion, filterPickStatus: stateData.filterPickStatus, filterSource: stateData.filterSource, filterFlagType: stateData.filterFlagType } });
  const orders = data && data.lines;
  const count = data && data.count;

  return (
    <>
      <ModalHeader>
        Export Orders
      </ModalHeader>
      <ModalBody>
        Exporting { count } orders.
      </ModalBody>
      <ModalFooter>
        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 8 }}>
        {
          !data ? (
            <div className="spinner-grow text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <CSVLink
              data={orders}
              filename={`order_export.csv`}
            >
              <Button color="primary"> Download </Button>
            </CSVLink>
          )
        }
        </div>
      </ModalFooter>
    </>
  )
}

const PickModal = ({togglemodal, selectedIds, monogram, refresh}) => {
  const [loading, setLoading] = useState(true)
  const [orderData, setOrderData] = useState([])
  const [selectedLineItems, setSelectedLineItems] = useState([])
  const [monogramRecords, setMonogramRecords] = useState([])
  const labelData = monogramRecords.map(data => ({
    monogramId: data.monogramId,
    location: data.monogram_position,
    letters: data.monogram_text,
    font: data.monogram_font,
    mainCol: data.monogram_color,
    backCol:  data.monogram_shadow,
    created_at: data.created_at,
    shopifyId: data.orderName,
    sku:data.sku,
    quantity:data.quantity,
    shippingType: data.shippingType,
    lineItems: data.lineItems,
    country: data.country,
    embroided: data.embroided,

    pet: data.pet,
    monogram_pets: data.monogram_pets && JSON.parse(atob(data.monogram_pets)),
    monogram_airtable_line: data.monogram_airtable_line,
    completedThumbnails: data.completedThumbnails
  }))

  const [labelsPrinted, setLabelsPrinted] = useState(false)
  const [pickListPrinted, setPickListPrinted] = useState(false)


  useEffect(() => {
    loadData()
    setLabelsPrinted(false)
    setPickListPrinted(false)
  }, [selectedIds, monogram])

  const loadData = () => {
    setLoading(true)
    superagent.post(`${backendUrl}/orders/getPicklist`)
      .send({
        ids: selectedIds.map(val => val._id)
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          setOrderData(response.body)
          let lineItems = []
          let monogramIndex = 0
            response.body.map(order =>{
              const data = order.line_items.map((lineItem, key) => {
                let monogramId
                if(lineItem.monogram) {
                  monogramId = `${order.name.replace('#', '')}-${monogramIndex}`
                  monogramIndex += 1
                } else {
                  monogramIndex = 0
                }
                if (lineItem.fulfillment_status !== 'deleted'){
                  lineItems.push({
                    ...lineItem,
                    shippingType: order.shipping_type,
                    created_at: order.orderCreated,
                    country: order.shipping_address.country,
                    orderName: order.name,
                    completedThumbnails: order.completedThumbnails,
                    ...(monogramId ? {monogramId: monogramId} : {}),
                    lineItems: order.line_items.filter(val => val._id !== lineItem._id && val.fulfillment_status !== 'deleted').map(val => Array(val.quantity).fill().map(i => val.sku) ).flat()
                  })
                }
              })
              monogramIndex = 0
              return data
            })
          if (monogram) {
            setSelectedLineItems(lineItems.filter(val => val.fulfillment_status === 'to-be-monogrammed'))
            setMonogramRecords(lineItems.filter(val => val.monogram === true && val.fulfillment_status !== 'deleted'))
          } else {
            setSelectedLineItems(lineItems.filter(val => val.fulfillment_status === 'unfulfilled'))
            setMonogramRecords(lineItems.filter(val => val.monogram === false && val.fulfillment_status !== 'deleted'))
          }

          setLoading(false)
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });
  }


  const save = () => {
    if (labelsPrinted && pickListPrinted) {
      setLoading(true)
      superagent.post(`${backendUrl}/orders/setPickStatus`)
        .send({
          orderIds: selectedIds.map(val => val._id),
          status: monogram ? 'monogram' : 'fulfillment'
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
          setLoading(false)
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
            togglemodal()
            refresh()
          }
        }).catch(err => {
          console.log(err)
          setLoading(false)
        });
    }
  }

  const packingSlipRef = useRef();
  const printLabels = useReactToPrint({
    content: () => packingSlipRef.current,
  });
  const handlePrintLabel = () => {
    printLabels()
    setLabelsPrinted(true)
  }

  const picklistRef = useRef();
  const printPicklist = useReactToPrint({
    content: () => picklistRef.current,
  });
  const handlePrintPicklist = () => {
    printPicklist()
    setPickListPrinted(true)
  }

  return (
    <div>
      <div className="modal-content">
        <ModalHeader toggle={togglemodal}>
          Create Picklist
        </ModalHeader >
        <ModalBody>
          {
            loading ? (
              <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                <div className="spinner-grow text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div style={{display: 'flex'}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50%', borderRight: '1px solid #eeeeeeee'}}>
                  <Button color={labelsPrinted ? 'secondary' : 'primary'} className="m-2" onClick={handlePrintLabel}>
                    { labelsPrinted && <i className="mdi mdi-sticker-check-outline mr-1" /> }
                    Print Labels
                  </Button>
                  <Button color={pickListPrinted ? 'secondary' : 'primary'} className="m-2" onClick={handlePrintPicklist}>
                    { pickListPrinted && <i className="mdi mdi-sticker-check-outline mr-1" /> }
                    Print Picklist
                  </Button>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50%'}}>
                  <Button type="button" color={labelsPrinted && pickListPrinted ? 'success' : 'secondary'} onClick={save}>Pick</Button>
                </div>
              </div>
            )
          }
        </ModalBody>
      </div>
      <div style={{display: 'none'}}>
        <PrintPage
          ref={packingSlipRef}
          monogramRecords={labelData}
          monogram={monogram}
        />
      </div>

      <div style={{display: 'none'}}>
        <PickList
          ref={picklistRef}
          lineItems={selectedLineItems}
          orderNames={orderData.map(val => val.name)}
          monogram={monogram}
        />
      </div>
    </div>
  )
}

export default OrdersPage;
