import React from 'react'

import FontFile from '../shared/font-file'

const Word = (props) => {
  return (
    <FontFile
      font={'script'}
      // topOffset='0.05em'
      multiplier={1}
      topOffset="0.13em"
      {...props}
    />
  )
}

export default Word
