import React, { useState, useEffect, useRef } from "react";
import {
  Tooltip,
  Nav,
  CardTitle,
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Container,
  Button,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from "reactstrap";
import { Link } from "react-router-dom";
import "../datatables.scss";
import useQuery from '../../../helpers/JIFI/useQuery';
import Select from 'react-select'
import classnames from "classnames";
import CommentHistory from "../../Skote/Dashboard/CommentHistory";
import useProductData from '../orders/useProductData'
import Autocomplete from "react-google-autocomplete";

import superagent from 'superagent'
import { backendUrl } from '../../../helpers/consts.js'
import moment from 'moment'

import { Country, State } from 'country-state-city';
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import AsyncSelect from 'react-select/async';
import SKUToolTip from "../helpers/SKUToolTip.js";
import useSKULevels from "../helpers/useSKULevels.js";
import useSKUStatus from "../helpers/useSKUStatus.js";
import useUpcomingStock from "../helpers/useUpcomingStock.js";

const returnedToBadge = (returned) => {
  return returned ? 'success' : 'warning'
}

const typeToBadge = (pick) => {
  const conversion = {
    'TSA_LOCK': 'info',
    'HANDLE_TELESCOPIC': 'info',
    'HANDLE_CARRY': 'info',
    'TRUNK_LOCK': 'info',
    'SNAPSLEEVE_FIDLOCK': 'info',
    'TRUNK_CLIP': 'info',
    'LUGGAGE_FEET': 'info',
    'MONOGRAM_REPRINT': 'info',
    'ZIP': 'info',
  }
  return conversion[pick] || 'success'
}
const stageToBadge = (pick) => {
  const conversion = {
    'SENT_TO_CUSTOMER': 'warning',
    'RECEIVED_BY_WAREHOUSE': 'warning',
    'REPAIR_UNSUCCESSFUL': 'danger',
    'REPAIR_COMPLETED': 'success',
    'REAPIR_DISPACHED': 'success',
    'AWAITING_LABEL': 'danger',
    'COMPLETED': 'success',
  }
  return conversion[pick] || 'success'
}



const PreviousOrders = ({ orderID }) => {
  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
    setRefresh(prev => !prev)
  }
  const [data, state] = useQuery({
    url: `/orders/${orderID}/customerOrders`,
    refresh
  });

  if (!data || data.length === 0) return <> </>

  const filteredOrders = data.filter(val => orderID !== `${val._id}`)

  return <div className="mt-2 pt-3 border-top border-light">
    <h5 className="mb-2"> {filteredOrders.length} Previous Orders </h5>
    <br />
    {
      filteredOrders.map(order => {
        return <div>
          <Link to={`/orders/${order._id}`}> <h6> {order.name} - ${order.total_price}</h6> </Link>
          <div className="mb-3">
            {
              order.line_items.map(lineItem => <div> {lineItem.quantity} x {lineItem.sku} </div>)
            }
          </div>
        </div>
      })
    }
  </div>
}

const ReturnPage = ({
  match: {
    params
  },
}) => {
  const { returnID } = params
  const [refresh, setRefresh] = useState(true)

  const refreshData = () => {
    setRefresh(prev => !prev)
  }

  const [data, state] = useQuery({
    url: `/returns/find/${returnID}`,
    refresh
  });
  const retex = data ? data : {}

  const getVariant = useProductData()

  const stages = [
    'SENT_TO_CUSTOMER',
    'RECEIVED_BY_WAREHOUSE',
    'REPAIR_UNSUCCESSFUL',
    'REPAIR_COMPLETED',
    'REAPIR_DISPACHED'
  ]
  const currentStage = stages.findIndex(val => val === retex.stage)

  const completeStage = () => {
    if (currentStage + 2 > stages.length) return
    const url = `${backendUrl}/returns/updateReturn/${retex._id}`
    superagent.post(url)
      .send({
        stage: stages[currentStage + 2]
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          refreshData()
        }
      }).catch(err => {
        console.log(err)
      });
  }

  const updateStage = () => {
    if (currentStage + 2 > stages.length) return
    const url = `${backendUrl}/returns/updateReturn/${retex._id}`
    superagent.post(url)
      .send({
        stage: stages[currentStage + 1]
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          refreshData()
        }
      }).catch(err => {
        console.log(err)
      });
  }

  const [editReturnItem, setEditReturnItem] = useState(undefined)
  const closeModal = () => {
    refreshData()
    setEditReturnItem(undefined)
  }

  const trackingOpen = editReturnItem !== undefined && editReturnItem.type === 'TRACKING'
  const priceOpen = editReturnItem !== undefined && editReturnItem.type === 'PRICE'
  const returnedOpen = editReturnItem !== undefined && editReturnItem.type === 'RETURNED'

  const findSKULevel = useSKULevels()
  const findUpcomingStock = useUpcomingStock()
  const findSKUStatus = useSKUStatus()

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col s="12" md='8'>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <h2 className="mb-4">
                      {retex.orderNumber}
                      <UncontrolledDropdown style={{ display: 'inline-block', padding: '0 16px' }}>
                        {retex?.stage?.toUpperCase() !== 'REPAIR_UNSUCCESSFUL' &&  retex?.stage?.toUpperCase() !== 'REAPIR_DISPACHED' && (
                          <>
                            <DropdownToggle href="#" className="card-drop" tag="i">
                              <i className="mdi mdi-dots-horizontal font-size-18"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              {
                                currentStage + 1 < stages.length && (
                                  <>
                                    {currentStage === 1 ? (
                                      <>
                                        <DropdownItem onClick={updateStage} href="#" style={{ color: 'red' }}> MARK AS {stages[currentStage + 1]} </DropdownItem>
                                        <DropdownItem onClick={completeStage} href="#" style={{ color: 'green' }}> MARK AS {stages[currentStage + 2]} </DropdownItem>
                                      </>
                                    ) : (
                                      <DropdownItem onClick={updateStage} href="#"> MARK AS {stages[currentStage + 1]} </DropdownItem>
                                    )
                                    }
                                  </>
                                )
                              }
                            </DropdownMenu>
                          </>
                        )}
                      </UncontrolledDropdown>
                    </h2>
                    <h6 className="mb-4">{(new Date(retex.cxDate).toString())}</h6>
                    <div> {retex?.shipping_address?.name} </div>
                    <Badge className={"mr-2 px-4 py-2 font-size-12 badge-soft-" + stageToBadge(retex?.stage)} color={stageToBadge(retex?.stage)} pill>{retex?.stage?.toUpperCase()}</Badge>
                    <Badge className={"mr-2 px-4 py-2 font-size-12 badge-soft-" + typeToBadge(retex?.returnType)} color={typeToBadge(retex?.returnType)} pill>{retex?.returnType?.toUpperCase()}</Badge>
                    <Badge className={"mr-2 px-4 py-2 font-size-12 badge-soft-" + retex.badgeclass} color={retex.badgeClass} pill>{retex?.reason?.toUpperCase()}</Badge>
                    <div className="my-3">
                      <Link to={`/orders/${retex?.order?._id}`}>
                        <Button
                          color="success"
                          className='btn btn-primary waves-effect waves-light btn btn-success'
                          style={{ marginRight: 8, marginTop: 8 }}
                        >
                          View Order
                        </Button>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <CardTitle>Line Items</CardTitle>
                    <Nav tabs className="">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: true
                          })}
                          onClick={() => {

                          }}
                        >
                          All
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={'1'}>
                      <TabPane tabId="1" className="p-3">
                        <Row>
                          <Col sm="12">
                            {
                              retex?.return_items && retex?.return_items.map(returnItem => {
                                let lineItem = returnItem.line_item
                                let parcels = returnItem.parcels
                                if (lineItem[0]) {
                                  lineItem = lineItem[0]
                                }
                                return (
                                  <Row>
                                    <Col sm="2">
                                      <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={getVariant(lineItem.sku)?.image} alt="" className="img-fluid" />
                                      </div>
                                    </Col>
                                    <Col sm="4" className="p-3">
                                      <h6>{getVariant(lineItem.sku)?.name}</h6>
                                      <h6 className='text-muted'> {lineItem.monogrammed_by && `Monogrammed by ${lineItem.monogrammed_by.replace('iron-man', 'Iron Man').replace('green-machine', 'Green Machine')}`}</h6>
                                      <div>
                                        <Badge className={"font-size-12 badge-soft-" + returnedToBadge(returnItem.isReturned)} color={returnedToBadge(returnItem.isReturned)} pill>
                                          {returnItem.isReturned ? 'RETURNED' : (returnItem.tracking_link === undefined ? 'NEEDS-TRACKING' : 'IN TRANSIT')}
                                        </Badge>
                                      </div>
                                      <div>SKU: <SKUToolTip sku={lineItem.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} /> x {lineItem.quantity} </div>
                                    </Col>
                                    <Col sm="4" className="p-3">
                                      {
                                        returnItem.tracking_link === undefined ? (
                                          <>
                                            <h6>Parcel Data</h6>
                                            {
                                              parcels.map(parcel => {
                                                return (
                                                  <>
                                                    <div>{parcel.width}cm x{parcel.length}cm x{parcel.depth}cm - {parcel.weight}kg</div>
                                                    <div>{parseFloat(parcel.width / 2.54).toFixed(2)}" x{parseFloat(parcel.length / 2.54).toFixed(2)}" x{parseFloat(parcel.depth / 2.54).toFixed(2)}" - {parcel.weight * 2.2046}lbs</div>
                                                  </>
                                                )
                                              })
                                            }
                                          </>
                                        ) : (
                                          <a href={returnItem.tracking_link} target="_blank" rel="noopener noreferrer"> <h6>Track item</h6> </a>
                                        )
                                      }
                                    </Col>
                                    {
                                      (
                                        (lineItem.fulfillment_status === 'unfulfilled' && (lineItem.monogram == false || lineItem.monogram == undefined)) ||
                                        (lineItem.fulfillment_status === 'to-be-monogrammed' && lineItem.monogram === true) ||
                                        (lineItem.fulfillment_status !== 'deleted')
                                      ) && (
                                        <Col sm="2" className="p-3">
                                          <UncontrolledDropdown>
                                            <DropdownToggle href="#" className="card-drop" tag="i">
                                              <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                              {
                                                returnItem.shipping_price === undefined && (
                                                  <DropdownItem onClick={() => setEditReturnItem({ returnItem, type: 'PRICE' })} href="#"> Add shipping price </DropdownItem>
                                                )
                                              }
                                              {
                                                returnItem.tracking_link === undefined && (
                                                  <DropdownItem onClick={() => setEditReturnItem({ returnItem, type: 'TRACKING' })} href="#"> Add tracking link </DropdownItem>
                                                )
                                              }
                                              {
                                                returnItem.isReturned !== true && (
                                                  <DropdownItem onClick={() => setEditReturnItem({ returnItem, type: 'RETURNED' })} href="#"> Mark as recieved </DropdownItem>
                                                )
                                              }


                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                                        </Col>
                                      )
                                    }
                                  </Row>
                                )
                              })
                            }
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col s="12" md="4">
            <div style={{ position: 'relative', height: '100%' }}>
              <div id="stickyTest" style={{ position: 'sticky', top: 150 }}>
                <CommentHistory overrideRefresh={refresh} title="Notes" initCommentType={'Order'} initOrderName={retex?.orderNumber} />
                <Card>
                  <CardBody>
                    <h6 style={{ marginBottom: 16 }}> Shipping Address </h6>
                    <div> {retex?.shipping_address?.name} </div>
                    <div> {retex?.shipping_address?.address1} </div>
                    <div> {retex?.shipping_address?.address2 || ''} </div>
                    <div> {`${retex?.shipping_address?.city || ''} ${retex?.shipping_address?.province || ''} ${retex?.shipping_address?.zip || ''}`} </div>
                    <div> {retex?.shipping_address?.country || ''} </div>
                    <div> {retex?.shipping_address?.phone || ''} </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <AddShippingPrice returnItem={editReturnItem?.returnItem} isOpen={priceOpen} toggle={closeModal} />
      <AddTrackingLink returnItem={editReturnItem?.returnItem} isOpen={trackingOpen} toggle={closeModal} />
      <MarkAsReturned returnItem={editReturnItem?.returnItem} isOpen={returnedOpen} toggle={closeModal} />
    </div>
  )
}

const AddShippingPrice = ({ isOpen, toggle, returnItem }) => {
  const [shippingPrice, setShippingPrice] = useState(0)
  const updateReturnLineStatus = async (id) => {
    await new Promise(resolve => {
      const url = `${backendUrl}/returns/updateReturnLineItemState/${id}`
      superagent.post(url)
        .send({
          shipping_price: shippingPrice
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
          console.log(response)
          resolve()
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
            resolve()
          }
        }).catch(err => {
          console.log(err)
          resolve()
        });
  
    })
  }
  const onSave = async () => {
    await updateReturnLineStatus(returnItem._id)
    toggle()
  }
  return (
    <Modal isOpen={isOpen} role="dialog" autoFocus={true} centered={true} tabindex="-1" size="lg" toggle={toggle}>
      <ModalHeader>
        Add shipping price
      </ModalHeader>
      <ModalBody>
        <div className="form-group row">
          <label htmlFor="example-text-input" className="col-md-4 col-form-label"> Refund Amount </label>
          <div className="col-md-8">
            <Input
              id={`refund-amount`}
              className="mb-2"
              type="number"
              step="1"
              value={shippingPrice}
              onChange={(e) => { setShippingPrice(Number(e.target.value)) }} />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="float-left" color="danger" onClick={toggle}>Cancel</Button>
        <Button className="float-right" color="success" onClick={onSave}>Save</Button>
      </ModalFooter>
    </Modal>
  )
}
const AddTrackingLink = ({ isOpen, toggle, returnItem }) => {
  const [trackingLink, setTrackingLink] = useState('')
  const updateReturnLineStatus = async (id) => {
    await new Promise(resolve => {
      const url = `${backendUrl}/returns/updateReturnLineItemState/${id}`
      superagent.post(url)
        .send({
          tracking_link: trackingLink
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
          console.log(response)
          resolve()
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
            resolve()
          }
        }).catch(err => {
          console.log(err)
          resolve()
        });

    })
  }
  const onSave = async () => {
    await updateReturnLineStatus(returnItem._id)
    toggle()
  }
  return (
    <Modal isOpen={isOpen} role="dialog" autoFocus={true} centered={true} tabindex="-1" size="lg" toggle={toggle}>
      <ModalHeader>
        Add Tracking Link
      </ModalHeader>
      <ModalBody>
        <div className="form-group row">
          <label htmlFor="example-text-input" className="col-md-4 col-form-label"> Other reason </label>
          <div className="col-md-8">
            <Input placeholder="Other reason" className="mb-2" type="text" value={trackingLink} onChange={(e) => setTrackingLink(e.target.value)} />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="float-left" color="danger" onClick={toggle}>Cancel</Button>
        <Button className="float-right" color="success" onClick={onSave}>Save</Button>
      </ModalFooter>
    </Modal>
  )
}
const MarkAsReturned = ({ isOpen, toggle, returnItem }) => {
  const updateReturnLineStatus = async (id) => {
    await new Promise(resolve => {
      const url = `${backendUrl}/returns/updateReturnLineItemState/${id}`
      superagent.post(url)
        .send({
          isReturned: true
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
          console.log(response)
          resolve()
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
            resolve()
          }
        }).catch(err => {
          console.log(err)
          resolve()
        });

    })
  }
  const onSave = async () => {
    await updateReturnLineStatus(returnItem._id)
    toggle()
  }
  return (
    <Modal isOpen={isOpen} role="dialog" autoFocus={true} centered={true} tabindex="-1" size="lg" toggle={toggle}>
      <ModalHeader>
        Mark As Returned
      </ModalHeader>
      <ModalFooter>
        <Button className="float-left" color="danger" onClick={toggle}>Cancel</Button>
        <Button className="float-right" color="secondary" onClick={onSave}>Save</Button>
        {/* <Button className="float-right" color="success" onClick={onSave}>Save & Add Stock Back</Button> */}
      </ModalFooter>
    </Modal>

  )
}

export default ReturnPage