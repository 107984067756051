import React, { useState } from 'react';
import { Container, Col, Card } from 'reactstrap';
import SupplyChainNav from '../../../components/supply-chain/nav.js';
import useQuery from '../../../helpers/JIFI/useQuery.js';
import NotificationItem from './components/NotificationItem';
import MarkAsReadButton from './components/MarkAsReadButton';
import { useLocation } from 'react-router-dom';
import superagent from 'superagent';
import { backendUrl } from '../../../helpers/consts.js';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import RemoveNotification from './components/RemoveNotification.js';

const NOTIFICATION = {
  READ: 1,
  INBOX: 2,
}
const NotificationListPage = () => {
  const location = useLocation();
  const [refresh, setRefresh] = useState(false);
  const isInbox = location.pathname.includes('/inbox');
  const history = useHistory();
  const [notifications, setNotifications] = useQuery({
    url: '/notifications',
    queryData: isInbox ? { read: NOTIFICATION.INBOX} : { read: NOTIFICATION.READ },
    refresh: refresh
  });
  const [selectedIds, setSelectedIds] = useState([]);

  const timeAgo = (date) => {
    const now = new Date();
    const diffInMilliseconds = now - new Date(date);
    const diffInMinutes = Math.floor(diffInMilliseconds / 60000);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
    } else {
      return `${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  const removeNotification = () => {
    superagent
      .post(`${backendUrl}/notifications/remove-notifications`)
      .send({ ids: selectedIds })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        } else {
          toast.success('Notifications marked as read successfully');
          setSelectedIds([]);
          setRefresh(!refresh);
        }
      })
      .catch((err) => {
        const errorMessage =
          err.response?.body?.message || err.message || 'Internal server error';
        toast.error(errorMessage, {
          autoClose: 5000,
        });
        console.error('Failed to mark notifications as read:', err);
      });

    setSelectedIds([]);
  };

  const markSelectedAsRead = () => {
    superagent
      .post(`${backendUrl}/notifications/mark-as-read`)
      .send({ ids: selectedIds }) // Send the list of selected notification IDs
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        } else {
          toast.success('Notifications marked as read successfully');
          setSelectedIds([]);
          setRefresh(!refresh);
        }
      })
      .catch((err) => {
        const errorMessage =
          err.response?.body?.message || err.message || 'Internal server error';
        toast.error(errorMessage, {
          autoClose: 5000,
        });
        console.error('Failed to mark notifications as read:', err);
      });

    setSelectedIds([]);
  };

  return (
    <SupplyChainNav menuType="notifications">
      <Container>
        <Col xs="12" m="12" lg="12" xl="12">
          <Card>
            <div className='d-flex justify-content-end'>
              {isInbox && (
                <MarkAsReadButton
                  onClick={markSelectedAsRead}
                  isDisabled={selectedIds.length === 0}
                />
              )}

              <RemoveNotification
                onClick={removeNotification}
                isDisabled={selectedIds.length === 0}
              />
            </div>
            {notifications?.map((notification) => (
              <NotificationItem
                url={notification.url}
                key={notification.id}
                notification={notification}
                onCheckboxChange={handleCheckboxChange}
                isSelected={selectedIds.includes(notification.id)}
                timeAgo={timeAgo}
              />
            ))}
          </Card>
        </Col>
      </Container>
    </SupplyChainNav>
  );
};

export default NotificationListPage;
