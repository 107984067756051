import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import classname from "classnames";
import { navBarMenu } from "../supply-chain/nav-menu";
import moment from 'moment';
import { withNamespaces } from 'react-i18next';

const Navbar = (props) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")) || {});
    const [fulfillmentState, setFulfillmentState] = useState(false);
    const [auditState, setAuditState] = useState(false);
    const [inventoryState, setInventoryState] = useState(false);
    const [financeState, setFinanceState] = useState(false);
    const [settingState, setSettingState] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const ul = document.getElementById("navigation");
        const items = ul.getElementsByTagName("a");
        let matchingMenuItem = null;

        for (let i = 0; i < items.length; ++i) {
            if (location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }

        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }, [location.pathname]);

    const activateParentDropdown = (item) => {
        item.classList.add("active");
        let parent = item.parentElement;
        while (parent) {
            parent.classList.add("active");
            parent = parent.parentElement;
        }
    };

    const getDefaultNavItem = (type, permissions = []) => {
        const permission = permissions.find(val => user.permissions.includes(val));
        return navBarMenu[type]?.find(val => val.permission === permission);
    };

    return (
        <React.Fragment>
            <div style={{ background: 'rgb(31, 62, 60)', top: 0 }} className="topnav">
                <div className="container-fluid">
                    <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
                        <Collapse isOpen={props.menuOpen} className="navbar-collapse" id="topnav-menu-content">
                            <ul className="navbar-nav">
                                <li className="nav-item dropdown">
                                    <Link className="nav-link" to="/dashboard">
                                        <i className="bx bx-home-circle mr-2"></i>{props.t('Dashboard')} {props.menuOpen}
                                    </Link>
                                </li>

                                {(
                                    user.permissions.includes('ViewOrders') ||
                                    user.permissions.includes('ViewDeliveries') ||
                                    user.permissions.includes('CreateOrder') ||
                                    user.permissions.includes('UNISPersonalisation') ||
                                    user.permissions.includes('AirboxPersonalisation')
                                ) && (
                                    <li className="nav-item dropdown">
                                        <Link to="/#" onClick={e => { e.preventDefault(); setFulfillmentState(!fulfillmentState); }} className="nav-link dropdown-toggle arrow-none">
                                            <i className="bx bx-box"></i> Fulfillment <div className="arrow-down"></div>
                                        </Link>
                                        <div style={{ background: 'rgb(31, 62, 60)' }} className={classname("dropdown-menu", { show: fulfillmentState })}>
                                            {user.permissions.includes('ViewOrders') && (
                                                <>
                                                    <Link style={{ background: 'rgb(31, 62, 60)' }} to="/orders" className="dropdown-item">Ecommerce Orders</Link>
                                                    <Link style={{ background: 'rgb(31, 62, 60)' }} to="/corp-orders" className="dropdown-item">Corporate Orders</Link>
                                                    <Link style={{ background: 'rgb(31, 62, 60)' }} to="/eiz" className="dropdown-item">EIZ Manifests</Link>
                                                </>
                                            )}
                                            {/* {user.permissions.includes('ViewReturns') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/returns" className="dropdown-item">Repairs</Link>} */}
                                            {user.permissions.includes('CreateOrder') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/order/create" className="dropdown-item">Create Order</Link>}
                                            {getDefaultNavItem('fulfillment-location', ['UNISPersonalisation', 'LaunchPersonalisation', 'ViewOrders', 'AirboxPersonalisation']) && (
                                                <Link style={{ background: 'rgb(31, 62, 60)' }} to={`${getDefaultNavItem('fulfillment-location', ['UNISPersonalisation', 'LaunchPersonalisation', 'ViewOrders', 'AirboxPersonalisation'])?.url}`} className="dropdown-item">Personalisation Batches</Link>
                                            )}
                                        </div>
                                    </li>
                                )}

                                {(
                                    user.permissions.includes('ViewTotalCount') ||
                                    user.permissions.includes('ViewContainer')
                                ) && (
                                    <li className="nav-item dropdown">
                                        <Link to="/#" onClick={e => { e.preventDefault(); setAuditState(!auditState); }} className="nav-link dropdown-toggle arrow-none">
                                            <i className="bx bx-list-check"></i> Stock Levels <div className="arrow-down"></div>
                                        </Link>
                                        <div style={{ background: 'rgb(31, 62, 60)' }} className={classname("dropdown-menu", { show: auditState })}>
                                            {user.permissions.includes('ViewTotalCount') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/all-stock" className="dropdown-item">Total Stock</Link>}
                                            {user.permissions.includes('ViewContainer') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/upcoming-container" className="dropdown-item">Upcoming Containers</Link>}
                                            {user.permissions.includes('ViewTotalCount') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/stock-warning" className="dropdown-item">Stock Warnings</Link>}
                                            {user.permissions.includes('ViewOrders') && (
                                                <>
                                                    <Link style={{ background: 'rgb(31, 62, 60)' }} to="/awaiting-stock" className="dropdown-item">Awaiting Stock Summary</Link>
                                                </>
                                            )}

                                        </div>
                                    </li>
                                )}

                                {(
                                    user.permissions.includes('CreateApprovals') ||
                                    user.permissions.includes('TransferStock') ||
                                    user.permissions.includes('BookStockDrop') ||
                                    user.permissions.includes('ViewMovementHistory')
                                ) && (
                                    <li className="nav-item dropdown">
                                        <Link to="/#" onClick={e => { e.preventDefault(); setInventoryState(!inventoryState); }} className="nav-link dropdown-toggle arrow-none">
                                            <i className="bx bx-transfer"></i> Inventory <div className="arrow-down"></div>
                                        </Link>
                                        <div style={{ background: 'rgb(31, 62, 60)' }} className={classname("dropdown-menu", { show: inventoryState })}>
                                            {user.permissions.includes('CreateApproval') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/stock" className="dropdown-item">Stock Audit</Link>}
                                            {user.permissions.includes('TransferStock') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/stock/transfer" className="dropdown-item">Transfer Stock</Link>}
                                            {user.permissions.includes('BookStockDrop') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/stock/replenish" className="dropdown-item">Store Replenishments</Link>}
                                            {user.permissions.includes('ViewMovementHistory') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/movement-history" className="dropdown-item">Movement History</Link>}
                                        </div>
                                    </li>
                                )}

                                {(
                                    user.permissions.includes('ViewApprovals') ||
                                    user.permissions.includes('CreateASN') ||
                                    user.permissions.includes('ViewDemand')
                                ) && (
                                    <li className="nav-item dropdown">
                                        <Link to="/#" onClick={e => { e.preventDefault(); setFinanceState(!financeState); }} className="nav-link dropdown-toggle arrow-none">
                                            <i className="bx bx-transfer"></i> Finance <div className="arrow-down"></div>
                                        </Link>
                                        <div style={{ background: 'rgb(31, 62, 60)' }} className={classname("dropdown-menu", { show: financeState })}>
                                            {user.permissions.includes('ViewApprovals') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/approvals" className="dropdown-item">Audit Approvals</Link>}
                                            {user.permissions.includes('ViewDemand') && (
                                                <>
                                                    <Link style={{ background: 'rgb(31, 62, 60)' }} to="/forecast" className="dropdown-item">Forecast</Link>
                                                    <Link style={{ background: 'rgb(31, 62, 60)' }} to="/demand/stock-status" className="dropdown-item">Historic Stock Status</Link>
                                                    <Link style={{ background: 'rgb(31, 62, 60)' }} to="/demand/stock-status-month" className="dropdown-item">Historic Stock Status Months</Link>
                                                </>
                                            )}
                                        </div>
                                    </li>
                                )}

                                {(
                                    user.permissions.includes('EditProductVariant') ||
                                    user.permissions.includes('EditLocations') ||
                                    user.permissions.includes('EditUser') ||
                                    user.permissions.includes('ViewFactories') ||
                                    user.permissions.includes('ViewPurchaseOrders')
                                ) && (
                                    <li className="nav-item dropdown">
                                        <Link to="/#" onClick={e => { e.preventDefault(); setSettingState(!settingState); }} className="nav-link dropdown-toggle arrow-none">
                                            <i className="bx bx-transfer"></i> Settings <div className="arrow-down"></div>
                                        </Link>
                                        <div style={{ background: 'rgb(31, 62, 60)' }} className={classname("dropdown-menu", { show: settingState })}>
                                            {user.permissions.includes('EditProductVariant') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/product/all" className="dropdown-item">Products</Link>}
                                            {user.permissions.includes('EditLocations') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/location/all" className="dropdown-item">Locations</Link>}
                                            {user.permissions.includes('EditUser') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/users/all" className="dropdown-item">Users</Link>}
                                            {user.permissions.includes('EditReturns') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/parcels" className="dropdown-item">Parcels</Link>}
                                            {user.permissions.includes('ViewFactories') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/factories" className="dropdown-item">Factories</Link>}
                                            {user.permissions.includes('ViewPurchaseOrders') && <Link style={{ background: 'rgb(31, 62, 60)' }} to="/purchase-order" className="dropdown-item">Purchase Orders</Link>}
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </Collapse>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withNamespaces()(Navbar);
