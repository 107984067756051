import React, { Component, Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CustomInput, CardTitle, Input, FormGroup, Label, Button, CardHeader, Media, Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import superagent from 'superagent'
import {backendUrl} from '../../../helpers/consts.js'

import PerfectScrollbar from 'react-perfect-scrollbar';


const presets = {
  retail: [
    "CreateApproval",
    "ViewStockLevels",
    "EditFlagType",
    "ViewFlagType",
    "ViewLocations",
    "AlterStockLevels",
    "ViewContainer",
    "BookStockDrop",
    "ApproveStockDrop",
    "TransferStock",
    "ViewMovementHistory",
    "CreateComment",
    "DeleteOwnComment",
    "FulfillOrder",
    "SplitOrder",
    "CreateOrder",
    "EditOrder",
    "ViewOrders",
    "ViewProductVariant",
    "ViewTotalCount",
    "PickOrders"
  ],

}

const generateWelcomeString = (email, userName, password) => {
return ` Welcome ${userName} to JIFI,

Your credentials are
Email: ${email}
Password: ${password}

Please go in and change your password and add a profile picture. 
You can find the profile settings in the top right.`
}

const UserProfile = ({ props }) => {
    const history = useHistory();

    const [users, setUsers] = useState([])
    const [permissions, setPermissions] = useState([])
    const [selectedUserKey, setSelectedUserKey] = useState(0)

    const [createModal, setCreateModal] = useState(false)

    useEffect(() => {
      getAllUsers()
      fetchPermissions()
    }, [])

    console.log(permissions)

    const setPreset = () => {
      overridePermissions([...presets['retail']])
    }

    const fetchPermissions = () => {
      superagent.get(`${backendUrl}/users/permissions/`)
          .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
          .set('accept', 'json')
          .on('error', (response) => {
              const code = response.status
              history.replace(history.location.pathname, {
                  errorStatusCode: code
              });
          })
          .then(response => {
              const code = response.status
              if (code > 400) {
                  history.replace(history.location.pathname, {
                      errorStatusCode: code
                  });
              } else {
                setPermissions(response.body)
              }
          }).catch(err => {
              console.log(err)
          });
  }

    const getAllUsers = () => {
        superagent.get(`${backendUrl}/users/all/`)
            .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
            .set('accept', 'json')
            .on('error', (response) => {
                const code = response.status
                history.replace(history.location.pathname, {
                    errorStatusCode: code
                });
            })
            .then(response => {
                const code = response.status
                if (code > 400) {
                    history.replace(history.location.pathname, {
                        errorStatusCode: code
                    });
                } else {
                  setUsers(response.body.data.map((val, key) => ({...val, isActive: key === 0 ? true : false})))
                }
            }).catch(err => {
                console.log(err)
            });
    }

    const toggleDiabled = () => {
      superagent.get(`${backendUrl}/users/toggleDisabled/${users[selectedUserKey]._id}`)
          .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
          .set('accept', 'json')
          .on('error', (response) => {
              const code = response.status
              history.replace(history.location.pathname, {
                  errorStatusCode: code
              });
          })
          .then(response => {
              const code = response.status
              if (code > 400) {
                  history.replace(history.location.pathname, {
                      errorStatusCode: code
                  });
              } else {
                getAllUsers()
              }
          }).catch(err => {
              console.log(err)
          });
  }

  const saveUser = () => {
    const sendDataCopy = { 
      userName: users[selectedUserKey].userName,
      email: users[selectedUserKey].email,
      permissions: users[selectedUserKey].permissions,
    }
    const sendData = JSON.parse(JSON.stringify(sendDataCopy))
    console.log(sendData)
    setUsers([])

    superagent.post(`${backendUrl}/users/updateUser/${users[selectedUserKey]._id}`)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .send(sendData)
        .on('error', (response) => {
            const code = response.status
            history.replace(history.location.pathname, {
                errorStatusCode: code
            });
        })
        .then(response => {
            const code = response.status
            if (code > 400) {
                history.replace(history.location.pathname, {
                    errorStatusCode: code
                });
            } else {
              getAllUsers()
            }
        }).catch(err => {
            console.log(err)
        });
  }

    const changeSelected = (selectedKey) => {
      setUsers(prev => prev.map((val, key) => ({...val, isActive: key === selectedKey ? true : false})))
      setSelectedUserKey(selectedKey)
    }

    const handleAlterPermission = (permission) => {
      if (users[selectedUserKey].permissions.includes(permission)) {
        setUsers(prev => {
          let updatedUsers = [...prev]
          let updateUser = updatedUsers[selectedUserKey]
          updateUser.permissions = updateUser.permissions.filter(val => val !== permission)
          updatedUsers[selectedUserKey] = updateUser
          return updatedUsers
        })
      } else {
        setUsers(prev => {
          let updatedUsers = [...prev]
          let updateUser = updatedUsers[selectedUserKey]
          updateUser.permissions.push(permission)
          updatedUsers[selectedUserKey] = updateUser
          return updatedUsers
        })
      }
    }

    const overridePermissions = (permissions) => {
      setUsers(prev => {
        let updatedUsers = [...prev]
        let updateUser = updatedUsers[selectedUserKey]
        updateUser.permissions = updateUser.permissions = permissions
        updatedUsers[selectedUserKey] = updateUser
        return updatedUsers
      })
    }

    const handleAlterName = (name) => {
      setUsers(prev => {
        let updatedUsers = [...prev]
        let updateUser = updatedUsers[selectedUserKey]
        updateUser.userName = name
        updatedUsers[selectedUserKey] = updateUser
        return updatedUsers
      })
    }

    const handleAlterEmail = (email) => {
      setUsers(prev => {
        let updatedUsers = [...prev]
        let updateUser = updatedUsers[selectedUserKey]
        updateUser.email = email
        updatedUsers[selectedUserKey] = updateUser
        return updatedUsers
      })
    }


    toast.configure({ autoClose: 8000, hideProgressBar: true })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Setting" breadcrumbItem="Users" />
                    <Row>
                        <Col lg="3">
                            <Card>
                                <CardHeader>
                                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                                    All Users
                                    <Button style={{float: 'right'}} color="success" onClick={() => setCreateModal(true)}> Create </Button>
                                  </div>
                                </CardHeader>
                                <CardBody>
                                <div>
                                  <ul className="list-unstyled chat-list">
                                    {
                                      users.length > 0 ? users.map((user, key) => {
                                        if (user.disabled)
                                          return

                                        return (
                                          <li key={user._id} className={user.isActive ? "active" : ""}>
                                            <Link to="#" onClick={() => { changeSelected(key) }}>
                                              <Media>
                                                <div className="align-self-center mr-3">
                                                  <i className={`mdi mdi-circle text-${user.disabled ? 'danger' : 'success'} font-size-10`}></i>
                                                </div>
                                                <div className="align-self-center mr-3">
                                                  <img style={{objectFit: 'cover'}} src={user.profile_image} className="rounded-circle avatar-xs" alt="" />
                                                </div>
                                                <Media className="overflow-hidden" body>
                                                  <h5 className="text-truncate font-size-14 mb-1">{user.userName}</h5>
                                                  <p className="text-truncate mb-0">{user.email}</p>
                                                </Media>
                                              </Media>
                                            </Link>
                                          </li>
                                        )
                                      }) : (
                                        <div className="d-flex justify-content-center">
                                          <div className="spinner-border text-primary m-1" role="status">
                                            <span className="sr-only">Loading...</span>
                                          </div>
                                        </div>
                                      )
                                    }
                                  </ul>
                                </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="9">
                          {
                            (users.length > 0 && users[selectedUserKey] !== undefined) ? (
                              <Card>
                                <CardHeader>
                                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                                    {users[selectedUserKey].userName}
                                    <Button onClick={saveUser} style={{float: 'right'}} color="success"> Save </Button>
                                  </div>
                                </CardHeader>
                                <CardBody>
                                  <Row>
                                    <Col lg="8">
                                      <FormGroup className="mb-4" row>
                                        <Label htmlFor="userName" className="col-form-label col-lg-2">User Name</Label>
                                        <Col lg="10">
                                          <Input name="userName" type="text" className="form-control" placeholder="Enter User Name..." value={users[selectedUserKey].userName}
                                              onChange={(e) => handleAlterName(e.target.value)} />
                                        </Col>
                                      </FormGroup>
                                      <FormGroup className="mb-4" row>
                                        <Label htmlFor="email" className="col-form-label col-lg-2">Email</Label>
                                        <Col lg="10">
                                          <Input name="email" type="text" className="form-control" placeholder="Enter User Email..." value={users[selectedUserKey].email}
                                              onChange={(e) => handleAlterEmail(e.target.value)} />
                                        </Col>
                                      </FormGroup>
                                      <br/>
                                      {
                                        users[selectedUserKey].disabled ? (
                                          <Button onClick={toggleDiabled} style={{float: 'right'}} color="success"> Enable </Button>
                                        ) : (
                                          <Button onClick={toggleDiabled} style={{float: 'right'}} color="danger"> Disable </Button>
                                        )
                                      }
                                      <br/>
                                      <Button onClick={setPreset}> Set as retail </Button>
                                    </Col>
                                    <Col lg="4">
                                      <div className="table-responsive">
                                        <Table className="table table-nowrap table-centered table-hover mb-0">
                                          <tbody>
                                              {
                                                permissions.length > 0 && permissions.map( permission =>
                                                  <tr>
                                                    <td style={{width : "50px"}}>
                                                      <div className="custom-control custom-checkbox">
                                                        <Input
                                                          type="checkbox"
                                                          className="custom-control-input"
                                                          id={`customCheck${permission}`}
                                                          checked={users[selectedUserKey].permissions.includes(permission)}
                                                          onChange={() => handleAlterPermission(permission)}
                                                        />
                                                        <Label className="custom-control-label" for={`customCheck${permission}`}/>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <h5 className="text-truncate font-size-14 mb-1"><Link
                                                          to="#"
                                                          className="text-dark"
                                                        >{permission}</Link></h5>
                                                      {/* <p className="text-muted mb-0">Assigned to Mark</p> */}
                                                    </td>
                                                  </tr>
                                                )
                                              }
                                            </tbody>
                                        </Table>
                                      </div>

                                    </Col>
                                  </Row>

                                </CardBody>
                              </Card>
                            ) : (
                              <div className="d-flex justify-content-center">
                                <div className="spinner-border text-primary m-1" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            )
                          }
                        </Col>
                    </Row>
                </Container>
            </div>
            <CreateModal setUsers={setUsers} getAllUsers={getAllUsers} createModal={createModal} setCreateModal={setCreateModal}/>
        </React.Fragment>
    );
}


const CreateModal = ({createModal, setCreateModal, setUsers, getAllUsers}) => {
  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')

  const [welcomeString, setWelcomeString] = useState(undefined)

  const [permissions, setPermissions] = useState(['ViewOrders'])

  const togglemodal = () => setCreateModal(prev => !prev)

  const history = useHistory();

  const createPassword = () => {
    var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var passwordLength = 12;
    var password = "";

    for (var i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber +1);
    }

    return password
  }

  const handleCreateUser = () => {
    const password = createPassword()
    superagent.post(`${backendUrl}/users/`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .send({
        userName: userName,
        email: email,
        password: password
      })
      .on('error', (response) => {
          const code = response.status
          history.replace(history.location.pathname, {
              errorStatusCode: code
          });
      })
      .then(response => {
          const code = response.status
          if (code > 400) {
              history.replace(history.location.pathname, {
                  errorStatusCode: code
              });
          } else {
            navigator.clipboard.writeText(generateWelcomeString(email, userName, password));
            setWelcomeString(generateWelcomeString(email, userName, password))
          }
      }).catch(err => {
          console.log(err)
      });
  }

  const closeModal = () => {
    setUsers([])
    getAllUsers()
    togglemodal()
  }

  const handleAlterName = (name) => {
    setUserName(name)
  }

  const handleAlterEmail = (email) => {
    setEmail(email)
  }


  return (
    <div>
      <Modal isOpen={createModal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" toggle={togglemodal}>
        <ModalHeader toggle={togglemodal}>
          Create User
        </ModalHeader >
        <ModalBody> 
        {
          welcomeString === undefined ? (
            <>
              <FormGroup className="mb-4" row>
                <Label htmlFor="userName" className="col-form-label col-lg-2">User Name</Label>
                <Col lg="10">
                  <Input name="userName" type="text" className="form-control" placeholder="Enter User Name..." value={userName}
                      onChange={(e) => handleAlterName(e.target.value)} />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="email" className="col-form-label col-lg-2">Email</Label>
                <Col lg="10">
                  <Input name="email" type="text" className="form-control" placeholder="Enter User Email..." value={email}
                      onChange={(e) => handleAlterEmail(e.target.value)} />
                </Col>
              </FormGroup>
            </>
          ) : (
            <>
              The welcome text has been copied to your clipboard. Please paste this to the user so they can get set up.
            </>
          )
        }

        </ModalBody>
        <ModalFooter>
          {
            welcomeString === undefined ? (
              <Button color="success" onClick={handleCreateUser}> Create </Button>
            ) : (
              <Button color="success" onClick={closeModal}> Done </Button>
            )
          }

        </ModalFooter>
      </Modal>
    </div>
  )
}

export default UserProfile;