import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { FormGroup, Container, Input, Button, Table, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Progress, TabContent, TabPane, NavLink, NavItem} from "reactstrap";
import { CSVLink, CSVDownload } from "react-csv";

import PrintPage from '../monogram/PrintPage'
import PickList from './PickList'

import {backendUrl} from '../../../helpers/consts.js'

import { useReactToPrint } from 'react-to-print';

import Select from 'react-select';
import img4 from "../../../assets/images/product/img-4.png";
import img7 from "../../../assets/images/product/img-7.png";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import "../datatables.scss";
import useQuery from '../../../helpers/JIFI/useQuery';
import { effectTypes, select } from "redux-saga/effects";
import moment from 'moment';
import classnames from "classnames";

import superagent from 'superagent'

import SKUToolTip from "../helpers/SKUToolTip.js";
import useUpcomingStock from "../helpers/useUpcomingStock.js";
import useSKULevels from "../helpers/useSKULevels.js";
import useSKUStatus from "../helpers/useSKUStatus.js";

const perPageOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500 - (WARNING SLOW)' },
  { value: 1000, label: '1000 - (WARNING SLOW)' },
]

const fulfillmentToBadge = (fulfillment) => {
  if (fulfillment === 'unfulfilled') return 'warning'
  if (fulfillment === 'awaiting-stock') return 'secondary'
  const conversion = {
    'fulfilled': 'success',
    'partially_fulfilled': 'error',
  }
  return conversion[fulfillment]
}

const pickToName = (pick) => {
  const conversion = {
    'none': 'UNPICKED',
    'monogram': 'TO-MONOGRAM',
    'fulfillment': 'TO-SHIP',
    'EWE': 'EWE',
    'QV': 'QV',
    'EWESYD': 'EWE Sydney',
    'B360': 'B360',
    'UNIS': 'UNIS',
    'EWE-MONO': 'EWE-MONO',
    'AIRBOX': 'Airbox',
    'AIRBOX-MONO': 'Airbox Monogram',
  }
  return conversion[pick] || pick
}

const pickToBadge = (pick) => {
  const conversion = {
    'none': 'warning',
    'monogram': 'info',
    'fulfillment': 'success',
    'EWE': 'success',
    'QV': 'success',
    'B360': 'success',
    'EWESYD': 'success',
    'UNIS': 'success',
    'EWE-MONO': 'info',
    'AIRBOX': 'success',
    'AIRBOX-MONO': 'info',
  }
  return conversion[pick] || 'success'
}

const paginate = function (array, index) {
  return [
    array[index - 2],
    array[index - 1],
    array[index],
    array[index + 1],
    array[index + 2]
  ].filter(function (el) {
    return el != null;
  });
}

const SKUDropdown = ({skus}) => {
  const [skuArray, setSkuArray] = useState(skus.split(' ')) 
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>
        ▼
      </DropdownToggle>
      <DropdownMenu>
        {
          skuArray.map((sku) => <DropdownItem>{sku}</DropdownItem>)
        }
      </DropdownMenu>
    </Dropdown>
  );

}

const TablePagination = ({ count, page, setPage, perPage, setPerPage }) => {
  const pageCount = Math.ceil(count / perPage)
  const pages = paginate(Array.from({ length: pageCount }, (v, i) => i + 1), page)
  const handleNumberClicked = (key) => {
    setPage(key)
  }
  return (
    <Pagination className="pagination pagination-rounded justify-content-end mb-2">
      <div style={{ height: '100%', width: 140 }}>
        <Select
          options={perPageOptions}
          onChange={newPerPage => setPerPage(newPerPage.value)}
          value={perPageOptions.find(val => val.value === perPage)}
        />
      </div>
      <PaginationItem disabled>
        <PaginationLink previous />
      </PaginationItem>
      {
        pages.map(val => (
          <PaginationItem onClick={() => handleNumberClicked(val)} active={val === page}>
            <PaginationLink>
              {val}
            </PaginationLink>
          </PaginationItem>
        ))
      }
      <PaginationItem>
        <PaginationLink next />
      </PaginationItem>
    </Pagination>
  )
}

const OrdersPage = ({ url = '/orders/awaiting' }) => {
  const initialState = {
    selectedRegion: null,
    filterRegion: null,
    selectedSource: null,
    filterSource: null,
    selectedPickStatus: null,
    filterPickStatus: null,
    selectedState: null,
    filterState: null,
    selectedStatus: [{ label: 'Awaiting Stock', value: 'awaiting-stock' }],
    filterStatus: ['awaiting-stock'],
    selectedOrder: null,
    filterOrder: null,
    selectedShipping: null,
    filterShipping: null,
    searchTerm: '',
    orderStartDate: '',
    filterStartDate: '',
    orderEndDate: '',
    filterEndDate: '',
    regionOptions: [{ label: 'Australia', value: 'Australia' }, { label: 'China', value: 'China' }, { label: 'International', value: 'International' }],
    orderSourceOptions: [{ label: 'AU Shopify', value: 'shopify_au' }, { label: 'TMALL', value: 'tmall' }, { label: 'US Shopify', value: 'shopify_us' }, { label: 'NZ Shopify', value: 'shopify_nz' }, { label: 'UK Shopify', value: 'shopify_uk' }, { label: 'QV Store', value: 'qv_store' }, { label: 'Greene St', value: 'greene' }, { label: 'Galeries Store', value: 'galeries_store' }, { label: 'Bondi Store', value: 'bondi' }, { label: '80 Collins', value: '80_collins' }, { label: 'HQ Store', value: 'hq_store' }, { label: 'Pitt St', value: 'pitt_st' }, { label: 'PacFair', value: 'pacfair' }],
    stateOptions: [{ label: 'KY', value: 'KY' }, { label: 'VIC', value: 'Victoria' }, { label: 'NSW', value: 'New South Wales' }, { label: 'ACT', value: 'Australian Capital Territory' }, { label: 'QLD', value: 'Queensland' }, { label: 'WA', value: 'Western Australia' }, { label: 'SA', value: 'South Australia' }, { label: 'NT', value: 'Northern Territory' }, { label: 'TAS', value: 'Tasmania' }],
    stepOptions: [{ label: 'Unfulfilled', value: 'unfulfilled' }, { label: 'Fulfilled', value: 'fulfilled' }, { label: 'Partially fulfilled', value: 'partially-fulfilled' }, { label: 'Cancelled', value: 'cancelled' }, ],
    orderOptions: [{ label: 'Has personalisation', value: 'Has-personalisation' }, { label: 'Flagged', value: 'Flagged' }, { label: 'POS', value: 'POS' }, { label: 'Ready to ship', value: 'ready-to-ship' }],
    shippingOptions: [{ label: 'Standard', value: 'standard' }, { label: 'Express', value: 'express' }, { label: 'Same Day', value: 'same-day' }, { label: 'Next Day', value: 'next-day' }, { label: 'QV Pickup', value: 'pickup' }, { label: 'HQ Pickup', value: 'pickup_hq' }, { label: 'Uber', value: 'Uber' }, { label: 'Overnight', value: 'overnight' }],
    pickStatusOptions: [{ label: 'Unpicked', value: 'none' }, { label: 'To Ship', value: 'fulfillment' }, { label: 'To Monogram', value: 'monogram' }, { label: 'EWE Monogram', value: 'EWE-MONO' }, { label: 'EWE', value: 'EWE' }, { label: 'EWE Sydney', value: 'EWESYD'}, { label: 'B360', value: 'B360' }, { label: 'UNIS', value: 'UNIS' }],
  }

  const [stateData, setStateData] = useState(initialState);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(200);

  const [exportOpen, setExportModal] = useState();
  const toggleExportOpen = () => setExportModal(prev => !prev)

  const findSKULevel = useSKULevels()
  const findUpcomingStock = useUpcomingStock()
  const findSKUStatus = useSKUStatus()

  const handleTermChange = (e) => {
    const newTerm = e.currentTarget.value.toUpperCase().replace('#', '')
    setStateData((prevState => {
      return {
        ...prevState,
        searchTerm: newTerm
      }
    }))
  }

  const handleSelectSource = selectedSource => {
    setStateData((prevState => {
      return {
        ...prevState,
        selectedSource: selectedSource,
        filterSource: Array.isArray(selectedSource) && selectedSource.map(a => a.value)
      }
    }))
  };



  const ResetField = e => {
    e.preventDefault();
    setStateData(prevState => {
      return {
        ...prevState,
        selectedRegion: null,
        filterRegion: null,
        selectedPickStatus: null,
        filterPickStatus: null,
        selectedSource: null,
        filterSource: null,
        selectedState: null,
        filterState: null,
        selectedStatus: null,
        filterStatus: null,
        selectedOrder: null,
        filterOrder: null,
        selectedShipping: null,
        filterShipping: null,
        searchTerm: '',
        orderStartDate: '',
        filterStartDate: '',
        orderEndDate: '',
        filterEndDate: ''
      };
    });
  };

  const handleChange = (e) => {
    e.persist();
    setStateData((prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        filterStartDate: e.target.name === 'orderStartDate' ? moment(e.target.value).format('YYYY-MM-DD') : prevState.filterStartDate,
        filterEndDate: e.target.name === 'orderEndDate' ? moment(e.target.value).format('YYYY-MM-DD') : prevState.filterEndDate
      }
    }))
  };

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
      setRefresh(prev => !prev)
  }
  console.log(stateData)
  const [data, state] = useQuery({ 
    refresh, 
    url, 
    queryData: {
      page: page, 
      perPage: perPage, 
      searchTerm: stateData.searchTerm, 
      filterStatus: stateData.filterStatus, 
      filterStartDate: stateData.filterStartDate, 
      filterEndDate: stateData.filterEndDate, 
      filterOrder: stateData.filterOrder, 
      filterShipping: stateData.filterShipping, 
      filterState: stateData.filterState, 
      filterRegion: stateData.filterRegion, 
      filterPickStatus: stateData.filterPickStatus, 
      filterSource: stateData.filterSource
    } 
  });
  const orders = data && data.data;
  const count = data && data.count;

  const { orderStartDate, orderEndDate, searchTerm, orderSourceOptions, selectedSource } = stateData;

  const [tableData, setTableData] = useState({})

  const [products, productState] = useQuery({
    url: `/product`,
    refresh,
  });

  const [containers, containerState] = useQuery({
    url: `/inventory/fetchUpcomingContainer/`,
    refresh,
  });

  useEffect(() => {
    if (products && containers) {
      const updatedTable = {}

      products.data.map(product => {
        product.variants.map(variant => {
          const sku = variant.sku

          let usLocations = ['USA - Dallas', 'UNIS', 'LCHSLC', 'LCHKTC']

          let containerLineData = [...containers].reverse().map(container => {
            let containerLines = container.containerLines.find(variantCount => variantCount.variant.sku === sku)
            let count = containerLines == undefined ? 0 : containerLines.quantity
            let location = container.landingLocation.name
            let date = container.arrivalDate
            return {
              count,
              location,
              date,
              country: usLocations.includes(location) ? 'US' : 'AU'
            }
          }).filter(val => val.count !== 0)
          let usList = containerLineData.filter(val => val.country === 'US')
          let auList = containerLineData.filter(val => val.country === 'AU')

          updatedTable[sku] = {
            usNextContainer: usList.length > 0 ? usList[0] : null, 
            auNextContainer: auList.length > 0 ? auList[0] : null, 
          }
        })
      })
      console.log(updatedTable)
      setTableData(updatedTable)
    }
  }, [products, containers])


  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Fulfillment" breadcrumbItem="All Orders" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="12">
                      <FormGroup className="select2-container">
                        <Label className="control-label">FILTER</Label>
                        <div className="search-box mb-2 d-block">
                          <div className="position-relative">
                            <Input type="text" className="form-control" placeholder="Search..." value={searchTerm} onChange={handleTermChange} />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                      </FormGroup>
                      <Row>
                        <Col sm="6">
                          <FormGroup className="select2-container">
                            <Label className="control-label">STARTING</Label>
                            <input className="form-control" name="orderStartDate" value={orderStartDate} onChange={handleChange} type="date" defaultValue="2019-08-19" id="example-date-input" />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="select2-container">
                            <Label className="control-label">ENDING</Label>
                            <input className="form-control" name="orderEndDate" value={orderEndDate} onChange={handleChange} type="date" defaultValue="2019-08-19" id="example-date-input" />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="select2-container">
                            <Label className="control-label">ORDER SOURCE</Label>
                            <Select classNamePrefix="select2-selection" value={selectedSource} onChange={handleSelectSource} placeholder="All" title="Source" options={orderSourceOptions} isMulti />
                          </FormGroup>
                        </Col>
                        <Col sm="6" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                          {/* <Button onClick={toggleExportOpen}> Export </Button> */}
                          <FormGroup className="mb-0">
                            <Button type="button" color="info" className="btn-rounded waves-effect waves-light" onClick={ResetField} > Reset</Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  
                  </Row>
                  <style dangerouslySetInnerHTML={{__html: `
                    .checkmark {
                      display:inline-block;
                      width: 22px;
                      height:22px;
                      -ms-transform: rotate(45deg); /* IE 9 */
                      -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
                      transform: rotate(45deg);
                    }
                    
                    .checkmark_stem {
                        position: absolute;
                        width:3px;
                        height:9px;
                        background-color:#000;
                        left:11px;
                        top:6px;
                    }
                    
                    .checkmark_kick {
                        position: absolute;
                        width:3px;
                        height:3px;
                        background-color:#000;
                        left:8px;
                        top:12px;
                    }
                  `}}/>
                  <div className="table-responsive">
                    <Table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th>Order ID</th>
                          <th>Shipping</th>
                          <th>Region</th>
                          <th>Billing Name</th>
                          <th>Date</th>
                          <th>SKUs</th>
                          <th>Expected</th>
                          <th>Contacted</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          state === 'success' && (
                            orders && orders.map((order, key) =>
                              order.line_items.filter(val => val.fulfillment_status !== 'deleted').map((lineItem, key) => {
                                if (key === 0) {
                                  return (
                                    <tr style={order.flagged ? {background: "#fde1e1"} : {}} key={"_order_" + key}>
                                      <td><Link to={`/orders/${order['_id']}`} className="text-body font-weight-bold">{order['name']}</Link></td>
                                      <td>
                                        {order?.shipping_type}
                                      </td>
                                      <td>
                                        {order?.shipping_address?.province}, {order?.shipping_address?.country}
                                      </td>
                                      <td>{order?.shipping_address?.name}</td>
                                      <td>
                                        {(new Date(order.orderCreated)).toDateString()}
                                      </td>
                                      <td className={lineItem.fulfillment_status === 'awaiting-stock' ? 'text-danger' : 'text-success'}>
                                        <> <SKUToolTip sku={lineItem.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} /> x {lineItem.quantity} </>
                                      </td>
                                      <td>
                                        { lineItem.fulfillment_status === 'awaiting-stock' &&
                                          <> {tableData && tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')] && <> {tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].auNextContainer && <>AU: {moment(tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].auNextContainer.date).format('YYYY-MM-DD')} </>}{tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].usNextContainer !== null && tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].auNextContainer && <br/>} {tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].usNextContainer && <>US: {moment(tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].usNextContainer.date).format('YYYY-MM-DD')} </>} </>} </>
                                        }
                                      </td>
                                      <td>
                                        {
                                          order.contacted === true && lineItem.fulfillment_status === 'awaiting-stock' && (
                                            <span class="checkmark">
                                              <div class="checkmark_stem"></div>
                                              <div class="checkmark_kick"></div>
                                            </span>
                                          )
                                        }
                                      </td>
                                    </tr>
                                  )
                                }
                                return (
                                  <tr style={order.flagged ? {background: "#fde1e1"} : {}} key={"_order_" + key}>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className={lineItem.fulfillment_status === 'awaiting-stock' ? 'text-danger' : 'text-success'}>
                                      <> <SKUToolTip sku={lineItem.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} /> x {lineItem.quantity} </>
                                    </td>
                                    <td>
                                      { lineItem.fulfillment_status === 'awaiting-stock' &&
                                          <> {tableData && tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')] && <> {tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].auNextContainer && <>AU: {moment(tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].auNextContainer.date).format('YYYY-MM-DD')} </>}{tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].usNextContainer !== null && tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].auNextContainer && <br/>} {tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].usNextContainer && <>US: {moment(tableData[lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')].usNextContainer.date).format('YYYY-MM-DD')} </>} </>} </>
                                      }
                                    </td>
                                    <td>
                                      {
                                        order.contacted === true && lineItem.fulfillment_status === 'awaiting-stock' && (
                                          <span class="checkmark">
                                            <div class="checkmark_stem"></div>
                                            <div class="checkmark_kick"></div>
                                          </span>
                                        )
                                      }
                                    </td>
                                  </tr>
                                )
                              })
                            )
                          )
                        }
                      </tbody>
                    </Table>
                  </div>
                  {
                    (orders && state === 'success') ? (
                      <TablePagination count={count} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
                    ) : (
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                          <div className="spinner-grow text-primary m-1" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>  
      <Modal isOpen={exportOpen} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" toggle={toggleExportOpen}>
        <ExportModal toggleModal={toggleExportOpen} stateData={stateData}/>
      </Modal>

    </>
  );
}

const ExportModal = ({toggleModal, stateData}) => {
  const [csvData, setCsvData] = useState([])
  const { orderStartDate, orderEndDate, selectedRegion, selectedState, selectedStatus, selectedOrder, selectedShipping, searchTerm, regionOptions, stateOptions, stepOptions, orderOptions, shippingOptions, pickStatusOptions, selectedPickStatus, filterPickStatus, orderSourceOptions, selectedSource, filterSource} = stateData;

  const [data, state] = useQuery({ url: '/orders/export', queryData: { searchTerm: stateData.searchTerm, filterStatus: stateData.filterStatus, filterStartDate: stateData.filterStartDate, filterEndDate: stateData.filterEndDate, filterOrder: stateData.filterOrder, filterShipping: stateData.filterShipping, filterState: stateData.filterState, filterRegion: stateData.filterRegion, filterPickStatus: stateData.filterPickStatus, filterSource: stateData.filterSource } });
  const orders = data && data.data;
  const count = data && data.count;

  useEffect(() => {
    if (orders) {
      let lines = [
        [
          'Name',	'Email',	'Financial Status',	'Paid at',	'Fulfillment Status',	'Fulfilled at',	
          'Accepts Marketing',	'Currency',	'Subtotal',	'Shipping',	'Taxes',	'Total',	'Discount Code',	
          'Discount Amount',	'Shipping Method',	'Created at',	'Lineitem quantity',	'Lineitem name',	
          'Lineitem price',	'Lineitem compare at price',	'Lineitem sku',	'Lineitem requires shipping',	'Lineitem taxable',	
          'Lineitem fulfillment status',	'Billing Name',	'Billing Street',	'Billing Address1',	'Billing Address2',	'Billing Company',	
          'Billing City',	'Billing Zip',	'Billing Province',	'Billing Country',	'Billing Phone',	'Shipping Name',	'Shipping Street',	
          'Shipping Address1',	'Shipping Address2',	'Shipping Company',	'Shipping City',	'Shipping Zip',	'Shipping Province',	
          'Shipping Country',	'Shipping Phone',	'Notes',	'Note Attributes',	'Cancelled at',	'Payment Method',	'Payment Reference',	
          'Refunded Amount',	'Vendor',	'Outstanding Balance',	'Employee',	'Location',	'Device ID',	'Id',	'Tags',	'Risk Level',	
          'Source',	'Lineitem discount',	'Tax 1 Name',	'Tax 1 Value',	'Tax 2 Name',	'Tax 2 Value',	'Tax 3 Name',	'Tax 3 Value',	
          'Tax 4 Name',	'Tax 4 Value',	'Tax 5 Name',	'Tax 5 Value',	'Phone',	'Receipt Number', 'Order Source'
        ]
      ]
      for (let order of orders) {
        let key = 0
        for (let lineItem of order.line_items) {
          if (lineItem.fulfillment_status !== 'deleted') {
            lines.push([
              order.name,
              order.email,
              order.financial_status,
              '',
              order.fulfillment_status,
              '',
              order.customer?.accepts_marketing || false,
              order.currency,
              order.total_line_items_price,
              '',
              key === 0 ? order.total_tax : '',
              key === 0 ? order.total_price: '',
              '',
              order.total_discounts,
              order.shipping_type,
              order.orderCreated,
              lineItem.quantity,
              '',
              lineItem.price,
              lineItem.price,
              lineItem.sku,
              true,
              true,
              lineItem.fulfillment_status,
              order.billing_address?.name || '',
              `${order.billing_address?.address1 || '' } ${order.billing_address?.address2 || '' }`,
              order.billing_address?.address1,
              order.billing_address?.address2,
              order.billing_address?.company,
              order.billing_address?.city,
              order.billing_address?.zip,
              order.billing_address?.province,
              order.billing_address?.country,
              order.billing_address?.phone,
              order.shipping_address?.name,
              `${order.shipping_address?.address1 || '' } ${order.shipping_address?.address2 || '' }`,
              order.shipping_address?.address1,
              order.shipping_address?.address2,
              order.shipping_address?.company,
              order.shipping_address?.city,
              order.shipping_address?.zip,
              order.shipping_address?.province,
              order.shipping_address?.country,
              order.shipping_address?.phone,
              '',
              '',
              '',
              order.gateway,
              '',
              '',
              'July',
              '',
              '',
              '',
              '', 
              order.shopify_id,
              '',
              'Low',
              '2894495',
              '',
              ...(
                key === 0 ? (
                  [
                    'GST 10%',
                    Math.round(((order.total_price / 11 )+ Number.EPSILON) * 100) / 100
                  ]
                ) : (
                  [
                    '', 
                    ''
                  ]
                )
              ),
              '',
              '',
              '',
              '', 
              '',
              '',
              '',
              '', 
              order.shipping_address?.phone,
              '',
              order.order_source
            ])
          }
          key++
        }
      }
      setCsvData(lines)
    }
  }, [orders])
  
  return (
    <>
      <ModalHeader>
        Export Orders
      </ModalHeader>
      <ModalBody>
        Exporting { count } orders.
      </ModalBody>
      <ModalFooter>
        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 8 }}>
        {
          !data ? (
            <div className="spinner-grow text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <CSVLink 
              data={csvData}
              filename={`order_export.csv`}
            >
              <Button color="primary"> Download </Button>
            </CSVLink>
          )
        }
        </div>
      </ModalFooter>
    </>
  )
}

export default OrdersPage;


