import React from 'react'

import FontFile from '../shared/font-file'

const Word = (props) => {
  return (
    <FontFile font={'retro'} topOffset="0.15em" multiplier={1.4} {...props} />
  )
}

export default Word
