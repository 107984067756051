import React, { useState } from 'react';
import { Button, Input, ListGroup, ListGroupItem } from "reactstrap";

const ProductVariantSelector = ({ products, setSelectedVariant, setSelectedProduct, selectedProduct, selectedVariant, customContainerStyle, hidePersonalisationOptions }) => {
    const [localSelectedProduct, setLocalSelectedProduct] = useState()
    const setSelectedProductCurrent = setSelectedProduct ? setSelectedProduct : setLocalSelectedProduct
    const selectedProductCurrent = selectedProduct ? selectedProduct : localSelectedProduct

    const setSelectedVariantCurrent = setSelectedVariant ? setSelectedVariant : (() => { })
    const selectedVariantCurrent = selectedVariant ? selectedVariant : undefined

    const [searchTerm, setSearchTerm] = useState('');
    const [expandedVariant, setExpandedVariant] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const searchListOfProducts = searchTerm ? 
        products.filter(val => 
            val.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            val.variants?.some(variant => {
                return variant?.sku?.toLowerCase()?.includes(searchTerm?.toLowerCase()) || variant?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
            })
        )
        : products;

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setSelectedVariantCurrent(null);
        setSelectedProductCurrent(null);
        setIsDropdownOpen(true);
    };

    const handleVariantSelect = (product, variant, persSKU) => {
        if (persSKU) {
            variant.sku += '-P'
        }

        setSelectedProductCurrent(product)
        setSelectedVariantCurrent(variant);
        setSearchTerm(null);
        setIsDropdownOpen(false);
    };

    const handleProductSelect = (data) => {
        setSelectedProductCurrent(data);
        setSearchTerm(null);
        setIsDropdownOpen(false);
    };
    const findProductName = (selectedProduct) => {
        const normalizedSelectedProduct = selectedProduct?.name;
        const product = products?.find(option =>
            option?.name?.toLowerCase() === normalizedSelectedProduct?.toLowerCase()
        );
        if (product) {
            let name = product;
            return name;
        }
        return '';
    };

    const findProductNameById = (selectedProduct) => {
        const product = products?.find(option =>
            option?._id?.toLowerCase() === selectedProduct
        )
        if (product) {
            return product?.name
        }
        return ''
    };

    const toggleVariant = (name) => {
        setExpandedVariant(expandedVariant === name ? null : name);
    };

    const placeholderValue = setSelectedProduct === undefined ? 'Search for a variant...' : 'Search for a product...';

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    };

    return (
        <div style={customContainerStyle ? customContainerStyle : containerStyle}>
            <Input
                type="search"
                placeholder={placeholderValue}
                value={setSelectedProduct === undefined ? (selectedVariantCurrent ? selectedVariantCurrent?.name : searchTerm) : (selectedProductCurrent?.name ? selectedProductCurrent?.name : selectedProduct !== null ? findProductNameById(selectedProduct) : searchTerm)}
                onChange={handleSearchChange}  
                onFocus={() => setIsDropdownOpen(true)}
            />
            {isDropdownOpen && (
                <div id="accordion" style={{ position: 'relative' }}>
                    <ListGroup style={{  maxHeight: 440, overflowY: 'auto', width: '100%', position: 'absolute', zIndex: 999, 'box-shadow': '3px 4px 6px #0003', left: 0, right: 0 }}>
                        {setSelectedProduct === undefined ? (
                            <div>
                                {searchListOfProducts?.map((option) => (
                                    <div key={option.sku}>
                                        {
                                            option.sku !== undefined ?
                                                (
                                                    <div key={option.sku}>
                                                        <ListGroupItem  style={{ gap: '10px', justifyContent: 'space-between', display: 'flex', alignItems: 'center', }}>
                                                            <span>{option.name} / Default</span><Button onClick={() => handleVariantSelect(findProductName(option), option, false)}>Select</Button>
                                                        </ListGroupItem>
                                                        {(['COP', 'CHK', 'PLS', 'CME', 'BKP', 'BPK', 'WKD', 'TOT', 'PRO', 'LGT', 'BOT', 'IPC', 'TRK', 'NBK', 'TAG', 'SET', 'TEC', 'COE', 'EXP', 'EVE', 'DBK', 'SLG', 'JET', 'COM'].includes(option.sku.slice(0, 3)) || ['CAR-DPK', 'CAR-TOT', 'CAR-BPK', 'CAR-WKD'].includes(option.sku.slice(0, 7)) || ['CAR-WKDP'].includes(option.sku.slice(0, 8))) && !hidePersonalisationOptions &&
                                                            <ListGroupItem style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center'}}>
                                                                <span>{option.name} / <span style={{ fontWeight: 500 }}>Personalised</span></span><Button onClick={() => handleVariantSelect(findProductName(option), option, true)}>Select</Button>
                                                            </ListGroupItem>
                                                        }
                                                    </div>
                                                ) : (
                                                    <>
                                                        <ListGroupItem style={{ gap: '10px', border: '1px solid rgba(0, 0, 0, 0.05)', fontWeight: 500, cursor: 'pointer', justifyContent: 'space-between', display: 'flex', alignItems: 'center' }} onClick={() => toggleVariant(option.name)}>
                                                            {option.name}
                                                            <>
                                                                {
                                                                    expandedVariant === option.name
                                                                        ? (
                                                                            <i class="fa fa-chevron-up" aria-hidden="true"></i>
                                                                        ) : (
                                                                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                                                        )
                                                                }
                                                            </>
                                                        </ListGroupItem>
                                                        {expandedVariant === option.name && (
                                                            <>
                                                                {option.variants.filter(val => val?.sku?.toLowerCase()?.includes(searchTerm?.toLowerCase()) || val?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())).map((variant, variantIndex) => (
                                                                    <div key={variant.sku}>
                                                                        <ListGroupItem style={{ gap: '10px', justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                                                                            <span>{variant.name} / Default</span><Button onClick={() => handleVariantSelect(option, variant, false)}>Select</Button>
                                                                        </ListGroupItem>
                                                                        {(['COP', 'CHK', 'PLS', 'CME', 'BKP', 'BPK', 'WKD', 'TOT', 'PRO', 'LGT', 'BOT', 'IPC', 'TRK', 'NBK', 'TAG', 'SET', 'TEC', 'COE', 'EXP', 'EVE', 'DBK', 'SLG', 'JET', 'COM'].includes(variant?.sku?.slice(0, 3)) || ['CAR-DPK', 'CAR-TOT', 'CAR-BPK', 'CAR-WKD'].includes(variant?.sku?.slice(0, 7)) || ['CAR-WKDP']?.includes(variant?.sku?.slice(0, 8))) && !hidePersonalisationOptions &&
                                                                            <ListGroupItem style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                                                                                <span>{variant.name} / <span style={{ fontWeight: 500 }}>Personalised</span></span><Button onClick={() => handleVariantSelect(option, variant, true)}>Select</Button>
                                                                            </ListGroupItem>
                                                                        }
                                                                    </div>
                                                                ))}
                                                            </>
                                                        )}
                                                    </>
                                                )
                                        }
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <>
                                {
                                    searchListOfProducts?.map((product) => (
                                        <ListGroupItem style={{ gap: '10px', border: '1px solid rgba(0, 0, 0, 0.05)', fontWeight: 500, cursor: 'pointer', justifyContent: 'space-between', display: 'flex', alignItems: 'center' }} key={product.name}>
                                            <span>{product.name}</span><Button onClick={() => handleProductSelect(product)}> Select</Button>
                                        </ListGroupItem>
                                    ))
                                }
                            </>

                        )}
                    </ListGroup>
                </div>
            )}
        </div>
    );
};

export default ProductVariantSelector;
