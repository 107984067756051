import moment from 'moment'

function alphaOnly(event) {
    if (!/^[a-z A-Z]*$/.test(event.key)) {
        event.preventDefault();
    }
}

function alphaNumOnly(event) {
    if (/[^0-9a-zA-Z]/.test(event.key)) {
        event.preventDefault();
    }
}

function objectHasKey(obj, key) {
    if (obj !== undefined && obj !== null && Object.keys(obj).length > 0 && obj.hasOwnProperty(key)) {
        return true
    }
    return false
}

function objectHasKeys(obj) {
    if (obj !== undefined && obj !== null && Object.keys(obj).length > 0) {
        return true
    }
    return false
}

function validateEmail(emailField) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(emailField.value)) {
        return false;
    }
    return true;
}

function validatePassword(passwordField) {
    var reg = /^[@#][A-Za-z0-9]{8,20}$/;
    if (reg.test(passwordField.value)) {
        return false;
    }
    return true;
}

function arrayNotNull(array) {
    if (array !== undefined && array !== null && Array.isArray(array) && array.length > 0) {
        return true
    }
    return false
}

function notNull(data) {
    if (data !== undefined && data !== null && data !== '') {
        return true
    }
    return false
}

function printArrayOfObject(arr, key, seprator) {
    let temp = []
    arrayNotNull(arr) && arr.map(function (item, index) {
        temp.push(item[key])
    });
    return temp.join(seprator)
}


function defaultDate(date) {
    return moment(date).format('DD MMMM YYYY')
}

function defaultDateTime(date) {
    return moment(date).format('DD/MM/YYYY: hh:mm A')
}

export { alphaNumOnly, alphaOnly, objectHasKey, objectHasKeys, validateEmail, validatePassword, arrayNotNull, notNull, defaultDate, defaultDateTime, printArrayOfObject };
