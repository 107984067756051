import React from 'react'
import FontFile from '../shared/font-file'


const Word = (props) => { 
  return <FontFile 
            overlayOffset={[0.01, 0.03]}
            topOffset='-0.05em'
            multiplier={1}
            {...props}
            font={`decorative-${props.mainCol.toLowerCase()}`}
          />
}

export default Word