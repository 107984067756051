import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { FormGroup, Nav, CardSubtitle, CardTitle, CardText, TabContent, TabPane, Collapse, NavLink, NavItem, Container, Input, Button, Table, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "./datatables.scss";

import useQuery from '../../helpers/JIFI/useQuery';

import { useHistory } from "react-router-dom";
import superagent from 'superagent'
import { backendUrl } from '../../helpers/consts.js'
import LocationSelector from './shared/LocationSelector'

import Select from 'react-select';

import * as moment from 'moment-timezone';

import SKUToolTip from './helpers/SKUToolTip.js';
import useSKULevels from './helpers/useSKULevels.js';
import useSKUStatus from './helpers/useSKUStatus.js';
import useUpcomingStock from './helpers/useUpcomingStock.js';

const perPageOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
]

const paginate = function (array, index) {
  return [
    array[index - 2],
    array[index - 1],
    array[index],
    array[index + 1],
    array[index + 2]
  ].filter(function (el) {
    return el != null;
  });
}

const TablePagination = ({ count, page, setPage, perPage, setPerPage }) => {
  const pageCount = Math.ceil(count / perPage)
  const pages = paginate(Array.from({ length: pageCount }, (v, i) => i + 1), page)
  const handleNumberClicked = (key) => {
    setPage(key)
  }
  return (
    <Pagination className="pagination pagination-rounded justify-content-end mb-2">
      <div style={{ height: '100%', width: 140 }}>
        <Select
          options={perPageOptions}
          onChange={newPerPage => setPerPage(newPerPage.value)}
          value={perPageOptions.find(val => val.value === perPage)}
        />
      </div>
      <PaginationItem disabled>
        <PaginationLink previous />
      </PaginationItem>
      {
        pages.map(val => (
          <PaginationItem onClick={() => handleNumberClicked(val)} active={val === page}>
            <PaginationLink>
              {val}
            </PaginationLink>
          </PaginationItem>
        ))
      }
      <PaginationItem>
        <PaginationLink next />
      </PaginationItem>
    </Pagination>
  )
}


const MovementPage = () => {
  const history = useHistory();
  const [refresh, setRefresh] = useState(true)
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [loading, setlLoading] = useState(false)
  const [selectedType, setSelectedType] = useState(null)

  const findSKULevel = useSKULevels()
  const findUpcomingStock = useUpcomingStock()
  const findSKUStatus = useSKUStatus()

  const [data, state] = useQuery({
    url: `/inventory/movement-history/`,
    refresh,
    queryData: { page: page + 1, perPage: perPage, type: selectedType?.value }
  });
  const historyData = data && data.data
  const count = data ? data.count : 0

  useEffect(() => {
    document.title = `Movement History | JIFI`
  }, [])

  const [orders, setOrders] = useState([])

  const handleSelectType = selectedType => {
    setSelectedType(selectedType)
  }
  const selectTypeOptions = [
    { label: 'All', value: undefined },
    // { label: 'Misc', value: 'misc' },
    { label: 'Picked', value: 'picked' },
    { label: 'Transfer', value: 'transfer' },
    // { label: 'Added', value: 'added' },
    // { label: 'Container', value: 'container' },
    { label: 'Audit', value: 'audit' },
  ]



  return <div className="page-content">
    <Container fluid>
      <Breadcrumbs title="Inventory" breadcrumbItem={'Movement History'} />
      <Row className="mb-2">
        <Col sm="4">
          <FormGroup className="select2-container">
            <Label className="control-label">TYPE</Label>
            <Select
              placeholder="All"
              title="Type"
              classNamePrefix="select2-selection"
              value={selectedType}
              onChange={handleSelectType}
              options={selectTypeOptions}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-2">
        <Card>
          <CardBody>
            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead className="thead-light">
                  <tr>
                    <th>Location</th>
                    <th>Stock In</th>
                    <th>Stock Out</th>
                    <th>Reason</th>
                    <th>Message</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    state === 'success' && (
                      historyData && historyData.map((historyLine, key) => {
                        let inCount = []
                        let outCount = []
                        historyLine.counts.map(count => {
                          if (count?.count?.on_hand > 0) {
                            inCount.push({
                              sku: count?.variant?.sku,
                              count: count?.count?.on_hand
                            })
                          } else {
                            outCount.push({
                              sku: count?.variant?.sku,
                              count: count?.count?.on_hand
                            })
                          }
                        })
                        return (
                          <tr>
                            <td> {historyLine.location.display_name} </td>
                            <td>
                              {
                                inCount.map(val => <div>
                                  {val.sku}
                                  {" "} x <span className="text-success" >+{val.count}</span>
                                </div>)
                              }
                            </td>
                            <td>
                              {
                                outCount.map(val => <div>
                                  {val.sku}
                                  {" "} x <span className="text-danger" >{val.count}</span>
                                </div>)
                              }
                            </td>
                            <td> {historyLine.type} </td>
                            <td> {historyLine.message} </td>
                            <td> {moment.tz(historyLine.createdAt, "Australia/Melbourne").format('D MMM, h:mm a z')}  </td>
                          </tr>
                        )
                      })
                    )
                  }
                </tbody>
              </Table>
            </div>
            {
              (orders && state === 'success') ? (
                <TablePagination count={count} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
              ) : (
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                  <div className="spinner-grow text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )
            }
          </CardBody>
        </Card>
      </Row>
    </Container>
  </div>
}

export default MovementPage