import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import { Container, Input, Button, Table, Label, Badge, Modal, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, ModalHeader } from "reactstrap";

import { useReactToPrint } from 'react-to-print';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import "../datatables.scss";
import useQuery from '../../../helpers/JIFI/useQuery';
import moment from 'moment';
import superagent from 'superagent'

import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';
import { backendUrl } from "../../../helpers/consts";
import PrintPage from "./PrintPage";

const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function downloadPdfs(lineItems, zipFilename) {
    const urls = lineItems.map(lineItem => {
      console.log(lineItem)
      if (!lineItem.pet) {
        return {
          url: `https://storage.googleapis.com/july-monogramming-management.appspot.com/${`${lineItem.monogram_font.toLowerCase()}-${encodeURIComponent(lineItem.monogram_text)}-${lineItem.monogram_color}-${lineItem.monogram_shadow || ''}-undefined${lineItem.sku.startsWith('IPC') ? `-IPC-${lineItem.monogram_position}` : ''}${lineItem.sku.startsWith('BOT') && lineItem.monogram_position === 'Top' ? '-Lid' : ''}.pdf?avoidTheCaches=1`}`,
          filename: `${moment(new Date(lineItem.orderSent)).format('YYYY-MM-DD')}-${lineItem.monogramID}.pdf`,
          pet: false
        }
      } else {
        const petArray = JSON.parse(atob(lineItem.monogram_pets))

        return {
          petData: lineItem.monogram_airtable_line.map((val, key) => {
            return {
              id: val, 
              filename: `${moment(new Date(lineItem.orderSent)).format('YYYY-MM-DD')}-${lineItem.monogramID}-pet#${key}-${petArray[key].name}`
            }
          }),
          pet: true
        }
      }
    })
    const zip = new JSZip();
    let count = 0;
    urls.forEach(async function (url){
      if (url.pet === false) {
        const filename = url.filename
        try {
          const file = await JSZipUtils.getBinaryContent(url.url)
          zip.file(filename, file, { binary: true});
          count++;
          if(count === urls.length) {
            zip.generateAsync({type:'blob'}).then(function(content) {
              saveAs(content, zipFilename);
            });
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        let images = []
        try {
          for(let pet of url.petData) {
            const res = (await superagent.post(`${backendUrl}/orders/fetchPetAirtable`)
                                        .send({ 
                                          airtableId: pet.id
                                        })
                                        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
                                        .set('accept', 'json')).body.fields
            const completedIllustration = res['Completed illustration']
  
            images = [...completedIllustration.map((val, comKey) => {
              let filetype = val.filename.split('.')[1]
              let path = val.url
  
              return {
                url: path, 
                filename: `${pet.filename}-${comKey}.${filetype}`
              }
            })]
  
            await sleep(500)
            console.log(images)
          }
          images.forEach(async function (url){
            const filename = url.filename
            try {
              const file = await JSZipUtils.getBinaryContent(url.url)
              zip.file(filename, file, { binary: true});
              if(count === urls.length) {
                zip.generateAsync({type:'blob'}).then(function(content) {
                  saveAs(content, zipFilename);
                });
              }
            } catch (err) {
              console.log(err);
            }

          })
        } catch (err) {
          console.log(err);
        } 
        count++;
      }
    });
}


const DeliveriesPage = () => {
  const url = '/orders/fetchUNISPersLineItems'

  const [batchModal, setBatchModal] = useState();
  const toggleBatchModal = () => setBatchModal(prev => !prev)

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => setRefresh(prev => !prev)

  const [lineItems, state] = useQuery({ refresh, url });

  const [selected, setSelected] = useState([])
  useEffect(() => {
    lineItems ? setSelected(lineItems.map(() => false)) : setSelected([]) 
  }, [lineItems])
  const selectedIds = lineItems && lineItems.filter((val, key) => selected[key] === true)

  const handleGenerateBatch = () => {
    if (selectedIds && selectedIds.length > 0 && selectedIds.length <= 8){
      toggleBatchModal()
    }
  }

  return (
    <>
      <div>
        
          <Row>
            <Col xs="11">
              <Breadcrumbs title="Monograms" breadcrumbItem="Personalisation List" />
            </Col>
            <Col xs="1">
              <Link className="text-danger" to='/external-personalisation-batches'> Batches </Link>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {
                    (lineItems && state === 'success') ? (
                      <>
                        <Row>
                          <Col xs="4"> </Col>
                          <Col xs="4"> </Col>
                          <Col xs="4">
                            <Button onClick={handleGenerateBatch} color={!(selectedIds && selectedIds.length > 0 && selectedIds.length <= 8) ? 'secondary' : 'success'} style={{marginBottom: '1rem', width: '100%', }} disabled={!(selectedIds && selectedIds.length > 0 && selectedIds.length <= 8)}> Create Batch </Button>
                          </Col>
                        </Row>
                        <div className="table-responsive">
                          <Table className="table table-centered table-nowrap">
                            <thead className="thead-light">
                              <tr>
                                <th style={{ width: "20px" }}>
                                  <div className="custom-control custom-checkbox">
                                    <Input onChange={e => (lineItems ? setSelected(lineItems.map((i, key) => key < 8 ? e.target.checked : false)) : setSelected([])) } type="checkbox" className="custom-control-input" id="customCheck1" />
                                    <Label className="custom-control-label" htmlFor="customCheck1">&nbsp;</Label>
                                  </div>
                                </th>
                                <th>Monogram ID</th>
                                <th>Date</th>
                                <th>Shipping</th>
                                <th>SKU</th>
                                <th>Monogram Text</th>
                                <th>Monogram Font</th>
                                <th>Monogram Color</th>
                                <th>Monogram Secondary Color</th>
                                <th>Monogram Location</th>
                                <th>Pets</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                state === 'success' && (
                                  lineItems && lineItems.map((lineItem, key) =>
                                    <tr key={"_order_" + key}>
                                      <td>
                                        <div 
                                          className="custom-control custom-checkbox"
                                          onClick={() => setSelected(prev => {
                                            let newSelected = [...prev]
                                            newSelected[key] = !newSelected[key]
                                            return newSelected
                                          })} 
                                        >
                                          <Input 
                                            type="checkbox" 
                                            className="custom-control-input" 
                                            id={lineItem.id} 
                                            checked={selected[key]} />
                                          <Label className="custom-control-label" htmlFor={lineItem.id}>&nbsp;</Label>
                                        </div>
                                      </td>
                                      <td>{lineItem.monogramID}</td>
                                      <td>{(new Date(lineItem.orderSent)).toDateString()}</td>
                                      <td> {lineItem.shipping_type} </td>
                                      <td> {lineItem.sku} </td>
                                      <td> {lineItem.monogram_text} </td>
                                      <td> {lineItem.monogram_font} </td>
                                      <td> {lineItem.monogram_color} </td>
                                      <td> {lineItem.monogram_shadow} </td>
                                      <td> {lineItem.monogram_position} </td>
                                      <td> {lineItem.monogram_pets && JSON.parse(atob(lineItem.monogram_pets)).map(pet => pet.name).join(' + ')} </td>
                                    </tr>
                                  )
                                )
                              }
                            </tbody>
                          </Table>
                        </div>
                      </>
                    ) : (
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                          <div className="spinner-grow text-primary m-1" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        
      </div>   
      <Modal isOpen={batchModal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" toggle={toggleBatchModal}>
        <Createbatch batchModal={batchModal} lineItems={lineItems} refresh={refreshData}  togglemodal={toggleBatchModal} selectedIds={selectedIds}/>
      </Modal>
    </>
  );
}

const Createbatch = ({togglemodal, selectedIds, refresh, batchModal}) => {
  const [downloadPrintfiles, setDownloadPrintfiles] = useState(false)
  const [downloadLabel, setDownloadLabel] = useState(false)

  const [batchID, setBatchID] = useState()

  const [loading, setLoading] = useState(false)

  const downloadFiles = () => {
    downloadPdfs(selectedIds, `${batchID}.zip`)
    setDownloadPrintfiles(true)
  }

  const handleBatchList = () => {
    handlePrintLabel()
    setDownloadLabel(true)
  }

  const createBatch = () => {
    superagent.post(`${backendUrl}/orders/createPersonalisationBatch`)
      .send({ 
        lineItems: selectedIds
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          togglemodal()
          refresh()
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });

  }

  useEffect(() => {
    if (batchModal === true) {
      setLoading(true)
      superagent.get(`${backendUrl}/orders/getPersonalisationBatchID`)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
          setLoading(false)
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
            console.log(response.text)
            setBatchID(response.text)
            setLoading(false)
          }
        }).catch(err => {
          console.log(err)
          setLoading(false)
        });

    }
  }, [ batchModal ])


  const packingLabelsRef = useRef();
  const printLabels = useReactToPrint({ content: () => packingLabelsRef.current });
  const handlePrintLabel = () => printLabels()

  return (
    <div>
      <div className="modal-content">
        <ModalHeader toggle={togglemodal}>
          Confirm Batch {batchID}
        </ModalHeader >
        <ModalBody>
          {
            loading ? (
              <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                <div className="spinner-grow text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div style={{display: 'flex'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                  <Button color={downloadPrintfiles ? 'secondary' : 'primary'} className="m-2" onClick={downloadFiles}> 
                    { downloadPrintfiles && <i className="mdi mdi-sticker-check-outline mr-1" /> }
                    Download Print Files
                  </Button>
                  <Button color={downloadLabel ? 'secondary' : 'primary'} className="m-2" onClick={handleBatchList}> 
                    { downloadLabel && <i className="mdi mdi-sticker-check-outline mr-1" /> }
                    Print Batch list 
                  </Button>
                </div>
              </div>
            )
          }
        </ModalBody>
        <ModalFooter>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50%'}}>
            <Button type="button" color={downloadPrintfiles && downloadLabel ? 'success' : 'secondary'} onClick={createBatch}>Create Batch</Button>
          </div>
        </ModalFooter>
      </div>
      <div style={{display: 'none'}}>
        <PrintPage 
          ref={packingLabelsRef}
          monogramRecord={{
            batchID: batchID, 
            lineItems: selectedIds
          }} 
          monogram={false}
        />
      </div>
    </div>
  )
}


export default DeliveriesPage;
