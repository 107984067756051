import React, { useEffect, useState } from "react";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { Button, Card, Col, Container, Row, FormGroup, Label, Table, Tooltip } from "reactstrap";
import * as moment from 'moment-timezone';
import { useHistory } from "react-router-dom";
import useQuery from "../../../helpers/JIFI/useQuery";
import {backendUrl} from '../../../helpers/consts.js'
import superagent from 'superagent'
import Select from 'react-select';
var XLSX = require("xlsx");

const localLocations = {
  'AU': ['HQ2', 'QV Store', 'EWE', 'EWE - Sydney', 'Galeries Sydney', '80 Collins', 'CHDRETAIL', 'Bondi'],
  'US': ['USA - Dallas', 'UNIS'],
  'UK': ['Airbox UK']
}

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

// const reverseArr = (input) => {
//   var ret = new Array;
//   for(var i = input.length-1; i >= 0; i--) {
//       ret.push(input[i]);
//   }
//   return ret;
// }

// const abstractPost = (sendData, url, history, callback) => {
//   superagent.post(`${backendUrl}/${url}`)
//     .send(sendData)
//     .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
//     .set('accept', 'json')
//     .on('error', (response) => {
//         const code = response.status
//         history.replace(history.location.pathname, {
//             errorStatusCode: code
//         });
//     })
//     .then(response => {
//       const code = response.status
//       if (code > 400) {
//           history.replace(history.location.pathname, {
//             errorStatusCode: code
//           });
//       } else {
//         callback(response.body)
//       }
//     }).catch(err => {
//       console.log(err)
//     });
// }

const MonthCoverToolTip = ({sku, monthCover, startDate, children}) => {
  const [id, setID] = useState(`SKU-MONTH-${sku.split(' ').join('')}${Math.trunc(Math.random() * 1000 + 1)}_${Math.trunc(Math.random() * 1000 + 1)}`)
  useEffect(() => {
    setID((`SKU-${sku.split(' ').join('')}${Math.trunc(Math.random() * 1000 + 1)}_${Math.trunc(Math.random() * 1000 + 1)}`))
  }, [sku])
  const [toolOpen, setToolOpen] = useState(false)
  const toggle = () => {
    setToolOpen(prev => !prev)
  }

  const daysAdd = monthCover * 30
  const etaDate = moment(startDate).add(1, 'months').add(daysAdd, 'days')

  return (
    <span>
      <span style={{cursor: 'help'}} id={id}>{children}</span>
      <Tooltip placement="right" isOpen={toolOpen} target={id} toggle={toggle}>
        <div>ETA OOS: {etaDate.format('DD-MM-YYYY')}</div>
      </Tooltip>
    </span>
  )
}

const MonthsPage = () => {
  const history = useHistory()
  const [r, setR] = useState(false)
  const refresh = () => setR(prev => !prev)

  let [tableData, setTabledata] = useState([])
  let [isLoadingCSVData, setIsLoadingCSVData] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState({ label: 'AU', value: 'AU' })

  const [currentLocationCount, currentLocationCountState] = useQuery({
    url: '/forecast/fetchLocationCount',
    refresh: r,
    queryData: {location: selectedLocation.value}
  })

  const [monthTimeline, monthTimelineState] = useQuery({
    url: '/forecast/getMonthTimeline',
    refresh: r,
    queryData: {location: selectedLocation.value}
  })

  const [pendingData, pendingState] = useQuery({
    url: `/inventory/pendingStock${selectedLocation.value.replace('AU', '')}`,
    refresh: r,
  });

  const [containers, containerState] = useQuery({
    url: `/inventory/fetchUpcomingContainer/`,
    refresh: r,
  });

  const dateArray = monthTimeline && [
    ...monthTimeline.previousMonths.map(month => <th colspan="4">{moment(month.startDate).format('MMMM')}</th>),
    <th colspan="4">{moment(monthTimeline.currentMonth.startDate).format('MMMM')}</th>,
    ...monthTimeline.futureMonths.map(month => <th colspan="4">{moment(month.startDate).format('MMMM')}</th>)
  ]

  const dateArrayData = monthTimeline && [
    ...monthTimeline.previousMonths.map(month => moment(month?.startDate).format('MMMM')),
    moment(monthTimeline.currentMonths?.startDate).format('MMMM'),
    ...monthTimeline.futureMonths.map(month => moment(month?.startDate).format('MMMM'))
  ]

  const revArray = monthTimeline && [
    ...monthTimeline.previousMonths.map(month => month.realRevenue),
    monthTimeline.currentMonth.estimatedRevenue,
    ...monthTimeline.futureMonths.map(month => month.estimatedRevenue)
  ]

  const percentageArray = monthTimeline && revArray.map((revenue, key) => {
    if (key === 0) {
      return 0
    } else {
      return ((revenue - revArray[key-1])/revArray[key-1])
    }
  })

  useEffect(() => {
    if (containers && currentLocationCount && pendingData) {
      const allTableData = currentLocationCount.map(line => {
        const sku = line[0]
        const previousRevs = monthTimeline.previousMonths.map(month => month.realRevenue)
        const previousBreakdowns = monthTimeline.previousMonths.map(month => month.skuBreakdown.find(val => val.sku === sku)?.count || 0)
        let caseRevRatios = previousRevs.map((val, key) => previousBreakdowns[key]/val).filter(val => val !== 0)
        let casesPerRev = caseRevRatios.reduce((partialSum, a) => partialSum + a, 0) / caseRevRatios.length
        var start = moment().startOf('month').toDate(), // Jan 1, 2015
        end = moment().endOf('month').toDate(), // August 24, 2015
        today = moment().toDate(), // April 23, 2015
        p = 1 - (Math.round(((today - start) / (end - start)) * 100) / 100)
  
        const currentSalesEstimate = Math.ceil(casesPerRev * monthTimeline.currentMonth.estimatedRevenue) || 0
        const onHandPassDown = line.filter((val, key) => key !== 0).reduce((partialSum, a) => partialSum + a, 0) - (pendingData[sku] || 0) - (Math.ceil(currentSalesEstimate * p) || 0)
  
        const containerLineData = [...containers].reverse().map(container => {
          let containerLine = container.containerLines.find(containerLine => containerLine.variant.sku === sku)
          let count = containerLine === undefined ? 0 : containerLine.quantity
          let location = container.landingLocation.name
          let date = container.arrivalDate
          return {
            count,
            location,
            date
          }
        }).filter(val => val.count !== 0 && localLocations[selectedLocation.value].includes(val.location))
        const incomingCurrent = containerLineData.filter((containerLine) => moment(containerLine.date).isBefore(moment(monthTimeline.currentMonth.startDate).endOf('month')) && moment(containerLine.date).isAfter(moment(monthTimeline.currentMonth.startDate).startOf('month'))).map(val => val.count).reduce((partialSum, a) => partialSum + a, 0) || 0
        const onHand = onHandPassDown + incomingCurrent
        const currentMonthCover = Math.max(0, ((onHand / currentSalesEstimate ))).toFixed(1)
  
        const futureData = monthTimeline.futureMonths.map((month, monthKey) => {
          const salesEstimate = Math.ceil(casesPerRev * month.estimatedRevenue) || 0
  
          const incomingCount = containerLineData.filter((containerLine) => moment(containerLine.date).isBefore(moment(month.startDate).endOf('month')) && moment(containerLine.date).isAfter(moment(month.startDate).startOf('month'))).map(val => val.count).reduce((partialSum, a) => partialSum + a, 0)
          const incomingCountSum = containerLineData.filter((containerLine) => moment(containerLine.date).isBefore(moment(month.startDate).endOf('month'))).map(val => val.count).reduce((partialSum, a) => partialSum + a, 0)
  
          const onHandEstimate = Math.max(0, onHandPassDown - (monthTimeline.futureMonths.filter((val, key) => key <= monthKey).map(val => Math.ceil(casesPerRev * val.estimatedRevenue) || 0).reduce((partialSum, a) => partialSum + a, 0)) + incomingCountSum)
          const monthsCovered = Math.max( 0,  ( onHandEstimate / salesEstimate ) || 0 ).toFixed(1)
  
          let futureData = {
            sales: salesEstimate, 
            onHand: onHandEstimate, 
            incoming: incomingCount, 
            monthCover: monthsCovered
          }
          return futureData
        })

        const previousData = previousBreakdowns.map((sales) => {
          let previousData = {
            sales: sales, 
          }
          return previousData
        })

        return {
          sku: line[0],
          previousMonths: previousData,
          currentMonths: {
            sales: currentSalesEstimate,
            onHand: onHandPassDown,
            incoming: incomingCurrent,
            monthCover: currentMonthCover
          },
          futureMonths: futureData
        }
      })
      setTabledata(allTableData)
    }
  }, [containers, currentLocationCount, pendingData])

  let usLocations = ['USA - Dallas', 'UNIS']

  const handleCSV = async () => {
    setIsLoadingCSVData(true)
    let addMonthTimelineCSV = null
    let addLocationCountCSV = null

    try {
      const monthTimelineCSV = (await superagent.get(`${backendUrl}${'/forecast/getMonthTimeline'}${'?location='}${selectedLocation.value}&`)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')).body
      const locationCountCSV = (await  superagent.get(`${backendUrl}${'/forecast/fetchLocationCount'}${'?location='}${selectedLocation.value}&`)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')).body
      addLocationCountCSV = locationCountCSV
      addMonthTimelineCSV = monthTimelineCSV
    } catch (e) {
      console.log(e)
    }

    const allTableData = addLocationCountCSV.map(line => {
      const sku = line[0]
      const previousRevs = addMonthTimelineCSV.previousMonths.map(month => month.realRevenue)
      const previousBreakdowns = addMonthTimelineCSV.previousMonths.map(month => month.skuBreakdown.find(val => val.sku === sku)?.count || 0)
      let caseRevRatios = previousRevs.map((val, key) => previousBreakdowns[key]/val).filter(val => val !== 0)
      let casesPerRev = caseRevRatios.reduce((partialSum, a) => partialSum + a, 0) / caseRevRatios.length
      var start = moment().startOf('month').toDate(), // Jan 1, 2015
      end = moment().endOf('month').toDate(), // August 24, 2015
      today = moment().toDate(), // April 23, 2015
      p = 1 - (Math.round(((today - start) / (end - start)) * 100) / 100)

      const currentSalesEstimate = Math.ceil(casesPerRev * addMonthTimelineCSV.currentMonth.estimatedRevenue) || 0
      const onHandPassDown = line.filter((val, key) => key !== 0).reduce((partialSum, a) => partialSum + a, 0) - (pendingData[sku] || 0) - (Math.ceil(currentSalesEstimate * p) || 0)

      const containerLineData = [...containers].reverse().map(container => {
        let containerLine = container.containerLines.find(containerLine => containerLine.variant.sku === sku)
        let count = containerLine === undefined ? 0 : containerLine.quantity
        let location = container.landingLocation.name
        let date = container.arrivalDate
        return {
          count,
          location,
          date
        }
      }).filter(val => val.count !== 0 && localLocations[selectedLocation.value].includes(val.location))
      const incomingCurrent = containerLineData.filter((containerLine) => moment(containerLine.date).isBefore(moment(addMonthTimelineCSV.currentMonth.startDate).endOf('month')) && moment(containerLine.date).isAfter(moment(addMonthTimelineCSV.currentMonth.startDate).startOf('month'))).map(val => val.count).reduce((partialSum, a) => partialSum + a, 0) || 0
      const onHand = onHandPassDown + incomingCurrent
      const currentMonthCover = Math.max(0, ((onHand / currentSalesEstimate ))).toFixed(1)

      const futureData = addMonthTimelineCSV.futureMonths.map((month, monthKey) => {
        const salesEstimate = Math.ceil(casesPerRev * month.estimatedRevenue) || 0

        const incomingCount = containerLineData.filter((containerLine) => moment(containerLine.date).isBefore(moment(month.startDate).endOf('month')) && moment(containerLine.date).isAfter(moment(month.startDate).startOf('month'))).map(val => val.count).reduce((partialSum, a) => partialSum + a, 0)
        const incomingCountSum = containerLineData.filter((containerLine) => moment(containerLine.date).isBefore(moment(month.startDate).endOf('month'))).map(val => val.count).reduce((partialSum, a) => partialSum + a, 0)

        const onHandEstimate = Math.max(0, onHandPassDown - (addMonthTimelineCSV.futureMonths.filter((val, key) => key <= monthKey).map(val => Math.ceil(casesPerRev * val.estimatedRevenue) || 0).reduce((partialSum, a) => partialSum + a, 0)) + incomingCountSum)
        const monthsCovered = Math.max( 0,  ( onHandEstimate / salesEstimate ) || 0 ).toFixed(1)

        let futureData = {
          sales: salesEstimate, 
          onHand: onHandEstimate, 
          incoming: incomingCount, 
          monthCover: monthsCovered
        }
        return futureData
      })

      const previousData = previousBreakdowns.map((sales) => {
        let previousData = {
          sales: sales, 
        }
        return previousData
      })

      return {
        sku: line[0],
        previousMonths: previousData,
        currentMonths: {
          sales: currentSalesEstimate,
          onHand: onHandPassDown,
          incoming: incomingCurrent,
          monthCover: currentMonthCover
        },
        futureMonths: futureData
      }
    })

    let dateArrayDataCSV = addMonthTimelineCSV && [
      ...addMonthTimelineCSV.previousMonths.map(month => moment(month?.startDate).format('MMMM')),
      moment(addMonthTimelineCSV.currentMonths?.startDate).format('MMMM'),
      ...addMonthTimelineCSV.futureMonths.map(month => moment(month?.startDate).format('MMMM'))
    ]
  
    let revArrayCSV = addMonthTimelineCSV && [
      ...addMonthTimelineCSV.previousMonths.map(month => month.realRevenue),
      addMonthTimelineCSV.currentMonth.estimatedRevenue,
      ...addMonthTimelineCSV.futureMonths.map(month => month.estimatedRevenue)
    ]

    let csv_ws1 = [
      ['SKU', dateArrayData?.[0], dateArrayData?.[1], dateArrayData?.[2], dateArrayData?.[3], '', '', '', dateArrayData?.[4], '', '', '', dateArrayData?.[5], '', '', '', dateArrayData?.[6], '', '', '',],
      ['', 'SALES', 'SALES', 'SALES', 'SALES', 'ON HAND', 'INCOMING', 'MONTHS COVER', 'SALES', 'ON HAND', 'INCOMING', 'MONTHS COVER', 'SALES', 'ON HAND', 'INCOMING', 'MONTHS COVER', 'SALES', 'ON HAND', 'INCOMING', 'MONTHS COVER',],
      ['', formatter.format(revArray?.[0]), formatter.format(revArray?.[1]), formatter.format(revArray?.[2]), formatter.format(revArray?.[3]), '', '', '', formatter.format(revArray?.[4]), '', '', '', formatter.format(revArray?.[5]), '', '', '', formatter.format(revArray?.[6]), '', '', ''],
      ...tableData.map((item, index) => [
        item.sku,
        ...(item.previousMonths.map(month => [
          month.sales,
        ]).flat()),
        item.currentMonths.sales,
        item.currentMonths.onHand,
        item.currentMonths.incoming,
        item.currentMonths.monthCover,
        ...(item.futureMonths.map(month => [
          month.sales,
          month.onHand,
          month.incoming,
          month.monthCover,
        ]).flat())
      ])
    ];

    let csv_ws2 = [
      ['SKU', dateArrayDataCSV?.[0], dateArrayDataCSV?.[1], dateArrayDataCSV?.[2], dateArrayDataCSV?.[3], '', '', '', dateArrayDataCSV?.[4], '', '', '', dateArrayDataCSV?.[5], '', '', '', dateArrayDataCSV?.[6], '', '', ''],
      ['', 'SALES', 'SALES', 'SALES', 'SALES', 'ON HAND', 'INCOMING', 'MONTHS COVER', 'SALES', 'ON HAND', 'INCOMING', 'MONTHS COVER', 'SALES', 'ON HAND', 'INCOMING', 'MONTHS COVER', 'SALES', 'ON HAND', 'INCOMING', 'MONTHS COVER'], 
      ['', formatter.format(revArrayCSV?.[0]), formatter.format(revArrayCSV?.[1]), formatter.format(revArrayCSV?.[2]), formatter.format(revArrayCSV?.[3]), '', '', '', formatter.format(revArrayCSV?.[4]), '', '', '', formatter.format(revArrayCSV?.[5]), '', '', '', formatter.format(revArrayCSV?.[6]), '', '', ''],
      ...allTableData.map((item, index) => [
        item.sku,
        ...(item.previousMonths.map(month => [
          month.sales,
        ]).flat()),
        item.currentMonths.sales,
        item.currentMonths.onHand,
        item.currentMonths.incoming,
        item.currentMonths.monthCover,
        ...(item.futureMonths.map(month => [
          month.sales,
          month.onHand,
          month.incoming,
          month.monthCover,
        ]).flat())
      ])
    ];  

    const ws1 = XLSX.utils.aoa_to_sheet(csv_ws1);
    const ws2 = XLSX.utils.aoa_to_sheet(csv_ws2);
  
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws1, selectedLocation.value === 'AU' ? 'AU' : 'US');
    XLSX.utils.book_append_sheet(wb, ws2, selectedLocation.value === 'AU' ? 'US' : 'AU');
    const dateString = (new Date()).toDateString().replace(' ', '-').replace(' ', '-').replace(' ', '-')

    XLSX.writeFileXLSX(wb, `FORECAST_${dateString}.csv`)

    setIsLoadingCSVData(false)
  }

  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Forecast" breadcrumbItem="Forecast" />
          <Row>
            <Col sm="2" style={{paddingBottom: 8}}>
              {isLoadingCSVData ? (
                <div className="spinner-grow text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <Button type="button" color="secondary" className="btn-lg btn-rounded" onClick={() => {handleCSV();}}>
                  CSV
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs="4">
              <FormGroup className="select2-container">
                <Label className="control-label">Location</Label>
                <Select classNamePrefix="select2-selection" value={selectedLocation} onChange={setSelectedLocation} placeholder="AU" title="Pickup Location" options={ [{ label: 'AU', value: 'AU' }, { label: 'US', value: 'US' }, { label: 'UK', value: 'UK' }]} />
              </FormGroup>
            </Col>
          </Row>
            {
              currentLocationCountState === 'success' && monthTimelineState === 'success' && currentLocationCountState && monthTimelineState ? (
                <Table className="table mb-0">
                  <thead style={{position: 'sticky', top: 123, background: 'white'}}>
                    <tr>
                      <th>SKU</th>
                      { dateArray }
                    </tr>
                    <tr>
                      <td></td>
                      {
                        revArray.map((rev, key) => <td colspan="4">
                          {formatter.format(rev)} 
                          {percentageArray[key] !== 0 && (
                            <span className={`text-${percentageArray[key] > 0 ? 'success' : 'danger'}`}>
                              <i className={`bx bx-caret-${percentageArray[key] > 0 ? 'up' : 'down'}`}/>
                              {(percentageArray[key] * 100).toFixed(2)}%
                            </span> 
                          )} 
                        </td>
                        )
                      }
                    </tr>
                    <tr>
                      <td></td>
                      {
                        revArray.map((rev, key) => (key >= monthTimeline.previousMonths.length) ? <>
                          <td> SALES </td>
                          <td> ON HAND </td>
                          <td> INCOMING </td>
                          <td> MONTHS COVER </td>
                        </>: <>
                          <td colspan="4">SALES</td>
                        </>)
                      }
                    </tr>
                  </thead>
                  <tbody>
                  {
                   tableData.map((item) => {
                      if (item.previousMonths.every(number => number.sales === 0)) {
                        return <></>
                      }
                      return (
                        <tr>
                          <td>{item.sku}</td>
                          {
                            item.previousMonths.map(val => (
                              <>
                                <td colspan="4">{val.sales}</td>
                              </>
                            ))
                          }
                          <td>{item.currentMonths.sales}</td>
                          <td>{item.currentMonths.onHand}</td>
                          <td>{item.currentMonths.incoming}</td>
                          <td style={{background: `rgba(200, 0, 0, ${item.currentMonths.monthCover > 3.5 ? 0 : (3.5 - item.currentMonths.monthCover) / 3.5})`}}>
                            <MonthCoverToolTip sku={item.sku} monthCover={item.currentMonths.monthCover} startDate={monthTimeline.currentMonth.startDate}>
                              {item.currentMonths.monthCover}
                            </MonthCoverToolTip>
                          </td>
                          {
                            item.futureMonths.map((month) => {
                              return (
                                <>
                                  <td>{month.sales}</td>
                                  <td>{month.onHand}</td>
                                  <td>{month.incoming}</td>
                                  <td style={{background: `rgba(200, 0, 0, ${month.monthCover > 3.5 ? 0 : (3.5 - month.monthCover) / 3.5})`}}>
                                    <MonthCoverToolTip sku={item.sku} monthCover={month.monthCover} startDate={month.startDate}>
                                      {month.monthCover}
                                    </MonthCoverToolTip>
                                  </td>
                                </>
                              )
                            })
                          }
                        </tr>
                      )
                    })
                  }
                  </tbody>
              </Table>
              ) : (
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                  <div className="spinner-grow text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )
            }
        </Container>
      </div>
    </div>
  )
}


export default MonthsPage
