import React, { useState, useEffect, useRef } from "react";
import { 
    Tooltip, 
    Nav, 
    CardTitle, 
    TabContent, 
    TabPane, 
    NavLink, 
    NavItem, 
    Container, 
    Button, 
    Badge, 
    UncontrolledDropdown, 
    DropdownToggle, 
    DropdownMenu, 
    DropdownItem,
    Row, 
    Col, 
    Card, 
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input
} from "reactstrap";
import { Link } from "react-router-dom";
import "../datatables.scss";
import useQuery from '../../../helpers/JIFI/useQuery';
import Select from 'react-select'
import classnames from "classnames";
import CommentHistory from "../../Skote/Dashboard/CommentHistory";
import useProductData from '../orders/useProductData'
import Autocomplete from "react-google-autocomplete";

import superagent from 'superagent'
import { backendUrl } from '../../../helpers/consts.js'
import moment from 'moment'

import { Country, State } from 'country-state-city';
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import AsyncSelect from 'react-select/async';

const returnedToBadge = (returned) => {
  return returned ? 'success' : 'warning'
}

const useSKULevels = (refresh) => {
  const [allStockLevels, allStockLevelState] = useQuery({
    url: `/product/all-stock-v2`,
    refresh,
  });

  const findSKULevel = (sku) => {
    if (!allStockLevels) {
      return [0, 0, 0, 0]
    }
    const rootSKU = sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')
    const findSKULine = allStockLevels.find(val => val[0] === rootSKU)

    if (findSKULine === undefined) {
      return [0, 0, 0, 0]
    }

    return findSKULine.slice(1)
  }
  return findSKULevel
}

const SKUToolTip = ({ sku, findSKULevel }) => {
  const [id, setID] = useState(`SKU-${sku}${Math.trunc(Math.random() * 1000 + 1)}_${Math.trunc(Math.random() * 1000 + 1)}`)
  useEffect(() => {
    setID((`SKU-${sku}${Math.trunc(Math.random() * 1000 + 1)}_${Math.trunc(Math.random() * 1000 + 1)}`))
  }, [sku])
  const [toolOpen, setToolOpen] = useState(false)
  const toggle = () => {
    setToolOpen(prev => !prev)
  }
  const values = findSKULevel(sku)
  return (
    <span>
      <span style={{ textDecoration: 'underline', cursor: 'help' }} id={id}>{sku}</span>
      <Tooltip placement="right" isOpen={toolOpen} target={id} toggle={toggle}>
        <div>HQ: {values[0]}</div>
        <div>QV Store: {values[1]}</div>
        <div>80 Collins: {values[7]}</div>
        <div>Galeries Store: {values[6]}</div>
        <div>EWE: {values[2]}</div>
        <div>EWE Syd: {values[5]}</div>
        <div>DALLAS: {values[3]}</div>
        <div>UNIS: {values[4]}</div>
      </Tooltip>
    </span>
  )
}

const calculateNonShippedSkus = (order) => {
    let allShippedSkus = (order.shipments?.map(val => val.items.map(item => item)).flat()) || []
    let allNonShippedSkus = order.line_items.filter(val => !allShippedSkus?.includes(val.sku) ) 
    return allNonShippedSkus
}

const LineItems = ({lineItems, getVariant, findSKULevel}) => {
    return (
        <Row>
            <Col sm="12">
                {
                    lineItems.map(lineItem => {
                    return (
                        <Row>
                            <Col sm="2">
                                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={getVariant(lineItem.sku)?.image} alt="" className="img-fluid" />
                                </div>
                            </Col>
                            <Col sm="4" className="p-3">
                                <h6>{getVariant(lineItem.sku)?.name}</h6>
                                <div>SKU: <SKUToolTip sku={lineItem.sku} findSKULevel={findSKULevel} /> x {lineItem.quantity} </div>
                            </Col>
                            <Col sm="4" className="p-3">

                            </Col>
                        </Row>
                    )
                    })
                }
            </Col>
      </Row>

    )
}

const OrderLine = ({order, getVariant, findSKULevel}) => {
    let [selected, setSelected] = useState(0)
    let [allNonShippedSkus, setAllNonShippedSkus] = useState(calculateNonShippedSkus(order))

    return (
        <Row>
        <Col s="12" md='8'>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>{order.name}</CardTitle>
                  <Nav tabs className="">
                    {
                        order.shipments?.map((shipmentTab, shipmentIndex) => {
                            return (
                                <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: `${shipmentIndex}` === `${selected}` })} onClick={() => setSelected(`${shipmentIndex}`)}>
                                        {shipmentTab.warehouse}
                                    </NavLink>
                                </NavItem>
                            )
                            
                        })
                    }
                    {
                        allNonShippedSkus.length > 0 && (
                            <NavItem>
                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: `${(order.shipments?.length || 0)}` === `${selected}` })} onClick={() => setSelected(`${(order.shipments?.length || 0)}`)} >
                                    NOT SHIPPED
                                </NavLink>
                            </NavItem>
                        )
                    }
                  </Nav>
                  <TabContent activeTab={`${selected}`}>
                    {
                        order.shipments?.map((shipmentTab, shipmentIndex) => {
                            return (
                                <TabPane tabId={`${shipmentIndex}`} className="p-3">
                                    <LineItems findSKULevel={findSKULevel} getVariant={getVariant} lineItems={order.line_items.filter(lineItem => shipmentTab.items.includes(lineItem.sku) )}/>
                                </TabPane>
                            )
                            
                        })
                    }
                    {
                        allNonShippedSkus.length > 0 && (
                            <TabPane tabId={`${(order.shipments?.length || 0)}`} className="p-3">
                                <LineItems findSKULevel={findSKULevel} getVariant={getVariant}  lineItems={allNonShippedSkus}/>
                            </TabPane>
                        )
                    }
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col s="12" md="4">
          <div style={{ position: 'relative', height: '100%' }}>
          <div id="stickyTest" style={{ position: 'sticky', top: 150 }}>
            <Card>
                <CardBody>
                  {/* <h6 style={{ marginBottom: 16 }}> Shipping Address </h6> */}
                  <div> {`${order?.shipping_address?.city || ''} ${order?.shipping_address?.province || ''}`} </div>
                  <div> {order?.shipping_address?.country || ''} </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    )
}

const Automations = () => {
  const [refresh, setRefresh] = useState(true)

  const refreshData = () => {
    setRefresh(prev => !prev)
  }

  const [data, state] = useQuery({
    url: `/orders/automation`,
    refresh
  });

//   let data = orderData
  const orders = data ? data.orders : []

  const getVariant = useProductData()

  const findSKULevel = useSKULevels()

  return (
    <div className="page-content">
      <Container fluid>
        {
            orders.map(order => <OrderLine findSKULevel={findSKULevel} getVariant={getVariant} order={order} />)
        }
      </Container>
    </div>
  )
}

export default Automations