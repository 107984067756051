import React from 'react'

import FontFile from '../shared/font-file'

const Word = (props) => {
  return (
    <FontFile
      font={'college'}
      topOffset="-0.08em"
      multiplier={1.3}
      letterSpace="0.1em"
      {...props}
      borderColor={props.backCol}
    />
  )
}

export default Word
