import React from 'react';
import { Container,  } from 'reactstrap';
import SupplyChainNav from '../../../components/supply-chain/nav.js';


import ExternalPers from '../external-pers';
import ExternalPersAirbox from '../external-pers-airbox';
import ExternalPersEWE from '../external-pers-ewe';
import ExternalPersLaunch from '../external-pers-launch';


import { useHistory } from 'react-router-dom';



const FulFillmentLocationPage = () => {
 
  const user = JSON.parse(localStorage.getItem("authUser"))


  const history = useHistory();
 

  return (
    <SupplyChainNav menuType="fulfillment-location">
      <Container>
        {history?.location?.pathname?.includes('launch') && (
          <ExternalPersLaunch></ExternalPersLaunch>
        )}
        {history?.location?.pathname?.includes('ewe') && (
          <ExternalPersEWE></ExternalPersEWE>
        )}

        {history?.location?.pathname?.includes('airbox') && (
          <ExternalPersAirbox></ExternalPersAirbox>
        )}

        {history?.location?.pathname?.includes('unis') && (
          <ExternalPers></ExternalPers>
        )}
      </Container>
    </SupplyChainNav>
  );
};

export default FulFillmentLocationPage;
