import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function splitInitialState(initialState) {
  const urlInitialState = {};
  const nonUrlInitialState = {};

  for (const [key, value] of Object.entries(initialState)) {
    if (value !== null && value !== undefined && value != '') {
      if (key.includes('Options')) {
        nonUrlInitialState[key] = value;
      } else {
        urlInitialState[key] = value;
      }
    }
  }

  return { urlInitialState, nonUrlInitialState };
}

function getIteratorLength(iterator) {
  let length = 0;
  for (let item of iterator) {
      length++;
  }
  return length;
}

export function useUrlState(initialState) {
  const history = useHistory();
  const location = useLocation();

  const { urlInitialState, nonUrlInitialState } = splitInitialState(initialState);

  const [urlState, setUrlState] = useState({ current: urlInitialState, previous: {} });
  const [nonUrlState, setNonUrlState] = useState(nonUrlInitialState);
  const [loaded, setLoaded] = useState(false);

  const urlUpdater = useCallback((newState) => {
    setUrlState((prevState) => {
      return {
        current: newState,
        previous: prevState.current,
      };
    });
  }, []);

  const nonUrlUpdater = useCallback((newState) => {
    setNonUrlState((prevState) => ({ ...prevState, ...newState }));
  }, []);

  // Update state from URL or use initial state if it's not in the URL.
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const newState = {};

    for (const [key, value] of searchParams.entries()) {
      const parsedValue = JSON.parse(decodeURIComponent(value));
      if (parsedValue != null && parsedValue != '') {
        newState[key] = parsedValue
      }
    }

    if (getIteratorLength(searchParams.entries()) === 0 && loaded == false) {
      setLoaded(true)
      setState(initialState)
    } else if (JSON.stringify(newState) !== JSON.stringify(urlState.current)) {
      urlUpdater(newState);
    }
    
  }, [location.search, urlState.current, urlUpdater]);

  // Function to update both urlState and nonUrlState.
  const setState = useCallback((newStateOrUpdater) => {
    const newState = typeof newStateOrUpdater === 'function'
      ? newStateOrUpdater({ ...urlState.current, ...nonUrlState })
      : newStateOrUpdater;

    const urlStateUpdates = {};
    const nonUrlStateUpdates = {};

    console.log(newState)

    for (const [key, value] of Object.entries(newState)) {
      if (value !== null && value !== undefined && value != '') {
        if (key.includes('Options')) {
          nonUrlStateUpdates[key] = value;
        } else {
          urlStateUpdates[key] = value;
        }
      }
    }

    console.log(urlStateUpdates)

    if (true) {
      urlUpdater(urlStateUpdates);

      const newSearchParams = new URLSearchParams();

      for (const [key, value] of Object.entries(urlStateUpdates)) {
        if (value !== null && value !== undefined && value != '') {
          newSearchParams.set(key, encodeURIComponent(JSON.stringify(value)));
        }
      }

      if (location.search.slice(1) !== newSearchParams.toString()) {
        history.push({
          ...location,
          search: newSearchParams.toString(),
        });
      }
    }

    if (Object.keys(nonUrlStateUpdates).length > 0) {
      nonUrlUpdater(nonUrlStateUpdates);
    }
  }, [urlState.current, nonUrlState, urlUpdater, nonUrlUpdater, location.search, history]);
  
  // Merge urlState and nonUrlState into one state object.
  const state = { ...urlState.current, ...nonUrlState };

  return [state, setState];
}
