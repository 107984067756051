import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError } from './actions';

//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import { postLogin } from '../../../helpers/JIFI/authHandler';

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history, path } }) {
    try {
        const response = yield call(postLogin, { email: user.email, password: user.password });
        if (response.error !== undefined) {
            throw response.error.response.body.message
        }
        console.log(response)
        localStorage.setItem("authJWT", response.token);
        localStorage.setItem("refreshJWT", response.refresh);
        localStorage.setItem("authUser", JSON.stringify(response.user));
        yield put(loginSuccess(response));
        console.log(user, history, path)
        history.push(path !== undefined ? path : '/dashboard');
    } catch (error) {
        console.log(error)
        yield put(apiError(error));
    }
}

function* refreshLogin() {
    try {
        const response = yield call(refreshLogin );
        localStorage.setItem("authJWT", response.token);
        localStorage.setItem("authUser", JSON.stringify(response.user));
        yield put(loginSuccess(response));
    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        localStorage.removeItem("authJWT");

        if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
            const response = yield call(fireBaseBackend.logout);
            yield put(logoutUserSuccess(response));
        }
        history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}


export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default authSaga;