import React, { useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { get } from "lodash";
import Pages404 from "../../pages/Utility/pages-404";

import jwt_decode from "jwt-decode";
import { refreshLogin } from "./authHandler";

const checkJWT = async (location) => {
  const redirect = (
    <Redirect
      to={{
        pathname: "/login",
        state: {
          from: location.pathname
        }
      }}
    />
  )

  let token = localStorage.getItem('refreshJWT');
  if (token == undefined) {
    return redirect
  }

  let decodedToken = jwt_decode(token);
  let currentDate = new Date();

  // JWT exp is in seconds
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    window.localStorage.removeItem('authJWT')
    window.localStorage.removeItem('refreshJWT')
    return redirect
  } else {
    try {
      await refreshLogin()
      return <Redirect
          to={{
            pathname: location.pathname
          }}
        />
  
    } catch (e) {
      console.log(e)
      window.localStorage.removeItem('authJWT')
      window.localStorage.removeItem('refreshJWT')  
      return <Redirect
          to={{
            pathname: 'login'
          }}
        />
  
    }
  }
}

const ErrorHandler = ({ children }) => {
  const location = useLocation();

  const [jwtErrorValue, setJWTErrorValue] = useState(<></>)
  useEffect(() => {
    if (get(location.state, "errorStatusCode") === 401) {
      checkJWT(location).then((res) => {
        setJWTErrorValue(res)
      })
  
    }
  }, [location])

  switch (get(location.state, "errorStatusCode")) {
    case 404:
      return <Pages404 />;
    case 401:
      return jwtErrorValue 
    default:
      return children;
  }
};

export default ErrorHandler;
