import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, Media } from "reactstrap";

import china from "./china.png";
import aus from "./aus.png";
// Pages Components
import WelcomeComp from "./WelcomeComp";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { arrayNotNull } from "../../../utilities/utilities";
import useQuery from '../../../helpers/JIFI/useQuery';

import { Link } from "react-router-dom";

import './skelton.css'

const countryLookup = {
  "Andorra": "🇦🇩",
  "United Arab Emirates": "🇦🇪",
  "Afghanistan": "🇦🇫",
  "Antigua And Barbuda": "🇦🇬",
  "Anguilla": "🇦🇮",
  "Albania": "🇦🇱",
  "Armenia": "🇦🇲",
  "Angola": "🇦🇴",
  "Antarctica": "🇦🇶",
  "Argentina": "🇦🇷",
  "American Samoa": "🇦🇸",
  "Austria": "🇦🇹",
  "Australia": "🇦🇺",
  "Aruba": "🇦🇼",
  "Aland Islands": "🇦🇽",
  "Azerbaijan": "🇦🇿",
  "Bosnia And Herzegovina": "🇧🇦",
  "Barbados": "🇧🇧",
  "Bangladesh": "🇧🇩",
  "Belgium": "🇧🇪",
  "Burkina Faso": "🇧🇫",
  "Bulgaria": "🇧🇬",
  "Bahrain": "🇧🇭",
  "Burundi": "🇧🇮",
  "Benin": "🇧🇯",
  "Saint Barthelemy": "🇧🇱",
  "Bermuda": "🇧🇲",
  "Brunei Darussalam": "🇧🇳",
  "Bolivia": "🇧🇴",
  "BQ": "🇧🇶",
  "Brazil": "🇧🇷",
  "Bahamas": "🇧🇸",
  "Bhutan": "🇧🇹",
  "Bouvet Island": "🇧🇻",
  "Botswana": "🇧🇼",
  "Belarus": "🇧🇾",
  "Belize": "🇧🇿",
  "Canada": "🇨🇦",
  "Cocos (Keeling) Islands": "🇨🇨",
  "Congo, Democratic Republic": "🇨🇩",
  "Central African Republic": "🇨🇫",
  "Congo": "🇨🇬",
  "Switzerland": "🇨🇭",
  "Cote D'Ivoire": "🇨🇮",
  "Cook Islands": "🇨🇰",
  "Chile": "🇨🇱",
  "Cameroon": "🇨🇲",
  "China": "🇨🇳",
  "Colombia": "🇨🇴",
  "Costa Rica": "🇨🇷",
  "Cuba": "🇨🇺",
  "Cape Verde": "🇨🇻",
  "CW": "🇨🇼",
  "Christmas Island": "🇨🇽",
  "Cyprus": "🇨🇾",
  "Czech Republic": "🇨🇿",
  "Germany": "🇩🇪",
  "Djibouti": "🇩🇯",
  "Denmark": "🇩🇰",
  "Dominica": "🇩🇲",
  "Dominican Republic": "🇩🇴",
  "Algeria": "🇩🇿",
  "Ecuador": "🇪🇨",
  "Estonia": "🇪🇪",
  "Egypt": "🇪🇬",
  "Western Sahara": "🇪🇭",
  "Eritrea": "🇪🇷",
  "Spain": "🇪🇸",
  "Ethiopia": "🇪🇹",
  "Finland": "🇫🇮",
  "Fiji": "🇫🇯",
  "Falkland Islands (Malvinas)": "🇫🇰",
  "Micronesia, Federated States Of": "🇫🇲",
  "Faroe Islands": "🇫🇴",
  "France": "🇫🇷",
  "Gabon": "🇬🇦",
  "United Kingdom": "🇬🇧",
  "Grenada": "🇬🇩",
  "Georgia": "🇬🇪",
  "French Guiana": "🇬🇫",
  "Guernsey": "🇬🇬",
  "Ghana": "🇬🇭",
  "Gibraltar": "🇬🇮",
  "Greenland": "🇬🇱",
  "Gambia": "🇬🇲",
  "Guinea": "🇬🇳",
  "Guadeloupe": "🇬🇵",
  "Equatorial Guinea": "🇬🇶",
  "Greece": "🇬🇷",
  "South Georgia And Sandwich Isl.": "🇬🇸",
  "Guatemala": "🇬🇹",
  "Guam": "🇬🇺",
  "Guinea-Bissau": "🇬🇼",
  "Guyana": "🇬🇾",
  "Hong Kong": "🇭🇰",
  "Heard Island & Mcdonald Islands": "🇭🇲",
  "Honduras": "🇭🇳",
  "Croatia": "🇭🇷",
  "Haiti": "🇭🇹",
  "Hungary": "🇭🇺",
  "Indonesia": "🇮🇩",
  "Ireland": "🇮🇪",
  "Israel": "🇮🇱",
  "Isle Of Man": "🇮🇲",
  "India": "🇮🇳",
  "British Indian Ocean Territory": "🇮🇴",
  "Iraq": "🇮🇶",
  "Iran, Islamic Republic Of": "🇮🇷",
  "Iceland": "🇮🇸",
  "Italy": "🇮🇹",
  "Jersey": "🇯🇪",
  "Jamaica": "🇯🇲",
  "Jordan": "🇯🇴",
  "Japan": "🇯🇵",
  "Kenya": "🇰🇪",
  "Kyrgyzstan": "🇰🇬",
  "Cambodia": "🇰🇭",
  "Kiribati": "🇰🇮",
  "Comoros": "🇰🇲",
  "Saint Kitts And Nevis": "🇰🇳",
  "KP": "🇰🇵",
  "Korea": "🇰🇷",
  "Kuwait": "🇰🇼",
  "Cayman Islands": "🇰🇾",
  "Kazakhstan": "🇰🇿",
  "Lao People's Democratic Republic": "🇱🇦",
  "Lebanon": "🇱🇧",
  "Saint Lucia": "🇱🇨",
  "Liechtenstein": "🇱🇮",
  "Sri Lanka": "🇱🇰",
  "Liberia": "🇱🇷",
  "Lesotho": "🇱🇸",
  "Lithuania": "🇱🇹",
  "Luxembourg": "🇱🇺",
  "Latvia": "🇱🇻",
  "Libyan Arab Jamahiriya": "🇱🇾",
  "Morocco": "🇲🇦",
  "Monaco": "🇲🇨",
  "Moldova": "🇲🇩",
  "Montenegro": "🇲🇪",
  "Saint Martin": "🇲🇫",
  "Madagascar": "🇲🇬",
  "Marshall Islands": "🇲🇭",
  "Macedonia": "🇲🇰",
  "Mali": "🇲🇱",
  "Myanmar": "🇲🇲",
  "Mongolia": "🇲🇳",
  "Macao": "🇲🇴",
  "Northern Mariana Islands": "🇲🇵",
  "Martinique": "🇲🇶",
  "Mauritania": "🇲🇷",
  "Montserrat": "🇲🇸",
  "Malta": "🇲🇹",
  "Mauritius": "🇲🇺",
  "Maldives": "🇲🇻",
  "Malawi": "🇲🇼",
  "Mexico": "🇲🇽",
  "Malaysia": "🇲🇾",
  "Mozambique": "🇲🇿",
  "Namibia": "🇳🇦",
  "New Caledonia": "🇳🇨",
  "Niger": "🇳🇪",
  "Norfolk Island": "🇳🇫",
  "Nigeria": "🇳🇬",
  "Nicaragua": "🇳🇮",
  "Netherlands": "🇳🇱",
  "Norway": "🇳🇴",
  "Nepal": "🇳🇵",
  "Nauru": "🇳🇷",
  "Niue": "🇳🇺",
  "New Zealand": "🇳🇿",
  "Oman": "🇴🇲",
  "Panama": "🇵🇦",
  "Peru": "🇵🇪",
  "French Polynesia": "🇵🇫",
  "Papua New Guinea": "🇵🇬",
  "Philippines": "🇵🇭",
  "Pakistan": "🇵🇰",
  "Poland": "🇵🇱",
  "Saint Pierre And Miquelon": "🇵🇲",
  "Pitcairn": "🇵🇳",
  "Puerto Rico": "🇵🇷",
  "Palestinian Territory, Occupied": "🇵🇸",
  "Portugal": "🇵🇹",
  "Palau": "🇵🇼",
  "Paraguay": "🇵🇾",
  "Qatar": "🇶🇦",
  "Reunion": "🇷🇪",
  "Romania": "🇷🇴",
  "Serbia": "🇷🇸",
  "Russian Federation": "🇷🇺",
  "Rwanda": "🇷🇼",
  "Saudi Arabia": "🇸🇦",
  "Solomon Islands": "🇸🇧",
  "Seychelles": "🇸🇨",
  "Sudan": "🇸🇩",
  "Sweden": "🇸🇪",
  "Singapore": "🇸🇬",
  "Saint Helena": "🇸🇭",
  "Slovenia": "🇸🇮",
  "Svalbard And Jan Mayen": "🇸🇯",
  "Slovakia": "🇸🇰",
  "Sierra Leone": "🇸🇱",
  "San Marino": "🇸🇲",
  "Senegal": "🇸🇳",
  "Somalia": "🇸🇴",
  "Suriname": "🇸🇷",
  "SS": "🇸🇸",
  "Sao Tome And Principe": "🇸🇹",
  "El Salvador": "🇸🇻",
  "SX": "🇸🇽",
  "Syrian Arab Republic": "🇸🇾",
  "Swaziland": "🇸🇿",
  "Turks And Caicos Islands": "🇹🇨",
  "Chad": "🇹🇩",
  "French Southern Territories": "🇹🇫",
  "Togo": "🇹🇬",
  "Thailand": "🇹🇭",
  "Tajikistan": "🇹🇯",
  "Tokelau": "🇹🇰",
  "Timor-Leste": "🇹🇱",
  "Turkmenistan": "🇹🇲",
  "Tunisia": "🇹🇳",
  "Tonga": "🇹🇴",
  "Turkey": "🇹🇷",
  "Trinidad And Tobago": "🇹🇹",
  "Tuvalu": "🇹🇻",
  "Taiwan": "🇹🇼",
  "Tanzania": "🇹🇿",
  "Ukraine": "🇺🇦",
  "Uganda": "🇺🇬",
  "United States Outlying Islands": "🇺🇲",
  "United States": "🇺🇸",
  "Uruguay": "🇺🇾",
  "Uzbekistan": "🇺🇿",
  "Holy See (Vatican City State)": "🇻🇦",
  "Saint Vincent And Grenadines": "🇻🇨",
  "Venezuela": "🇻🇪",
  "Virgin Islands, British": "🇻🇬",
  "Virgin Islands, U.S.": "🇻🇮",
  "Viet Nam": "🇻🇳",
  "Vanuatu": "🇻🇺",
  "Wallis And Futuna": "🇼🇫",
  "Samoa": "🇼🇸",
  "Yemen": "🇾🇪",
  "Mayotte": "🇾🇹",
  "South Africa": "🇿🇦",
  "Zambia": "🇿🇲",
  "Zimbabwe": "🇿🇼"
}

const Dashboard = ({ props }) => {
    const initialState = {
        reportCard: [
            { key: 'fraudReview', title: "Fraud Review", iconClass: "bx-copy-alt", count: 0, color: 'danger', url: '/orders?' },
            { key: 'zendeskOpenTickets', title: "Zendesk Open Tickets", iconClass: "bx-archive-in", count: 0, color: 'primary', url: '/orders?' },
            { key: 'orderIssues', title: "Order/Stock Issues", iconClass: "bx-purchase-tag-alt", count: 0, color: 'warning', url: '/orders?' }
        ],
        statusCard: [
            { key: 'flagged', title: "Flagged", iconClass: "mdi-flag-outline", count: 0, color: 'danger', url: '/orders?selectedStatus=%255B%257B%2522label%2522%253A%2522Unfulfilled%2522%252C%2522value%2522%253A%2522unfulfilled%2522%257D%252C%257B%2522label%2522%253A%2522Awaiting%2520Stock%2522%252C%2522value%2522%253A%2522awaiting-stock%2522%257D%255D&filterStatus=%255B%2522unfulfilled%2522%252C%2522awaiting-stock%2522%255D&selectedOrder=%255B%257B%2522label%2522%253A%2522Flagged%2522%252C%2522value%2522%253A%2522Flagged%2522%257D%255D&filterOrder=%255B%2522Flagged%2522%255D' },
            { key: 'monogrammed', title: "Monogrammed", iconClass: "mdi mdi-drawing", count: 0, color: 'primary', url: '/orders?selectedStatus=%255B%257B%2522label%2522%253A%2522Unfulfilled%2522%252C%2522value%2522%253A%2522unfulfilled%2522%257D%252C%257B%2522label%2522%253A%2522Awaiting%2520Stock%2522%252C%2522value%2522%253A%2522awaiting-stock%2522%257D%255D&filterStatus=%255B%2522unfulfilled%2522%252C%2522awaiting-stock%2522%255D&selectedOrder=%255B%257B%2522label%2522%253A%2522Has%2520personalisation%2522%252C%2522value%2522%253A%2522Has-personalisation%2522%257D%255D&filterOrder=%255B%2522Has-personalisation%2522%255D' },
            { key: 'ready', title: "Ready To Ship", iconClass: "mdi-truck-outline", count: 0, color: 'warning', url: '/orders?selectedStatus=%255B%257B%2522label%2522%253A%2522Unfulfilled%2522%252C%2522value%2522%253A%2522unfulfilled%2522%257D%255D&filterStatus=%255B%2522unfulfilled%2522%255D&selectedOrder=%255B%257B%2522label%2522%253A%2522Ready%2520to%2520ship%2522%252C%2522value%2522%253A%2522ready-to-ship%2522%257D%255D&filterOrder=%255B%2522ready-to-ship%2522%255D' },
            { key: 'awaiting', title: "Awaiting Stock", iconClass: "mdi-truck-outline", count: 0, color: 'secondary', url: '/orders/?selectedStatus=%255B%257B%2522label%2522%253A%2522Awaiting%2520Stock%2522%252C%2522value%2522%253A%2522awaiting-stock%2522%257D%255D&filterStatus=%255B%2522awaiting-stock%2522%255D' },
            { key: 'partial', title: "Partially Fulfilled", iconClass: "mdi-progress-check", count: 0, color: 'secondary', url: '/orders/?selectedStatus=%255B%257B%2522label%2522%253A%2522Partially%2520Fulfilled%2522%252C%2522value%2522%253A%2522partially-fulfilled%2522%257D%255D&filterStatus=%255B%2522partially-fulfilled%2522%255D' },
        ],
        shippingCard: [
            { key: 'sameday', title: "Same Day", iconClass: "mdi-clock-fast", count: 0, color: 'danger', url: '/orders' },
            { key: 'nextday', title: "Next Day", iconClass: "mdi-truck-fast-outline", count: 0, color: 'danger', url: '/orders' },
            { key: 'express', title: "Express", iconClass: "mdi-truck-delivery", count: 0, color: 'danger', url: '/orders/?selectedStatus=%255B%257B%2522label%2522%253A%2522Unfulfilled%2522%252C%2522value%2522%253A%2522unfulfilled%2522%257D%252C%257B%2522label%2522%253A%2522Awaiting%2520Stock%2522%252C%2522value%2522%253A%2522awaiting-stock%2522%257D%255D&filterStatus=%255B%2522unfulfilled%2522%252C%2522awaiting-stock%2522%255D&selectedShipping=%255B%257B%2522label%2522%253A%2522Express%2522%252C%2522value%2522%253A%2522express%2522%257D%255D&filterShipping=%255B%2522express%2522%255D' },
            { key: 'standard', title: "Standard", iconClass: "mdi-truck-outline", count: 0, color: 'warning', url: '/orders/?selectedStatus=%255B%257B%2522label%2522%253A%2522Unfulfilled%2522%252C%2522value%2522%253A%2522unfulfilled%2522%257D%252C%257B%2522label%2522%253A%2522Awaiting%2520Stock%2522%252C%2522value%2522%253A%2522awaiting-stock%2522%257D%255D&filterStatus=%255B%2522unfulfilled%2522%252C%2522awaiting-stock%2522%255D&selectedShipping=%255B%257B%2522label%2522%253A%2522Standard%2522%252C%2522value%2522%253A%2522standard%2522%257D%255D&filterShipping=%255B%2522standard%2522%255D' },
            { key: 'pickup', title: "Pickup", iconClass: "mdi-inbox-arrow-down", count: 0, color: 'primary', url: '/orders/?selectedStatus=%255B%257B%2522label%2522%253A%2522Unfulfilled%2522%252C%2522value%2522%253A%2522unfulfilled%2522%257D%252C%257B%2522label%2522%253A%2522Awaiting%2520Stock%2522%252C%2522value%2522%253A%2522awaiting-stock%2522%257D%255D&filterStatus=%255B%2522unfulfilled%2522%252C%2522awaiting-stock%2522%255D&selectedShipping=%255B%257B%2522label%2522%253A%2522HQ%2520Pickup%2522%252C%2522value%2522%253A%2522pickup_hq%2522%257D%252C%257B%2522label%2522%253A%2522QV%2520Pickup%2522%252C%2522value%2522%253A%2522pickup%2522%257D%255D&filterShipping=%255B%2522pickup_hq%2522%252C%2522pickup%2522%255D' },
            { key: 'uber', title: "Uber", fabIcon: "fab fa-uber", count: 0, color: 'secondary', url: '/orders/?selectedStatus=%255B%257B%2522label%2522%253A%2522Unfulfilled%2522%252C%2522value%2522%253A%2522unfulfilled%2522%257D%252C%257B%2522label%2522%253A%2522Awaiting%2520Stock%2522%252C%2522value%2522%253A%2522awaiting-stock%2522%257D%255D&filterStatus=%255B%2522unfulfilled%2522%252C%2522awaiting-stock%2522%255D&selectedShipping=%255B%257B%2522label%2522%253A%2522Uber%2522%252C%2522value%2522%253A%2522Uber%2522%257D%255D&filterShipping=%255B%2522Uber%2522%255D' },
          ],
        regionCard: [
            { key: 'australia', title: "Australia", count: 0, url: '/orders/?selectedStatus=%255B%257B%2522label%2522%253A%2522Unfulfilled%2522%252C%2522value%2522%253A%2522unfulfilled%2522%257D%252C%257B%2522label%2522%253A%2522Awaiting%2520Stock%2522%252C%2522value%2522%253A%2522awaiting-stock%2522%257D%255D&filterStatus=%255B%2522unfulfilled%2522%252C%2522awaiting-stock%2522%255D&selectedRegion=%257B%2522label%2522%253A%2522Australia%2522%252C%2522value%2522%253A%2522Australia%2522%257D&filterRegion=%2522Australia%2522' },
            { key: 'china', title: "China", count: 0, url: '/orders/?selectedStatus=%255B%257B%2522label%2522%253A%2522Unfulfilled%2522%252C%2522value%2522%253A%2522unfulfilled%2522%257D%252C%257B%2522label%2522%253A%2522Awaiting%2520Stock%2522%252C%2522value%2522%253A%2522awaiting-stock%2522%257D%255D&filterStatus=%255B%2522unfulfilled%2522%252C%2522awaiting-stock%2522%255D&selectedRegion=%257B%2522label%2522%253A%2522China%2522%252C%2522value%2522%253A%2522China%2522%257D&filterRegion=%2522China%2522' },
            { key: 'international', title: "International", count: 0, url: '/orders/?selectedStatus=%255B%257B%2522label%2522%253A%2522Unfulfilled%2522%252C%2522value%2522%253A%2522unfulfilled%2522%257D%252C%257B%2522label%2522%253A%2522Awaiting%2520Stock%2522%252C%2522value%2522%253A%2522awaiting-stock%2522%257D%255D&filterStatus=%255B%2522unfulfilled%2522%252C%2522awaiting-stock%2522%255D&selectedRegion=%257B%2522label%2522%253A%2522International%2522%252C%2522value%2522%253A%2522International%2522%257D&filterRegion=%2522International%2522' }
        ],
        approvalCard: [
            { key: 'approvals', title: "Approvals", count: 0, url: '/approvals' },
        ],
        replenishCard: [
            { key: 'pendingEWE', title: "EWE DROPS", count: 0, url: '/bookEWE' },
        ],
        flagTypeCart: [
            { key: 'cancellation', title: "Cancellation", iconClass: "mdi-flag-outline", color: 'danger', url: '/orders?selectedFlagType=%255B%257B%2522label%2522%253A%2522Cancellation%2522%252C%2522value%2522%253A%2522637ac735edde8a0e0f297017%2522%257D%255D&filterFlagType=%255B%2522637ac735edde8a0e0f297017%2522%255D' },
            { key: 'fraud', title: "Fraud", iconClass: "mdi-flag-outline", color: 'danger', url: '/orders?selectedFlagType=%255B%257B%2522label%2522%253A%2522Fraud%2522%252C%2522value%2522%253A%2522637b09d5913a570e12fb4882%2522%257D%255D&filterFlagType=%255B%2522637b09d5913a570e12fb4882%2522%255D'},
            { key: 'onhold', title: "On Hold", iconClass: "mdi-flag-outline", color: 'danger', url: '/orders?selectedFlagType=%255B%257B%2522label%2522%253A%2522On%2520Hold%2522%252C%2522value%2522%253A%252263747fbe6e76212105356e89%2522%257D%255D&filterFlagType=%255B%252263747fbe6e76212105356e89%2522%255D'},
            { key: 'orderAmendment', title: "Order Amendment ", iconClass: "mdi-flag-outline", color: 'danger', url: '/orders?selectedFlagType=%255B%257B%2522label%2522%253A%2522Order%2520Amendment%2520%2522%252C%2522value%2522%253A%2522638027d42ca6d70de7eb17f3%2522%257D%255D&filterFlagType=%255B%2522638027d42ca6d70de7eb17f3%2522%255D'},
            { key: 'outOfStock', title: "Out of Stock ", iconClass: "mdi-flag-outline", color: 'danger', url: '/orders?selectedFlagType=%255B%257B%2522label%2522%253A%2522Out%2520of%2520Stock%2520%2522%252C%2522value%2522%253A%2522639150744abc2b0e001a657e%2522%257D%255D&filterFlagType=%255B%2522639150744abc2b0e001a657e%2522%255D'},
            { key: 'waitResponse', title: "OOS - Waiting for Response", iconClass: "mdi-flag-outline", color: 'danger', url: '/orders?selectedFlagType=%255B%257B%2522label%2522%253A%2522OOS%2520-%2520Waiting%2520for%2520Response%2522%252C%2522value%2522%253A%252264990d52216d3926ad651645%2522%257D%255D&filterFlagType=%255B%252264990d52216d3926ad651645%2522%255D'},
            { key: 'pendingRetailChange', title: "Pending retail changes", iconClass: "mdi-flag-outline", color: 'danger', url: '/orders?selectedFlagType=%255B%257B%2522label%2522%253A%2522Pending%2520retail%2520changes%2522%252C%2522value%2522%253A%252264b4786529c83526aee2f4af%2522%257D%255D&filterFlagType=%255B%252264b4786529c83526aee2f4af%2522%255D'},
            { key: 'fulfilment', title: "BMPRO Fulfilment", iconClass: "mdi-flag-outline", color: 'danger', url: '/orders?selectedFlagType=%255B%257B%2522label%2522%253A%2522BMPRO%2520FULFILMENT%2520%2522%252C%2522value%2522%253A%252264dab0300b53c626aea8d9a9%2522%257D%255D&filterFlagType=%255B%252264dab0300b53c626aea8d9a9%2522%255D'},
            { key: 'retailStockTransfer', title: "Retail Stock Transfer", iconClass: "mdi-flag-outline", color: 'danger', url: '/orders?selectedFlagType=%255B%257B%2522label%2522%253A%2522Retail%2520Stock%2520Transfer%2522%252C%2522value%2522%253A%25226668e77d4610bb4b5fb7815f%2522%257D%255D&filterFlagType=%255B%25226668e77d4610bb4b5fb7815f%2522%255D'},
            { key: 'pendingTracking', title: "Pending - Tracking Update", iconClass: "mdi-flag-outline", color: 'danger', url: '/orders?selectedFlagType=%255B%257B%2522label%2522%253A%2522Pending%2520-%2520Tracking%2520Update%2522%252C%2522value%2522%253A%25226668e7a03b0e4bb0f5598a7f%2522%257D%255D&filterFlagType=%255B%25226668e7a03b0e4bb0f5598a7f%2522%255D'},
        ],
        newOrders: 0,
        corp: 0
    }
    const [stateData, setStateData] = useState(initialState);

    const [data, state] = useQuery({ url: "/dashboard/details", queryData: {} });

    const [monogramData, monogramState] = useQuery({ url: "/dashboard/monogram", queryData: {} });

    const dashboard = data && data.data

    useEffect(() => {
        if (dashboard) {
            let { regionCard, statusCard, approvalCard, shippingCard, replenishCard, flagTypeCart } = { ...stateData };
            regionCard && regionCard.map((card, i) => {
                regionCard[i].count = dashboard[`${card.key}`]
            });
            statusCard && statusCard.map((card, i) => {
                statusCard[i].count = dashboard[`${card.key}`]
            });
            approvalCard && approvalCard.map((card, i) => {
                approvalCard[i].count = dashboard[`${card.key}`]
            });
            replenishCard && replenishCard.map((card, i) => {
                replenishCard[i].count = dashboard[`${card.key}`]
            });
            shippingCard && shippingCard.map((card, i) => {
                shippingCard[i].count = dashboard[`${card.key}`]
            });
            setStateData((prevState => {
                return {
                    ...prevState,
                    newOrders: dashboard ? dashboard.newOrder : 0,
                    corp: dashboard ? dashboard.corp : 0,
                    regionCard: regionCard,
                    statusCard: statusCard,
                    approvalCard: approvalCard,
                    replenishCard: replenishCard,
                    flagTypeCart: flagTypeCart,
                }
            }))
        }
    }, [dashboard])

    const user = JSON.parse(localStorage.getItem("authUser"))

    const { reportCard, statusCard, regionCard, approvalCard, shippingCard, replenishCard, flagTypeCart } = stateData;
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />
                    <Row>
                        <Col xl="5">
                            <WelcomeComp monogramData={monogramData} stateData={stateData} dashboard={dashboard} />
                            {/* <TopCities /> */}
                        </Col>
                        <Col xl="7">
                            {/* <Row>
                                {arrayNotNull(reportCard) && reportCard.map((report, key) =>
                                    <Col md="4" key={`_col_${key}`}>
                                        <Link to={report.url}>
                                            <Card className="mini-stats-wid">
                                                <CardBody>
                                                    <Media>
                                                        <Media body>
                                                            <p className="text-muted font-weight-medium">{report.title}</p>
                                                            <h4 className="mb-0">{report.count}</h4>
                                                        </Media>
                                                        <div className={`mini-stat-icon avatar-sm rounded-circle bg-${report.color} d-flex align-items-center justify-content-center`}>
                                                            <span className="">
                                                                <i className={`text-white bx ${report.iconClass} font-size-24`}></i>
                                                            </span>
                                                        </div>
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </Col>
                                )}
                            </Row> */}
                            {
                                (
                                    user.permissions.includes('BookStockDrop') ||
                                    user.permissions.includes('ViewApprovals')
                                ) && (
                                    <>
                                        <CardTitle>
                                            PENDING:
                                        </CardTitle>
                                        <Row>
                                            {
                                                user.permissions.includes('BookStockDrop') && (
                                                    <Col sm="6">
                                                        <Row>
                                                            {arrayNotNull(replenishCard) && replenishCard.map((region, key) =>
                                                                <Col sm="12" key={`_col_1_${key}`}>
                                                                    <Link to={region.url}>
                                                                        <Card className="mini-stats-wid">
                                                                            <CardBody>
                                                                                <Media>
                                                                                    <Media body>
                                                                                        <p className="text-muted font-weight-medium">{region.title}</p>
                                                                                        <h4 className={`mb-0 ${dashboard ? '' : 'placeholder'}`}>{dashboard ? region.count : ' '}</h4>
                                                                                    </Media>
                                                                                </Media>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Link>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    </Col>
                                                )
                                            }
                                            {
                                                user.permissions.includes('ViewApprovals')  && (
                                                    <Col sm="6">
                                                        <Row>
                                                            {arrayNotNull(approvalCard) && approvalCard.map((region, key) =>
                                                                <Col sm="12" key={`_col_1_${key}`}>
                                                                    <Link to={region.url}>
                                                                        <Card className="mini-stats-wid">
                                                                            <CardBody>
                                                                                <Media>
                                                                                    <Media body>
                                                                                        <p className="text-muted font-weight-medium">{region.title}</p>
                                                                                        <h4 className={`mb-0 ${dashboard ? '' : 'placeholder'}`}>{dashboard ? region.count : ' '}</h4>
                                                                                    </Media>
                                                                                </Media>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Link>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    </>
                                )
                            }
                            <CardTitle className="mb-4">
                                UNFULFILLED ORDERS BY STATUS:
                            </CardTitle>
                            <Row>
                                {arrayNotNull(statusCard) && statusCard.map((status, key) =>
                                    <Col sm="4" key={`_col_0_${key}`}>
                                        <Link to={status.url}>
                                            <Card className="mini-stats-wid">
                                                <CardBody>
                                                    <Media>
                                                        <Media body>
                                                            <p className="text-muted font-weight-medium">{status.title}</p>
                                                            <h4 className={`mb-0 ${dashboard ? '' : 'placeholder'}`}>{dashboard ? status.count : ' '}</h4>
                                                        </Media>
                                                        <div className={`mini-stat-icon avatar-sm rounded-circle bg-${status.color} d-flex align-items-center justify-content-center`}>
                                                            <span className="">
                                                                {
                                                                    status.fabIcon ? <i className={`text-white ${status.iconClass} font-size-24`}></i> : <i className={`text-white mdi ${status.iconClass} font-size-24`}></i>
                                                                }
                                                                <img />
                                                            </span>
                                                        </div>
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </Col>
                                )}
                            </Row>
                            <CardTitle className="mb-4">
                                FLAGGED ORDERS BY TYPES:
                            </CardTitle>
                            <Row>
                                {arrayNotNull(dashboard?.flaggedCounts) && dashboard?.flaggedCounts.sort((a,b) => a._id?.localeCompare(b._id)).map((flaggedType, key) =>
                                    <Col sm="4" key={`_col_1_${key}`}>
                                        <Link to={flagTypeCart.find(element => element.title === flaggedType._id)?.url ?? '#'}>
                                            <Card className="mini-stats-wid">
                                                <CardBody>
                                                    <Media>
                                                        <Media body>
                                                            <p className="text-muted font-weight-medium">{flaggedType._id ?? 'Unknown'}</p>
                                                            <h4 className={`mb-0 ${dashboard ? '' : 'placeholder'}`}>{dashboard ? flaggedType.flaggedCount: ' '}</h4>
                                                        </Media>
                                                        <div className={`mini-stat-icon avatar-sm rounded-circle bg-danger d-flex align-items-center justify-content-center`}>
                                                            <span className="" style={{fontSize: 32}}>
                                                                <i className={`text-white mdi mdi-flag-outline font-size-24`}></i>
                                                            </span>
                                                        </div>
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </Col>
                                )}
                            </Row>
                            <CardTitle className="mb-4">
                                UNFULFILLED ORDERS BY SHIPPING:
                            </CardTitle>
                            <Row>
                                {arrayNotNull(shippingCard) && shippingCard.map((status, key) =>
                                    <Col sm="4" key={`_col_0_${key}`}>
                                        <Link to={status.url}>
                                            <Card className="mini-stats-wid">
                                                <CardBody>
                                                    <Media>
                                                        <Media body>
                                                            <p className="text-muted font-weight-medium">{status.title}</p>
                                                            <h4 className={`mb-0 ${dashboard ? '' : 'placeholder'}`}>{dashboard ? status.count : ' '}</h4>
                                                        </Media>
                                                        <div className={`mini-stat-icon avatar-sm rounded-circle bg-${status.color} d-flex align-items-center justify-content-center`}>
                                                            <span className="">
                                                                {
                                                                    status.fabIcon ? <i className={`text-white ${status.iconClass} font-size-24`}></i> : <i className={`text-white mdi ${status.iconClass} font-size-24`}></i>
                                                                }
                                                                <img />
                                                            </span>
                                                        </div>
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </Col>
                                )}
                            </Row>
                            <CardTitle>
                                UNFULFILLED ORDERS BY REGION:
                            </CardTitle>
                            <Row>
                                {arrayNotNull(dashboard?.countryCounts) && dashboard?.countryCounts.sort((a,b) => a._id?.localeCompare(b._id)).map((region, key) =>
                                    <Col sm="4" key={`_col_1_${key}`}>
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <Media>
                                                    <Media body>
                                                        <p className="text-muted font-weight-medium">{region._id || 'Unknown'}</p>
                                                        <h4 className={`mb-0 ${dashboard ? '' : 'placeholder'}`}>{dashboard ? region.countryCount: ' '}</h4>
                                                    </Media>
                                                    <div className={`mini-stat-icon avatar-sm rounded-circle bg-warning d-flex align-items-center justify-content-center`}>
                                                        <span className="avatar-title" style={{fontSize: 32}}>
                                                            {countryLookup[region._id]}
                                                        </span>
                                                    </div>
                                                </Media>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Dashboard;
