import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Table, Pagination, PaginationItem, PaginationLink, Row, Col, Card, CardBody, Badge, FormGroup, Label, Input } from "reactstrap";
import '../../Skote/Dashboard/skelton.css'
import Select from 'react-select';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import "../datatables.scss";
import useQuery from '../../../helpers/JIFI/useQuery';
import moment from 'moment';
import { CSVLink, CSVDownload } from "react-csv";

import superagent from 'superagent'

const perPageOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500 - (WARNING SLOW)' },
  { value: 1000, label: '1000 - (WARNING SLOW)' },
]

const paginate = function (array, index) {
  return [
    array[index - 2],
    array[index - 1],
    array[index],
    array[index + 1],
    array[index + 2]
  ].filter(function (el) {
    return el != null;
  });
}


const stageToBadge = (pick) => {
  const conversion = {
    'SENT_TO_CUSTOMER': 'info',
    'RECEIVED_BY_WAREHOUSE': 'warning',
    'REPAIR_UNSUCCESSFUL': 'danger',
    'REPAIR_COMPLETED': 'success',
    'REAPIR_DISPACHED': 'success'
  }
  return conversion[pick] || 'success'
}


const TablePagination = ({ count, page, setPage, perPage, setPerPage }) => {
  const pageCount = Math.ceil(count / perPage)
  const pages = paginate(Array.from({ length: pageCount }, (v, i) => i + 1), page)
  const handleNumberClicked = (key) => {
    setPage(key)
  }
  return (
    <Pagination className="pagination pagination-rounded justify-content-end mb-2">
      <div style={{ height: '100%', width: 140 }}>
        <Select
          options={perPageOptions}
          onChange={newPerPage => setPerPage(newPerPage.value)}
          value={perPageOptions.find(val => val.value === perPage)}
        />
      </div>
      <PaginationItem disabled>
        <PaginationLink previous />
      </PaginationItem>
      {
        pages.map(val => (
          <PaginationItem onClick={() => handleNumberClicked(val)} active={val === page}>
            <PaginationLink>
              {val}
            </PaginationLink>
          </PaginationItem>
        ))
      }
      <PaginationItem>
        <PaginationLink next />
      </PaginationItem>
    </Pagination>
  )
}

const stages = [
  'SENT_TO_CUSTOMER',
  'RECEIVED_BY_WAREHOUSE',
  'REPAIR_UNSUCCESSFUL',
  'REPAIR_COMPLETED',
  'REAPIR_DISPACHED'
]

const returnTypes = [
  'TSA_LOCK',
  'HANDLE_TELESCOPIC',
  'HANDLE_CARRY',
  'TRUNK_LOCK',
  'SNAPSLEEVE_FIDLOCK',
  'TRUNK_CLIP',
  'LUGGAGE_FEET',
  'MONOGRAM_REPRINT',
  'ZIP'
]

const regions = [
  'Australia',
  'New Zealand',
  'United States',
  'United Kingdom'
]

const orderSources = [
  "shopify_au",
  "shopify_us",
  "shopify_nz",
  "shopify_uk",
  "qv_store",
  "galeries_store",
  "bondi",
  "80_collins",
  "hq_store",
  "goop",
  "custom",
  'pitt_st',
  'pacfair', 
  'james_st',
  'parramatta'
]

const OrdersPage = () => {
  const url = '/returns/allReturns'
  const initialState = {}

  const [stateData, setStateData] = useState(initialState);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
    setRefresh(prev => !prev)
  }

  const [searchTerm, setSearchTerm] = useState()
  const handleTermChange = (e) => {
    const newTerm = e.currentTarget.value.toUpperCase().replace('#', '')
    setSearchTerm(newTerm)
  }
  const [orderStartDate, setOrderStartDate] = useState('')
  const [orderEndDate, setOrderEndDate] = useState('')
  const handleChange = (e) => {
    e.persist();
    if (e.target.name === 'orderStartDate') {
      setOrderStartDate(moment(e.target.value).format('YYYY-MM-DD'))
    } else if (e.target.name === 'orderEndDate') {
      setOrderEndDate(moment(e.target.value).format('YYYY-MM-DD'))
    }
  };
  const [selectedStage, setSelectedStage] = useState()
  const [selectedType, setSelectedType] = useState()
  const [selectedRegion, setSelectedRegion] = useState()

  const queryObject = {
    startDate: orderStartDate,
    endDate: orderEndDate,
    stage: selectedStage?.map(val => val.value),
    type: selectedType?.map(val => val.value),
    region: selectedRegion?.map(val => val.value),
    searchTerm: searchTerm
  }
  Object.keys(queryObject).forEach(key => (queryObject[key] === undefined || queryObject[key] === '') && delete queryObject[key])

  const [data, state] = useQuery({ refresh, url, queryData: { page: page, perPage: perPage, ...queryObject } });

  const returns = data && data.data;
  const count = data && data.count;

  let dataToCsv = []
  if (data) {
    dataToCsv = data.map((line, key) => {
      return [
        line.orderNumber,
        line.cxDate,
        line.shipping_address[0].country,
        line.shipping_address[0].name,
        line.reason,
        line.stage,
      ]
    })
  }

  const csvData = [
    ['Order ID', 'Date', 'Country', 'Customer Name', 'Reason', 'State'],
    ...dataToCsv
  ]

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Fulfillment" breadcrumbItem="Repairs" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="6">
                      <FormGroup className="select2-container">
                        <Label className="control-label">FILTER</Label>
                        <div className="search-box mb-2 d-block">
                          <div className="position-relative">
                            <Input type="text" className="form-control" placeholder="Search..." value={searchTerm} onChange={handleTermChange} />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col sm="5">
                      <FormGroup className="select2-container">
                        <Label className="control-label">REGION</Label>
                        <Select classNamePrefix="select2-selection" value={selectedRegion} onChange={setSelectedRegion} placeholder="All" title="Country" options={regions.map(val => ({ value: val, label: val }))} isMulti />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup className="select2-container">
                        <Label className="control-label">STARTING</Label>
                        <input className="form-control" name="orderStartDate" value={orderStartDate} onChange={handleChange} type="date" defaultValue="2019-08-19" id="example-date-input" />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup className="select2-container">
                        <Label className="control-label">ENDING</Label>
                        <input className="form-control" name="orderEndDate" value={orderEndDate} onChange={handleChange} type="date" defaultValue="2019-08-19" id="example-date-input" />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup className="select2-container">
                        <Label className="control-label">TYPE</Label>
                        <Select classNamePrefix="select2-selection" value={selectedType} onChange={e => setSelectedType(e)} placeholder="All" title="Type" options={returnTypes.map(val => ({ value: val, label: val }))} isMulti />
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup className="select2-container">
                        <Label className="control-label">STAGE</Label>
                        <Select classNamePrefix="select2-selection" value={selectedStage} onChange={e => setSelectedStage(e)} placeholder="All" title="Stage" options={stages.map(val => ({ value: val, label: val }))} isMulti />
                      </FormGroup>
                    </Col>
                    <Col sm="1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 8 }}>
                      <CSVLink
                        data={csvData}
                        filename={`Repairs_${new Date()}.csv`}
                      >
                        <Button type="button" color="secondary" className="btn-lg btn-rounded">
                          CSV
                        </Button>
                      </CSVLink>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <Table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th>Order</th>
                          <th>Date</th>
                          <th>Country</th>
                          <th>Customer Name</th>
                          <th>Reason</th>
                          <th>Stage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          state !== 'loading' ?
                            data?.map(returnLine => {
                              return (
                                <tr key={returnLine._id}>
                                  <td><Link to={`/returns/${returnLine._id}`} className="text-body font-weight-bold">{returnLine.orderNumber}</Link></td>
                                  <td>{returnLine.cxDate}</td>
                                  <td>{returnLine.shipping_address[0].country}</td>
                                  <td>{returnLine.shipping_address[0].name}</td>
                                  <td>{returnLine.reason}</td>
                                  <td>
                                    { }
                                    <Badge className={"font-size-12 badge-soft-" + stageToBadge(returnLine.stage)} color={stageToBadge(returnLine.stage)} pill>
                                      <i className={"fab " + " mr-1 font-size-12 badge-soft-primary"}></i> {returnLine.stage}
                                    </Badge>
                                  </td>
                                </tr>
                              )
                            }) : (
                              perPage && (
                                [...Array(perPage)].map((_, i) => (
                                  <tr className="py-8" style={{ padding: '16px 0' }} key={"_order_" + i}>
                                    <td> <div className="placeholder" /> </td>
                                    <td> <div className="placeholder" /> </td>
                                    <td> <div className="placeholder" /> </td>
                                    <td> <div className="placeholder" /> </td>
                                    <td> <div className="placeholder" /> </td>
                                    <td> <div className="placeholder" /> </td>
                                  </tr>

                                ))
                              )
                            )
                        }
                      </tbody>
                    </Table>
                  </div>
                  <TablePagination count={count} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}


export default OrdersPage;


