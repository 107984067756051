import React, { useState } from 'react';
import {
  Table,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  Row,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
// Editable
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import superagent from 'superagent';
import { TablePagination } from '../../../components/supply-chain/TablePagination';
//Import Breadcrumb

import useQuery from '../../../helpers/JIFI/useQuery';
import SupplyChainNav from '../../../components/supply-chain/nav.js';
import { backendUrl } from '../../../helpers/consts.js';
import { StatusBadge } from '../../../components/supply-chain/StatusBadge';

const statusOptions = [
  { value: '', label: 'All' },
  { value: 'approved', label: 'Approved' },
  { value: 'pending', label: 'Pending' },
  { value: 'disapproved', label: 'Disapproved' },
];

const PurchaseOrderList = () => {
  const history = useHistory();
  const [refresh, setRefresh] = useState(true);
  const [status, setStatus] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const dateString = new Date()
    .toDateString()
    .replace(' ', '-')
    .replace(' ', '-')
    .replace(' ', '-');
  const [purchaseOrders, purchaseOrderState] = useQuery({
    url: `/purchase-order/all`,
    refresh,
    queryData: {
      page,
      perPage,
      status,
      searchTerm,
    },
  });

  const refreshData = () => setRefresh((prev) => !prev);

  const handleUpdatePOStatus = (status, id) => {
    superagent
      .post(`${backendUrl}/purchase-order/update-status/${id}`)
      .send({ status })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        refreshData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const purchaseOrderData = purchaseOrders?.data;
  const count = purchaseOrders?.totalCount;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      setSearchTerm(e.target.value);
    }
  }

  const csvData = [
    [
      'Reference',
      'Currency',
      'Expected Release Date',
      'Actual Release Date',
      'Status',
    ],
    ...(purchaseOrderData?.length > 0
      ? purchaseOrderData.map((purchaseOrderObject) => [
          purchaseOrderObject.reference,
          purchaseOrderObject.currency,
          purchaseOrderObject.expectedReleaseDate
            ? new Date(purchaseOrderObject.expectedReleaseDate).toDateString()
            : '',
          purchaseOrderObject.completedReleasedDate
            ? new Date(purchaseOrderObject.completedReleasedDate).toDateString()
            : '',
          purchaseOrderObject?.status ?? 'pending',
        ])
      : []),
  ];

  return (
    <>
      <SupplyChainNav>
        <Container>
          <Col xs="12" m="12">
            <Card>
              <CardBody>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      width: '50%',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '1rem',
                    }}
                  >
                    <Link to="/purchase-order/create">
                      <div className="text-sm-right">
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded waves-effect waves-light mb-2 mr-2"
                        >
                          <i className="mdi mdi-plus mr-1"></i> Create new{' '}
                        </Button>
                      </div>
                    </Link>
                  </div>
                  <CSVLink
                    style={{ float: 'right', marginBottom: '1.25rem' }}
                    data={csvData}
                    filename={`PURCHASE-ORDER-${dateString}.csv`}
                  >
                    <Button
                      type="button"
                      color="secondary"
                      className="btn-md btn-rounded"
                    >
                      CSV
                    </Button>
                  </CSVLink>
                </div>

                <Row>
                  <Col>
                    <Label className="control-label">FILTER</Label>
                    <Row>
                      <Col xs="3">
                    
                        <div className="search-box mb-2 d-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                              onKeyDown={handleKeyDown}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                    
                      </Col>
                      <Col xs="3">
                        <Select
                          options={statusOptions}
                          onChange={(val) => {
                            setPage(1);

                            setStatus(val.value);
                          }}
                          value={statusOptions.find(
                            (val) => val.value === status
                          )}
                        ></Select>
                    </Col> 
                    </Row>

                  </Col>
                </Row>

                {purchaseOrderState === 'success' ? (
                  <div className="table-responsive">
                    <Table className="table table-centered table-nowrap">
                      <tr>
                        <th>Reference</th>
                        <th className="text-center">Currency</th>
                        <th className="text-center">Expected Release Date</th>
                        <th className="text-center">Actual Release Date</th>
                        <th>Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                      <tbody>
                        {purchaseOrderData && purchaseOrderData.length > 0
                          ? purchaseOrderData.map((val, key) => {
                              return (
                                <tr>
                                  <td>
                                    <Link
                                      className="text-body font-weight-bold"
                                      to={`/purchase-order/${val._id}`}
                                    >
                                      {val.reference}
                                    </Link>
                                  </td>
                                  <td className="text-center">
                                    {val.currency}
                                  </td>
                                  <td className="text-center">
                                    {val.expectedReleaseDate
                                      ? new Date(
                                          val.expectedReleaseDate
                                        ).toDateString()
                                      : ''}
                                  </td>
                                  <td className="text-center">
                                    {val.completedReleasedDate
                                      ? new Date(
                                          val.completedReleasedDate
                                        ).toDateString()
                                      : ''}
                                  </td>
                                  <td style={{ textTransform: 'capitalize' }}>
                                    <StatusBadge
                                      status={val?.status ?? 'pending'}
                                    />
                                  </td>
                                  <td>
                                    {val?.status === 'pending' ||
                                    !val?.status ? (
                                      <Card
                                        className="flex flex-col mb-0 capitalize"
                                        style={{ gap: '3px' }}
                                      >
                                        <Button
                                          type="button"
                                          color="secondary"
                                          className="btn-sm btn-success w-fit"
                                          onClick={() =>
                                            handleUpdatePOStatus(
                                              'approved',
                                              val._id
                                            )
                                          }
                                        >
                                          Approved
                                        </Button>
                                        <Button
                                          type="button"
                                          color="secondary"
                                          className="btn-sm btn-danger w-fit"
                                          onClick={() =>
                                            handleUpdatePOStatus(
                                              'disapproved',
                                              val._id
                                            )
                                          }
                                        >
                                          Disapproved
                                        </Button>
                                      </Card>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          : []}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      className="spinner-border text-primary m-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
                <TablePagination
                  count={count}
                  page={page}
                  setPage={setPage}
                  perPage={perPage}
                  setPerPage={setPerPage}
                />
              </CardBody>
            </Card>
          </Col>
        </Container>
      </SupplyChainNav>
    </>
  );
};

export default PurchaseOrderList;
