import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, FormGroup, Input, Card, CardBody, CardTitle, CardSubtitle, Button, Label, Collapse, Nav, NavItem, NavLink, TabContent, TabPane, CardText } from "reactstrap";
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import useQuery from '../../../helpers/JIFI/useQuery';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import CardProduct from "./card-product";

import superagent from 'superagent'
import {backendUrl} from '../../../helpers/consts.js'
import { useHistory } from "react-router-dom";
import { uploadFileS3Bucket } from "../../../utilities/uploadFileS3Bucket";

import classnames from "classnames"

const ProductListPage  = ({
  match: {
    params
  },
}) => {
  const history = useHistory();
  const { productID, variantID } = params

  const [selectedFiles, setSelectedFiles] = useState([])

  const [variantName, setVariantName] = useState('')
  const [variantSku, setVariantSku] = useState('')
  const [variantImage, setVariantImage] = useState('')

  const [continueSelling, setContinueSelling] = useState()
  const [discontinued, setDiscontinued] = useState()
  const [oosMessage, setOosMessage] = useState()
  const [stockBuffer, setStockBuffer] = useState()
  const [preorder, setPreorder] = useState()
  const [isArchived, setIsArchived] = useState()

  const [continueSellingUS, setContinueSellingUS] = useState()
  const [discontinuedUS, setDiscontinuedUS] = useState()
  const [oosMessageUS, setOosMessageUS] = useState()
  const [stockBufferUS, setStockBufferUS] = useState()
  const [preorderUS, setPreorderUS] = useState()

  const [continueSellingUK, setContinueSellingUK] = useState()
  const [discontinuedUK, setDiscontinuedUK] = useState()
  const [oosMessageUK, setOosMessageUK] = useState()
  const [stockBufferUK, setStockBufferUK] = useState()
  const [preorderUK, setPreorderUK] = useState()

  const [averageCogAU, setAverageCogAU] = useState()
  const [averageCogUS, setAverageCogUS] = useState()
  const [averageCogUK, setAverageCogUK] = useState()

  const [usAllowAu, setUsAllowAu] = useState()
  const toggleUsAllowAu = () => {
    setUsAllowAu((prev) => !prev);
  };

  const [ukAllowAu, setUkAllowAu] = useState()
  const toggleUkAllowAu = () => {
    setUkAllowAu((prev) => !prev);
  };

  const [refresh, setRefresh] = useState(false)
  const refreshData = () => setRefresh(prev => !prev)


  const [product, state] = useQuery({
    url: `/product/${productID}`,
    refresh
  });
  const variant = product && product.variants.find(val => `${val._id}` === variantID)

  useEffect(() => {
    if (product != undefined && variant != undefined) {
      setVariantSku(variant.sku)
      setVariantName(variant.name)
      setVariantImage(variant.image)

      setContinueSelling(variant.continue_selling)
      setDiscontinued(variant.discontinued)
      setOosMessage(variant.oos_message)
      setStockBuffer(variant.stock_buffer)
      setPreorder(variant.preorder)

      setContinueSellingUS(variant.continue_selling_us)
      setDiscontinuedUS(variant.discontinued_us)
      setOosMessageUS(variant.oos_message_us)
      setStockBufferUS(variant.stock_buffer_us)
      setPreorderUS(variant.preorder_us)
      setUsAllowAu(variant.us_allow_au)

      setContinueSellingUK(variant.continue_selling_uk)
      setDiscontinuedUK(variant.discontinued_uk)
      setOosMessageUK(variant.oos_message_uk)
      setStockBufferUK(variant.stock_buffer_uk)
      setPreorderUK(variant.preorder_uk)
      setUkAllowAu(variant.uk_allow_au)
      
      setAverageCogAU(variant.average_cog_au)
      setAverageCogUS(variant.average_cog_us)
      setAverageCogUS(variant.average_cog_uk)
      setIsArchived(variant.isArchived)
    }
  }, [product])

  const handleIsArchived = () => {
    setIsArchived(prev => !prev)
  }

  const updateVariant = (e, image) => {
    e.preventDefault()
    if (image) {
      setVariantImage(image)
    }
    if (variantID === undefined) {
      superagent.post(`${backendUrl}/product/variant`)
        .send({ name: variantName, sku: variantSku, product: productID, isArchived: isArchived })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
            const code = response.status
            history.replace(history.location.pathname, {
                errorStatusCode: code
            });
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
              history.replace(history.location.pathname, {
                errorStatusCode: code
              });
          } else {
            history.push(`/product/edit/${productID}/variant/edit/${response.body._id}`)
          }
        }).catch(err => {
          console.log(err)
        });

    } else {
      superagent.put(`${backendUrl}/product/variant/${variantID}`)
        .send({ 
          name: variantName, 
          sku: variantSku, 
          image: image || variantImage ,

          stock_buffer: stockBuffer,
          continue_selling: continueSelling,
          discontinued: discontinued,
          oos_message: oosMessage,
          preorder: preorder,

          stock_buffer_us: stockBufferUS,
          continue_selling_us: continueSellingUS,
          discontinued_us: discontinuedUS,
          oos_message_us: oosMessageUS,
          preorder_us: preorderUS,

          stock_buffer_uk: stockBufferUK,
          continue_selling_uk: continueSellingUK,
          discontinued_uk: discontinuedUK,
          oos_message_uk: oosMessageUK,
          preorder_uk: preorderUK,

          us_allow_au: usAllowAu,
          uk_allow_au: ukAllowAu,
          isArchived: isArchived
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
            const code = response.status 
            history.replace(history.location.pathname, {
                errorStatusCode: code
            });
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
              history.replace(history.location.pathname, {
                errorStatusCode: code
              });
          } else {
              refreshData()
          }
        }).catch(err => {
          console.log(err)
        });
    }
  }

  const handleAcceptedFiles = async (files) => {
    const cancel = !files.length;
    if (cancel) return;
    let images = await Promise.all(files.map(file => uploadFileS3Bucket(file, `comment_file_${Date.now()}`)))

    superagent.put(`${backendUrl}/product/addImages/${productID}`)
      .send({ images: images })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
          const code = response.status
          history.replace(history.location.pathname, {
              errorStatusCode: code
          });
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
            history.replace(history.location.pathname, {
              errorStatusCode: code
            });
        } else {
            refreshData()
        }
      }).catch(err => {
        console.log(err)
      });
  }

  const [imagesOpen, setimagesOpen] = useState(false)

  const [loacaleTab, setLocaleTab] = useState('1')

  return (
    <>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Ecommerce"  breadcrumbItem={variantID != undefined ? 'Edit Variant' : 'New Variant'} />
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <CardTitle>Variant Information</CardTitle>
                            <Form>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label htmlFor="productname">Variant Name</Label>
                                            <Input id="productname" name="productname" type="text" className="form-control" value={variantName} onChange={(e) => setVariantName(e.target.value)} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor="productname">Variant SKU</Label>
                                            <Input id="productname" name="productname" type="text" className="form-control" value={variantSku} onChange={(e) => setVariantSku(e.target.value)} />
                                        </FormGroup>
                                        <br/><br/>
                                        <Row>
                                          <Col sm="6">
                                            <FormGroup>
                                              <Label htmlFor="productname">Average COG AU</Label>
                                              <Input id="productname" name="productname" type="text" className="form-control" value={averageCogAU}  />
                                            </FormGroup>
                                          </Col>
                                          <Col sm="6">
                                            <FormGroup>
                                              <Label htmlFor="productname">Average COG US</Label>
                                              <Input id="productname" name="productname" type="text" className="form-control" value={averageCogUS}  />
                                            </FormGroup>
                                          </Col>
                                        </Row>

                                        <FormGroup>
                                            <Label htmlFor="isArchived">Archived</Label>
                                            <Input id="isArchived" name="isArchived" className="form-control" type="checkbox" onChange={handleIsArchived} checked={isArchived} style={{
                                              width: 'calc(6.5em + 1rem + 2px)', height: '5%',
                                            }}/>
                                        </FormGroup>
                                    </Col>
                                    { variantID != undefined && continueSelling !== undefined && (
                                      <Col sm="6">
                                        <Row>
                                          <Col sm="12">
                                            <Nav className="nav-tabs-custom nav-justified">
                                              <NavItem>
                                                <NavLink
                                                  style={{ cursor: "pointer" }}
                                                  className={classnames({
                                                    active: loacaleTab === "1",
                                                  })}
                                                  onClick={() => {
                                                    setLocaleTab("1")
                                                  }}
                                                >
                                                  <span className="d-none d-sm-block"> AU</span>
                                                  <span className="d-block d-sm-none"></span>
                                                </NavLink>
                                              </NavItem>
                                              <NavItem>
                                                <NavLink
                                                  style={{ cursor: "pointer" }}
                                                  className={classnames({
                                                    active: loacaleTab === "2",
                                                  })}
                                                  onClick={() => {
                                                    setLocaleTab("2")
                                                  }}
                                                >
                                                  <span className="d-none d-sm-block"> US</span>
                                                  <span className="d-block d-sm-none"></span>
                                                </NavLink>
                                              </NavItem>
                                              <NavItem>
                                                <NavLink
                                                  style={{ cursor: "pointer" }}
                                                  className={classnames({
                                                    active: loacaleTab === "3",
                                                  })}
                                                  onClick={() => {
                                                    setLocaleTab("3")
                                                  }}
                                                >
                                                  <span className="d-none d-sm-block"> UK </span>
                                                  <span className="d-block d-sm-none"></span>
                                                </NavLink>
                                              </NavItem>
                                            </Nav>

                                            <TabContent activeTab={loacaleTab} className="p-3 text-muted">
                                              <TabPane tabId="1">
                                                {
                                                  loacaleTab === '1' && (
                                                    <Row>
                                                      <Col sm="12">
                                                        <CardText className="mb-0">
                                                          <FormGroup>
                                                            <Label htmlFor="productname">Out of stock message</Label>
                                                            <Input id="oosMessage" name="oosMessage" type="text" className="form-control" value={oosMessage} onChange={(e) => setOosMessage(e.target.value)} />
                                                          </FormGroup>
                                                          <FormGroup>
                                                              <Label htmlFor="buffer">Buffer Amount</Label>
                                                              <input id="buffer" value={stockBuffer} onChange={(e) => setStockBuffer(e.target.value)} type="number" step={1} min={0} className="form-control"/>
                                                          </FormGroup>
                                                          <br/>
                                                          <FormGroup tag="fieldset">
                                                            <FormGroup check>
                                                              <Label check>
                                                                <Input defaultChecked={continueSelling === false && discontinued === false && preorder === false} onChange={(e) => {setContinueSelling(false); setDiscontinued(false); setPreorder(false)}} type="radio" name="radio1" /> Mark as unavaliable site once Buffer is reached
                                                              </Label>
                                                            </FormGroup>
                                                            <FormGroup check>
                                                              <Label check>
                                                                <Input defaultChecked={continueSelling === true} onChange={(e) => {setContinueSelling(true); setDiscontinued(false); setPreorder(false)}} type="radio" name="radio1" /> Keep selling, even when OOS
                                                              </Label>
                                                            </FormGroup>
                                                            <FormGroup check>
                                                              <Label check>
                                                                <Input defaultChecked={preorder === true} onChange={(e) => {setPreorder(true);setContinueSelling(false); setDiscontinued(false);}} type="radio" name="radio1" /> Sell as preorder
                                                              </Label>
                                                            </FormGroup>
                                                            <FormGroup check>
                                                              <Label check>
                                                                <Input defaultChecked={discontinued === true} onChange={(e) => {setDiscontinued(true);setContinueSelling(false);setPreorder(false)}} type="radio" name="radio1" /> Take off site completely
                                                              </Label>
                                                            </FormGroup>
                                                          </FormGroup>
                                                        </CardText>
                                                      </Col>
                                                    </Row>
                                                  )
                                                }
                                              </TabPane>
                                              <TabPane tabId="2">
                                                {
                                                  loacaleTab === '2' && (
                                                  <>
                                                    <Row>
                                                      <Col sm="12">
                                                        <CardText className="mb-0">
                                                          <FormGroup>
                                                            <Label htmlFor="productname">Out of stock message</Label>
                                                            <Input id="oosMessage" name="oosMessage" type="text" className="form-control" value={oosMessageUS} onChange={(e) => setOosMessageUS(e.target.value)} />
                                                          </FormGroup>
                                                          <FormGroup>
                                                              <Label htmlFor="buffer">Buffer Amount</Label>
                                                              <input id="buffer" value={stockBufferUS} onChange={(e) => setStockBufferUS(e.target.value)} type="number" step={1} min={0} className="form-control"/>
                                                          </FormGroup>
                                                          <br/>
                                                          <FormGroup tag="fieldset">
                                                            <FormGroup check>
                                                              <Label check>
                                                                <Input defaultChecked={continueSellingUS === false && discontinuedUS === false && preorderUS === false} onChange={(e) => {setContinueSellingUS(false); setDiscontinuedUS(false);setPreorderUS(false)}} type="radio" name="radio1" /> Mark as unavaliable site once Buffer is reached
                                                              </Label>
                                                            </FormGroup>
                                                            <FormGroup check>
                                                              <Label check>
                                                                <Input defaultChecked={continueSellingUS === true} onChange={(e) => {setContinueSellingUS(true);setDiscontinuedUS(false);setPreorderUS(false)}} type="radio" name="radio1" /> Keep selling, even when OOS
                                                              </Label>
                                                            </FormGroup>
                                                            <FormGroup check>
                                                              <Label check>
                                                                <Input defaultChecked={preorderUS === true} onChange={(e) => {setPreorderUS(true);setContinueSellingUS(false); setDiscontinuedUS(false);}} type="radio" name="radio1" /> Sell as preorder
                                                              </Label>
                                                            </FormGroup>
                                                            <FormGroup check>
                                                              <Label check>
                                                                <Input defaultChecked={discontinuedUS === true} onChange={(e) => {setDiscontinuedUS(true);setContinueSellingUS(false);setPreorderUS(false)}} type="radio" name="radio1" /> Take off site completely
                                                              </Label>
                                                            </FormGroup>
                                                          </FormGroup>
                                                          
                                                        </CardText>
                                                      </Col>
                                                    </Row>
                                                    <div
                                                      className="custom-control custom-checkbox"
                                                      onClick={toggleUsAllowAu}
                                                    >
                                                      <Input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id={"toggleEWE"}
                                                        onChange={toggleUsAllowAu}
                                                        checked={usAllowAu}
                                                      />
                                                      <Label
                                                        className="custom-control-label"
                                                        htmlFor={"toggleEWE"}
                                                      >
                                                        Allow AU Stock
                                                      </Label>
                                                    </div>
                                                  </>
                                                  )
                                                }
                                              </TabPane>
                                              <TabPane tabId="3">
                                                {
                                                  loacaleTab === '3' && (
                                                  <>
                                                    <Row>
                                                      <Col sm="12">
                                                        <CardText className="mb-0">
                                                          <FormGroup>
                                                            <Label htmlFor="productname">Out of stock message</Label>
                                                            <Input id="oosMessage" name="oosMessage" type="text" className="form-control" value={oosMessageUK} onChange={(e) => setOosMessageUK(e.target.value)} />
                                                          </FormGroup>
                                                          <FormGroup>
                                                              <Label htmlFor="buffer">Buffer Amount</Label>
                                                              <input id="buffer" value={stockBufferUK} onChange={(e) => setStockBufferUK(e.target.value)} type="number" step={1} min={0} className="form-control"/>
                                                          </FormGroup>
                                                          <br/>
                                                          <FormGroup tag="fieldset">
                                                            <FormGroup check>
                                                              <Label check>
                                                                <Input defaultChecked={continueSellingUK === false && discontinuedUK === false && preorderUK === false} onChange={(e) => {setContinueSellingUK(false); setDiscontinuedUK(false);setPreorderUK(false)}} type="radio" name="radio1" /> Mark as unavaliable site once Buffer is reached
                                                              </Label>
                                                            </FormGroup>
                                                            <FormGroup check>
                                                              <Label check>
                                                                <Input defaultChecked={continueSellingUK === true} onChange={(e) => {setContinueSellingUK(true);setDiscontinuedUK(false);setPreorderUK(false)}} type="radio" name="radio1" /> Keep selling, even when OOS
                                                              </Label>
                                                            </FormGroup>
                                                            <FormGroup check>
                                                              <Label check>
                                                                <Input defaultChecked={preorderUK === true} onChange={(e) => {setPreorderUK(true);setContinueSellingUK(false); setDiscontinuedUK(false);}} type="radio" name="radio1" /> Sell as preorder
                                                              </Label>
                                                            </FormGroup>
                                                            <FormGroup check>
                                                              <Label check>
                                                                <Input defaultChecked={discontinuedUK === true} onChange={(e) => {setDiscontinuedUK(true);setContinueSellingUK(false);setPreorderUK(false)}} type="radio" name="radio1" /> Take off site completely
                                                              </Label>
                                                            </FormGroup>
                                                          </FormGroup>
                                                          
                                                        </CardText>
                                                      </Col>
                                                    </Row>
                                                    <div
                                                      className="custom-control custom-checkbox"
                                                      onClick={toggleUkAllowAu}
                                                    >
                                                      <Input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id={"toggleEWE"}
                                                        onChange={toggleUkAllowAu}
                                                        checked={ukAllowAu}
                                                      />
                                                      <Label
                                                        className="custom-control-label"
                                                        htmlFor={"toggleEWE"}
                                                      >
                                                        Allow AU Stock
                                                      </Label>
                                                    </div>
                                                  </>
                                                  )
                                                }
                                              </TabPane>
                                            </TabContent>  
                                          </Col>
                                        </Row>
                                      </Col>
                                    )}
                                </Row>
                                <Button onClick={updateVariant} type="submit" color="primary" className="mr-1 waves-effect waves-light" style={{marginTop: '36px'}}>Save Changes</Button>
                            </Form>
                        </CardBody>
                    </Card>
                    {
                      variantID != undefined && (
                        <Card>
                          <CardBody>
                            <CardTitle style={{cursor: 'pointer'}} onClick={() => setimagesOpen(prev => !prev)} className="mb-3">Variant Images</CardTitle>
                            <Collapse isOpen={imagesOpen}>
                              <div  className="p-3 mb-3 bg-light rounded">
                                <Row>
                                  {
                                    product && product.files && product.files.map((image, key) => (
                                      <Col xl="2" key={`_product_image_${key}`} onClick={(e) => {updateVariant(e, image)}}>
                                        <img className={`rounded ${image == variantImage ? 'border border-success' : ''}`} style={{width: '100%', }} src={image}/>
                                      </Col>
                                    ))
                                  }
                                </Row>
                              </div>
                              <Form>
                                  <Dropzone
                                      onDrop={acceptedFiles =>
                                          handleAcceptedFiles(acceptedFiles)
                                      }
                                  >
                                      {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                              <div
                                                  className="dz-message needsclick"
                                                  {...getRootProps()}
                                              >
                                                  <input {...getInputProps()} />
                                                  <div className="dz-message needsclick">
                                                      <div className="mb-3">
                                                          <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                                      </div>
                                                      <h4>Drop files here or click to upload.</h4>
                                                  </div>
                                              </div>
                                          </div>
                                      )}
                                  </Dropzone>
                              </Form>
                            </Collapse>
                          </CardBody>
                      </Card>
                      )
                    }
                </Col>
            </Row>
        </Container>
      </div>
    </>
  )
}

export default ProductListPage