import create from 'zustand';
import superagent from 'superagent'
import { backendUrl } from '../helpers/consts';


const useUserStore = create((set, get) => ({
  userList: undefined,
  setUserList: (userList) => set({ userList }),
  fetchUserListState: 'idle',
  fetchUserList: async () => {

    const result = get().userList;
    
    if(result) {
      return
    }
    superagent.get(`${backendUrl}/users/active-user`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          set({ userList: response.body.data })
        }
      }).catch(err => {
        console.log(err)
      });
  },
  setFetchUserListState: (state) => set({ fetchUserListState: state }),
}));

export default useUserStore;
