import React, { Component, useState, useEffect } from 'react';
import { Table, Row, Col, Card, CardBody, CardTitle, Button, Badge } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import superagent from 'superagent';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import useQuery from '../../helpers/JIFI/useQuery';
import { backendUrl } from '../../helpers/consts.js';
import Select from 'react-select';
import moment from 'moment';
import './index.css';
const numberWithSign = (number) => {
  let signed = '';
  const positive = number > 0;
  if (positive) {
    signed += '+';
  }
  signed += number;
  return (
    <span className={`text-${positive || number === 0 ? 'success' : 'danger'}`}>
      {' '}
      {signed}{' '}
    </span>
  );
};

const EditableTables = () => {
  const history = useHistory();
  const [selectedLocation, setSelectedLocation] = useState();

  const [formData, setFormData] = useState(null);
  const setCount = (x, y, z, v) => {
    setFormData((prev) => {
      let newData = { ...prev };
      newData[x][y][z] = v;
      return newData;
    });
  };

  const [sendingAudit, setSendingAudit] = useState(false);

  const [toggle, setToggle] = useState({});
  const [refresh, setRefresh] = useState(true);
  const refreshData = () => {
    setRefresh((prev) => !prev);
  };

  const [submittingKey, setSubmittingKey] = useState(undefined);

  const toggleProduct = (productKey) => {
    const newToggle = { ...toggle };
    newToggle[productKey] = !newToggle[productKey];
    setToggle(newToggle);
  };

  const [data, state] = useQuery({
    url: `/product`,
    refresh,
  });

  const [recentApprovals, stateRecentApproval] = useQuery({
    url: `/inventory/recentSubmitApprovals`,
    refresh,
    queryData: {
      location: selectedLocation,
    },
  })

  const productData = data && data.data;

  const [variantCount, setVariantCount] = useState({});
  const [dataStock, stateStock] = useQuery({
    url: `/inventory/fetchStockCount`,
    refresh,
    queryData: {
      location: selectedLocation,
    },
  });
  useEffect(() => {
    if (dataStock) {
      let count = {};
      dataStock.map((productCount) => {
        productCount.variantCounts.map((variantCount) => {
          const sku = variantCount.variant.sku;
          count[sku] = variantCount.count;
        });
      });

      setVariantCount(count);
    }
  }, [dataStock]);

  const initialData = () => {
    if(productData?.length) {
    const initialProduct = {
      ...productData.map((product) =>
        product.variants.map(() => [null, null, null, null])
      ),
    };

    setFormData(initialProduct);
  }};

  useEffect(() => {
    if (productData) {
      if (submittingKey === undefined) {
        setFormData(
          productData.map((product) =>
            product.variants.map(() => [null, null, null, null])
          )
        );
      } else {
        setFormData((prev) => {
          const data = productData.map((product, key) => {
            if (key !== submittingKey) {
              return prev[key];
            }
            return product.variants.map(() => [null, null, null, null]);
          });

          return data;
        });
        setSubmittingKey(undefined);
      }
    }
  }, [productData]);

  const auditSubmittedAllProduct = () => {
    setSubmittingKey(true);
    setSendingAudit(true)

    const allProductCounts = productData.map((product, productKey) => {
      const productID = product._id;


      const count = formData[productKey]
        .map((countItem, variantKey) => ({
          variant: product.variants[variantKey]._id,
          on_hand: countItem[0],
          display: countItem[1],
          comment: countItem[3],
        }))
        .filter(countItem =>

          countItem.on_hand !== null && countItem.on_hand !== "" ||
          countItem.display !== null && countItem.display !== "" ||
          countItem.comment !== null && countItem.comment !== ""
        );


      if (count.length > 0) {
        return {
          product: productID,
          count,
        };
      }
      return null;
    }).filter(product => product !== null);


    if (allProductCounts.length === 0) {

      return;
    }


    superagent
      .post(`${backendUrl}/inventory/audit-multiple/?location=${selectedLocation}`)
      .send({
        products: allProductCounts,
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        } else {
          initialData()
          refreshData();
          setSendingAudit(false)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const auditSubmitted = (key) => {
    setSubmittingKey(key);
    const product = productData[key];
    const productID = product._id;
    const count = formData[key].map((countItem, variantKey) => ({
      variant: product.variants[variantKey]._id,
      on_hand: countItem[0],
      display: countItem[1],
      dead: countItem[2],
      comment: countItem[3],
    }));

    superagent
      .post(`${backendUrl}/inventory/audit/?location=${selectedLocation}`)
      .send({
        count,
        product: productID,
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        } else {
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [locations, stateLocations] = useQuery({
    url: '/inventory/getAllLocationNames',
  });

  const [pendingData, pendingState] = useQuery({
    url: `/inventory/pendingApprovals/`,
    refresh,
    queryData: {
      location: selectedLocation,
    },
  });

  const [location, stateLocation] = useQuery({
    url: `/inventory/locationByName/`,
    queryData: {
      name: selectedLocation || 'HQ2',
    },
  });
  const isCurrentLocation = location?.name === selectedLocation;

  let [shopifySkus, setShopifySkus] = useState({});
  useEffect(() => {
    if (
      location?.name === selectedLocation &&
      location?.shopify_links.length > 0
    ) {
      let shopifyLink = location.shopify_links.find(
        (val) => val.sync_type === 'PULL'
      );
      if (shopifyLink) {
        let skus = {};
        shopifyLink.locationVariantIds.map((variantLine) => {
          const sku = variantLine.sku;
          skus[sku] = variantLine.inventory_item_id;
        });
        setShopifySkus(skus);
      } else {
        setShopifySkus(null);
      }
    }
  }, [location]);


  if (!locations) return <></>;

  const locationOptions = [
    { value: undefined, label: 'Please select your location.' },
    ...locations.map((val) => ({ value: val.name, label: val.display_name })),
  ];
  const locationValue = locationOptions.find(
    (val) => val.value === selectedLocation
  );

  const result = productData?.length
    ? productData.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 2);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, [])
    : [];

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Inventory" breadcrumbItem="Stock Audit" />
          <Row className="mb-2">
            <Col sm="12">
              <Select
                disabled={ state !== "success"}
                options={locationOptions}
                value={locationValue}
                onChange={(e) =>
                {
                  initialData()
                  setSelectedLocation(e.value) }}
              />
            </Col>
          </Row>

          <Row>
            <Col className="stock-audit-container">
              {isCurrentLocation &&
                selectedLocation &&
                formData &&
                productData &&
                productData.map((product, productKey) => {
                  const variantLines = product.variants
                    .sort((a, b) => a.sku.localeCompare(b.sku))
                    .map((variant, variantKey) => {
                      const onHandDiff =
                        variantCount &&
                        variantCount[variant.sku] &&
                        parseInt(formData[productKey][variantKey][0] || '0') -
                          variantCount[variant.sku].on_hand;

                      if (
                        shopifySkus === null ||
                        (shopifySkus && shopifySkus[variant.sku])
                      ) {
                        return (
                          <tr>
                            <th scope="row">{variant.sku}</th>
                            <td>
                              <input
                                value={
                                  formData[productKey][variantKey][0] || ''
                                }
                                onChange={(e) =>
                                  setCount(
                                    productKey,
                                    variantKey,
                                    0,
                                    e.target.value
                                  )
                                }
                                type="number"
                                step={1}
                                min={0}
                                className="form-control"
                              />{' '}
                              {stateStock !== 'loading'
                                ? formData[productKey][variantKey][0] &&
                                  onHandDiff &&
                                  numberWithSign(onHandDiff)
                                : formData[productKey][variantKey][0] && (
                                    <div
                                      style={{
                                        width: '10%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: 4,
                                      }}
                                    >
                                      {' '}
                                      <div
                                        className="spinner-grow text-primary"
                                        role="status"
                                      >
                                        {' '}
                                        <span className="sr-only">
                                          Loading...
                                        </span>{' '}
                                      </div>{' '}
                                    </div>
                                  )}
                            </td>
                            <td>
                              <input
                                value={
                                  formData[productKey][variantKey][1] || ''
                                }
                                onChange={(e) =>
                                  setCount(
                                    productKey,
                                    variantKey,
                                    1,
                                    e.target.value
                                  )
                                }
                                type="number"
                                step={1}
                                min={0}
                                className="form-control"
                              />
                            </td>
                            {/* <td><input value={formData[productKey][variantKey][2]} onChange={(e) => setCount(productKey, variantKey, 2, e.target.value)} type="number" step={1} min={0} className="form-control"/></td> */}
                            <td>
                              <input
                                value={
                                  formData[productKey][variantKey][3] || ''
                                }
                                onChange={(e) =>
                                  setCount(
                                    productKey,
                                    variantKey,
                                    3,
                                    e.target.value
                                  )
                                }
                                className="form-control"
                              />
                            </td>
                          </tr>
                        );
                      }
                      return undefined;
                    })
                    .filter((val) => val !== undefined);

                  if (
                    !variantLines || variantLines.length == 0
                    // || product.category === 'SET'
                  )
                    return <></>;

                  return (
                    <>
                    <Card className={`stock-audit-item`}>
                      <CardBody>
                        <div className="d-flex flex-row">
                          <div className="d-flex flex-column w-100">
                            <CardTitle
                              style={{ cursor: 'pointer', width: '100%' }}
                              onClick={() => toggleProduct(productKey)}
                            >
                              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                <div>{product.name} Stock</div>
                                {
                                  !pendingData?.includes(`${product._id}`) && (
                                    <div style={{marginLeft: '8px'}}> {toggle[productKey] ? '-': '+' }</div>
                                  )
                                }
                              </div>
                            </CardTitle>
                            {!pendingData?.includes(`${product._id}`) ? (
                              <>
                                <div
                                  className={`table-responsive  ${
                                    !toggle[productKey] ? 'd-none' : ''
                                  }`}
                                >
                                  <Table className="table mb-0">
                                    <thead>
                                      <tr>
                                        <th>SKU</th>
                                        <th>Stock On Hand</th>
                                        <th>Display Stock</th>

                                        <th>Comment</th>
                                      </tr>
                                    </thead>
                                    <tbody>{variantLines}</tbody>
                                  </Table>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                          </div>
                        </div>
                        {recentApprovals?.[product._id] && (
                          <div className='flex flex-row flex-center' style={{gap: 10}}>
                            Last count: 
                            <Badge color="secondary" pill style={{marginLeft: 5}}>
                              {moment(recentApprovals[product._id]).format('MMMM Do, h:mm:ss a') }
                            </Badge>
                          </div>
                        )}
                        {
                          pendingData?.includes(`${product._id}`) && (
                            <div>
                              <Badge color="warning" pill >
                                Pending approval
                              </Badge>
                            </div>
                          )
                        }
                      </CardBody>
                    </Card>

                   </>
                  );
                })}
            </Col>
          </Row>
          {stateStock==='success' && (
            <div className='d-flex' style={{gap:10, position: 'fixed', bottom: 0, left: 0, width: '100%', backgroundColor: 'white', padding: 10}}>
              {
                sendingAudit ? (
                  <>
                    Sending audit...
                  </>
                ) : (
                  <>
                    <Button className="me-2" onClick={auditSubmittedAllProduct} color="success">
                      Submit
                    </Button>

                    <Button color='error' onClick={initialData}>Clear</Button>
                  </>
                )
              }
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditableTables;
