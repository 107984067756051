import React, { useEffect, useState } from 'react'
import useQuery from '../../../helpers/JIFI/useQuery';
import { Table, Container, Row, Col, Form, FormGroup, Input, Card, CardBody, CardTitle, CardSubtitle, Button, Label, Collapse, Nav, NavItem, NavLink, TabContent, TabPane, CardText } from "reactstrap";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import classnames from "classnames"
import superagent from 'superagent'
import {backendUrl} from '../../../helpers/consts.js'

import Select from 'react-select';

const variantSelectOptions = [
  'Mark as unavaliable site once Buffer is reached',
  'Keep selling, even when OOS',
  'Sell as preorder',
  'Take off site completely'
]

const valueLookups = {
  'AU': {
    'no_reorder': 'no_reorder',
    'isArchived': 'isArchived',
    'stock_buffer': 'stock_buffer',
    'oos_message': 'oos_message',
    'continue_selling': 'continue_selling',
    'discontinued': 'discontinued',
    'preorder': 'preorder',
    'preorder_buffer': 'preorder_buffer',
    'pause_preorder': 'pause_preorder'
  }, 
  'US': {
    'no_reorder': 'no_reorder',
    'isArchived': 'isArchived',
    'stock_buffer': 'stock_buffer_us',
    'oos_message': 'oos_message_us',
    'continue_selling': 'continue_selling_us',
    'discontinued': 'discontinued_us',
    'preorder': 'preorder_us',
    'allow_au': 'us_allow_au',
    'preorder_buffer': 'preorder_buffer_us',
    'pause_preorder': 'pause_preorder_us'
  },
  'UK': {
    'no_reorder': 'no_reorder',
    'isArchived': 'isArchived',
    'stock_buffer': 'stock_buffer_uk',
    'oos_message': 'oos_message_uk',
    'continue_selling': 'continue_selling_uk',
    'discontinued': 'discontinued_uk',
    'preorder': 'preorder_uk',
    'allow_au': 'uk_allow_au',
    'preorder_buffer': 'preorder_buffer_uk',
    'pause_preorder': 'pause_preorder_uk'
  }
}

const switchStringToBools = (lookupString) => {
  const lookUp = {
    'Mark as unavaliable site once Buffer is reached': {
      continue_selling: false,
      discontinued: false,
      preorder: false
    },
    'Keep selling, even when OOS': {
      continue_selling: true,
      discontinued: false,
      preorder: false
    },
    'Sell as preorder': {
      continue_selling: false,
      discontinued: false,
      preorder: true
    },
    'Take off site completely': {
      continue_selling: false,
      discontinued: true,
      preorder: false
    }
  }
  return lookUp[lookupString]
}

const switchBoolsToString = (continue_selling, discontinued, preorder) => {
  if (continue_selling == false && discontinued == false && preorder == false) {
    return 'Mark as unavaliable site once Buffer is reached'
  }
  if (continue_selling == true && discontinued == false && preorder == false) {
    return 'Keep selling, even when OOS'
  }
  if (continue_selling == false && discontinued == false && preorder == true) {
    return 'Sell as preorder'
  }
  if (continue_selling == false && discontinued == true && preorder == false) {
    return 'Take off site completely'
  }
}

const VariantTableLine = ({variant, locale, setVariant, lineIndex}) => {
  const getValue = (key) => {
    return variant[valueLookups[locale][key]]
  }
  const setValue = (key, value) => {
    setVariant({
      ...variant,
      [valueLookups[locale][key]]: value
    })
  }
  const setValues = (newFields) => {
    let localisedNewFields = {}
    Object.keys(newFields).forEach(key => {
      localisedNewFields[valueLookups[locale][key]] = newFields[key]
    })
    setVariant({
      ...variant,
      ...localisedNewFields
    })
  }
  const oosString = switchBoolsToString(getValue('continue_selling'), getValue('discontinued'), getValue('preorder'))
  const handleOOSSelectChange = (e) => {
    const {
      continue_selling,
      discontinued,
      preorder
    } = switchStringToBools(e.value)
    
    setValues({
      'continue_selling': continue_selling,
      'discontinued': discontinued,
      'preorder': preorder
    })
  }

  const handleOOSMessageChange = (e) => {
    setValues({
      'oos_message': e.target.value,
    })
  }

  const handleBufferChange = (e) => {
    setValues({
      'stock_buffer': Number(e.target.value) || 0,
    })
  }

  const handlePreorderBufferChange = (e) => {
    setValues({
      'preorder_buffer': Number(e.target.value) || 0,
    })
  }

  const handlePausePreorderChange = (e) => {
    setValues({
      'pause_preorder': !getValue('pause_preorder')
    })
  }

  const handleIsArchived = (e) => {
    setValues({
      'isArchived': !getValue('isArchived')
    })
  }

  const handleNoReorder = (e) => {
    setValues({
      'no_reorder': !getValue('no_reorder')
    })
  }

  const handleAllowAU = (e) => {
    setValues({
      'allow_au': !getValue('allow_au')
    })
  }


  const useStockBuffer = !getValue('continue_selling') && !getValue('discontinued') && !getValue('preorder')
  const usePreorderBuffer = getValue('preorder')


  return (
    <tr id={`variantLine-${lineIndex}`} key={lineIndex} style={variant.changed === true ? {background: '#ccc'} : {}}>
      <td>{variant.sku}</td>
      <td>
        <FormGroup>
          {/* <Label htmlFor="productname">Out of stock message</Label> */}
          <Input value={getValue('oos_message')} onChange={handleOOSMessageChange} id="oosMessage" name="oosMessage" type="text" className="form-control" />
        </FormGroup>
      </td>
      <td>
        <FormGroup>
          {
            useStockBuffer && (
              <input value={getValue('stock_buffer')} onChange={handleBufferChange} id="buffer" type="number" step={1} min={0} className="form-control"/>
            )
          }
          {
            usePreorderBuffer && (
              <input value={getValue('preorder_buffer')} onChange={handlePreorderBufferChange} id="preorderbuffer" type="number" step={1} min={0} className="form-control"/>
            )
          }
        </FormGroup>
      </td>
      <td>
        <FormGroup style={{minWidth: 200}}>
          {/* <Label htmlFor="buffer">OOS Options</Label> */}
          <Select 
            value={{label: oosString, value: oosString}} 
            options={variantSelectOptions.map(val => ({label: val, value: val}))} 
            onChange={handleOOSSelectChange}
          />
        </FormGroup>
      </td>
      <td>
        <FormGroup style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {/* <Label htmlFor="isArchived">Archived</Label> */}
            <Input id="isArchived" name="isArchived" type="checkbox" onChange={handlePausePreorderChange} checked={getValue('pause_preorder')} />
        </FormGroup>
      </td>
      <td>
        <FormGroup style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {/* <Label htmlFor="isArchived">Archived</Label> */}
            <Input id="isArchived" name="isArchived" type="checkbox" onChange={handleIsArchived} checked={getValue('isArchived')} />
        </FormGroup>
      </td>

      <td>
        <FormGroup style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {/* <Label htmlFor="isArchived">Archived</Label> */}
            <Input id="noReorder" name="noReorder" type="checkbox" onChange={handleNoReorder} checked={getValue('no_reorder')} />
        </FormGroup>
      </td>

      {
        locale !== 'AU' && (
          <td>
            <FormGroup style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {/* <Label htmlFor="isArchived">Allow AU Stock</Label> */}
                <Input id="isArchived" name="isArchived" type="checkbox" onChange={handleAllowAU} checked={getValue('allow_au')} />
            </FormGroup>
          </td>
        )
      }
    </tr>
  )
}

const saveVariant = (variant) => {
  return new Promise((resolve, reject) => {
    superagent.put(`${backendUrl}/product/variant/${variant._id}`)
      .send({ 
        no_reorder: variant.no_reorder,
        stock_buffer: variant.stock_buffer,
        continue_selling: variant.continue_selling,
        discontinued: variant.discontinued,
        oos_message: variant.oos_message,
        preorder: variant.preorder,

        stock_buffer_us: variant.stock_buffer_us,
        continue_selling_us: variant.continue_selling_us,
        discontinued_us: variant.discontinued_us,
        oos_message_us: variant.oos_message_us,
        preorder_us: variant.preorder_us,

        stock_buffer_uk: variant.stock_buffer_uk,
        continue_selling_uk: variant.continue_selling_uk,
        discontinued_uk: variant.discontinued_uk,
        oos_message_uk: variant.oos_message_uk,
        preorder_uk: variant.preorder_uk,

        us_allow_au: variant.us_allow_au,
        uk_allow_au: variant.uk_allow_au,
        isArchived: variant.isArchived,

        preorder_buffer: variant.preorder_buffer,
        preorder_buffer_us: variant.preorder_buffer_us,
        preorder_buffer_uk: variant.preorder_buffer_uk,

        pause_preorder: variant.pause_preorder,
        pause_preorder_us: variant.pause_preorder_us,
        pause_preorder_uk: variant.pause_preorder_uk
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
          const code = response.status 
          resolve({
            _id: variant._id,
            status: 'error'
          })
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
          resolve({
            _id: variant._id,
            status: 'error'
          })
        } else {
          resolve({
            _id: variant._id,
            status: 'success'
          })
        }
      }).catch(err => {
        resolve({
          _id: variant._id,
          status: 'error'
        })
      });
  })
}

const VariantBulkEditor = () => {
  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
    setRefresh(prev => !prev)
  }

  const [variantsRaw, variantsState] = useQuery({ 
    refresh, 
    url: `/product/findAllVariantsFull`,
  });

  const [variants, setVariants] = useState([])
  useEffect(() => {
    if (variantsRaw) {
      setVariants(variantsRaw)
    }
  }, [variantsRaw])

  const [loacaleTab, setLocaleTab] = useState('AU')
  const changedCount = variants.filter(val => val.changed === true).length

  const [loadingSend, setLoadingSend] = useState(false)

  const handleSubmit = async (e) => {
    if (loadingSend === true) return 
    setLoadingSend(true)

    const changedVariants = variants.filter(val => val.changed === true)

    console.log(changedVariants)

    let updatedPromises = changedVariants.map(val => saveVariant(val))
    let variantsUpdated = await Promise.all(updatedPromises)
    console.log(variantsUpdated)
    refreshData()
    setLoadingSend(false)
  }
  
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Ecommerce"  breadcrumbItem={'Bulk Edit Variants'} />
        {
            (
              changedCount > 0 &&
                <Button onClick={handleSubmit} style={{position: 'fixed', right: 32, bottom: 48, paddingLeft: 48, paddingRight: 48, zIndex: 99}} className={`btn-lg btn-rounded btn btn-success px-6 ${loadingSend ? 'disabled' : ''}`}> 
                    {loadingSend ? (variantsRaw === undefined ? 'Loading Audits...' : 'Submitting...') : `Submit ${changedCount}`} 
                </Button>
            )
        }
        <Row>
          <Col xs="12">
            <Card>
                <CardBody>
                  <CardTitle>Variants</CardTitle>
                  <Row>
                    <Col sm="12">
                      <Nav className="nav-tabs-custom nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: loacaleTab === "AU",
                            })}
                            onClick={() => {
                              setLocaleTab("AU")
                            }}
                          >
                            <span className="d-none d-sm-block"> AU</span>
                            <span className="d-block d-sm-none"></span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: loacaleTab === "US",
                            })}
                            onClick={() => {
                              setLocaleTab("US")
                            }}
                          >
                            <span className="d-none d-sm-block"> US</span>
                            <span className="d-block d-sm-none"></span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: loacaleTab === "UK",
                            })}
                            onClick={() => {
                              setLocaleTab("UK")
                            }}
                          >
                            <span className="d-none d-sm-block"> UK </span>
                            <span className="d-block d-sm-none"></span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Table className="table mb-0 vert-hover">
                        <thead style={{position: 'sticky', top: 123, background: 'white', zIndex: 10}}>
                          <tr>
                            <th> SKU </th>
                            <th> OOS Message </th>
                            <th> Buffer Amount </th>
                            <th> OOS Options </th>
                            <th> Pause Preorder </th>
                            <th> Archived </th>
                            <th> Do not reorder </th>
                            {
                              loacaleTab !== 'AU' && <th> Allow AU Stock </th>
                            }
                          </tr>
                        </thead>
                        {
                          variantsState === 'loading' ? (
                            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                              <div className="spinner-grow text-primary m-1" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          ) : (
                            variants && variants.map((variant, index) => {
                              const setVariant = (variant) => {
                                setVariants(prev => {
                                  let newVariants = [...prev]
                                  return newVariants.map((v, i) => {
                                    if (i === index) {
                                      return {
                                        ...variant,
                                        changed: true
                                      }
                                    } else {
                                      return v
                                    }
                                  })
                                })
                              } 
                              if (variant.sku === '') {
                                return <></>
                              }
                              return (
                                <VariantTableLine lineIndex={index} locale={loacaleTab} variant={variant} setVariant={setVariant}/>
                              )
                            })
                          )
                        }
                      </Table>
                    </Col>
                  </Row>
                </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default VariantBulkEditor