import React, {useState} from 'react'

import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { backendUrl } from '../../../helpers/consts';
import superagent from 'superagent'
 
function useGoogleAuthentication(history, path) {
  const handleSuccess = async (response) => {
    if ('accessToken' in response) {
      const accessToken = response.accessToken;

      return superagent.post(`${backendUrl}/auth/google`)
        .send({
          token: accessToken
        })
        .set('accept', 'json')
        .then(response => {
          if (response.status === 400 || response.status === 401 || response.status === 500) {
            throw response.body;
          }
            
            localStorage.setItem("authJWT", response.body.token);
            localStorage.setItem("refreshJWT", response.body.refresh);
            localStorage.setItem("authUser", JSON.stringify(response.body.user));
            history.push(path !== undefined ? path : '/dashboard');
        }).catch(err => {
            console.log(err)
        });
    }
  }
 
  return {
    handleSuccess,
  }
}
 
export default useGoogleAuthentication