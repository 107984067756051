import React, { useState } from 'react';
import {
  Col,
  Card,
  CardBody,
  Container,
  Button,
  Table,
  Input,
  Badge,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import '../../datatables.scss';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import SupplyChainNav from '../../../../components/supply-chain/nav.js';
import useQuery from '../../../../helpers/JIFI/useQuery.js';
import superagent from 'superagent';
import { backendUrl } from '../../../../helpers/consts.js';
import { StatusBadge } from '../../../../components/supply-chain/StatusBadge/index.js';

const statusOptions = [
  { value: '', label: 'All' },
  { value: 'approved', label: 'Approved' },
  { value: 'pending', label: 'Pending' },
  { value: 'disapproved', label: 'Disapproved' },
];

const perPageOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500 - (WARNING SLOW)' },
  { value: 1000, label: '1000 - (WARNING SLOW)' },
];

const paginate = function (array, index) {
  return [
    array[index - 2],
    array[index - 1],
    array[index],
    array[index + 1],
    array[index + 2],
  ].filter(function (el) {
    return el != null;
  });
};

const TablePagination = ({ count, page, setPage, perPage, setPerPage }) => {
  const pageCount = Math.ceil(count / perPage);
  const pages = paginate(
    Array.from({ length: pageCount }, (v, i) => i + 1),
    page
  );
  const handleNumberClicked = (key) => {
    setPage(key);
  };
  return (
    <Pagination className="pagination pagination-rounded justify-content-end mb-2">
      <div style={{ height: '100%', width: 140 }}>
        <Select
          options={perPageOptions}
          onChange={(newPerPage) => setPerPage(newPerPage.value)}
          value={perPageOptions.find((val) => val.value === perPage)}
        />
      </div>
      <PaginationItem disabled={page === 1}>
        <PaginationLink previous onClick={() => setPage(page - 1)} />
      </PaginationItem>
      {pages.map((val) => (
        <PaginationItem
          onClick={() => handleNumberClicked(val)}
          active={val === page}
        >
          <PaginationLink>{val}</PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem>
        <PaginationLink next={true} onClick={() => setPage(page + 1)} />
      </PaginationItem>
    </Pagination>
  );
};

const PullPage = () => {
  const [refresh, setRefresh] = useState(true);
  const history = useHistory();
  /*
    Pagination
  */
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [status, setStatus] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const refreshData = () => setRefresh((prev) => !prev);

  const dateString = new Date()
    .toDateString()
    .replace(' ', '-')
    .replace(' ', '-')
    .replace(' ', '-');

  const handleUpdateRelease = (status, id) => {
    superagent
      .post(`${backendUrl}/purchase-order/release/update-status/${id}`)
      .send({ status })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        refreshData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [replenishHistory, stateReplenishHistory] = useQuery({
    url: `/purchase-order/release/all`,
    refresh,
    queryData: {
      page,
      perPage,
      status,
      searchTerm,
    },
  });
  const replenishHistoryData = replenishHistory?.releases;
  const totalCount = replenishHistory?.totalCount;

  const csvData = [
    [
      'Purchase Order',
      'Expected Release Date',
      'SKUs Releases',
      'Total Release Count',
      'Actual Release Date',
      'Status',
    ],
    ...(replenishHistoryData?.length > 0
      ? replenishHistoryData.map((replenishHistoryObject) => {
          return [
            replenishHistoryObject['purchaseOrder']?.reference,
            (replenishHistoryObject?.purchaseOrder?.expectedReleaseDate &&
              new Date(
                replenishHistoryObject?.purchaseOrder?.expectedReleaseDate
              ).toDateString()) ||
              '',
            replenishHistoryObject?.releaseLines?.length,
            replenishHistoryObject.releaseLines.reduce(
              (sum, line) => sum + line.quantity,
              0
            ),
            (replenishHistoryObject?.actualDateReleased &&
              new Date(
                replenishHistoryObject?.actualDateReleased
              ).toDateString()) ||
              '',
            replenishHistoryObject?.status,
          ];
        })
      : []),
  ];
  const handleKeyDown = (e) => {
    
    if (e.key === 'Enter' || e.keyCode === 13) {
      setSearchTerm(e.target.value);
    }
  };

  return (
    <SupplyChainNav>
      <Container fluid>
        <Col xs="12" m="12" lg="12" xl="12">
          <Card>
            <CardBody>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', gap: 20 }}>
                  <div className="search-box mb-2 d-block">
                    <div className="position-relative">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onKeyDown={handleKeyDown}
                      />
                      <i className="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>

                  <div style={{ width: '60%' }}>
                    <Select
                      options={statusOptions}
                      onChange={(val) => {
                        setPage(1);

                        setStatus(val.value);
                      }}
                      value={statusOptions.find((val) => val.value === status)}
                    ></Select>
                  </div>
                </div>

                <CSVLink
                  style={{ float: 'right', marginBottom: '1.25rem' }}
                  data={csvData}
                  filename={`PURCHASE_ORDERS_RELEASE_${dateString}.csv`}
                >
                  <Button
                    type="button"
                    color="secondary"
                    className="btn-md btn-rounded"
                  >
                    CSV
                  </Button>
                </CSVLink>
              </div>

              {stateReplenishHistory === 'success' ? (
                <div className="table-responsive">
                  <Table className="table table-centered table-nowrap">
                    <thead className="thead-light">
                      <tr>
                        <th>Purchase Order</th>
                        <th>Expected Release Date</th>
                        <th>SKUs Releases</th>
                        <th>Total Release Count</th>
                        <th>Actual Release Date</th>

                        <th>Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {replenishHistoryData &&
                        replenishHistoryData.length > 0 &&
                        replenishHistoryData.map((replenishHistoryObject) => {
                          return (
                            <tr>
                              <td>
                                <Link
                                  to={`/purchase-order/${replenishHistoryObject['purchaseOrder']?._id}`}
                                  className="text-body font-weight-bold"
                                >
                                  {
                                    replenishHistoryObject['purchaseOrder']
                                      ?.reference
                                  }
                                </Link>
                              </td>
                              <td>
                                {(replenishHistoryObject?.purchaseOrder
                                  ?.expectedReleaseDate &&
                                  new Date(
                                    replenishHistoryObject?.purchaseOrder?.expectedReleaseDate
                                  ).toDateString()) ||
                                  ''}
                              </td>
                              <td className="text-center">
                                {replenishHistoryObject?.releaseLines?.length}
                              </td>
                              <td className="text-center">
                                {replenishHistoryObject.releaseLines.reduce(
                                  (sum, line) => sum + line.quantity,
                                  0
                                )}
                              </td>
                              <td>
                                {' '}
                                {(replenishHistoryObject?.actualDateReleased &&
                                  new Date(
                                    replenishHistoryObject?.actualDateReleased
                                  ).toDateString()) ||
                                  ''}
                              </td>
                              <td style={{ textTransform: 'capitalize' }}>
                                <StatusBadge
                                  status={replenishHistoryObject?.status}
                                />
                              </td>
                              <td>
                                {replenishHistoryObject.status ===
                                  'pending' && (
                                  <Card
                                    className="flex flex-col mb-0 capitalize"
                                    style={{ gap: '3px' }}
                                  >
                                    <Button
                                      type="button"
                                      color="secondary"
                                      className="btn-sm btn-success"
                                      onClick={() =>
                                        handleUpdateRelease(
                                          'approved',
                                          replenishHistoryObject._id
                                        )
                                      }
                                    >
                                      Approved
                                    </Button>
                                    <Button
                                      type="button"
                                      color="secondary"
                                      className="btn-sm btn-danger"
                                      onClick={() =>
                                        handleUpdateRelease(
                                          'disapproved',
                                          replenishHistoryObject._id
                                        )
                                      }
                                    >
                                      Disapproved
                                    </Button>
                                  </Card>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    className="spinner-border text-primary m-1"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}

              <TablePagination
                count={totalCount}
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </CardBody>
          </Card>
        </Col>
      </Container>
    </SupplyChainNav>
  );
};

export default PullPage;
