import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form, FormGroup, Input, Card, CardBody, CardTitle, CardSubtitle, Button, Label } from "reactstrap";

import superagent from 'superagent'
import {backendUrl} from '../../helpers/consts.js'

export const AddProduct = ({refreshData, toggle}) => {
    const history = useHistory();
    const [name, setName] = useState('')
    const createProduct = (e) => {
        e.preventDefault()
        superagent.post(`${backendUrl}/product`)
            .send({ name: name })
            .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
            .set('accept', 'json')
            .on('error', (response) => {
                const code = response.status
                history.replace(history.location.pathname, {
                    errorStatusCode: code
                });
            })
            .then(response => {
            const code = response.status
            if (code > 400) {
                history.replace(history.location.pathname, {
                errorStatusCode: code
                });
            } else {
                refreshData()
                toggle()
            }
            }).catch(err => {
            console.log(err)
            });
    }
    return (
        <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <Form>
                                        <Row>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label htmlFor="name">Product Name</Label>
                                                    <Input value={name} onChange={(e) => setName(e.target.value)} id="name" name="name" type="text" className="form-control" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Button onClick={createProduct} color="primary" className="mr-1 waves-effect waves-light">Create</Button>
                                    </Form>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
        </React.Fragment>
    );
}

export const AddVariant = ({id, refreshData, toggle}) => {
    const history = useHistory();
    const [name, setName] = useState('')
    const [sku, setSku] = useState('')
    const createVariant = (e) => {
        e.preventDefault()
        superagent.post(`${backendUrl}/product/variant`)
            .send({ name: name, sku: sku, product: id })
            .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
            .set('accept', 'json')
            .on('error', (response) => {
                const code = response.status
                history.replace(history.location.pathname, {
                    errorStatusCode: code
                });
            })
            .then(response => {
            const code = response.status
            if (code > 400) {
                history.replace(history.location.pathname, {
                errorStatusCode: code
                });
            } else {
                refreshData()
                toggle()
            }
            }).catch(err => {
            console.log(err)
            });
    }
    return (
        <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <Form>
                                        <Row>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label htmlFor="name">Variant Title</Label>
                                                    <Input value={name} onChange={(e) => setName(e.target.value)} id="name" name="name" type="text" className="form-control" />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label htmlFor="sku">SKU</Label>
                                                    <Input value={sku} onChange={(e) => setSku(e.target.value)} id="sku" name="sku" type="text" className="form-control" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Button onClick={createVariant} color="primary" className="mr-1 waves-effect waves-light">Create</Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
        </React.Fragment>
    );
}

export default AddProduct;