import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Badge, Col, CustomInput, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'; // Assuming you are using Reactstrap for styling
import superagent from 'superagent'; // Make sure to import superagent or replace it with your preferred HTTP client
import { backendUrl } from '../../../../helpers/consts';
import WizardNav from './WizardNav'; // Import WizardNav component if it exists
//Import Breadcrumb
import "../../datatables.scss";
import { embosColours, embosFonts } from '..';
import SKUToolTip from '../../helpers/SKUToolTip';
import useSKULevels from "../../helpers/useSKULevels.js";
import useSKUStatus from "../../helpers/useSKUStatus.js";
import useUpcomingStock from "../../helpers/useUpcomingStock.js";

const newColorsBags = {
  Coral: '#FDC0B8',
  'Dusty-Pink': '#F5DFE2',
  Fuschia: '#DF3C7F',
  Mint: '#D4EECC',
  'Sky-Blue': '#BBE0EE',
  'Aqua-Blue': '#47BCEE',
  'French-Navy': '#182D6D',
  Red: '#C63531',
  Plum: '#AC3856',
  Purple: '#64258E',
  Lavender: '#957EA3',
  'Forest-Green': '#1C4125',
  Green: '#6A9A52',
  Yellow: '#E8BE47',
  Orange: '#E96737',
  White: '#ffffff',
  Grey: '#97999D',
  Black: '#000',
}
export const newColors = {
  'Lavender': '#90759B',
  'Lilac': '#DCC5DF',
  'Ballet-Pink': '#F1C9DE',
  'Rose-Pink': '#DC75A4',
  'Grenaine': '#9E5360',
  'Blush-Pink': '#E77472',
  'Magenta': '#B12D52',
  'Maroon': '#731A29',
  'Red': '#C92327',
  'Burnt-Orange': '#DC5527',
  'Mango': '#EF7E2E',
  'Rust': '#9B4827',
  'Toffee': '#705037',
  'Taupe': '#D3A289',
  'Butter-Yellow': '#FBF3BE',
  'Yellow': '#E8B629',
  'Lime': '#C6B937',
  'Green': '#4A813C',
  'Forest-Green': '#034123',
  'Mint': '#78AB93',
  'Ice-Blue': '#BEE3E9',
  'Denim': '#75A6B3',
  'Ocean-Blue': '#3E8EA1',
  'Blue': '#1466B2',
  'Grey': '#95979E',
  'Slate-Grey': '#63727E',
  'Black': '#000000',
  White: '#FFFFFF',
}
export const fonts = {
  'heritage': 'Heritage',
  'bold-new': 'Bold (new)',
  'bubble': 'Bubble',
  'retro-serif': 'Retro Serif',
  'graphic': 'Graphic',
  'sans-serif': 'Sans Serif',
  block: 'Block',
  signature: 'Signature',
  retro: 'Retro',
  serif: 'Serif',
  decorative: 'Decorative',

  painted: 'Painted',
  college: 'College',
  script: 'Script',
  casual: 'Casual',
  wide: 'Wide',
  bold: 'Block Serif',
  cursive: 'Modern Script',
  modern: 'Modern Serif',
  'zh-ser': 'Chinese Serif',
  'zh-san': 'Chinese Sans Serif',
  'zh-block': 'Chinese Block',
  'zh-script': 'Chinese Script',
  'zh-casual': 'Chinese Casual',
}
export const fontsBags = {
  'all-sans': 'Carry All Sans Serif',
  'all-serif': 'Carry All Serif',
}
export const locations = {
  'Top': 'Top',
  'Side': 'Side',
  'Back': 'Back',
}
export const TecLocations = {
  'Left': 'Left',
  'Right': 'Right',
}
export const TagLocations = {
  'Horizontal': 'Horizontal',
  'Vertical': 'Vertical',
}
export const Notebooklocations = {
  'center-landscape': 'center-landscape',
  'center-portrait': 'center-portrait',
  'bottom-left': 'bottom-left'
}
const fulfillmentToBadge = (fulfillment) => {
  if (fulfillment === 'unfulfilled') return 'warning';
  if (fulfillment === 'awaiting-stock') return 'secondary';
  const conversion = {
    'fulfilled': 'success',
    'partially_fulfilled': 'error',
  };
  return conversion[fulfillment];
};

const OrderWizardSKUchange = ({ togglemodal, order, orderID, refresh, getVariant, lineItems }) => {
  const availableItems = order.line_items.filter((item) => item.fulfillment_status !== 'deleted' && item.fulfillment_status === "unfulfilled");
  const [selectedItems, setSelectedItems] = useState(lineItems.map(() => true));
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editLineItem, setEditLineItem] = useState(order?.line_items.filter((item) => item?.fulfillment_status !== 'deleted')[0] || null)
  const [modalEdit, setModalEdit] = useState();
  const [step, setStep] = useState(1);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  const findSKULevel = useSKULevels()
  const findUpcomingStock = useUpcomingStock()
  const findSKUStatus = useSKUStatus()

  const [allUniquePets, setAllUniquePets] = useState({})
  const addUniquePet = (pet, airtableID) => {
    setAllUniquePets(prev => {
      let all = { ...prev }
      all[airtableID] = pet
      return all
    })
  }

  const handleEditLineItem = (id) => {
    setEditLineItem(id)
    togglemodalEdit()
  }

  const togglemodalEdit = () => {
    setModalEdit(prev => !prev)
  }

  const handleItemClick = (index) => {
    setSelectedItemIndex(index === selectedItemIndex ? null : index);
  };

  const handleNext = () => {
    const selectedLineItem = availableItems[selectedItemIndex];
    if (selectedLineItem) {
      handleEditLineItem(selectedLineItem);
      setStep(2);
    }
  };
  const [monogramType, setMonogramType] = useState(editLineItem?.pet === true ? 'pets' : 'text')

  const [monogramPets, setMonogramPets] = useState(editLineItem?.monogram_pets)
  const [monogramAirtableLines, setMonogramAirtableLines] = useState(editLineItem.monogram_airtable_line)

  const [selectedOption, setSelectedOption] = useState()

  useEffect(() => {
    setLoading(true)
    superagent.get(`${backendUrl}/product/variant/${editLineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')}/find`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          setOptions(response.body)
          setSelectedOption(editLineItem.sku)
          setLoading(false)
        }
      }).catch(err => {
        setLoading(false)
      });

    if (editLineItem.monogram === true) {
      setMonogramText(editLineItem.monogram_text)
      setMonogramFont(editLineItem.monogram_font)
      setMonogramColor(editLineItem.monogram_color)
      setMonogramShadow(editLineItem.monogram_shadow || 'None')
      setMonogramLocation(editLineItem.monogram_position)
    }
  }, [editLineItem])

  const handleOptionChanged = (e) => {
    const option = e.target.value
    setSelectedOption(option)
  }

  const [monogramText, setMonogramText] = useState('')
  const [monogramFont, setMonogramFont] = useState()
  const [monogramColor, setMonogramColor] = useState()
  const [monogramShadow, setMonogramShadow] = useState()
  const [monogramLocation, setMonogramLocation] = useState()

  const [edittingPet, setEdittingPet] = useState()

  const updateLineItem = () => {
    let newLineItem = {
      _id: editLineItem._id,
      sku: selectedOption,
      orderID: orderID,
      ...(
        (selectedOption.split('-').filter(val => val !== 'P' && val !== 'LE').join('-') !== selectedOption) ? (
          monogramType === 'text' ? (
            {
              monogram_text: encodeURIComponent(monogramText),
              monogram_font: monogramFont,
              monogram_color: monogramColor,
              monogram_position: monogramLocation,
              ...(
                (monogramFont === 'block' || monogramFont === 'college' || monogramFont === 'heritage' || monogramFont === 'decorative') ?
                  { monogram_shadow: monogramShadow, } : {}
              ),
              monogram: true,
              pet: false
            }
          ) : (
            {
              monogram_position: monogramLocation,
              monogram: true,
              monogram_pets: monogramPets,
              monogram_airtable_line: monogramAirtableLines,
              pet: true
            }
          )
        ) : {
          monogram: false
        }
      )
    }

    setLoading(true)
    superagent.post(`${backendUrl}/orders/${editLineItem._id}/editLineItem`)
      .send(newLineItem)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          refresh()
          togglemodal()
          setLoading(false)
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });
  }

  let [comment_file, setCommentFile] = useState(null)
  let [imageData, setImageData] = useState()
  let [uploadedAirtableID, setUploadedAirtableID] = useState('')
  let [newPetName, setNewPetName] = useState('')

  const handleFileChange = ({ target: { files } }) => {
    const cancel = !files.length;
    if (cancel) return;

    let file = files[0]
    setCommentFile(file)

    const reader = new FileReader()
    reader.readAsBinaryString(file)

    reader.onload = function (event) {
      setImageData({
        base64: `data:${file.type};base64,${btoa(event.target.result)}`,
        filename: file.name,
      })
      event.target.value = null
      event.target.files = null
      setCommentFile(null)
    }
  }

  const uploadToS3 = () => {
    const uploadData = {
      "petName": newPetName,
      "base64Image": imageData.base64
    }

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    }

    options.body = JSON.stringify(uploadData)

    return new Promise((resolve, reject) => {
      fetch(
        'https://az25kdsm7h.execute-api.ap-southeast-2.amazonaws.com/production/uploadPetImagesToS3',
        options
      )
        .then((response) => response.json())
        .then((response) => {
          let base64ToString = Buffer.from(response.url, "base64").toString();
          resolve(base64ToString)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const createAirtable = (printJobID) => {
    const uploadData = {
      printJobID: printJobID,
      OrderNumber: order.name,
      email: order.email
    }

    return new Promise((resolve, reject) => {
      superagent.post(`${backendUrl}/orders/createPetAirtable/`)
        .send(uploadData)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
          reject(response)
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
            resolve(response.body)
          }
        }).catch(err => {
          reject(err)
        });
    })
  }

  const fetchAirtable = (airtableId) => {
    const uploadData = {
      airtableId
    }

    return new Promise((resolve, reject) => {
      superagent.post(`${backendUrl}/orders/fetchPetAirtable/`)
        .send(uploadData)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
          reject(response)
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
            resolve(response.body)
          }
        }).catch(err => {
          reject(err)
        });
    })
  }

  const sleep = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const savePet = async () => {
    let airtableID
    if (imageData !== undefined) {
      let printjobID = await uploadToS3()
      airtableID = (await createAirtable(printjobID)).id
      await sleep(2000)
    } else if (uploadedAirtableID !== '') {
      airtableID = uploadedAirtableID
    } else {
      return
    }

    let airtableRecord = await fetchAirtable(airtableID)

    let uniquePet = {
      ...airtableRecord['fields'],
      name: newPetName
    }

    addUniquePet(uniquePet, airtableID)
    setEdittingPet()
  }


  if (loading === true) {
    return (
      <div>
        <div>
          <ModalBody>
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </ModalBody>
        </div>
      </div>
    )
  }

  const colorType = selectedOption ? ['BKP', 'BPK', 'WKD', 'TOT', 'CAR', 'DBK'].includes(selectedOption.slice(0, 3)) || ['EVETOT'].includes(selectedOption.slice(0, 6)) : false
  let colors = colorType ? newColorsBags : newColors
  let fontsFor = colorType ? fontsBags : fonts

  const isEmboss = selectedOption && selectedOption.includes('JETTE')
  if (isEmboss) {
    colors = embosColours
    fontsFor = embosFonts
  }

  const monogramTypes = {
    'text': 'Text',
    'pets': 'Pets',
  }


  return (
    <div>
      <WizardNav steps={['SKU Select', 'Edit SKU']} activeTabProgress={step} />
      <ModalBody>
        {step === 1 ? (
          <div>
            {lineItems.map((lineItem, key) => {
              const isSelected = key === selectedItemIndex;
              return (
                <div className="col-md-12">
                  <Row key={key} onClick={() => handleItemClick(key)} style={{ cursor: 'pointer', border: '1px solid #ddd', borderRadius: '5px', margin: '5px 0', backgroundColor: isSelected ? '#f0f0f0' : 'inherit' }}>
                    <Col sm="3">
                      <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={getVariant(lineItem.sku)?.image} alt="" className="img-fluid" />
                      </div>
                    </Col>
                    <Col sm="8" className="p-3">
                      <h6>{getVariant(lineItem.sku)?.name}</h6>
                      <div>
                        <Badge className={"font-size-12 badge-soft-" + fulfillmentToBadge(lineItem?.fulfillment_status)} color={fulfillmentToBadge(lineItem?.fulfillment_status)} pill>
                          {lineItem?.fulfillment_status ? lineItem?.fulfillment_status.replace('_', ' ').toUpperCase() : 'UN-FULFILLED'}
                        </Badge>
                      </div>
                      <div>SKU: <SKUToolTip sku={lineItem.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} /></div>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        ) : (
          <div className='col-md-12'>
            <div>
              <ModalBody>
                {
                  edittingPet !== undefined ? (
                    <>
                      {
                        edittingPet.airtableId === undefined ? (
                          <>
                            {
                              uploadedAirtableID === '' && (
                                imageData === undefined ? (
                                  <FormGroup className="mb-4" row>
                                    <Col lg="12" className="d-flex align-items-center custome-input">
                                      <CustomInput
                                        accept="image/*"
                                        className=" ml-2"
                                        type="file"
                                        id="comment_file"
                                        name="comment_file"
                                        label={comment_file || 'Add image'}
                                        onChange={(e) => handleFileChange(e)}
                                      />
                                    </Col>
                                  </FormGroup>
                                ) : (
                                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => setImageData()}>
                                    <img style={{ height: 100, width: 100 }} src={imageData.base64} />
                                  </div>
                                )
                              )
                            }
                            {
                              imageData === undefined && (
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className="col-md-2 col-form-label"> Airtable ID </label>
                                  <div className="col-md-10">
                                    <Input placeholder="Airtable ID" className="mb-2" type="text" value={uploadedAirtableID} onChange={(e) => setUploadedAirtableID(e.target.value)} />
                                  </div>
                                </div>
                              )
                            }
                            <div className="form-group row">
                              <label htmlFor="example-text-input" className="col-md-2 col-form-label"> Pet name </label>
                              <div className="col-md-10">
                                <Input placeholder="Pet name" className="mb-2" type="text" value={newPetName} onChange={(e) => setNewPetName(e.target.value)} />
                              </div>
                            </div>
                          </>
                        ) : (
                          <> {JSON.stringify(edittingPet)} </>
                        )
                      }
                    </>
                  ) : (
                    <>
                      <Label>SKU: </Label>
                      <Input onChange={handleOptionChanged} value={selectedOption} type="select" name="select" id="exampleSelect">
                        {
                          options.map((option) => <option value={option?.sku}> {option?.name} </option>)
                        }
                        {
                          (['COP', 'CHK', 'PLS', 'CME', 'BKP', 'BPK', 'WKD', 'TOT', 'PRO', 'LGT', 'BOT', 'IPC', 'TRK', 'NBK', 'TAG', 'SET', 'TEC', 'COE', 'EXP', 'EVE', 'DBK', 'JET'].includes(editLineItem.sku.slice(0, 3)) || ['CAR-DPK', 'CAR-TOT', 'CAR-BPK', 'CAR-WKD'].includes(editLineItem.sku.slice(0, 7)) || ['CAR-WKDP'].includes(editLineItem.sku.slice(0, 8))) &&
                          options.map((option) => <option value={`${option.sku}-P`}> {option.name} Personalised </option>)
                        }
                        {
                          ['COP'].includes(editLineItem.sku.slice(0, 3)) &&
                          <option value={`COPB02-CHA-LE`}> Carry On Charcoal - Luxury Escapes </option>
                        }
                        {
                          ['WKD'].includes(editLineItem.sku.slice(0, 3)) &&
                          <option value={`WKD02-CHA-LE`}> Weekender Black - Luxury Escapes </option>
                        }
                      </Input>
                      <br />
                      {
                        selectedOption && (selectedOption.split('-').filter(val => val !== 'P' && val !== 'LE').join('-') !== selectedOption) && (
                          <div>
                            <br />
                            <h6> Monogram Data</h6>
                            <br />
                            <div className="form-group row">
                              <div className="col-md-12">
                                <Input className="mb-2" onChange={(e) => setMonogramType(e.target.value)} value={monogramType} type="select" name="type">
                                  <option disabled selected value> -- select an option -- </option>
                                  {Object.keys(monogramTypes).map((val) => <option value={val}>{monogramTypes[val]}</option>)}
                                </Input>
                              </div>
                            </div>
                            <br />
                            {
                              monogramType === 'text' && (
                                <>
                                  <div className="form-group row">
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label"> Text </label>
                                    <div className="col-md-10">
                                      <Input placeholder="Monogram text" className="mb-2" type="text" value={monogramText} onChange={(e) => setMonogramText(e.target.value)} />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label"> Font </label>
                                    <div className="col-md-10">
                                      <Input className="mb-2" onChange={(e) => setMonogramFont(e.target.value)} value={monogramFont} type="select" name="select">
                                        <option disabled selected value> -- select an option -- </option>
                                        {Object.keys(fontsFor).map((val) => <option value={val}>{fontsFor[val]}</option>)}
                                      </Input>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Main Colour</label>
                                    <div className="col-md-10">
                                      <Input className="mb-2" onChange={(e) => setMonogramColor(e.target.value)} value={monogramColor} type="select" name="select">
                                        <option disabled selected value> -- select an option -- </option>
                                        {Object.keys(colors).map((val) => <option style={{ color: colors[val] }} value={val}>{val}</option>)}
                                      </Input>
                                    </div>
                                  </div>
                                  {
                                    (monogramFont === 'block' || monogramFont === 'college' || monogramFont === 'heritage' || monogramFont === 'decorative') && (
                                      <div className="form-group row">
                                        <label htmlFor="example-text-input" className="col-md-2 col-form-label">Shadow Colour</label>
                                        <div className="col-md-10">
                                          <Input className="mb-2" onChange={(e) => setMonogramShadow(e.target.value)} value={monogramShadow} type="select" name="select">
                                            <option disabled selected value> -- select an option -- </option>
                                            {Object.keys(newColors).map((val) => <option style={{ color: newColors[val] }} value={val}>{val}</option>)}
                                          </Input>
                                        </div>
                                      </div>
                                    )
                                  }
                                  <div className="form-group row">
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Location</label>
                                    <div className="col-md-10">
                                      <Input className="mb-2" onChange={(e) => setMonogramLocation(e.target.value)} value={monogramLocation} type="select" name="select">
                                        <option disabled selected value> -- select an option -- </option>
                                        {
                                          editLineItem.sku.includes('NBK') ? (
                                            Object.keys(Notebooklocations).map((val) => <option value={Notebooklocations[val]}>{Notebooklocations[val]}</option>)
                                          ) : (
                                            editLineItem.sku.includes('TEC') || editLineItem.sku.includes('CME') ? (
                                              Object.keys(TecLocations).map((val) => <option value={TecLocations[val]}>{TecLocations[val]}</option>)
                                            ) : editLineItem.sku.includes('TAG') ? (
                                              Object.keys(TagLocations).map((val) => <option value={TagLocations[val]}>{TagLocations[val]}</option>)
                                            ) : (
                                              Object.keys(locations).map((val) => <option value={locations[val]}>{locations[val]}</option>)
                                            )
                                          )
                                        }
                                      </Input>
                                    </div>
                                  </div>

                                </>
                              )
                            }
                            {
                              monogramType === 'pets' && (
                                <>
                                  <div className="form-group row">
                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">Location</label>
                                    <div className="col-md-10">
                                      <Input className="mb-2" onChange={(e) => setMonogramLocation(e.target.value)} value={monogramLocation} type="select" name="select">
                                        <option disabled selected value> -- select an option -- </option>
                                        {
                                          editLineItem.sku.includes('NBK') ? (
                                            Object.keys(Notebooklocations).map((val) => <option value={Notebooklocations[val]}>{Notebooklocations[val]}</option>)
                                          ) : (
                                            editLineItem.sku.includes('TEC') || editLineItem.sku.includes('CME') ? (
                                              Object.keys(TecLocations).map((val) => <option value={TecLocations[val]}>{TecLocations[val]}</option>)
                                            ) : editLineItem.sku.includes('TAG') ? (
                                              Object.keys(TagLocations).map((val) => <option value={TagLocations[val]}>{TagLocations[val]}</option>)
                                            ) : (
                                              Object.keys(locations).map((val) => <option value={locations[val]}>{locations[val]}</option>)
                                            )
                                          )
                                        }
                                      </Input>
                                    </div>
                                  </div>
                                  <Row>
                                    {
                                      Object.keys(allUniquePets).map((petID, key) => {
                                        let pet = allUniquePets[petID]
                                        let selected = monogramAirtableLines.includes(petID)
                                        const handleTogglePetSelect = () => {
                                          if (selected) {
                                            setMonogramAirtableLines(prev => prev.filter(val => val !== petID))
                                            setMonogramPets(prev => btoa(JSON.stringify(JSON.parse(atob(prev)).filter(val => val.signedUrl !== pet.printJobID))))
                                          } else {
                                            setMonogramAirtableLines(prev => [...prev, petID])
                                            setMonogramPets(prev => btoa(JSON.stringify([...(prev ? JSON.parse(atob(prev)) : []), { signedUrl: pet.printJobID, name: pet.name }])))
                                          }
                                        }

                                        return (
                                          <Col xl="3" sm="6" key={"_pet_" + key} >
                                            <Card style={{ background: '#d5d5d5' }} onClick={handleTogglePetSelect}>
                                              <CardBody style={{ position: 'relative' }}>
                                                <div style={{ position: 'absolute', top: 8, right: 8, fontSize: 24 }}>
                                                  <i className={selected ? 'bx bx-check-square' : 'bx bx-square'} />
                                                </div>
                                                <h5>{pet.name}</h5>
                                                <h6>{pet.Status}</h6>
                                                <img src={pet['Provided Image'][0]['url']} style={{ width: 100, height: 100, objectFit: 'contain' }} />
                                                <br />
                                                <br />
                                                {/* <Button color="warning" onClick={(e) => {e.stopPropagation(); setEdittingPet({...pet, airtableId: petID})}}> Edit. </Button> */}
                                              </CardBody>
                                            </Card>
                                          </Col>
                                        )
                                      })
                                    }
                                    <Col xl="3" sm="6" key={"_pet_add"} >
                                      <Card style={{ background: '#d5d5d5' }}>
                                        <CardBody>
                                          <Button color="success" onClick={() => setEdittingPet({ airtableId: undefined })}> Add new pet. </Button>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </>
                              )
                            }

                          </div>
                        )
                      }

                    </>
                  )
                }
              </ModalBody>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="form-group row">
          <div className="col-md-12">
            <Button color="danger" onClick={togglemodal} style={{ marginRight: '16px' }}> Close </Button>
            {step === 1 ? (
              <Button color="success" onClick={handleNext}> Next </Button>
            ) : (
              <Button color="success" onClick={updateLineItem}> Save </Button>
            )}
          </div>
        </div>
      </ModalFooter>
    </div>
  );
};

export default OrderWizardSKUchange;
