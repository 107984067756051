import React from 'react'

Date.prototype.yyyymmdd = function() {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();
  
    return [
        (dd>9 ? '' : '0') + dd,
        (mm>9 ? '' : '0') + mm,
        this.getFullYear(),
    ].join(' / ');
};

class PrintPage extends React.Component {
    render() {
        let labels = this.props.labels.map(val => {
            let items = []
            for (let i = 0; i < val.quantity; i++) {
              items.push( <PrintPageNonFunc name={this.props.name} address1={this.props.address1} city={this.props.city} zip={this.props.zip} phone={this.props.phone} />)
            }
            return items
        }).flat()
        return <div className="Labels"> {labels} </div>
    }
}


const PrintPageNonFunc = ({name, address1, city, zip, phone}) => {
    return (
        <div className="PrintCanvasDelivery">
            {/* <div className="PrintLine"> Hello </div> */}
            <img style={{right: '-10%', left: '-10%', top: '-5%', width: '120%',position: 'absolute', objectFit: 'contain'}} src='/label/top.png'/>
            <img style={{right: '-10%', left: '-10%', bottom: '0%', width: '120%',position: 'absolute', objectFit: 'contain'}} src='/label/bottom.png'/>
            <div className="PrintBodyDelivery">
              {name} <br/>
              <br/>
              {address1} <br/>
              {city} {zip} <br/>
              <br/>
              {phone}
            </div>
            <div style={{position: 'absolute',left: 'calc(65px + 20mm)', bottom: '20%', right: 'calc(65px + 20mm)', color: 'black', fontSize: 32, fontWeight: 600}}>
              Same-day delivery to our favourite people.<br/>
              Out of lockdown and ready for the world.
            </div>
        </div>
    )
}

export default PrintPage