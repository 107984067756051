import React, {useState, useEffect} from 'react'
import { Container, Row, Col, Form, FormGroup, Input, Card, CardBody, CardTitle, Button, Label } from "reactstrap";
import useQuery from '../../../helpers/JIFI/useQuery';
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import superagent from 'superagent'
import { backendUrl } from '../../../helpers/consts.js'
import { useHistory } from "react-router-dom";

const FlagTypeListPage  = ({
  match: {
    params
  },
}) => {
  const history = useHistory();
  const { flagTypeID } = params

  const [flagTypeName, setFlagTypeName] = useState('')
  const [refresh, setRefresh] = useState(false)
  const refreshData = () => setRefresh(prev => !prev)

  const [flag, state] = useQuery({
    refresh,
    url: `/orders/flagtype/${flagTypeID}`
  });

  useEffect(() => {
    if (flag !== undefined) {
      setFlagTypeName(flag.name)
    }
  }, [flag])

  const updateFlagType = (e) => {
    e.preventDefault()
  
    if (flag === undefined) {
      superagent.post(`${backendUrl}/orders/createFlagType`)
        .send({ 
          name: flagTypeName, 
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
            const code = response.status
            history.replace(history.location.pathname, {
                errorStatusCode: code
            });
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
              history.replace(history.location.pathname, {
                errorStatusCode: code
              });
          } else {
            history.push(`/flagtype/edit/${response.body._id}`)
          }
        }).catch(err => {
          console.log(err)
        });
    } else {
      superagent.put(`${backendUrl}/order/flagtype/${flagTypeID}`)
        .send({ 
          name: flagTypeName, 
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
            const code = response.status
            history.replace(history.location.pathname, {
                errorStatusCode: code
            });
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
              history.replace(history.location.pathname, {
                errorStatusCode: code
              });
          } else {
            history.refresh()
          }
        }).catch(err => {
          console.log(err)
        });
    }
  }

  return (
    <>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Ecommerce" breadcrumbItem={flagTypeID !== undefined ? 'Edit Flag Type' : 'New Flag Type'} />
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <CardTitle>Flag Type Information</CardTitle>
                            <Form>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label htmlFor="locationname">Name</Label>
                                            <Input id="locationname" name="locationname" type="text" className="form-control" value={flagTypeName} onChange={(e) => setFlagTypeName(e.target.value)} />
                                        </FormGroup>                                        
                                    </Col>                                                                      
                                </Row>
                                <Button onClick={updateFlagType} type="submit" color="success" className="mr-1 waves-effect waves-light">{flagTypeID === undefined ? 'Add' : 'Save Changes'} </Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
      </div>
    </>
  )
}

export default FlagTypeListPage