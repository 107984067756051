import React, { useEffect, useState } from "react";
import Select from 'react-select';
import {
    Badge, Button, ModalFooter, Card, CardBody, CardHeader, CardText, CardTitle, Col, Input, Label, ModalBody, NavItem, NavLink, Progress, Row, TabContent,
    TabPane
} from "reactstrap";
import WizardNav from "./WizardNav.js";
import classnames from "classnames";
import superagent from "superagent";
import useQuery from '../../../../helpers/JIFI/useQuery';
import { backendUrl } from "../../../../helpers/consts.js";
import SKUToolTip from "../../helpers/SKUToolTip.js";
import useSKULevels from "../../helpers/useSKULevels.js";
import useSKUStatus from "../../helpers/useSKUStatus.js";
import useUpcomingStock from "../../helpers/useUpcomingStock.js";

const OrderWizardFulfillALL = ({
    togglemodal,
    shipping_address,
    printDeliveryLabels,
    lineItems,
    orderID,
    order_source,
    refresh,
    shipping_type,
    contains_monogram,
    orderName,
    distanceFromHQ,
    pick_status,
    postcode,
    setModalOpen,
    getVariant
}) => {
    const [selectedItems, setSelectedItems] = useState(lineItems.map((val) => true));

    const findSKULevel = useSKULevels()
    const findUpcomingStock = useUpcomingStock()
    const findSKUStatus = useSKUStatus()

    const nestingData = {
        'COPB': ['CHK', 'PLS'],
        'CHK': ['PLS'],
        'PLS': [],

        'SET-CHKCOPB': [],

        'TRK-COPB': ['CHK', 'PLS'],
        'TRK-CHK': [],

        'LGT-CO': ['CHK', 'PLS'],
        'LGT-COEX': ['CHK', 'PLS'],
        'COES': ['CHK', 'PLS'],

        'PRO-COPB': ['CHK', 'PLS'],
        'PROM-COPB': ['CHK', 'PLS'],
        'TMA-COPB': ['CHK', 'PLS'],

        'CAR-WKDP': ['CHK', 'PLS'],

        'CAR-TOT': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB'],
        'TOT': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB'],
        'CAR-BPK': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB'],
        'BPK': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB'],
        'CAR-WKD': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB'],
        'WKD': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB'],


        'CAR-DPK': [],
        'CAR-SLI': [],
        'CAR-POC': [],

        'DBK-WKD': [],
        'DBK-BPK': [],
        'DBK-TOT': [],
        'VOL-BPK': [],
        'VOL-WKD': [],

        'PRO-SLEE': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB'],
    }

    const accNestingdata = {
        'PB': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'],
        'CME': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'],
        '4PC': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'],
        '8PC': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'],
        'TEC': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'],
        'COV-TRK': [],
        'TPC': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-WKDP'],
        'APC': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'],
        'NBK': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'],
        'TAG': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'],
        'BOT': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'],
        'IPC': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP'],
        'STR': ['COPB', 'CHK', 'PLS', 'SET-CHKCOPB', 'TRK-COPB', 'TRK-CHK', 'LGT-CO', 'LGT-COEX', 'COES', 'PRO-COPB', 'PROM-COPB', 'TMA-COPB', 'CAR-TOT', 'TOT', 'CAR-BPK', 'BPK', 'CAR-WKD', 'WKD', 'CAR-WKDP', 'CME'],
        'TOY': [],
    }

    const DIPPostcodes = [
        3067,
        3040,
        3042,
        3021,
        3206,
        3020,
        3078,
        3018,
        3028,
        3025,
        3022,
        3143,
        3032,
        3147,
        3147,
        3195,
        3195,
        3049,
        3034,
        3183,
        3103,
        3104,
        3175,
        3153,
        3153,
        3807,
        3193,
        3160,
        3160,
        3160,
        3081,
        3204,
        3165,
        3806,
        3193,
        3130,
        3130,
        3130,
        3196,
        3155,
        3977,
        3128,
        3129,
        3128,
        3195,
        3019,
        3088,
        3186,
        3187,
        3047,
        3012,
        3056,
        3057,
        3055,
        3105,
        3083,
        3121,
        3023,
        3023,
        3125,
        3151,
        3023,
        3037,
        3124,
        3061,
        3126,
        3053,
        3054,
        3163,
        3023,
        3197,
        3201,
        3162,
        3145,
        3161,
        3162,
        3148,
        3196,
        3196,
        3192,
        3116,
        3169,
        3168,
        3169,
        3068,
        3978,
        3978,
        3058,
        3058,
        3066,
        3048,
        3064,
        3977,
        3977,
        3977,
        3977,
        3977,
        3121,
        3136,
        3136,
        3136,
        3136,
        3047,
        3175,
        3175,
        3175,
        3103,
        3023,
        3037,
        3026,
        3089,
        3172,
        3008,
        3108,
        3109,
        3111,
        3754,
        3177,
        3084,
        3002,
        3196,
        3185,
        3095,
        3095,
        3184,
        3802,
        3076,
        3040,
        3041,
        3041,
        3040,
        3177,
        3078,
        3060,
        3156,
        3065,
        3068,
        3031,
        3011,
        3131,
        3199,
        3200,
        3199,
        3185,
        3043,
        3163,
        3146,
        3150,
        3046,
        3043,
        3088,
        3059,
        3046,
        3803,
        3188,
        3188,
        3976,
        3122,
        3123,
        3202,
        3135,
        3084,
        3081,
        3081,
        3190,
        3037,
        3029,
        3166,
        3166,
        3099,
        3079,
        3079,
        3047,
        3977,
        3064,
        3766,
        3021,
        3036,
        3038,
        3033,
        3038,
        3036,
        3042,
        3031,
        3101,
        3102,
        3173,
        3137,
        3137,
        3021,
        3083,
        3012,
        3180,
        3144,
        3075,
        3910,
        3028,
        3026,
        3140,
        3093,
        3975,
        3975,
        3156,
        3156,
        3085,
        3012,
        3144,
        3145,
        3032,
        3204,
        3048,
        3000,
        3004,
        3045,
        3194,
        3754,
        3064,
        3206,
        3082,
        3132,
        3127,
        3129,
        3094,
        3765,
        3039,
        3189,
        3194,
        3138,
        3195,
        3931,
        3930,
        3796,
        3149,
        3170,
        3163,
        3805,
        3804,
        3805,
        3015,
        3042,
        3174,
        3174,
        3051,
        3113,
        3070,
        3168,
        3131,
        3046,
        3166,
        3166,
        3167,
        3809,
        3204,
        3810,
        3114,
        3195,
        3052,
        3044,
        3044,
        3197,
        3090,
        3030,
        3207,
        3181,
        3072,
        3054,
        3023,
        3095,
        3073,
        3121,
        3134,
        3135,
        3134,
        3185,
        3084,
        3178,
        3064,
        3977,
        3191,
        3179,
        3028,
        3198,
        3018,
        3011,
        3159,
        3977,
        3062,
        3015,
        3205,
        3752,
        3006,
        3141,
        3006,
        3015,
        3171,
        3172,
        3021,
        3088,
        3182,
        3183,
        3182,
        3041,
        3041,
        3020,
        3020,
        3020,
        3127,
        3037,
        3029,
        3037,
        3038,
        3160,
        3106,
        3107,
        3154,
        3074,
        3071,
        3142,
        3012,
        3032,
        3029,
        3043,
        3156,
        3158,
        3133,
        3133,
        3084,
        3152,
        3152,
        3113,
        3134,
        3134,
        3195,
        3087,
        3087,
        3096,
        3030,
        3030,
        3012,
        3003,
        3049,
        3150,
        3027,
        3016,
        3016,
        3181,
        3750,
        3115,
        3024,
        3085,
        3091,
        3013,
    ]
    const fulfillmentToBadge = (fulfillment) => {
        if (fulfillment === 'unfulfilled') return 'warning';
        if (fulfillment === 'awaiting-stock') return 'secondary';
        const conversion = {
            'fulfilled': 'success',
            'partially_fulfilled': 'error',
        };
        return conversion[fulfillment];
    };


    const distanceToBadge = (distance) => {
        if (distance < 10000) return "success";
        return "danger";
    };

    const shippingConverter = (pick) => {
        const conversion = {
            pickup: "Pickup QV",
            pickup_hq: "Pickup HQ",
            standard: "Standard",
            express: "Express",
            uber: "Uber",
            Uber: "Uber",
            overnight: "Overnight",
        };
        return conversion[pick];
    };

    const AvaliableCard = ({
        courier,
        price,
        serviceLevel,
        estimate,
        onClick,
        disabled,
        surgeCharged,
    }) => {
        var formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        });
        const surge = surgeCharged ? surgeCharged[courier] : undefined;
        const formattedSurge = surgeCharged ? formatter.format(surge) : undefined;

        return (
            <Col
                style={{ cursor: "pointer" }}
                onClick={disabled ? () => { } : onClick}
                lg={6}
            >
                <Card outline color={disabled ? "danger" : "primary"} className="border">
                    <CardHeader className="bg-transparent">
                        <h5 className="my-0 text-primary">
                            <i className="mdi mdi-bullseye-arrow mr-3"></i>Avaliable
                        </h5>
                    </CardHeader>
                    <CardBody>
                        <CardTitle className="mt-0">
                            {courier} - ${price}{" "}
                            {surge !== undefined ? ` + ${formattedSurge} Surge` : ""}
                        </CardTitle>
                        <CardText>
                            <p>
                                Time estimate: {estimate} <br />
                                {serviceLevel && <> Service level: {serviceLevel} </>}
                            </p>
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        );
    };

    const FulFillmentTypeCard = ({ name, description, avaliable, onClick }) => {
        if (avaliable) {
            return (
                <Col onClick={onClick} lg={6} style={{ cursor: "pointer" }}>
                    <Card outline color="primary" className="border">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0 text-primary">
                                <i className="mdi mdi-bullseye-arrow mr-3"></i>Avaliable
                            </h5>
                        </CardHeader>
                        <CardBody>
                            <CardTitle className="mt-0">{name}</CardTitle>
                            <CardText>{description}</CardText>
                        </CardBody>
                    </Card>
                </Col>
            );
        }
        return (
            <Col lg={6}>
                <Card outline color="danger" className="border">
                    <CardHeader className="bg-transparent">
                        <h5 className="my-0 text-danger">
                            <i className="mdi mdi-block-helper mr-3"></i>Unavaliable
                        </h5>
                    </CardHeader>
                    <CardBody>
                        <CardTitle className="mt-0">{name}</CardTitle>
                        <CardText>{description}</CardText>
                    </CardBody>
                </Card>
            </Col>
        );
    };
    const [progress, setProgress] = useState({
        activeTab: 1,
        activeTabProgress: 1,
        progressValue: 25,
    });

    const selectedLineItems = selectedItems
        .map((val, key) => (val ? lineItems[key] : null))
        .filter((el) => el != null);
    console.log()
    const [bookItems, setBookItems] = useState(
        selectedLineItems.map((val) => true)
    );

    const bookLineItems = bookItems
        .map((val, key) => (val ? lineItems[key] : null))
        .filter((el) => el != null);

    const initParcel = {
        lineItems: [],
        lineItemsFlat: undefined,
        product: undefined,
        productData: undefined,
        parcelSize: undefined,
        parcelSizeData: undefined,
        quantity: 1
    }

    const [parcelData, setParcelData] = useState([initParcel])
    const [returnItems, setReturnItems] = useState([])

    const [data, stateProduct] = useQuery({
        url: `/product`
    });
    const productData = data && data.data

    const [dataParcel, stateParcel] = useQuery({
        url: `/orders/fetchAllParcels`
    });
    const parcels = dataParcel

    useEffect(() => {
        setParcelData(prev => {
            const tempReturnItems = selectedLineItems.map(lineItem => Array.from(Array(lineItem.quantity)).map((_, i) => ({
                sku: lineItem.sku,
                quantity: 1,
                id: lineItem.id,
                dupeId: `${lineItem._id}-${i}`,
                selected: undefined
            }))).flat()

            if (!productData || !parcels) {
                return [initParcel]
            }

            let flatItems = tempReturnItems.map(item => {
                const rootSku = item.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')
                const filteredProducts = productData.map((product, productId) => {
                    const filteredVariants = product.variants.filter(variant => variant.sku === rootSku)
                    if (filteredVariants.length === 0) {
                        return undefined
                    }
                    return { id: productId, variant: filteredVariants }
                }).filter(val => val !== undefined)
                let p, pd
                if (filteredProducts.length > 0) {
                    const selectedProduct = productData[filteredProducts[0].id]
                    p = selectedProduct && selectedProduct._id
                    pd = { label: selectedProduct.name, value: selectedProduct._id }
                }

                let filteredParcelSize = parcels.filter(parcel => `${parcel.product._id}` == `${p}`)
                let parcelSize, parcelSizeData, cubic

                let parcel = filteredParcelSize.length > 0 ? filteredParcelSize[0] : parcels[0]
                parcelSize = parcel._id
                parcelSizeData = { label: `${parcel.product.name} - ${parcel.length}x${parcel.width}x${parcel.depth} ${parcel.weight}kg`, value: parcel._id }
                cubic = parcel.length * parcel.width * parcel.depth

                return ({
                    lineItems: [item.dupeId],
                    lineItemsFlat: [{ label: item.sku, value: item.dupeId }],
                    product: p,
                    productData: pd,
                    parcelSize: parcelSize,
                    parcelSizeData: parcelSizeData,
                    quantity: 1,
                    cubic,
                    sku: item.sku

                })
            })


            // sort items smallest to biggest
            let sortedItems = flatItems.sort((a, b) => a.cubic - b.cubic);

            console.log(sortedItems)

            const testCanNest = (currentItem, checkItem) => {
                let currentNestingData, isAcc
                currentNestingData = nestingData[Object.keys(nestingData).find(val => currentItem.sku.startsWith(val))]
                if (currentNestingData === undefined || currentNestingData.length === 0) {
                    currentNestingData = accNestingdata[Object.keys(accNestingdata).find(val => currentItem.sku.startsWith(val))]
                    isAcc = true
                }

                if (currentNestingData === undefined || currentNestingData.length === 0) {
                    return false
                }

                if (currentNestingData.find(val => checkItem.sku.startsWith(val)) === undefined) {
                    return false
                }

                if (checkItem.currentNestedSKU === undefined) {
                    return true
                } else if (isAcc) {
                    return true
                } else if (currentNestingData.find(val => checkItem.currentNestedSKU.startsWith(val)) !== undefined) {
                    return true
                } else {
                    return true
                }

            }

            let returnList = []
            for (let i = 0; i < sortedItems.length; i++) {
                let currentItem = sortedItems[i]
                let canNestItem
                for (let j = i + 1; j < sortedItems.length; j++) {
                    let currentCheckNestingItem = sortedItems[j]
                    // if canNestItem
                    if (testCanNest(currentItem, currentCheckNestingItem)) {
                        canNestItem = currentCheckNestingItem
                    }
                }

                if (canNestItem !== undefined) {
                    canNestItem.lineItems = [...canNestItem.lineItems, ...currentItem.lineItems]
                    canNestItem.lineItemsFlat = [...canNestItem.lineItemsFlat, ...currentItem.lineItemsFlat]
                    canNestItem.currentNestedSKU = currentItem.lineItemsFlat.sku
                } else {
                    returnList.push(currentItem)
                }
            }

            return returnList

        })
        setReturnItems(selectedLineItems.map(lineItem => Array.from(Array(lineItem.quantity)).map((_, i) => ({
            sku: lineItem.sku,
            quantity: 1,
            id: lineItem._id,
            dupeId: `${lineItem._id}-${i}`,
            selected: undefined
        }))).flat())
    }, [productData, parcels, selectedItems])

    useEffect(() => {
        if (returnItems) {
            setReturnItems(prev => {
                return prev.map((returnItem, rID) => {
                    let parcelReduced = parcelData.map((parcel, parcelId) => ({ id: parcelId, lineItem: parcel.lineItems.find(val => val === returnItem.dupeId) }))
                    parcelReduced = parcelReduced.filter(val => val.lineItem !== undefined)
                    let updatedReturnItem = { ...returnItem }
                    if (parcelReduced.length > 0) {
                        updatedReturnItem.selected = parcelReduced[0].id
                    } else {
                        updatedReturnItem.selected = undefined
                    }
                    return updatedReturnItem
                })
            })

        }
    }, [parcelData])

    const addParcel = () => {
        setParcelData(prev => {
            let updatedLines = [...prev]
            updatedLines.push(initParcel)
            return updatedLines
        })
    }

    const deleteParcel = (id) => {
        setParcelData(prev => {
            return prev.filter((val, parcelId) => parcelId !== id)
        })
    }

    const handleSelectProduct = (selectedProduct, id) => {
        setParcelData(prev => {
            let updatedLines = [...prev]
            updatedLines[id].parcelSize = selectedProduct && selectedProduct.value
            updatedLines[id].parcelSizeData = selectedProduct
            return updatedLines
        })

    }

    const handleSelectSkus = (selectedSkus, id) => {
        let updatedSelectedSkus
        if (selectedSkus && selectedSkus.filter(val => val.value === 'All').length > 0) {
            let currentSelectedItems = selectedSkus.filter(val => val.value !== 'All')
            let allRemainingItems = returnItems.filter(val => (val.selected === undefined)).map((val, id) => ({ label: val.sku, value: val.dupeId }))
            updatedSelectedSkus = [...currentSelectedItems, ...allRemainingItems]
        } else {
            updatedSelectedSkus = selectedSkus
        }

        setParcelData(prev => {
            let updatedLines = [...prev]
            updatedLines[id].lineItems = (Array.isArray(updatedSelectedSkus) && updatedSelectedSkus.map(a => a.value)) || []
            updatedLines[id].lineItemsFlat = updatedSelectedSkus

            if (updatedLines[id].lineItems.length === 1) {
                const selectedLineId = updatedLines[id].lineItems[0]
                const selectedLine = returnItems.find(val => val.dupeId === selectedLineId)
                const rootSku = selectedLine.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')
                const filteredProducts = productData.map((product, productId) => {
                    const filteredVariants = product.variants.filter(variant => variant.sku === rootSku)
                    if (filteredVariants.length === 0) {
                        return undefined
                    }
                    return { id: productId, variant: filteredVariants }
                }).filter(val => val !== undefined)

                if (filteredProducts.length > 0) {
                    const selectedProduct = productData[filteredProducts[0].id]
                    updatedLines[id].product = selectedProduct && selectedProduct._id
                    updatedLines[id].productData = { label: selectedProduct.name, value: selectedProduct._id }
                }
            }
            return updatedLines
        })

    };

    const sendLineItems = selectedLineItems.map((value, key) => {
        return {
            ...value,
            fulfill: true,
            book: bookItems[key],
        };
    });

    const [loadingData, setLoadingData] = useState(false);
    const [quoteOptions, setQuoteOptions] = useState(undefined);
    const [shippingLabel, setShippingLabel] = useState(undefined);
    const [fulfillmentType, setFulfillmentType] = useState("shippit");

    const [manualUrl, setManualUrl] = useState("");
    const handleManualUrlChange = (e) => {
        setManualUrl(e.target.value);
    };

    const [sendToEWE, setSendToEWE] = useState(false);
    const toggleSendToEWE = () => {
        setSendToEWE((prev) => !prev);
    };
    const handleEWESendChange = () => {
        setSendToEWE((prev) => !prev);
    };

    useEffect(() => {
        if (progress.activeTabProgress === 3) {
            if (fulfillmentType === "shippit") {
                quoteOrders();
            } else if (fulfillmentType === "easyship") {
                quoteOrdersEasyship();
            } else if (fulfillmentType === "deliverInPerson") {
                quoteOrdersDeliverInPerson()
            }
        }
        if (progress.activeTabProgress === 2) {
            console.log(bookItems);
        }
    }, [progress]);

    useEffect(() => {
        setBookItems(selectedLineItems.map((val) => true));
    }, [selectedItems]);

    const quoteOrders = () => {
        setLoadingData(true);
        setQuoteOptions(undefined);
        superagent
            .post(`${backendUrl}/orders/quoteParcels`)
            .send({
                ids: bookLineItems.map((val) => val._id),
                orderId: orderID,
                parcelData: parcelData
            })
            .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
            .set("accept", "json")
            .on("error", (response) => {
                console.log(response);
                setLoadingData(false);
            })
            .then((response) => {
                const code = response.status;
                if (code > 400) {
                } else {
                    
                    setQuoteOptions(response.body);
                    setLoadingData(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingData(false);
            });
    };

    const quoteOrdersDeliverInPerson = () => {
        setLoadingData(true)
        setQuoteOptions(undefined)
        superagent.post(`${backendUrl}/orders/quoteParcelsDeliverInPerson`)
            .send({
                ids: bookLineItems.map(val => val._id),
                orderId: orderID,
                parcelData: parcelData
            })
            .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
            .set('accept', 'json')
            .on('error', (response) => {
                setLoadingData(false)
            })
            .then(response => {
                const code = response.status
                if (code > 400) {
                } else {
                    setQuoteOptions(response.body)
                    setLoadingData(false)
                }
            }).catch(err => {
                console.log(err)
                setLoadingData(false)
            });
    }

    const quoteOrdersEasyship = () => {
        setLoadingData(true);
        setQuoteOptions(undefined);
        superagent
            .post(`${backendUrl}/orders/quoteParcelsEasyship`)
            .send({
                ids: bookLineItems.map((val) => val._id),
                orderId: orderID,
                parcelData: parcelData
            })
            .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
            .set("accept", "json")
            .on("error", (response) => {
                console.log(response);
                setLoadingData(false);
            })
            .then((response) => {
                const code = response.status;
                if (code > 400) {
                } else {
                    
                    setQuoteOptions(response.body);
                    setLoadingData(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingData(false);
            });
    };

    const bookOrders = (courier_allocation, trackingUrl) => {
        setLoadingData(true);
        setQuoteOptions(undefined);
        superagent
            .post(`${backendUrl}/orders/bookParcels`)
            .send({
                line_items: sendLineItems,
                orderId: orderID,
                courier_allocation: courier_allocation,
                trackingUrl: trackingUrl,
                sendToEWE: sendToEWE,
                parcelData: parcelData
            })
            .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
            .set("accept", "json")
            .on("error", (response) => {
                console.log(response);
                setLoadingData(false);
            })
            .then((response) => {
                const code = response.status;
                if (code > 400) {
                } else {
                    console.log(response);
                    setShippingLabel(response.body.response.qualified_url);
                    setLoadingData(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingData(false);
            });
    };

    const bookOrdersDeliverInPerson = (courier_allocation, trackingUrl) => {
        setLoadingData(true);
        setQuoteOptions(undefined);
        superagent
            .post(`${backendUrl}/orders/bookParcelsDIP`)
            .send({
                line_items: sendLineItems,
                orderId: orderID,
                courier_allocation: courier_allocation,
                trackingUrl: trackingUrl,
                sendToEWE: sendToEWE,
                parcelData: parcelData,
                eweLocation: sendToEWE ? 'EWE' : undefined
            })
            .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
            .set("accept", "json")
            .on("error", (response) => {
                console.log(response);
                setLoadingData(false);
            })
            .then((response) => {
                const code = response.status;
                if (code > 400) {
                } else {
                    console.log(response);
                    setShippingLabel(response.body.label_url);
                    setLoadingData(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingData(false);
            });
    };

    const bookOrdersEasyship = (courier_allocation, trackingUrl) => {
        setLoadingData(true);
        setQuoteOptions(undefined);
        superagent
            .post(`${backendUrl}/orders/bookParcelsEasyship`)
            .send({
                line_items: sendLineItems,
                orderId: orderID,
                courier_allocation: courier_allocation,
                trackingUrl: trackingUrl,
                sendToEWE: sendToEWE,
                parcelData: parcelData
            })
            .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
            .set("accept", "json")
            .on("error", (response) => {
                console.log(response);
                setLoadingData(false);
            })
            .then((response) => {
                const code = response.status;
                if (code > 400) {
                } else {
                    console.log(response);
                    setShippingLabel(response.body);
                    setLoadingData(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingData(false);
            });
    };

    const handleManualUrlBook = () => {
        bookOrdersManual(manualUrl);
        setFulfillmentType("manual");
        toggleTabProgress(progress.activeTabProgress + 1);
    };

    const handleQVPickup = () => {
        setFulfillmentType("pickup");
        toggleTabProgress(progress.activeTabProgress + 2);

        setLoadingData(true);
        setQuoteOptions(undefined);
        superagent
            .post(`${backendUrl}/orders/bookQVPickup`)
            .send({
                line_items: sendLineItems,
                orderId: orderID,
                trackingUrl: "",
                parcelData: parcelData
            })
            .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
            .set("accept", "json")
            .on("error", (response) => {
                console.log(response);
                setLoadingData(false);
            })
            .then((response) => {
                const code = response.status;
                if (code > 400) {
                } else {
                    setLoadingData(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingData(false);
            });
    };

    const handleHQPickup = () => {
        setFulfillmentType("pickup");
        toggleTabProgress(progress.activeTabProgress + 2);

        setLoadingData(true);
        setQuoteOptions(undefined);
        superagent
            .post(`${backendUrl}/orders/bookHQPickup`)
            .send({
                line_items: sendLineItems,
                orderId: orderID,
                trackingUrl: "",
                parcelData: parcelData
            })
            .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
            .set("accept", "json")
            .on("error", (response) => {
                console.log(response);
                setLoadingData(false);
            })
            .then((response) => {
                const code = response.status;
                if (code > 400) {
                } else {
                    setLoadingData(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingData(false);
            });
    };

    const bookOrdersManual = (trackingUrl) => {
        setLoadingData(true);
        setQuoteOptions(undefined);
        superagent
            .post(`${backendUrl}/orders/bookParcelsManual`)
            .send({
                line_items: sendLineItems,
                orderId: orderID,
                trackingUrl: trackingUrl,
                parcelData: parcelData
            })
            .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
            .set("accept", "json")
            .on("error", (response) => {
                console.log(response);
                setLoadingData(false);
            })
            .then((response) => {
                const code = response.status;
                if (code > 400) {
                } else {
                    setLoadingData(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingData(false);
            });
    };

    const bookOrdersDelivery = () => {
        setLoadingData(true);
        setQuoteOptions(undefined);
        superagent
            .post(`${backendUrl}/orders/bookParcelsDelivery`)
            .send({
                line_items: sendLineItems,
                orderId: orderID,
                parcelData: parcelData
            })
            .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
            .set("accept", "json")
            .on("error", (response) => {
                console.log(response);
                setLoadingData(false);
            })
            .then((response) => {
                const code = response.status;
                if (code > 400) {
                } else {
                    setLoadingData(false);
                    setShippingLabel(response.body.url);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingData(false);
            });
    };

    const bookOrdersDeliveryEWE = () => {
        setLoadingData(true);
        setQuoteOptions(undefined);
        superagent
            .post(`${backendUrl}/orders/bookParcelsDeliveryEWE`)
            .send({
                line_items: sendLineItems,
                orderId: orderID,
                parcelData: parcelData
            })
            .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
            .set("accept", "json")
            .on("error", (response) => {
                console.log(response);
                setLoadingData(false);
            })
            .then((response) => {
                const code = response.status;
                if (code > 400) {
                } else {
                    setLoadingData(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingData(false);
            });
    };

    const bookOrdersDeliveryEWESydney = () => {
        setLoadingData(true);
        setQuoteOptions(undefined);
        superagent
            .post(`${backendUrl}/orders/bookParcelsDeliveryEWESYD`)
            .send({
                line_items: sendLineItems,
                orderId: orderID,
                parcelData: parcelData
            })
            .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
            .set("accept", "json")
            .on("error", (response) => {
                console.log(response);
                setLoadingData(false);
            })
            .then((response) => {
                const code = response.status;
                if (code > 400) {
                } else {
                    setLoadingData(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingData(false);
            });

    }

    const handleDomesticAirboxUrlBook = () => {
        setFulfillmentType("manual");
        toggleTabProgress(progress.activeTabProgress + 2);
        setLoadingData(true)
        setQuoteOptions(undefined);
        superagent.post(`${backendUrl}/orders/bookParcelsDomesticAirbox`)
            .send({
                line_items: sendLineItems,
                orderId: orderID
            })
            .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
            .set('accept', 'json')
            .on('error', (response) => {
                setLoadingData(false)
            })
            .then(response => {
                const code = response.status
                if (code > 400) {
                } else {
                    setLoadingData(false)
                }
            }).catch(err => {
                console.log(err)
                setLoadingData(false)
            });
    }

    const handleDomesticEWEUrlBook = () => {
        setFulfillmentType("manual");
        toggleTabProgress(progress.activeTabProgress + 2);

        setLoadingData(true);
        setQuoteOptions(undefined);
        superagent
            .post(`${backendUrl}/orders/bookParcelsDomesticEWE`)
            .send({
                line_items: sendLineItems,
                orderId: orderID,
                parcelData: parcelData
            })
            .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
            .set("accept", "json")
            .on("error", (response) => {
                console.log(response);
                setLoadingData(false);
            })
            .then((response) => {
                const code = response.status;
                if (code > 400) {
                } else {
                    setLoadingData(false);
                    
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingData(false);
            });
    };

    const handleDomesticEWESydneyUrlBook = () => {
        setFulfillmentType("manual");
        toggleTabProgress(progress.activeTabProgress + 2);

        setLoadingData(true);
        setQuoteOptions(undefined);
        superagent
            .post(`${backendUrl}/orders/bookParcelsDomesticEWESydney`)
            .send({
                line_items: sendLineItems,
                orderId: orderID,
                parcelData: parcelData
            })
            .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
            .set("accept", "json")
            .on("error", (response) => {
                console.log(response);
                setLoadingData(false);
            })
            .then((response) => {
                const code = response.status;
                if (code > 400) {
                } else {
                    setLoadingData(false);
                    
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingData(false);
            });
    };

    const bookParcelsManualQV = () => {
        setFulfillmentType("manual");
        toggleTabProgress(progress.activeTabProgress + 2);

        setLoadingData(true);
        setQuoteOptions(undefined);

        superagent
            .post(`${backendUrl}/orders/bookParcelsManualQV`)
            .send({
                line_items: sendLineItems,
                orderId: orderID,
                trackingUrl: '',
                parcelData: parcelData
            })
            .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
            .set("accept", "json")
            .on("error", (response) => {
                console.log(response);
                setLoadingData(false);
            })
            .then((response) => {
                const code = response.status;
                if (code > 400) {
                } else {
                    setLoadingData(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingData(false);
            });

    }

    const handleDomesticB360UrlBook = () => {
        setFulfillmentType("manual");
        toggleTabProgress(progress.activeTabProgress + 2);

        setLoadingData(true);
        setQuoteOptions(undefined);
        superagent
            .post(`${backendUrl}/orders/bookParcelsDomesticB360`)
            .send({
                line_items: sendLineItems,
                orderId: orderID,
                parcelData: parcelData
            })
            .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
            .set("accept", "json")
            .on("error", (response) => {
                console.log(response);
                setLoadingData(false);
            })
            .then((response) => {
                const code = response.status;
                if (code > 400) {
                } else {
                    setLoadingData(false);
                    
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingData(false);
            });
    };

    const handleDomesticUNISUrlBook = () => {
        setFulfillmentType("manual");
        toggleTabProgress(progress.activeTabProgress + 2);

        setLoadingData(true);
        setQuoteOptions(undefined);
        superagent
            .post(`${backendUrl}/orders/bookParcelsDomesticUNIS`)
            .send({
                line_items: sendLineItems,
                orderId: orderID,
                parcelData: parcelData
            })
            .set("Authorization", `Bearer ${localStorage.getItem("authJWT")}`)
            .set("accept", "json")
            .on("error", (response) => {
                console.log(response);
                setLoadingData(false);
            })
            .then((response) => {
                const code = response.status;
                if (code > 400) {
                } else {
                    setLoadingData(false);
                    
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingData(false);
            });
    };

    const toggleTabProgress = (tab) => {
        if (progress.activeTabProgress !== tab) {
            if (tab >= 1 && tab <= 4) {
                setProgress((prev) => ({
                    ...prev,
                    activeTabProgress: tab,
                }));

                if (tab === 1) {
                    setProgress((prev) => ({ ...prev, progressValue: 25 }));
                }
                if (tab === 2) {
                    setProgress((prev) => ({ ...prev, progressValue: 50 }));
                }
                if (tab === 3) {
                    setProgress((prev) => ({ ...prev, progressValue: 75 }));
                }
                if (tab === 4) {
                    setProgress((prev) => ({ ...prev, progressValue: 100 }));
                }
            }
        }
    };

    const buttons = [
        <></>,
        <>
            <li className={progress.activeTabProgress === 4 ? "next disabled" : "px-2 next"} >
                {returnItems.filter(val => val.selected === undefined).length === 0 && (<Button to="#" onClick={() => { toggleTabProgress(progress.activeTabProgress + 1); }}  > Next </Button>)}
            </li>
        </>,
        <>
            <li className={progress.activeTabProgress === 4 ? "next disabled" : "px-2 next"} >
                <Button to="#" onClick={() => { toggleTabProgress(progress.activeTabProgress - 1); }} >
                    Back
                </Button>
            </li>
            <li className={progress.activeTabProgress === 4 ? "next disabled" : "px-2 next"} >
                <Button to="#" onClick={() => { toggleTabProgress(progress.activeTabProgress + 1); }} >
                    Next
                </Button>
            </li>
        </>,
        <>
            <li className={progress.activeTabProgress === 4 ? "next disabled" : "px-2 next"} >
                <Button to="#" onClick={() => { toggleTabProgress(progress.activeTabProgress - 1); }} >
                    Back
                </Button>
            </li>
        </>,
        <>
            <li className={progress.activeTabProgress === 4 ? "next disabled" : "px-2 next"} >
                <Button to="#" onClick={() => { setModalOpen(false); refresh(); }} > Done </Button>
            </li>
        </>,
    ];

    return (
        <>
            <WizardNav steps={['Select', 'Nesting', 'Fulfillment Type', 'Booking', 'Labels']} activeTabProgress={progress.activeTabProgress} />
            <ModalBody>
                <div id="progrss-wizard mg-s" className="twitter-bs-wizard">
                    <TabContent
                        activeTab={progress.activeTabProgress}
                        className="twitter-bs-wizard-tab-content"
                    >
                        <TabPane tabId={1}>
                            <>
                                {lineItems.map((lineItem, key) => {
                                    const handleSelected = () => {
                                        setSelectedItems(prev => {
                                            const newItems = [...prev];
                                            newItems[key] = !newItems[key];
                                            return newItems;
                                        });
                                    };
                                    const isSelected = selectedItems[key] === true;

                                    return (
                                        <ModalBody>
                                            <Row key={key}>
                                                <Col sm="1">
                                                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {lineItem.fulfillment_status !== 'deleted' && (
                                                            <input className="form-check-input" type="checkbox" onChange={handleSelected} checked={isSelected} id="defaultCheck1" />
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col sm="2">
                                                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <img src={getVariant(lineItem.sku)?.image} alt="" className="img-fluid" />
                                                    </div>
                                                </Col>
                                                <Col sm="5" className="p-3">
                                                    <h6>{getVariant(lineItem.sku)?.name}</h6>
                                                    <div>
                                                        <Badge className={"font-size-12 badge-soft-" + fulfillmentToBadge(lineItem.fulfillment_status)} color={fulfillmentToBadge(lineItem.fulfillment_status)} pill>
                                                            {lineItem.fulfillment_status ? lineItem.fulfillment_status.replace('_', ' ').toUpperCase() : 'UN-FULFILLED'}
                                                        </Badge>
                                                    </div>
                                                    <div>SKU: <SKUToolTip sku={lineItem.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} /></div>
                                                </Col>
                                                <Col sm="2" className="p-3">
                                                    {/* ... rest of the code for monogram and other details */}
                                                </Col>
                                                <Col sm="2" className="p-3">
                                                    QUANTITY: {lineItem.quantity}
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                    );
                                })}
                            </>
                        </TabPane>
                        <TabPane tabId={2}>
                            <>
                                {
                                    (stateParcel === 'loading' || stateProduct === 'loading' || parcels === undefined || productData === undefined) ? (
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div className="spinner-border text-primary m-1" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="row">
                                                <div className="col-md-5">  PARCEL SIZE </div>
                                                <div className="col-md-4"> SKUS </div>
                                                <div className="col-md-1" />
                                                <div className="col-md-1" />
                                            </div>

                                            {
                                                parcelData.map((parcel, id) => {
                                                    return (
                                                        <div className="form-group row">
                                                            <div className="col-md-5">
                                                                {
                                                                    productData && (
                                                                        <Select
                                                                            classNamePrefix="select2-selection"
                                                                            value={parcel.parcelSizeData}
                                                                            onChange={(val => { handleSelectProduct(val, id) })}
                                                                            options={parcels.map((val, id) => ({ label: `${val.product.name} - ${val.length}x${val.width}x${val.depth} ${val.weight}kg`, value: val._id }))}
                                                                        />
                                                                    )
                                                                }
                                                            </div>
                                                            <div className="col-md-4">
                                                                <Select
                                                                    classNamePrefix="select2-selection"
                                                                    value={parcel.lineItemsFlat}
                                                                    onChange={(val => { handleSelectSkus(val, id) })}
                                                                    options={[
                                                                        ...returnItems.filter(val => (val.selected === id || val.selected === undefined)).map((val, id) => ({ label: val.sku, value: val.dupeId })),
                                                                        ...(returnItems.filter(val => val.selected === undefined).length > 1 ? [{ label: 'All', value: 'All' }] : [])
                                                                    ]}
                                                                    isMulti
                                                                />
                                                            </div>
                                                            <div className="col-md-1" />
                                                            <div className="col-md-1">
                                                                <Button color="danger" onClick={() => deleteParcel(id)}> X </Button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                selectedItems.filter(val => val.selected === undefined).length > 0 && (
                                                    <Button color="primary" onClick={addParcel}> Add Line + </Button>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </>
                        </TabPane>
                        <TabPane tabId={3}>
                            <div>
                                {distanceFromHQ && (
                                    <>
                                        DISTANCE:{" "}
                                        <Badge
                                            className={
                                                "ml-2  px-4 py-2 font-size-12 badge-soft-" +
                                                distanceToBadge(distanceFromHQ)
                                            }
                                            color={distanceToBadge(distanceFromHQ)}
                                            pill
                                        >
                                            {distanceFromHQ / 1000} KM
                                        </Badge>
                                        <br />
                                        <br />
                                    </>
                                )}
                                <Row>
                                    <>
                                        <FulFillmentTypeCard
                                            onClick={() => {
                                                toggleTabProgress(progress.activeTabProgress + 1);
                                                setFulfillmentType("shippit");
                                            }}
                                            name="SHIPPIT"
                                            description="This pulls from multiple Australian carriers to fulfill order"
                                            avaliable={true}
                                        />
                                        <FulFillmentTypeCard
                                            onClick={() => {
                                                toggleTabProgress(progress.activeTabProgress + 1);
                                                setFulfillmentType("easyship");
                                            }}
                                            name="EasyShip"
                                            description="This pulls from Auspost and Fedex"
                                            avaliable={true}
                                        />
                                        {
                                            DIPPostcodes.includes(parseInt(shipping_address.zip)) && (
                                                <FulFillmentTypeCard
                                                    onClick={() => {
                                                        toggleTabProgress(progress.activeTabProgress + 1);
                                                        setFulfillmentType("deliverInPerson");
                                                    }}
                                                    name="Deliver In Person"
                                                    description="Premium Same Day / Next Day delivery"
                                                    avaliable={true}
                                                />
                                            )
                                        }
                                        <FulFillmentTypeCard
                                            onClick={() => {
                                                toggleTabProgress(progress.activeTabProgress + 1);
                                                setFulfillmentType("manual");
                                            }}
                                            name="MANUAL"
                                            description="You can manually fulfill an order by providing a tracking url"
                                            avaliable={true}
                                        />
                                        {
                                            pick_status === 'none' && (
                                                <FulFillmentTypeCard
                                                    onClick={bookParcelsManualQV}
                                                    name="MANUAL FROM QV"
                                                    description="Fulfill this order from QV Stock"
                                                    avaliable={true}
                                                />
                                            )
                                        }
                                        {contains_monogram === false && pick_status === 'none' && (
                                            <FulFillmentTypeCard
                                                onClick={handleDomesticEWEUrlBook}
                                                name="EWE Melbourne"
                                                description="Send to EWE for fulfillment"
                                                avaliable={true}
                                            />
                                        )}
                                        {contains_monogram === false && pick_status === 'none' && (
                                            <FulFillmentTypeCard
                                                onClick={handleDomesticEWESydneyUrlBook}
                                                name="EWE Sydney"
                                                description="Send to EWE Sydney for fulfillment"
                                                avaliable={true}
                                            />
                                        )}
                                        {contains_monogram === false && pick_status === 'none' && order_source === 'shopify_uk' && (
                                            <FulFillmentTypeCard
                                                onClick={handleDomesticAirboxUrlBook}
                                                name="Airbox"
                                                description="Send to Airbox for fulfillment"
                                                avaliable={true}
                                            />
                                        )}
                                        {(order_source === "shopify_us" ||
                                            order_source === "goop" ||
                                            order_source === "custom" ||
                                            order_source === undefined) && pick_status === 'none' && (
                                                <FulFillmentTypeCard
                                                    onClick={handleDomesticB360UrlBook}
                                                    name="B360"
                                                    description="Send to B360 for fulfillment"
                                                    avaliable={true}
                                                />
                                            )}
                                        {(order_source === "shopify_us" ||
                                            order_source === "goop" ||
                                            order_source === "custom" || order_source === undefined) && pick_status === 'none' && (
                                                <FulFillmentTypeCard
                                                    onClick={handleDomesticUNISUrlBook}
                                                    name="UNIS"
                                                    description="Send to UNIS for fulfillment"
                                                    avaliable={true}
                                                />
                                            )}
                                        {shipping_type === "pickup" && (
                                            <FulFillmentTypeCard
                                                onClick={handleQVPickup}
                                                name="Send To QV"
                                                description="Send mongram order to QV for pickup"
                                                avaliable={true}
                                            />
                                        )}
                                        {shipping_type === "pickup_hq" && (
                                            <FulFillmentTypeCard
                                                onClick={handleHQPickup}
                                                name="Pickup From HQ"
                                                description="Notify customer for HQ pickup"
                                                avaliable={true}
                                            />
                                        )}
                                    </>
                                </Row>
                            </div>
                        </TabPane>
                        <TabPane tabId={4}>
                            SHIPPING:{" "}
                            <b>{shippingConverter(shipping_type)?.toUpperCase()} </b>
                            <br />
                            {["easyship", "shippit", 'deliverInPerson'].includes(fulfillmentType) && (
                                <>
                                    <br />
                                    <div
                                        className="custom-control custom-checkbox"
                                        onClick={toggleSendToEWE}
                                    >
                                        <Input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={"toggleEWE"}
                                            onChange={handleEWESendChange}
                                            checked={sendToEWE}
                                        />
                                        <Label
                                            className="custom-control-label"
                                            htmlFor={"toggleEWE"}
                                        >
                                            Send to EWE
                                        </Label>
                                    </div>
                                    <br />
                                </>
                            )}
                            {fulfillmentType === "easyship" ? (
                                <div>
                                    {loadingData && (
                                        <div className="d-flex justify-content-center">
                                            <div
                                                className="spinner-border text-primary m-1"
                                                role="status"
                                            >
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    )}
                                    <Row>
                                        {quoteOptions &&
                                            quoteOptions.rates.map((val) =>
                                                !val.courier_name.toUpperCase().includes("FEDEX") ? (
                                                    <AvaliableCard
                                                        onClick={() => {
                                                            bookOrdersEasyship(val.courier_id);
                                                            toggleTabProgress(progress.activeTabProgress + 1);
                                                        }}
                                                        courier={val.courier_name}
                                                        estimate={`${val.min_delivery_time !== val.max_delivery_time
                                                            ? `${val.min_delivery_time}-`
                                                            : ""
                                                            }${val.max_delivery_time} days`}
                                                        price={`${val.total_charge}${val.currency}`}
                                                        disabled={val.success === false}
                                                    />
                                                ) : <></>
                                            )}
                                    </Row>
                                </div>
                            ) : fulfillmentType === "shippit" ? (
                                <div>
                                    {loadingData && (
                                        <div className="d-flex justify-content-center">
                                            <div
                                                className="spinner-border text-primary m-1"
                                                role="status"
                                            >
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    )}

                                    <Row>
                                        {quoteOptions &&
                                            quoteOptions.response.map((val) =>
                                                val.success ? (
                                                    <AvaliableCard
                                                        onClick={() => {
                                                            bookOrders(val.courier_type);
                                                            toggleTabProgress(progress.activeTabProgress + 1);
                                                        }}
                                                        courier={val.courier_type}
                                                        serviceLevel={val.service_level}
                                                        estimate={val.quotes[0]?.estimated_transit_time}
                                                        price={`${val.quotes[0]?.price}`}
                                                        disabled={val.success === false}
                                                        surgeCharged={quoteOptions.surgeCharged}
                                                    />
                                                ) : (
                                                    <></>
                                                )
                                            )}
                                    </Row>
                                </div>
                            ) : fulfillmentType === 'deliverInPerson' ? (
                                <>
                                    {
                                        loadingData && (
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-border text-primary m-1" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        quoteOptions && quoteOptions.map(val => {
                                            const courier_type = val.itemsPrices[0].deliveryType
                                            return (
                                                <AvaliableCard
                                                    onClick={() => { bookOrdersDeliverInPerson(courier_type); toggleTabProgress(progress.activeTabProgress + 1); }}
                                                    courier={courier_type}
                                                    serviceLevel={courier_type}
                                                    estimate={''}
                                                    price={`${val.totalPrice.value}`}
                                                />
                                            )
                                        })
                                    }
                                </>
                            ) : (
                                <div>
                                    <Input
                                        onChange={handleManualUrlChange}
                                        value={manualUrl}
                                        placeholder="Tracking Url"
                                    />
                                    <Button onClick={handleManualUrlBook} className="mt-4">
                                        {" "}
                                        Book{" "}
                                    </Button>
                                </div>
                            )}

                        </TabPane>
                        <TabPane tabId={4}>
                            <div className="row justify-content-center">
                                <Col lg="12">
                                    <div className="text-center">
                                        <div className="mb-4">
                                            <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                        </div>
                                        <div>
                                            <h5>Download labels</h5>
                                            {loadingData ? (
                                                <div className="d-flex justify-content-center">
                                                    <div
                                                        className="spinner-border text-primary m-1"
                                                        role="status"
                                                    >
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    {shippingLabel && fulfillmentType !== "easyship" && (
                                                        <a href={shippingLabel} target="_blank">
                                                            {" "}
                                                            <p className="text-muted">
                                                                {" "}
                                                                Labels download
                                                            </p>{" "}
                                                        </a>
                                                    )}

                                                    {shippingLabel && fulfillmentType === "easyship" && (
                                                        <>
                                                            <a
                                                                download={`${orderName}_label.pdf`}
                                                                href={`data:application/pdf;base64,${shippingLabel.shipment.shipping_documents.find(
                                                                    (val) => val.category === "label"
                                                                ).base64_encoded_strings[0]
                                                                    }`}
                                                                title="Download pdf document"
                                                            >
                                                                {" "}
                                                                Label{" "}
                                                            </a>
                                                        </>
                                                    )}

                                                    {(fulfillmentType === "delivery" ||
                                                        fulfillmentType === "pickup") && (
                                                            <>
                                                                {
                                                                    shippingLabel ? (
                                                                        <></>
                                                                    ) : (
                                                                        <Button onClick={printDeliveryLabels}>
                                                                            {" "}
                                                                            Print Delivery/Pickup Labels{" "}
                                                                        </Button>
                                                                    )
                                                                }

                                                            </>
                                                        )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </TabPane>
                    </TabContent>
                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                        <ModalFooter>
                            {buttons[progress.activeTabProgress]}
                        </ModalFooter>
                    </ul>
                </div>
            </ModalBody>
        </>
    );
};

export default OrderWizardFulfillALL;
