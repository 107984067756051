import React, { useRef } from 'react'
import PrintPage from './monogram/PrintPage'
import { useReactToPrint } from 'react-to-print';
import queryString from 'query-string';


const PrintLabels = ({
  match: {
    params
  },
  location
}) => {
  let { orderData } = queryString.parse(location.search)
  const decodedBase64 = Buffer.from(orderData, 'base64')
  const decodedURI = decodeURI(decodedBase64)
  const decodedURICompenent = decodeURIComponent(decodedURI)
  const parseJson = JSON.parse(decodedURICompenent)
  const {order, monogram} = parseJson
  const packingSlipRef = useRef();
  const printLabels = useReactToPrint({
    content: () => packingSlipRef.current,
  });
  const handlePrintLabel = () => {
    printLabels()
  }

  const lines = order.line_items.filter(lineItem => lineItem.monogram === monogram && lineItem.fulfillment_status !== 'deleted').map((lineItem, key) => ({
    monogramId: `${order.name}-${key}`,
    location: lineItem.monogram_position,
    letters: lineItem.monogram_text,
    font: lineItem.monogram_font,
    mainCol: lineItem.monogram_color,
    backCol:  lineItem.monogram_shadow,
    created_at: order.orderCreated,
    shopifyId: order.name,
    country: order.shipping_address.country,
    lineItems: [],
    sku:lineItem.sku,
    quantity:lineItem.quantity,
    embroided: lineItem.embroided,

    pet: lineItem.pet,
    monogram_pets: lineItem.monogram_pets && JSON.parse(atob(lineItem.monogram_pets)),
    monogram_airtable_line: lineItem.monogram_airtable_line,
    completedThumbnails: order.completedThumbnails
  }))
  
  return <div onClick={handlePrintLabel}>
    <PrintPage 
      ref={packingSlipRef}
      monogram={monogram}
      monogramRecords={lines} 
    />
  </div>
} 

export default PrintLabels