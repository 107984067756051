import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { Button, Card, CardBody, CardTitle, Col, Row, Table } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import useQuery from "../../../helpers/JIFI/useQuery";

const BasicTable = () => {
    const [refresh, setRefresh] = useState(true)

    const refreshData = () => {
        setRefresh(prev => !prev)
    }

    const [products] = useQuery({
        url: `/product/findAll`,
        refresh,
    });

    let productList

    if (products) {
        productList = products.data.sort(line => line.name).map(product => product.variants.map(variant => ({ sku: variant.sku, average_cog_au: variant.average_cog_au, average_cog_us: variant.average_cog_us }))).flat()
    }

    const dateString = (new Date()).toDateString().replace(' ', '-').replace(' ', '-').replace(' ', '-')
    const csvData = [
        ['SKU', 'AUSTRALIA', 'UNITED STATES'],
        ...(productList ? productList.map(line => [line.sku, line.average_cog_au, line.average_cog_us]) : [])
    ]

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Inventory" breadcrumbItem="COG" />
                <Row>
                    <Col sm="2" style={{ paddingBottom: 8 }}>
                        <CSVLink
                            data={csvData}
                            filename={`COG_${dateString}.csv`}
                        >
                            <Button type="button" color="secondary" className="btn-lg btn-rounded">
                                CSV
                            </Button>
                        </CSVLink>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    Average COG (AU/US)
                                </CardTitle>
                                {
                                    productList ? (
                                        <Table className="table mb-0">
                                            <thead style={{ position: 'sticky', top: 123, background: 'white' }}>
                                                <tr>
                                                    <th className="table-header" style={{ cursor: 'default' }}><b>SKU</b></th>
                                                    <th className="table-header" style={{ cursor: 'default' }}>
                                                        <b>Australia</b>
                                                    </th>
                                                    <th className="table-header" style={{ cursor: 'default' }}>
                                                        <b>United States</b>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    productList.map(lineItem => lineItem.sku &&
                                                        <tr>
                                                            <th scope="row"><option value={lineItem.sku}>{lineItem.sku}</option></th>
                                                            <th scope="row"><option value={lineItem.average_cog_au}>{lineItem.average_cog_au ? lineItem.average_cog_au : 'N/A'}</option></th>
                                                            <th scope="row"><option value={lineItem.average_cog_us}>{lineItem.average_cog_us ? lineItem.average_cog_us : 'N/A'}</option></th>
                                                        </tr>

                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    ) : (
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div className="spinner-border text-primary m-1" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    )
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div >
    )
}

export default BasicTable