import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Container, Collapse, CardHeader, Table, Badge, Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import superagent from 'superagent'

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import "../datatables.scss";
import useQuery from '../../../helpers/JIFI/useQuery';
import useProductData from "../orders/useProductData";
import { backendUrl } from "../../../helpers/consts";

import Word from '../../../components/Personalisation/font'

const displaySize = { 
  height: 24,
  width: 120,
  padding: {
    top: 4,
    bottom: 4,
    left: 0,
    right: 4
  }
}

const Batch = ({batch, doneID, setDone, openNames, setOpenNames, getVariant, state}) => {
  const isOpen = openNames.includes(batch.name)
  const toggleIsOpen = () => {
    setOpenNames(prev => {
      if (prev.includes(batch.name)) {
        return prev.filter(val => val !== batch.name)
      } else {
        return [...prev, batch.name]
      }
    })
  }
  
  const numberOfLines = batch.line_items.length
  const numberOfComplete = batch.line_items.filter(val => val.fulfillment_status === 'fulfilled' || val.fulfillment_status === 'deleted').length

  return (
    <Card style={numberOfLines === numberOfComplete ? {display: 'none'}: {display: 'block'}}>
      <CardHeader onClick={() => toggleIsOpen()}>
        <h4 style={{cursor: 'pointer'}}>{batch.name}</h4>
        <Badge className={"mr-2 px-4 py-2 font-size-12 badge-soft-"} color={numberOfLines === numberOfComplete ? 'success' : 'warning'} pill> {numberOfComplete}/{numberOfLines} </Badge>
      </CardHeader>
      <CardBody>
        <Collapse isOpen={isOpen} >
          <div className="table-responsive">
            <Table className="table table-centered table-nowrap">
              <thead className="thead-light">
                <tr>
                  <th>Monogram ID</th>
                  <th>Date</th>
                  <th>SKU</th>
                  <th>Variant</th>
                  <th>Monogram Text</th>
                  <th>Monogram Font</th>
                  <th>Monogram Color</th>
                  <th>Monogram Secondary Color</th>
                  <th>Monogram Location</th>
                  <th>Pets</th>
                  <th>Preview</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  batch.line_items && batch.line_items.sort((a,b) => a.monogramID.localeCompare(b.monogramID)).map((lineItem, key) =>
                    <tr key={"_order_" + key}>
                      <td>{lineItem.monogramID}</td>
                      <td>{(new Date(lineItem.orderSent)).toDateString()}</td>
                      <td> {lineItem.sku} </td>
                      <td> {getVariant(lineItem.sku.replace('-P', '')).name} </td>
                      <td> {lineItem.monogram_text} </td>
                      <td> {lineItem.monogram_font} </td>
                      <td> {lineItem.monogram_color} </td>
                      <td> {lineItem.monogram_shadow} </td>
                      <td> {lineItem.monogram_position} </td>
                      <td> {lineItem.monogram_pets && JSON.parse(atob(lineItem.monogram_pets)).map(pet => pet.name).join(' + ')} </td>
                      <td>
                        {
                          !lineItem.pet && (
                            <Word 
                              left={true} 
                              maxLetters={20} 
                              size={displaySize} 
                              mainCol={lineItem.monogram_color} 
                              backCol={lineItem.monogram_shadow} 
                              letters={lineItem.monogram_text} 
                              fontSize={32} 
                              font={lineItem.monogram_font} 
                            />
                          )
                        }
                      </td>
                      <td> 
                        {
                          lineItem.fulfillment_status === 'in-for-monogram' && (
                            state === 'loading' ? (
                              <Button color="disabled" disabled={true}> 
                                <div className="spinner-grow text-primary m-1" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </Button>
                            ) : (
                              <Button color="success" onClick={() => setDone(lineItem)}> Done </Button>
                            )
                          )
                        }
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
          </div>
        </Collapse>
      </CardBody>
    </Card>
  )
}


const DeliveriesPage = () => {
  const url = '/orders/getPersonalisationBatches'

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => setRefresh(prev => !prev)

  const [batches, state] = useQuery({ refresh, url });

  const [cachedBatchData, setCachedBatchData] = useState([])
  useEffect(() => {
    if (batches) {
      setCachedBatchData(batches)
    }
  }, [batches])


  let [doneID, setDone] = useState()

  let [openNames, setOpenNames] = useState([])

  const getVariant = useProductData()

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row onClick={() => refreshData()}>
            <Col xs="11">
              <Breadcrumbs title="Monograms" breadcrumbItem="Personalisation Batches" />
            </Col>
          </Row>
          <Row>
            {
              (cachedBatchData.length > 0) ? (
                cachedBatchData.map(batch => (
                  <Col xs="12" id={batch.name}>
                    <Batch getVariant={getVariant} openNames={openNames} setOpenNames={setOpenNames} batch={batch} doneID={doneID} setDone={setDone} state={state}/>
                  </Col>
                ))
              ) : (
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                  <div className="spinner-grow text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )
            }
          </Row>
        </Container>
        <Modal isOpen={doneID !== undefined} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" toggle={() => setDone()}>
          <CompletePersonalisation lineItem={doneID} refresh={refreshData}  togglemodal={() => setDone()} />
        </Modal>
      </div>   
    </>
  );
}

const CompletePersonalisation = ({togglemodal, refresh, lineItem}) => {
  const [loading, setLoading] = useState(false)

  const send = () => {
    superagent.post(`${backendUrl}/orders/completePersonalisation/${lineItem._id}`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          togglemodal()
          refresh()
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });
  }

  return (
    <div>
      {
        lineItem !== undefined && (
          <div className="modal-content">
            <ModalHeader toggle={togglemodal}>
              Confirm Personalisation Complete - {lineItem.monogramID}
            </ModalHeader >
            <ModalBody>
              This will fulfill this item in the system.
            </ModalBody>
            <ModalFooter>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%'}}>
                <Button type="button" color="warning" onClick={send}>Complete</Button>
              </div>
            </ModalFooter>
          </div>
        )
      }
    </div>
  )
}


export default DeliveriesPage;


