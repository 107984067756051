import React, {useEffect} from 'react'
import { refreshLogin } from './JIFI/authHandler';
import jwt_decode from "jwt-decode";

const refreshTime = 1000 * 60 * 3

const checkAuth = () => {
  let currentDate = new Date();

  let refreshToken = localStorage.getItem('refreshJWT');
  let authToken = localStorage.getItem('authJWT');

  if (refreshToken == undefined || authToken == undefined) {
    window.localStorage.removeItem('authJWT')
    window.localStorage.removeItem('refreshJWT')
    return 
  }

  let decodedAuthToken = jwt_decode(authToken);
  let decodedRefreshToken = jwt_decode(refreshToken);

  if (decodedAuthToken.exp * 1000 - refreshTime < currentDate.getTime()) {
    // JWT exp is in seconds
    if (decodedRefreshToken.exp * 1000 - refreshTime < currentDate.getTime()) {
      window.localStorage.removeItem('authJWT')
      window.localStorage.removeItem('refreshJWT')
    } else {
      refreshLogin()
    }  
  }

}

const AuthRefresh = () => {
  useEffect(() => {
    setInterval(() => {
      checkAuth()
    }, refreshTime);
    refreshLogin()
  }, [])
  return <></>
}

export default AuthRefresh