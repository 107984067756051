import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardHeader, FormGroup } from "reactstrap";
import { Nav, CardSubtitle, CardTitle, CardText, TabContent, TabPane, Collapse, NavLink, NavItem, Container, Input, Button, Table, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import "../datatables.scss";

import useQuery from '../../../helpers/JIFI/useQuery';
import Select from 'react-select';

import { useHistory } from "react-router-dom";
import superagent from 'superagent'
import { backendUrl } from '../../../helpers/consts.js'

import '../../Skote/Dashboard/skelton.css'
import { useUrlState } from '../../../helpers/useUrlState';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { CSVReader } from 'react-papaparse';

const perPageOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500 - (WARNING SLOW)' },
  { value: 1000, label: '1000 - (WARNING SLOW)' },
]

const paginate = function (array, index) {
  return [
    array[index - 2],
    array[index - 1],
    array[index],
    array[index + 1],
    array[index + 2]
  ].filter(function (el) {
    return el != null;
  });
}

const TablePagination = ({ count, page, setPage, perPage, setPerPage }) => {
  const pageCount = Math.ceil(count / perPage)
  const pages = paginate(Array.from({ length: pageCount }, (v, i) => i + 1), page)
  const handleNumberClicked = (key) => {
    setPage(key)
  }
  return (
    <Pagination className="pagination pagination-rounded justify-content-end mb-2">
      <div style={{ height: '100%', width: 140 }}>
        <Select
          options={perPageOptions}
          onChange={newPerPage => setPerPage(newPerPage.value)}
          value={perPageOptions.find(val => val.value === perPage)}
        />
      </div>
      <PaginationItem disabled>
        <PaginationLink previous />
      </PaginationItem>
      {
        pages.map(val => (
          <PaginationItem onClick={() => handleNumberClicked(val)} active={val === page}>
            <PaginationLink>
              {val}
            </PaginationLink>
          </PaginationItem>
        ))
      }
      <PaginationItem>
        <PaginationLink next />
      </PaginationItem>
    </Pagination>
  )
}



const StockInput = ({ stock, setStock, productData, maxCounts, onHand, isStockLoading, isOnHandLoading }) => {
  const handleAddRow = () => {
    const prefillProduct = stock[stock.length - 1]?.product
    let prefillVariant
    if (prefillProduct) {
      prefillVariant = productData.find(val => prefillProduct === val._id)['variants'][0]._id
    }
    const item = {
      product: prefillProduct || productData[0]._id,
      variant: prefillVariant || productData[0]['variants'][0]._id,
      quantity: 0
    };
    setStock(prev => [...prev, item])
  };

  const editProductRow = (id, productId) => {
    setStock(prev => {
      let newList = [...prev]
      newList[id]['product'] = productId
      newList[id]['quantity'] = 0
      const variant = productData.find(val => `${val._id}` === `${productId}`)
      newList[id]['variant'] = variant.variants[0]?._id
      return newList
    })
  };

  const editVariantRow = (id, val) => {
    setStock(prev => {
      let newList = [...prev]
      newList[id]['variant'] = val
      newList[id]['quantity'] = 0
      return newList
    })
  };

  const editQuantityRow = (id, val) => {
    setStock(prev => {
      let newList = [...prev]

      const variantList = productData.map(product => product.variants).flat()
      const sku = variantList.find(val => `${val._id}` === `${newList[id]['variant']}`).sku
      const maxCount = maxCounts[sku]

      let parsedInput = parseInt(val)
      if (parsedInput > maxCount) {
        parsedInput = maxCount
      }
      if (parsedInput < 0) {
        parsedInput = 0
      }

      newList[id]['quantity'] = parsedInput
      return newList
    })
  };

  const removeRow = (id) => {
    setStock(prev => {
      let newList = prev.filter((item, key) => key !== id)
      return newList
    })
  };

  const products = productData ? productData.map(product => {
    const variants = product.variants.map(variant => {
      return {
        _id: variant._id,
        name: variant.name,
        selected: stock.findIndex(val => val.variant === variant._id)
      }
    })

    return {
      name: product.name,
      _id: product._id,
      variants
    }
  }) : []

  return <>
    <Row>
      <Col xs="4">
        Product
      </Col>
      <Col xs="4">
        Variant
      </Col>
      <Col xs="4">
        Quantity
      </Col>
    </Row>
    <br />
    {
      productData && stock.map((line, lineKey) => {
        const selectedVariant = productData.find(val => val._id === stock[lineKey]['product']).variants.find((val) => `${line.variant}` === `${val._id}`).sku
        const maxCount = productData &&
          stock[lineKey]['product'] &&
          maxCounts[productData.find(val => val._id === stock[lineKey]['product']).variants.find((val) => `${line.variant}` === `${val._id}`).sku]

        const onHandStock = onHand[selectedVariant]

        return (
          <Row style={{ padding: '12px 0' }}>
            <Col xs="4">
              <Input type="select" style={{ width: '100%' }} onChange={(e) => editProductRow(lineKey, e.target.value)} name="products" id="products">
                {products.map((val, key) => <option selected={line.product === val._id} value={val._id}>{val.name}</option>)}
              </Input>
            </Col>
            <Col xs="4">
              <Input type="select" style={{ width: '100%' }} onChange={(e) => editVariantRow(lineKey, e.target.value)} name="products" id="products">
                {
                  productData &&
                  stock[lineKey]['product'] &&
                  products.find(val => val._id === stock[lineKey]['product']).variants.map((val, key) =>
                    <option selected={line.variant === val._id} disabled={val.selected !== -1 && val.selected !== lineKey} value={val._id}>{val.name}</option>
                  )
                }
              </Input>
              {/* {productData && productData.find(val => val._id === stock[lineKey]['product']).variants.find((val) => val._id === stock[lineKey]['variant']).name} */}
            </Col>
            <Col xs="3">
              <Input style={{ width: '90%', ...(stock[lineKey]['quantity'] <= 0 ? { border: '1px solid red' } : {}) }} type="number" value={stock[lineKey]['quantity']} onChange={(e) => editQuantityRow(lineKey, e.target.value)} />
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
                <span className={`text-${maxCount > 0 ? 'success' : 'warning'}`}>Available:</span>
                <h5 className={`font-size-13 text-left pl-2 flex-grow-1 mb-0 ${!isStockLoading && maxCount !== undefined ? '' : 'placeholder'}`}>{!isStockLoading && maxCount !== undefined ? maxCount : ' '}</h5>
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
                <span className={`text-${onHandStock > 0 ? 'success' : 'warning'}`}>SOH:</span>
                <h5 className={`font-size-13 text-left pl-2 flex-grow-1 mb-0 ${!isOnHandLoading && onHandStock !== undefined  ? '' : 'placeholder'}`}> {!isOnHandLoading && onHandStock !== undefined ? onHandStock : ' '}</h5>
              </div>
            </Col>
            <Col xs="1" >
              <Button onClick={() => removeRow(lineKey)} color="danger">
                X
              </Button>{" "}
            </Col>
          </Row>
        )
      })
    }
    {
      productData === undefined ?
        <div className="spinner-border text-primary m-1" role="status">
          <span className="sr-only">Loading...</span>
        </div> :
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 12 }}>
          <Button onClick={handleAddRow} color="primary">
            Add{" "}
          </Button>{" "}
          <span />
        </div>
    }

  </>
}

const DropLine = ({ locationFrom, replenishHistoryObject, lineKey, isOpen, setReplenishHistory, history, updateHistoryOpen }) => {
  const [loading, setLoading] = useState(false)
  const [acceptModal, setAcceptModal] = useState(false)
  const toggleAcceptModal = () => setAcceptModal(prev => !prev)

  const [approveLevels, setApproveLevels] = useState(replenishHistoryObject.counts.map(variantCount => [variantCount.variant.sku, variantCount.count.on_hand]))
  useEffect(() => {
    setApproveLevels(replenishHistoryObject.counts.map(variantCount => [variantCount.variant.sku, variantCount.count.on_hand]))
  }, [replenishHistoryObject])
  const updateLevel = (lineKey, value) => {
    if (value > replenishHistoryObject.counts[lineKey].count.on_hand) {
      value = replenishHistoryObject.counts[lineKey].count.on_hand
    }
    if (value < 0) {
      value = 0
    }
    setApproveLevels(prev => {
      let newVal = [...prev]
      newVal[lineKey][1] = value
      return newVal
    })
  }

  const handleAccept = () => {
    const id = replenishHistoryObject._id
    let approvalCount = approveLevels.map(val => ({ sku: val[0], count: val[1] })).filter(val => val.count > 0)

    setLoading(true)
    superagent.post(`${backendUrl}/inventory/approveReplenish/${id}`)
      .send(approvalCount)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status
        setLoading(false)
        history.replace(history.location.pathname, {
          errorStatusCode: code
        });
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code
          });
        } else {
          history.go(0)
          setLoading(false)
        }
      }).catch(err => {
        setLoading(false)
        console.log(err)
      });

  }
  return (
    <>
      <div id="accordion">
        <Card className="mb-1">
          <CardHeader onClick={() => updateHistoryOpen(lineKey)} className="p-3" id="headingOne">
            <h6 className="m-0 font-14">
              <span
                style={{ cursor: "pointer" }} className={replenishHistoryObject.status === 'pending' ? 'text-warning' : 'text-success'}>
                {locationFrom} || Booked:{(new Date(replenishHistoryObject.createdAt)).toDateString()} - {replenishHistoryObject.status.toUpperCase()}{replenishHistoryObject.approvalDate && ` ON: ${(new Date(replenishHistoryObject.approvalDate)).toDateString()}`}
              </span>
            </h6>
          </CardHeader>
          <Collapse isOpen={isOpen}>
            <Card>
              <CardBody>
                {
                  replenishHistoryObject.counts.map(variantCount => <div> {variantCount.count.on_hand} x {variantCount.variant.sku} </div>)
                }
                {
                  replenishHistoryObject.status === 'pending' && (
                    <>
                      {
                        loading === true ? (
                          <div className="d-flex justify-content-center">
                            <div className="spinner-border text-primary m-1" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <Button color="success" className='float-right' onClick={() => toggleAcceptModal()}> Accept </Button>
                        )
                      }
                    </>
                  )
                }
              </CardBody>
            </Card>
          </Collapse>
        </Card>
      </div>
      {
        acceptModal && (
          <Modal isOpen={acceptModal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" toggle={toggleAcceptModal}>
            <ModalBody>
              <h3>Accept Stock</h3>
            </ModalBody>
            <ModalBody>
              {
                approveLevels.map((level, key) => (
                  <Row className="mb-2">
                    <Col xs="4">{level[0]}</Col>
                    <Col xs="8"><Input style={{ width: '90%', height: '100%' }} type="number" value={level[1]} onChange={(e) => updateLevel(key, e.target.value)} /></Col>
                  </Row>
                ))
              }
            </ModalBody>
            <ModalFooter>
              <>
                {
                  loading === true ? (
                    <div className="d-flex justify-content-center">
                      <div className="spinner-border text-primary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <Button onClick={handleAccept} color="danger" className="btn-lg btn-rounded">
                      Confirm
                    </Button>
                  )
                }
              </>

            </ModalFooter>
          </Modal>
        )
      }
      <br /><br />
    </>
  )
}


const WarehouseTransferPage = () => {
  const location = useLocation()
  const history = useHistory();
  const [refresh, setRefresh] = useState(true)
  const [update, setUpdate] = useState(true)
  const [loading, setlLoading] = useState(false)

  const [loadingTopup, setlLoadingTopup] = useState(false)  

  

  /*
    Pagination
  */
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10);
  
  /*
    Modal
  */
    const [modal, setModal] = useState(false)
    const togglemodal = () => setModal(prev => !prev)

  /*

  */

  /*
    Location Selectors
  */

  const [bookableLocations, bookableLocationsState] = useQuery({
    url: `/inventory/getTransferableWarehouses`,
    refresh
  });

  const [selectedFromLocationOptions, setSelectedFromLocationOptions] = useState()
  const [selectedToLocationOptions, setSelectedToLocationOptions] = useState()

  const [stateData, setStateData] = useUrlState({
    selectedFromLocation: undefined,
    selectedToLocation: undefined
  });

  useEffect(() => {
    if (location.search === '') {
      setStateData({
        selectedFromLocation: undefined,
        selectedToLocation: undefined
      })
      setUpdate(prev => !prev)
    }
    console.log()
  }, [location.search])

  const {
    selectedFromLocation,
    selectedToLocation
  } = stateData

  console.log(selectedFromLocation,
    selectedToLocation)

  const setSelectedFromLocation = (selectedFrom) => {
    setStateData(prev => ({
      ...prev,
      selectedFromLocation: selectedFrom
    }))
  }

  const setSelectedToLocation = (selectedTo) => {
    setStateData(prev => ({
      ...prev,
      selectedToLocation: selectedTo
    }))
  }

  useEffect(() => {
    if (bookableLocations && bookableLocations.length > 0) {
      setSelectedFromLocationOptions(bookableLocations.map(val => ({ label: val.display_name, value: val.name })))
      let selectedWarehouse = bookableLocations.find(location => location.name === selectedFromLocation?.value) || bookableLocations[0]
      setSelectedFromLocation()

      const filteredToLocations = bookableLocations.map(val => ({ label: val.display_name, value: val.name })).filter(val => val.value !== selectedWarehouse.name)
      setSelectedToLocationOptions(filteredToLocations)
      let firstToLocation = filteredToLocations[0]

      setStateData({
        selectedFromLocation: {
          label: selectedWarehouse.display_name,
          value: selectedWarehouse.name,
        },
        selectedToLocation: {
          label: firstToLocation.display_name,
          value: firstToLocation.name,
        }
      })
    }
  }, [bookableLocations, update])

  useEffect(() => {
    if (bookableLocations && bookableLocations.length > 0) {
      let fromLocation = bookableLocations.find(val => val.name === selectedFromLocation?.value)
      setSelectedToLocationOptions(bookableLocations.map(val => ({ label: val.display_name, value: val.name })).filter(val => val.value !== fromLocation.value))
      let firstToLocation = bookableLocations[0]
      setSelectedToLocation({
          label: firstToLocation.display_name,
          value: firstToLocation.name,
      })
    }
  }, [selectedFromLocation])

  /*
    Stock Counts
  */

  const [stockCount, setStockCount] = useState({})
  const [stockCountFull, stockCountState] = useQuery({
    url: `/inventory/fetchStockCount`,
    refresh,
    queryData: {
      location: selectedFromLocation?.value
    }
  });
  const isStockLoading = stockCountState === 'loading'

  useEffect(() => {
    let collatedStockCount = {}
    if (stockCountFull) {
      stockCountFull.map(product => {
        product.variantCounts.map(variantCount => {
          collatedStockCount[variantCount.variant.sku] = variantCount.count.on_hand
        })
      })
      setStockCount(collatedStockCount)
    }
  }, [stockCountFull])

  const [onHand, setOnHand] = useState({})

  const [dataStock, dataStockState] = useQuery({
    url: `/inventory/fetchStockCount`,
    refresh,
    queryData: {
      location: selectedToLocation?.value
    }
  });
  const isOnHandLoading = dataStockState === 'loading'

  useEffect(() => {
    let collatedStockCount = {}
    if (dataStock) {
      dataStock.map(product => {
        product.variantCounts.map(variantCount => {
          collatedStockCount[variantCount.variant.sku] = variantCount.count.on_hand
        })
      })
      setOnHand(collatedStockCount)
    }
  }, [dataStock])

  /*
    Replenish History
  */
  const [stockPull, setStockPull] = useState([])
  const [historyOpen, setHistoryOpen] = useState([])

  const [selectedStatus, setSelectedStatus] = useState('Approved')

  const [replenishHistory, setReplenishHistory] = useQuery({
    url: `/inventory/fetchTransfers`,
    queryData: {
      page,
      perPage,
      location: selectedToLocation?.value,
      status: selectedStatus.toLowerCase()
    },
    refresh
  });
  const replenishHistoryData = replenishHistory

  const updateHistoryOpen = (key) => {
    setHistoryOpen(prev => {
      let newData = [...prev]
      newData[key] = !newData[key]
      return newData
    })
  }

  useEffect(() => {
    if (replenishHistoryData) {
      setHistoryOpen(replenishHistoryData.map(val => false))
    }
  }, [replenishHistoryData])


  const [data, state] = useQuery({
    url: `/product`,
    refresh
  });
  const productData = data && data.data



  const handleSubmit = () => {
    setlLoading(true)
    const exportData = {
      locationFrom: selectedFromLocation?.value,
      locationTo: selectedToLocation?.value,
      pullStock: stockPull,
    }

    superagent.post(`${backendUrl}/inventory/bookTransfer`)
      .send(exportData)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status
        history.replace(history.location.pathname, {
          errorStatusCode: code
        });
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code
          });
        } else {
          history.go(0)
        }
      })
      .catch(err => {
        console.log(err)
      });
  }


  const handleOnDrop = (data) => {
    let items = []
    data.map((line, key) => {
      if (key > 0) {
        const count = line.data[1]
        const sku = line.data[0]

        let product = productData.find(val => (val.variants.find(variant => variant.sku === sku) != undefined))
        if (product != undefined) {
          let variant = product.variants.find(variant => variant.sku === sku)
          if (variant != undefined) {
            const maxCount = stockCount[sku] || 0
            const item = {
              product: product._id,
              variant: variant._id,
              quantity: Math.min(count, maxCount),
              sku: sku
            }; 
            items.push(item)
          }
        }
      }
    })

    items = items.sort((a, b) => (a.sku < b.sku) ? -1 : (a.sku > b.sku) ? 1 : 0);
    setStockPull(prev => [...prev, ...items])
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    // console.log(data);
  };

  return <div className="page-content">
    <Container fluid>
      <div>
        <Row className="mb-2">
          <Col xs="12" m={stockPull.length > 0 ? "8" : "12"} lg={stockPull.length > 0 ? "8" : "12"} xl={stockPull.length > 0 ? "8" : "12"} >
            <Card>
              <CardHeader>
                <h2 className="">
                  Warehouse Transfers
                </h2>
              </CardHeader>
              <CardBody>
                <div className="my-2">
                    <FormGroup> 
                        <Label> 3PL: </Label>
                        <Select 
                            options={selectedFromLocationOptions}
                            value={selectedFromLocation}
                            onChange={setSelectedFromLocation}
                        />
                    </FormGroup>
                    <FormGroup> 
                        <Label> Warehouse: </Label>
                        <Select 
                            options={selectedToLocationOptions}
                            value={selectedToLocation}
                            onChange={setSelectedToLocation}
                        />
                    </FormGroup>
                </div>
                <br/>
                <div>
                  <div className="mb-2">
                    <a href="https://jifi-storage-staging.s3-ap-southeast-2.amazonaws.com/comment_file_1637273421528.csv"> CSV Template </a>
                  </div>
                  <CSVReader
                    onDrop={handleOnDrop}
                    onError={handleOnError}
                    onRemoveFile={handleOnRemoveFile}
                  >
                    <span>Drop CSV file here or click to upload.</span>
                  </CSVReader>
                </div>

                <br />
                {
                  loadingTopup ? (
                    <div className="spinner-border text-primary m-1" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <>
                      <StockInput maxCounts={stockCount} stock={stockPull} setStock={setStockPull} productData={productData} locationFrom={selectedToLocation?.value} onHand={onHand} isStockLoading={isStockLoading} isOnHandLoading={isOnHandLoading} /><div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 12 }}>
                        <span />
                        {(productData && (stockPull.length > 0)) && (
                          <Button onClick={togglemodal} color="primary" className="btn-lg btn-rounded">
                            Submit
                          </Button>
                        )}
                      </div>
                    </>
                  )
                }
              </CardBody>
            </Card>
          </Col>
          {
            stockPull.length > 0 && (
              <Col xs="12" m={stockPull.length > 0 ? "4" : "0"} lg={stockPull.length > 0 ? "4" : "0"} xl={stockPull.length > 0 ? "4" : "0"} >
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">STOCK SUMMARY</h4>
                    <br />
                    {
                      productData && stockPull.length > 0 && 'Removing:'
                    }
                    {
                      productData && stockPull.map((line, lineKey) => {
                        return <div>  <span className="text-success">+{line['quantity']}</span> x {line['product'] && line['variant'] && productData.find(val => val._id === line['product']).variants.find(val => val._id === line['variant'])?.sku} </div>
                      })
                    }
                  </CardBody>
                </Card>
              </Col>
            )
          }
        </Row>
      </div>
      <div>
        <Row>
          <Col xs="12" m="12" lg="12" xl="12">
            <Card>
              <CardBody>
                <Row>
                  <Col sm="3">
                    <h4>Booked Replenishments</h4>
                  </Col>
                  <Col sm="9"></Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <div>
                      <FormGroup> 
                          <Label> Status: </Label>
                          <Select 
                              options={['Approved', 'Pending', 'Cancelled', 'Error'].map(val => ({value: val, label: val}))}
                              value={{value: selectedStatus, label: selectedStatus}}
                              onChange={e => setSelectedStatus(e.value)}
                          />
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                
                {
                  (setReplenishHistory === 'success' && replenishHistoryData) ? replenishHistoryData.map((replenishHistoryObject, key) => {
                    return (
                      <DropLine locationFrom={selectedToLocation?.value} history={history} updateHistoryOpen={updateHistoryOpen} isOpen={historyOpen[key]} setReplenishHistory={setReplenishHistory} replenishHistoryObject={replenishHistoryObject} lineKey={key} />
                    )
                  }) : (
                    <div className="spinner-border text-primary m-1" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )
                }
                <TablePagination count={100} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
    <Modal isOpen={modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" toggle={togglemodal}>
      <CardBody>
        <h4 className="card-title mb-4">INTERNAL STOCK SUMMARY</h4>
        <p>
          Any stock with 0 booked will not show up in the replenish history.
        </p>
        <br />
        {
          productData && stockPull.map((line, lineKey) => {
            return <div>  <span className={line['quantity'] > 0 ? "text-success" : 'text-danger'}>+{line['quantity']}</span> x {line['product'] && line['variant'] && productData.find(val => val._id === line['product']).variants.find(val => val._id === line['variant'])?.sku} </div>
          })
        }
      </CardBody>
      <ModalFooter>
        {
          loading ? (
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <Button onClick={handleSubmit} color="primary" className="btn-lg btn-rounded">
              Confirm
            </Button>
          )
        }
      </ModalFooter>
    </Modal>
  </div>
}

export default WarehouseTransferPage