import React from 'react'

import FontFile from '../shared/font-file'

const Word = (props) => {
  return (
    <FontFile
      {...props}
      fontSize={props.fontSize * 0.5}
      font={'wide'}
      multiplier={1.2}
      topOffset={'-0.015em'}
      uppercase={true}
      borderColor={props.mainCol}
    />
  )
}

export default Word
