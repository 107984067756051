export const navBarMenu = {
  'supply-chain': [
    {
      name: 'Overview',
      url: '/po-overview',
      lookup: 'overview',
    },
    {
      name: 'Containers',
      url: '/containers',
      lookup: 'container',
    },
    {
      name: 'Releases',
      url: '/releases',
      lookup: 'releases',
    },
    {
      name: 'Purchase Orders',
      url: '/purchase-order',
      lookup: 'purchase-order',
    },
    {
      name: 'Factories',
      url: '/factories',
      lookup: 'factories',
    },
  ],
  'notifications' :[
    {
      name: 'Inbox',
      url: '/notifications/inbox',
      lookup: 'notifications/inbox',
    },
    {
      name: 'Read',
      url: '/notifications/read',
      lookup: 'notifications/read',
    }
    
  ],
  'fulfillment-location' :[
    {
      permission: 'AirboxPersonalisation',
      name: 'Airbox Personalisation',
      url: '/fulfillment/locations/airbox',
      lookup: '/fulfillment/locations/airbox',
    },
    {
      permission: 'ViewOrders',
      name: 'EWE Personalisation',
      url: '/fulfillment/locations/ewe',
      lookup: '/fulfillment/locations/ewe',
    },
    {
      permission: 'LaunchPersonalisation',
      name: 'Launch Personalisation',
      url: '/fulfillment/locations/launch',
      lookup: '/fulfillment/locations/launch',
    },
    {

      permission: 'UNISPersonalisation',
      name: 'UNIS Personalisation',
      url: '/fulfillment/locations/unis',
      lookup: '/fulfillment/locations/unis',
    },

  ]
};
