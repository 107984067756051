import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { UncontrolledDropdown, FormGroup, Container, Input, Button, Table, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Progress, TabContent, TabPane, NavLink, NavItem} from "reactstrap";
import { CSVLink, CSVDownload } from "react-csv";

import {backendUrl} from '../../../helpers/consts.js'

import { useReactToPrint } from 'react-to-print';

import Select from 'react-select';
import img4 from "../../../assets/images/product/img-4.png";
import img7 from "../../../assets/images/product/img-7.png";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import "../datatables.scss";
import useQuery from '../../../helpers/JIFI/useQuery';
import { effectTypes, select } from "redux-saga/effects";
import moment from 'moment';
import classnames from "classnames";
import superagent from 'superagent'

const perPageOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
]

const ShipBadge = ({status}) => {
  const conversion = {
    'awaiting-delivery': 'warning',
    'delivered': 'success',
    'out-for-delivery': 'secondary',
  }
  const badgeColor =  conversion[status]
  return (
    <Badge className={"font-size-12 badge-soft-" + badgeColor} color={badgeColor} pill>
      {status.split('-').map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' ')}
    </Badge>
  )
}


const SKUDropdown = ({skus}) => {
  const [skuArray, setSkuArray] = useState(skus.split(' ')) 
  const [dropdownOpen, setDropdownOpen] = useState(false);
  useEffect(() => {
    setSkuArray(skus.split(' '))
  }, [skus])

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>
        ▼
      </DropdownToggle>
      <DropdownMenu>
        {
          skuArray.map((sku) => <DropdownItem>{sku}</DropdownItem>)
        }
      </DropdownMenu>
    </Dropdown>
  );

}

const paginate = function (array, index) {
  return [
    array[index - 2],
    array[index - 1],
    array[index],
    array[index + 1],
    array[index + 2]
  ].filter(function (el) {
    return el != null;
  });
}

const TablePagination = ({ count, page, setPage, perPage, setPerPage }) => {
  const pageCount = Math.ceil(count / perPage)
  const pages = paginate(Array.from({ length: pageCount }, (v, i) => i + 1), page)
  const handleNumberClicked = (key) => {
    setPage(key)
  }
  return (
    <Pagination className="pagination pagination-rounded justify-content-end mb-2">
      <div style={{ height: '100%', width: 140 }}>
        <Select
          options={perPageOptions}
          onChange={newPerPage => setPerPage(newPerPage.value)}
          value={perPageOptions.find(val => val.value === perPage)}
        />
      </div>
      <PaginationItem disabled>
        <PaginationLink previous />
      </PaginationItem>
      {
        pages.map(val => (
          <PaginationItem onClick={() => handleNumberClicked(val - 1)} active={val - 1 === page}>
            <PaginationLink>
              {val}
            </PaginationLink>
          </PaginationItem>
        ))
      }
      <PaginationItem>
        <PaginationLink next />
      </PaginationItem>
    </Pagination>
  )
}

const DeliveriesPage = () => {
  const url = '/deliveries/listDeliveryBatch'
  const [stateData, setStateData] = useState({
    searchTerm: '',
    selectedShipStatus: { label: 'Out For Delivery', value: 'out-for-delivery' },
    shipStatusOptions: [{ label: 'Awaiting Delivery', value: 'awaiting-delivery' }, { label: 'Out For Delivery', value: 'out-for-delivery' }, { label: 'Delivered', value: 'delivered' }],
  });
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
      setRefresh(prev => !prev)
  }
  const [data, state] = useQuery({ refresh, url, queryData: { page: page, perPage: perPage, searchTerm: stateData.searchTerm, shipStatus: stateData.selectedShipStatus?.value } });
  const orders = data && data.data;
  const count = data && data.count;

  const { searchTerm, selectedShipStatus, shipStatusOptions } = stateData
  const handleTermChange = (e) => {
    const newTerm = e.currentTarget.value.toUpperCase().replace('#', '')
    setStateData((prevState => {
      return {
        ...prevState,
        searchTerm: newTerm
      }
    }))
  }
  const handleSelectShipStatus = selectedSshipStatus => {
    console.log(selectedSshipStatus)
    setStateData((prevState => {
      return {
        ...prevState,
        selectedShipStatus: selectedSshipStatus
      }
    }))
  };

  const [selected, setSelected] = useState([])
  useEffect(() => {
    orders ? setSelected(orders.map(() => false)) : setSelected([]) 
  }, [orders])
  const selectedIds = orders && orders.filter((val, key) => selected[key] === true)

  const [approveId, setApproveId] = useState(null)
  const handleApprove = (id) => {
    setApproveId(id)
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Deliveries" breadcrumbItem="All Deliveries" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div>
                    <FormGroup className="select2-container">
                        <Label className="control-label">SEARCH</Label>
                        <div className="search-box mb-2 d-block">
                          <div className="position-relative">
                            <Input type="text" className="form-control" placeholder="Search..." value={searchTerm} onChange={handleTermChange} />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                      </FormGroup>
                  </div>
                  <Row>
                    <Col xs="8">
                      <FormGroup className="select2-container">
                        <Label className="control-label">SHIP STATUS</Label>
                        <Select classNamePrefix="select2-selection" value={selectedShipStatus} onChange={handleSelectShipStatus} placeholder="All" title="Ship Status" options={shipStatusOptions} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <Table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th>Date</th>
                          <th>Ship Status</th>
                          <th>Orders</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          state === 'success' && (
                            orders && orders.map((order, key) =>
                              <tr style={order.flagged ? {background: "#fde1e1"} : {}} key={"_order_" + key}>
                                <td>{(new Date(order.createdAt)).toDateString()}</td>
                                <td><ShipBadge status={order.ship_status} /></td>
                                <td><SKUDropdown skus={order.deliveries.map(lineItem => lineItem.name).join(' ')}/></td>
                                <td>
                                  {
                                    order.ship_status === 'out-for-delivery' && (
                                      <Button onClick={() => handleApprove(order._id)} color="success"> Approve </Button>
                                    )
                                  }
                                </td>
                              </tr>
                            )
                          )
                        }
                      </tbody>
                    </Table>
                  </div>
                  {
                    (orders && state === 'success') ? (
                      <div>

                      </div>
                      // <TablePagination count={count} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
                    ) : (
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                          <div className="spinner-grow text-primary m-1" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>   
      <ApproveModal approveId={approveId} setApproveId={setApproveId} refresh={refreshData}/>
    </>
  );
}


const ApproveModal = ({approveId, setApproveId, refresh}) => {
  const [loading, setLoading] = useState(false)
  let cancelOpen = approveId !== null
  const toggleCancelOpen = () => {
    setApproveId(null)
  }

  const [orderData, setOrderData] = useState({})

  useEffect(() => {
    loadData()
  }, [approveId])

  const [selected, setSelected] = useState([])
  useEffect(() => {
    orderData && orderData.deliveries ? setSelected(orderData.deliveries.map(() => false)) : setSelected([]) 
  }, [orderData])
  const selectedIds = orderData && orderData.deliveries && orderData.deliveries.filter((val, key) => selected[key] === true)
  
  const loadData = () => {
    setLoading(true)
    superagent.post(`${backendUrl}/deliveries/getDeliveryBatch`)
      .send({ 
        id: approveId
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          setOrderData(response.body)
          setLoading(false)
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });
  }


  const save = () => {
    console.log(selectedIds.map(val => val._id))
    setLoading(true)
    superagent.post(`${backendUrl}/deliveries/approveDeliveryBatch`)
      .send({ 
        ids: selectedIds.map(val => val._id),
        batchId: approveId
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          toggleCancelOpen()
          refresh()
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });

  }

  return (
    <Modal isOpen={cancelOpen} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" toggle={toggleCancelOpen}>
      <ModalBody>
        <h3>Approval Delivery Batch</h3>
      </ModalBody>
        {
          loading ? (
            <ModalBody>
              <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                <div className="spinner-grow text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </ModalBody>
          ) : (
              <>
                <ModalBody>
                  <div className="table-responsive">
                    <Table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: "20px" }}>
                            <div className="custom-control custom-checkbox">
                              <Input onChange={e => (orderData && orderData.deliveries ? setSelected(orderData.deliveries.map(() => e.target.checked)) : setSelected([])) } type="checkbox" className="custom-control-input" id="customCheck1" />
                              <Label className="custom-control-label" htmlFor="customCheck1">&nbsp;</Label>
                            </div>
                          </th>
                          <th>Order ID</th>
                          <th>Shipping</th>
                          <th>Region</th>
                          <th>Shipping Name</th>
                          <th>Date</th>
                          <th>SKUs</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          orderData?.deliveries && orderData.deliveries.map((order, key) => 
                            order.line_items.map((lineItem, keyLI) => keyLI === 0 ? (
                              <tr style={order.flagged ? {background: "#fde1e1"} : {}} key={"_order_" + key}>
                                <td>
                                  <div 
                                    className="custom-control custom-checkbox"
                                    onClick={() => setSelected(prev => {
                                      let newSelected = [...prev]
                                      newSelected[key] = !newSelected[key]
                                      return newSelected
                                    })} 
                                  >
                                    <Input 
                                      type="checkbox" 
                                      className="custom-control-input" 
                                      id={order.id} 
                                      checked={selected[key]} />
                                    <Label className="custom-control-label" htmlFor={order.id}>&nbsp;</Label>
                                  </div>
                                </td>
                                <td>{order.name}</td>
                                <td>{order.shipping_type}</td>
                                <td>{order?.shipping_address?.city}</td>
                                <td>{order?.shipping_address?.name}</td>
                                <td>{(new Date(order.orderCreated)).toDateString()}</td>
                                <td>{lineItem.sku}</td>
                              </tr>
                            ): (
                              <tr style={order.flagged ? {background: "#fde1e1"} : {}} key={"_order_" + key}>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{lineItem.sku}</td>
                              </tr>
                            ))
                          )
                        }
                      </tbody>
                    </Table>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button onClick={save} color="warning"> Confirm </Button>
                </ModalFooter>
              </>
          )
        }

    </Modal>
  )
}

export default DeliveriesPage;


