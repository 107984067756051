import React, { Component } from 'react';

import { Row, Col, CardBody, Card, Alert,Container } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { loginUser,apiError } from '../../../store/actions';

// import images
import profile from "../../../assets/images/profile-img.png";
import logo from "../../../assets/images/logo.svg";

import GoogleLogin from 'react-google-login';
import useGoogleAuthentication from "./useGoogleAuthentication";

function throttle(func, interval) {
    var lastCall = 0;
    return function() {
        var now = Date.now();
        if (lastCall + interval < now) {
            lastCall = now;
            return func.apply(this, arguments);
        }
    };
}

const GoogleButton = ({history, path}) => {
    const { handleSuccess } = useGoogleAuthentication(history, path);
   
    return (
      <GoogleLogin
        style={{width: '100%'}}
        clientId={'532750800945-jglcsgkpac7h1fgr2flkc0u30cb3j90r.apps.googleusercontent.com'}
        buttonText="Log in"
        onSuccess={handleSuccess}
      />
    );
  }

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {}

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.props.loginUser(values, this.props.history, this.props.location?.state?.from);
    }

    componentDidMount()
    {
        this.props.apiError("");
    }

    render() {
        const self = this
        return (
            <React.Fragment>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark"><i className="bx bx-home h2"></i></Link>
                </div>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div style={{backgroundColor: '#1e3d3c'}} className="">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-primary p-4">
                                                    <h5 style={{color: '#ffffff'}} className="">Welcome Back !</h5>
                                                    <p style={{color: '#ffffff'}}>Sign in to continue to JIFI.</p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                {/* <img src={profile} alt="" className="img-fluid" /> */}
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div>
                                            <Link to="/">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img src={logo} alt="" className="p-1" height="34" />
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="p-2">

                                            <AvForm 
                                                className="form-horizontal" 
                                                onValidSubmit={ function (e, v) {
                                                    throttle(self.handleValidSubmit(e, v), 1000)
                                                }}
                                            >

                                                {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}

                                                <div className="form-group">
                                                    <AvField name="email" label="Email" className="form-control" placeholder="Enter email" type="email" required />
                                                </div>

                                                <div className="form-group">
                                                    <AvField name="password" label="Password" type="password" required placeholder="Enter Password" />
                                                </div>

                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                    <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>
                                                </div>

                                                <div className="mt-3">
                                                    <button style={{background:'#1e3d3c'}} className="btn btn-primary btn-block waves-effect waves-light" type="submit">Log In</button>
                                                </div>

                                                {/* <div className="mt-4 text-center">
                                                    <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                                </div> */}
                                            </AvForm>
                                        </div>
                                        <div style={{textAlign: 'center', color: '#ccc', marginBottom: 8}}>@july.com emails</div>
                                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <GoogleButton 
                                                path={this.props.location?.state?.from}
                                                history={this.props.history}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { error } = state.Login;
    return { error };
}

export default withRouter(connect(mapStatetoProps, { loginUser,apiError })(Login));

