import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Table, FormGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Pagination, PaginationItem, PaginationLink } from "reactstrap";

import useQuery from '../../helpers/JIFI/useQuery';
import { useHistory } from "react-router-dom";
import Select from 'react-select';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { app } from 'firebase';

import moment from 'moment';

import '../Skote/Dashboard/skelton.css'

const ApprovalsBadge = ({status}) => {
    const statuses = {
        'pending': 'warning',
        'approved': 'success',
        'rejected': 'danger'
    }
    return <span className={`badge badge-${statuses[status]}`}>{status}</span>
}

const perPageOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 500, label: '500 - (WARNING SLOW)' },
    { value: 1000, label: '1000 - (WARNING SLOW)' },
  ]

const paginate = function (array, index) {
    return [
      array[index - 2],
      array[index - 1],
      array[index],
      array[index + 1],
      array[index + 2]
    ].filter(function (el) {
      return el != null;
    });
}

const TablePagination = ({ count, page, setPage, perPage, setPerPage }) => {
    const pageCount = Math.ceil(count / perPage)
    const pages = paginate(Array.from({ length: pageCount }, (v, i) => i + 1), page)
    const handleNumberClicked = (key) => {
      setPage(key)
    }
    return (
      <Pagination className="pagination pagination-rounded justify-content-end mb-2">
        <div style={{ height: '100%', width: 140 }}>
          <Select
            options={perPageOptions}
            onChange={newPerPage => setPerPage(newPerPage.value)}
            value={perPageOptions.find(val => val.value === perPage)}
          />
        </div>
        <PaginationItem disabled>
          <PaginationLink previous />
        </PaginationItem>
        {
          pages.map(val => (
            <PaginationItem onClick={() => handleNumberClicked(val)} active={val === page}>
              <PaginationLink>
                {val}
              </PaginationLink>
            </PaginationItem>
          ))
        }
        <PaginationItem>
          <PaginationLink next />
        </PaginationItem>
      </Pagination>
    )
}
  

const ProjectsList = () => {
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10);
    const [selectedStatus, setSelectedStatus] = useState('Pending')
    const [selectedLocation, setSelectedLocation] = useState()

    const [dataGroup, stateGroup] = useQuery({
        url: `/inventory/approvalGroup`,
        queryData: {
            page,
            perPage,
            location: selectedLocation,
            status: selectedStatus
        }
    });

    const [locations, stateLocations] = useQuery({
        url: '/inventory/getAllLocationNames'
    })

    const approvalsGroup = dataGroup && dataGroup.data
    const count = dataGroup && dataGroup.count

    const history = useHistory();

    if (!locations || !approvalsGroup) {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                <div className="spinner-grow text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }

    const statusOptions = [{value: undefined, label: 'All'}, ...['Pending', 'Approved', 'Rejected'].map(val => ({value: val, label: val}))]
    const statusValue = statusOptions.find(val => val.value === selectedStatus)
    const locationOptions = [{value: undefined, label: 'All'}, ...locations.map(val => ({value: val._id, label: val.display_name}))]
    const locationValue = locationOptions.find(val => val.value === selectedLocation)

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Inventory" breadcrumbItem="Approvals" />
                    <Row>
                        <Col sm="3">
                            <FormGroup>
                                <label>Status</label>
                                <Select 
                                    options={statusOptions}
                                    value={statusValue}
                                    onChange={(e) => setSelectedStatus(e.value)}
                                />
                            </FormGroup>
                           
                        </Col>
                        <Col sm="3">
                            <FormGroup>
                                <label>Location</label>
                                <Select 
                                    options={locationOptions}
                                    value={locationValue}
                                    onChange={(e) => setSelectedLocation(e.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <div className="">
                                <div className="table-responsive">
                                    <Table className="project-list-table table-nowrap table-centered table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Location</th>
                                                <th scope="col">Submission Date</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (stateGroup === 'success' && approvalsGroup) ? approvalsGroup.map((approval, key) => {
                                                    let status = 'approved'
                                                    approval.records.map(val => {
                                                        if (val.status === 'pending'){
                                                            status = 'pending'
                                                        }
                                                    })
                                                    return (
                                                        <tr style={{cursor: 'pointer'}} onClick={() => history.push(`/approval?date=${moment(new Date(approval._id.date)).format('YYYY-MM-DD')}&location=${approval?._id.location?._id}`)}>
                                                            <td>
                                                                <h5 className="text-truncate font-size-14">{approval?._id.location?.name}</h5>
                                                            </td>
                                                            <td>
                                                                {moment(new Date(approval._id.date)).format('DD/MM/YYYY')}
                                                            </td>
                                                            <td>
                                                                <ApprovalsBadge status={status} />
                                                                </td>
                                                            <td>
                                                                {approval.count}
                                                            </td>
                                                        </tr>
                                                    )
                                                }) : (
                                                    perPage && (
                                                        [...Array(perPage)].map((_, i) => (
                                                          <tr key={"_order_" + i}>
                                                            <td> <h5 className="text-truncate font-size-14 placeholder"></h5> </td>
                                                            <td> <div className="placeholder" /> </td>
                                                            <td> <div className="placeholder" /> </td>
                                                            <td> <div className="placeholder" /> </td>
                                                          </tr>
                            
                                                        ))
                                                      )

                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Load more </Link>
                            </div>
                        </Col>
                    </Row> */}
                    <TablePagination count={count} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
                </Container>
            </div>
        </>
    );
}

export default ProjectsList;