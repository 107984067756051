import React from 'react';
import { Button } from 'reactstrap';

const MarkAsReadButton = ({ onClick, isDisabled }) => (
  <div className="d-flex justify-content-end my-3 mr-3">
    <Button color="primary" onClick={onClick} disabled={isDisabled}>
      Mark as Read
    </Button>
  </div>
);

export default MarkAsReadButton;
