import React, { useState, useEffect } from 'react';
import useQuery from '../../../helpers/JIFI/useQuery';

const countriesToLocations = {
    AU: ["QV Store", "EWE - Sydney", "HQ2", "EWE", "DJ Store", "Galeries Sydney", "80 Collins", "Bondi",],
    US: ["UNIS", "USA - Dallas", 'LCHKTC', 'LCHSLC'],
    UK: ["Airbox UK"]
}

const useUpcomingStock = () => {
    const [products, productState] = useQuery({
        url: `/product/findAll`,
    });

    const [containers, containerState] = useQuery({
        url: `/inventory/fetchUpcomingContainer/`,
    });

    const getUpcomingStock = (SKUParam) => {
        const updatedTable = {};

        if (products && containers) {
            products.data.forEach((product) => {
                product.variants.forEach((variant) => {
                    const sku = variant.sku;
                    const containerLineData = [...containers]
                        .reverse()
                        .map((container) => {
                            const containerLine = container.containerLines.find(
                                (variantCount) => variantCount.variant.sku === sku
                            );
                            const count = containerLine ? containerLine.quantity : 0;
                            const location = container.landingLocation.name;
                            const country = Object.keys(countriesToLocations).find(val => countriesToLocations[val].includes(location))
                            const date = container.arrivalDate;
                            return { count, location, date, country };
                        })
                        .filter((val) => val.count !== 0);

                    const usList = containerLineData.filter((val) => val.country === 'US');
                    const auList = containerLineData.filter((val) => val.country === 'AU');
                    const ukList = containerLineData.filter((val) => val.country === 'UK');

                    updatedTable[sku] = {
                        usNextContainer: usList.length > 0 ? usList[0] : null,
                        auNextContainer: auList.length > 0 ? auList[0] : null,
                        ukNextContainer: ukList.length > 0 ? ukList[0] : null,
                    };
                });
            });

            if(updatedTable) {
                return updatedTable[SKUParam]
            }
        }

        return null
    }

    return getUpcomingStock;
};

export default useUpcomingStock;
