import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, Progress, ModalHeader } from 'reactstrap';
import classnames from 'classnames';


const WizardNav = ({ steps, activeTabProgress }) => {
  let stepProgress = steps.length + 0.5
  const [progress, setProgress] = useState({
    activeTab: 1,
    activeTabProgress: 1,
    progressValue: 100 / stepProgress,
  });

  const toggleTabProgress = (tab) => {
    if (progress.activeTabProgress !== tab) {
      const progressProgression = activeTabProgress / steps.length * 100
      setProgress((prev) => ({
        ...prev,
        activeTabProgress: tab,
        progressValue: progressProgression
      }));

      // if (tab >= 1 && tab <= 4) {
      //   setProgress((prev) => ({
      //     ...prev,
      //     activeTabProgress: tab,
      //   }));



      //   if (tab === 1) {
      //     setProgress((prev) => ({ ...prev, progressValue: 100 / stepProgress }));
      //   }
      //   if (tab === 2) {
      //     setProgress((prev) => ({ ...prev, progressValue: 50 }));
      //   }
      //   if (tab === 3) {
      //     setProgress((prev) => ({ ...prev, progressValue: 75 }));
      //   }
      //   if (tab === 4) {
      //     setProgress((prev) => ({ ...prev, progressValue: 100 }));
      //   }
      // }
    }
  };

  useEffect(() => {
    toggleTabProgress(activeTabProgress)
  })

  const renderSteps = () => {
    return steps?.map((step, index) => (

      <NavItem key={index}>
        <NavLink
          className={classnames({
            active: activeTabProgress === index + 1, // Incrementing by 1 to start from step 1
          })}
          style={{ 'text-align': 'center' }}
        >
          <span className="step-number mr-2">{`0${index + 1}`}</span>{step}
        </NavLink>
      </NavItem>

    ));
  };

  return (
      <div id="progrss-wizard mg-s" className="twitter-bs-wizard">
        <Nav className="twitter-bs-wizard-nav nav-justified nav nav-pills" style={{ padding: '0 16px' }}>
          {steps?.length > 0 && (
            <>
              {renderSteps()}
            </>
          )}
        </Nav>
        <div id="bar" className="mt-4" style={{ margin: '1.5rem 0.5rem' }}>
          <Progress
            color="success"
            striped
            animated
            value={progress.progressValue}
          />
          <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
        </div>
      </div>
  );
};

export default WizardNav;
