import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Badge, ModalBody, ModalFooter } from 'reactstrap';
import superagent from 'superagent'; // Import superagent if not already done
import { backendUrl } from '../../../../helpers/consts';
import WizardNav from './WizardNav';
import SKUToolTip from '../../helpers/SKUToolTip';
import useSKULevels from "../../helpers/useSKULevels.js";
import useSKUStatus from "../../helpers/useSKUStatus.js";
import useUpcomingStock from "../../helpers/useUpcomingStock.js";

const fulfillmentToBadge = (fulfillment) => {
    if (fulfillment === 'unfulfilled') return 'warning';
    if (fulfillment === 'awaiting-stock') return 'secondary';
    const conversion = {
        'fulfilled': 'success',
        'partially_fulfilled': 'error',
    };
    return conversion[fulfillment];
};

const SplitLineItems = ({ togglemodal, lineItems, orderID, order_source, refresh, shipping_type, contains_monogram, orderName, getVariant, distanceFromHQ }) => {
    const [selectedItems, setSelectedItems] = useState(lineItems.map(() => true));
    const [selectedCount, setSelectedCount] = useState(0);
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [manualName, setManualName] = useState(orderName);

    const findSKULevel = useSKULevels()
    const findUpcomingStock = useUpcomingStock()
    const findSKUStatus = useSKUStatus()

    useEffect(() => {
        setSelectedCount(selectedItems.reduce((n, val) => n + (val === true), 0));
    }, [selectedItems]);

    useEffect(() => {
        setSelectedItems(lineItems.map(val => val.fulfillment_status !== 'deleted'));
    }, [lineItems]);

    const handleFulfillClicked = () => {
        setStep(2);
    };

    const handleManualNameChange = (e) => {
        setManualName(e.target.value);
    };

    const handleCreateSplit = () => {
        setLoading(true);

        const sendLineItems = selectedItems
            .map((val, key) => (val ? lineItems[key] : null))
            .filter(el => el !== null);

        superagent
            .post(`${backendUrl}/orders/splitOrder`)
            .send({
                line_items: sendLineItems,
                orderId: orderID,
                updatedName: manualName,
            })
            .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
            .set('accept', 'json')
            .then(response => {
                const code = response.status;
                if (code > 400) {
                    // Handle error if necessary
                } else {
                    setLoading(false);
                    setStep(1); // Reset to Step 1
                    refresh();
                    togglemodal()
                }
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    };

    return (
        <div>
            <WizardNav steps={['SKU Select', 'Split Order Confirmation']} activeTabProgress={step} />
            {step === 1 && (
                <>
                    {lineItems.map((lineItem, key) => {
                        const handleSelected = () => {
                            setSelectedItems(prev => {
                                const newItems = [...prev];
                                newItems[key] = !newItems[key];
                                return newItems;
                            });
                        };
                        const isSelected = selectedItems[key] === true;

                        return (
                            <ModalBody>
                                <Row key={key}>
                                    <Col sm="1">
                                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {lineItem.fulfillment_status !== 'deleted' && (
                                                <input className="form-check-input" type="checkbox" onChange={handleSelected} checked={isSelected} id="defaultCheck1" />
                                            )}
                                        </div>
                                    </Col>
                                    <Col sm="2">
                                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={getVariant(lineItem.sku)?.image} alt="" className="img-fluid" />
                                        </div>
                                    </Col>
                                    <Col sm="5" className="p-3">
                                        <h6>{getVariant(lineItem.sku)?.name}</h6>
                                        <div>
                                            <Badge className={"font-size-12 badge-soft-" + fulfillmentToBadge(lineItem.fulfillment_status)} color={fulfillmentToBadge(lineItem.fulfillment_status)} pill>
                                                {lineItem.fulfillment_status ? lineItem.fulfillment_status.replace('_', ' ').toUpperCase() : 'UN-FULFILLED'}
                                            </Badge>
                                        </div>
                                        <div>SKU: <SKUToolTip sku={lineItem.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} /></div>
                                    </Col>
                                    <Col sm="2" className="p-3">
                                        {/* ... rest of the code for monogram and other details */}
                                    </Col>
                                    <Col sm="2" className="p-3">
                                        QUANTITY: {lineItem.quantity}
                                    </Col>
                                </Row>
                            </ModalBody>
                        );
                    })}
                    <ModalFooter>
                        <Row>
                            {selectedCount > 0 && (
                                <Button onClick={handleFulfillClicked} type="submit" color="success">
                                    Next
                                </Button>
                            )}
                        </Row>
                    </ModalFooter>
                </>
            )}

            {step === 2 && (
                <>
                    <ModalBody>
                        <div className="my-3">
                            {lineItems
                                .filter((val, key) => selectedItems[key] === true)
                                .map((val, key) => (
                                    <div className="my-1" key={key}>
                                        {val.sku} x {val.quantity}
                                    </div>
                                ))}
                        </div>
                        <div className="form-group">
                            <label htmlFor="manualName">New Order Name:</label>
                            <input type="text" className="form-control" id="manualName" value={manualName} onChange={handleManualNameChange} />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {loading ? (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-primary m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <Button color="danger" onClick={handleCreateSplit}>
                                Create Split
                            </Button>
                        )}
                    </ModalFooter>
                </>
            )}
        </div>
    );
};

export default SplitLineItems;
