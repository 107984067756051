import React, { useState } from 'react'
import { Container, Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { Line } from 'react-chartjs-2';

import useQuery from '../../../helpers/JIFI/useQuery';

import moment from 'moment';
import Select from 'react-select'

const LineChart = ({ data }) => {
  const chartData = {
    labels: data.map((d) => moment(d.date).format('DD-MM-YYYY')),
    datasets: [
      {
        label: "Dispatched from Melbourne",
        fill: true,
        lineTension: 0.5,
        backgroundColor: "rgba(85, 110, 230, 0.2)",
        borderColor: "#556ee6",
        pointBorderColor: "#556ee6",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#556ee6",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: data.map((d) => d.dispatchedFromEWE),
      },
      {
        label: "Dispatched from Sydney",
        fill: true,
        lineTension: 0.5,
        backgroundColor: "rgba(255, 193, 7, 0.2)",
        borderColor: "#ffc107",
        pointBorderColor: "#ffc107",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#ffc107",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: data.map((d) => d.dispatchedFromEWESYD),
      },
    ],
  };

  return (
    <div>
      <Line data={chartData} />
    </div>
  );
};

const stateOptions = [ 'New South Wales', 'Queensland', 'South Australia', 'Tasmania', 'Victoria', 'Western Australia', 'Australian Capital Territory', 'Northern Territory' ].map(val => ({ value: val, label: val }))

const StockTransferEfficency = () => {
  const [states, setStates] = useState(['Queensland', 'New South Wales'].map(val => ({ value: val, label: val })));
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD')) 
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
      setRefresh(prev => !prev)
  }

  const [data, state] = useQuery({ 
    refresh,
    url: `/analytics/stockTransferEfficency`, 
    queryData: { 
      ...(Array.isArray(states) ?  (states.length === 0 ? {states: stateOptions.map(a => a.value)} : {states: states.map(a => a.value)}) : {states: stateOptions.map(a => a.value)}),
      startDate,
      endDate
    }
  });


  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Fulfillment" breadcrumbItem="Stock Transfer Efficency" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <Row>
                  <Col sm="6">
                    <FormGroup className="select2-container">
                      <Label className="control-label">STARTING</Label>
                      <input className="form-control" name="orderStartDate" value={startDate} onChange={(e) => setStartDate(moment(e.target.value).format('YYYY-MM-DD'))} type="date" id="example-date-input" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className="select2-container">
                      <Label className="control-label">ENDING</Label>
                      <input className="form-control" name="orderEndDate" value={endDate} onChange={(e) => setEndDate(moment(e.target.value).format('YYYY-MM-DD'))} type="date" id="example-date-input" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <FormGroup className="select2-container">
                      <Label className="control-label">States</Label>
                      <Select classNamePrefix="select2-selection" value={states} onChange={(val) => setStates(val)} placeholder="All" title="Status" options={stateOptions} isMulti />
                    </FormGroup>
                  </Col>
                </Row>
                {
                  data && (
                    <LineChart data={data} />
                  )
                }
                
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default StockTransferEfficency