import React from 'react';
import SupplyChainNav from '../../../../components/supply-chain/nav';
import CommentHostory from '../../../Skote/Dashboard/CommentHistory';


const ReleasesSupplyChain = () => {
  return (
    <SupplyChainNav
      commentComponent={<CommentHostory title="Comments" initCommentType={'Release'} initOrderName={''} />}

    >
      Releases
    </SupplyChainNav>
  );
}

export default ReleasesSupplyChain;