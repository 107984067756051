import React, { useState, useEffect } from 'react'

class PickList extends React.Component {
  render() {
    return <div> <PickListFunc {...this.props}/> </div>
  }
}

const PickListFunc = ({lineItems, orderNames, monogram}) => {
  const [lineItemCount, setLineItemCount] = useState({})
  useEffect(() => {
    if(lineItems?.length > 0) {
      let count = {}
      lineItems.map(lineItem => {
        let alteredSku = lineItem.sku.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')
        if (lineItem.monogram === true && !monogram) {
          alteredSku = lineItem.monogramId
        }
        count[alteredSku] = count[alteredSku] ? count[alteredSku] + lineItem.quantity : lineItem.quantity
      })
      setLineItemCount(count)
    }
  }, [lineItems]) 

  return <div style={{height: '100vh', padding: '5vh', }}>
    <h1 style={{fontSize: 64}}> PickList </h1>
    {
      Object.keys(lineItemCount).map((key) => {
        const count = lineItemCount[key]
        return <div style={{fontSize: 48}}>
          {key} x {count}
        </div>
      })
    }
    <br/><br/><br/>
    <h2 style={{fontSize: 64}}> Orders </h2>
    {
      orderNames.map(val => <div style={{fontSize: 48}}> {val} </div>)
    }
  </div>
}

export default PickList