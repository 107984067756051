import React from 'react'
import { Card, Col, Row, Table } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { navBarMenu } from './nav-menu';


const SupplyChainNav = ({
  children,
  commentComponent,
  menuType = 'supply-chain',
}) => {

  const user = JSON.parse(localStorage.getItem("authUser"))
  const {permissions} = user
  const navItems = navBarMenu[menuType].filter((nav) =>  {
    if(nav.permission) {
      return permissions.includes(nav.permission)
    }
    return true
  }
  );
  
  const history = useHistory();
  return ( 
    <div className="page-content">
      <Row>
        <Col xs="3" style={{position: 'relative'}}>
          <Card style={{position: 'sticky', top: 128}}>
            <Table>
              {
                navItems.map(val => {
                  const isSelected = window.location.href.includes(val.lookup)
                  return (
                    <tr style={{...(isSelected ? {background: 'black', color: 'white'} : {}), userSelect: 'none', cursor: 'pointer'}} onClick={() => history.push(val.url)}>
                      <td> {val.name}</td> 
                    </tr>
                  )
                })
              }
            </Table>
            {
              commentComponent
            }
          </Card>
        </Col>
        <Col xs="9">
          {children}
        </Col>
      </Row>
    </div>
  );
}
 
export default SupplyChainNav;
