import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import user1 from '../../../assets/images/users/avatar-1.jpg';

class ProfileMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            username: ""
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    onClickProfile = () => {
        // console.log('Hiiiiiii')
        this.props.history.push('/profile');
        // console.log("this.state.adminDate", this.state.adminDetailsData);
        // this.props.history.push({
        //     pathname: RouteRules.adminProfile,
        //     state: {
        //         activeAdmindata: this.state.adminDetailsData
        //     }
        // })
    };

    render() {

        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <img className="rounded-circle header-profile-user" style={{objectFit: 'cover', width: 48, height: 48}} src={this.props.user.profile_image?.replace('.com//', '.com/')} alt="Header Avatar" />
                        <span className="d-none d-xl-inline-block ml-2 mr-1">{this.state.username}</span>
                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem tag="a" href="#" onClick={() => this.onClickProfile()} >
                            <i className="bx bx-user font-size-16 align-middle mr-1"></i>
                            {this.props.t('Profile')}
                        </DropdownItem>
                        {/* <DropdownItem tag="a" href="#">
                            <span className="badge badge-success float-right mt-1">5</span><i className="bx bx-wrench font-size-17 align-middle mr-1"></i>
                            {this.props.t('Settings')}
                        </DropdownItem> */}
                        <div className="dropdown-divider"></div>
                        <Link to="/logout" className="dropdown-item">
                            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                            <span>{this.props.t('Logout')}</span>
                        </Link>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

export default withRouter(withNamespaces()(ProfileMenu));
