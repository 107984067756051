import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { UncontrolledDropdown, FormGroup, Container, Input, Button, Table, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Progress, TabContent, TabPane, NavLink, NavItem} from "reactstrap";
import { CSVLink, CSVDownload } from "react-csv";

import {backendUrl} from '../../../helpers/consts.js'

import { useReactToPrint } from 'react-to-print';

import Select from 'react-select';
import img4 from "../../../assets/images/product/img-4.png";
import img7 from "../../../assets/images/product/img-7.png";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import "../datatables.scss";
import useQuery from '../../../helpers/JIFI/useQuery';
import { effectTypes, select } from "redux-saga/effects";
import moment from 'moment';
import classnames from "classnames";
import superagent from 'superagent'

const perPageOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
]

const ShipBadge = ({status}) => {
  const conversion = {
    'awaiting-delivery': 'warning',
    'delivered': 'success',
    'out-for-delivery': 'secondary',
  }
  const badgeColor =  conversion[status]
  return (
    <Badge className={"font-size-12 badge-soft-" + badgeColor} color={badgeColor} pill>
      {status.split('-').map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' ')}
    </Badge>
  )
}


const SKUDropdown = ({skus}) => {
  const [skuArray, setSkuArray] = useState(skus.split(' ')) 
  const [dropdownOpen, setDropdownOpen] = useState(false);
  useEffect(() => {
    setSkuArray(skus.split(' '))
  }, [skus])

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>
        ▼
      </DropdownToggle>
      <DropdownMenu>
        {
          skuArray.map((sku) => <DropdownItem>{sku}</DropdownItem>)
        }
      </DropdownMenu>
    </Dropdown>
  );

}

const paginate = function (array, index) {
  return [
    array[index - 2],
    array[index - 1],
    array[index],
    array[index + 1],
    array[index + 2]
  ].filter(function (el) {
    return el != null;
  });
}

const TablePagination = ({ count, page, setPage, perPage, setPerPage }) => {
  const pageCount = Math.ceil(count / perPage)
  const pages = paginate(Array.from({ length: pageCount }, (v, i) => i + 1), page)
  const handleNumberClicked = (key) => {
    setPage(key)
  }
  return (
    <Pagination className="pagination pagination-rounded justify-content-end mb-2">
      <div style={{ height: '100%', width: 140 }}>
        <Select
          options={perPageOptions}
          onChange={newPerPage => setPerPage(newPerPage.value)}
          value={perPageOptions.find(val => val.value === perPage)}
        />
      </div>
      <PaginationItem disabled>
        <PaginationLink previous />
      </PaginationItem>
      {
        pages.map(val => (
          <PaginationItem onClick={() => handleNumberClicked(val - 1)} active={val - 1 === page}>
            <PaginationLink>
              {val}
            </PaginationLink>
          </PaginationItem>
        ))
      }
      <PaginationItem>
        <PaginationLink next />
      </PaginationItem>
    </Pagination>
  )
}

const DeliveriesPage = () => {
  const url = '/deliveries/listDeliveries'
  const [stateData, setStateData] = useState({
    searchTerm: '',
    selectedShipStatus: { label: 'Awaiting Delivery', value: 'awaiting-delivery' },
    shipStatusOptions: [{ label: 'Awaiting Delivery', value: 'awaiting-delivery' }, { label: 'Out For Delivery', value: 'out-for-delivery' }, { label: 'Delivered', value: 'delivered' }],

    selectedPickupLocation: { label: 'HQ2', value: 'HQ2' },
    pickupLocationOptions: [{ label: 'HQ2', value: 'HQ2' }, { label: 'EWE', value: 'EWE' }, {label: 'EWE Sydney', value: 'EWE-SYD'}],
  });
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [batchModal, setBatchModal] = useState();
  const toggleBatchModal = () => {
    setBatchModal(prev => !prev)
  }

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
      setRefresh(prev => !prev)
  }
  const [data, state] = useQuery({ refresh, url, queryData: { page: page, perPage: perPage, searchTerm: stateData.searchTerm, shipStatus: stateData.selectedShipStatus?.value, pickupLocation: stateData.selectedPickupLocation?.value } });
  const orders = data && data.data;
  const count = data && data.count;

  const [deleteId, setDeleteId] = useState(null)

  const { searchTerm, selectedShipStatus, shipStatusOptions, selectedPickupLocation, pickupLocationOptions } = stateData
  const handleTermChange = (e) => {
    const newTerm = e.currentTarget.value.toUpperCase().replace('#', '')
    setStateData((prevState => {
      return {
        ...prevState,
        searchTerm: newTerm
      }
    }))
  }
  const handleSelectShipStatus = selectedSshipStatus => {
    console.log(selectedSshipStatus)
    setStateData((prevState => {
      return {
        ...prevState,
        selectedShipStatus: selectedSshipStatus
      }
    }))
  };
  const handleSelectPickupLocation = selectedPickupLocation => {
    console.log(selectedPickupLocation)
    setStateData((prevState => {
      return {
        ...prevState,
        selectedPickupLocation: selectedPickupLocation
      }
    }))
  };

  const [selected, setSelected] = useState([])
  useEffect(() => {
    orders ? setSelected(orders.map(() => false)) : setSelected([]) 
  }, [orders])
  const selectedIds = orders && orders.filter((val, key) => selected[key] === true)

  const handleGenerateBatch = () => {
    if (selectedIds && selectedIds.length > 0){
      toggleBatchModal()
    }
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="11">
              <Breadcrumbs title="Deliveries" breadcrumbItem="All Deliveries" />
            </Col>
            <Col xs="1">
              <Link className="text-danger" to='/deliveries-batch'> Delivery Batches </Link>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div>
                    <FormGroup className="select2-container">
                        <Label className="control-label">SEARCH</Label>
                        <div className="search-box mb-2 d-block">
                          <div className="position-relative">
                            <Input type="text" className="form-control" placeholder="Search..." value={searchTerm} onChange={handleTermChange} />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                      </FormGroup>
                  </div>
                  <Row>
                    <Col xs="4">
                      <FormGroup className="select2-container">
                        <Label className="control-label">PICKUP LOCATION</Label>
                        <Select classNamePrefix="select2-selection" value={selectedPickupLocation} onChange={handleSelectPickupLocation} placeholder="HQ2" title="Pickup Location" options={pickupLocationOptions} />
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup className="select2-container">
                        <Label className="control-label">SHIP STATUS</Label>
                        <Select classNamePrefix="select2-selection" value={selectedShipStatus} onChange={handleSelectShipStatus} placeholder="All" title="Ship Status" options={shipStatusOptions} />
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <Button onClick={handleGenerateBatch} color={!(selectedIds && selectedIds.length > 0) ? 'secondary' : 'success'} style={{marginBottom: '1rem', marginTop: '1.8rem', width: '100%', }} disabled={!(selectedIds && selectedIds.length > 0)}> Create Batch </Button>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <Table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: "20px" }}>
                            <div className="custom-control custom-checkbox">
                              <Input onChange={e => (orders ? setSelected(orders.map(() => e.target.checked)) : setSelected([])) } type="checkbox" className="custom-control-input" id="customCheck1" />
                              <Label className="custom-control-label" htmlFor="customCheck1">&nbsp;</Label>
                            </div>
                          </th>
                          <th>Order ID</th>
                          <th>Ship Status</th>
                          <th>Shipping</th>
                          <th>Region</th>
                          <th>Shipping Name</th>
                          <th>Date</th>
                          <th>SKUs</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          state === 'success' && (
                            orders && orders.map((order, key) =>
                              <tr style={order.flagged ? {background: "#fde1e1"} : {}} key={"_order_" + key}>
                                <td>
                                  <div 
                                    className="custom-control custom-checkbox"
                                    onClick={() => setSelected(prev => {
                                      let newSelected = [...prev]
                                      newSelected[key] = !newSelected[key]
                                      return newSelected
                                    })} 
                                  >
                                    <Input 
                                      type="checkbox" 
                                      className="custom-control-input" 
                                      id={order.id} 
                                      checked={selected[key]} />
                                    <Label className="custom-control-label" htmlFor={order.id}>&nbsp;</Label>
                                  </div>
                                </td>
                                <td>{order.name}</td>
                                <td><ShipBadge status={order.ship_status} /></td>
                                <td>{order.shipping_type}</td>
                                <td>{order?.shipping_address.address1}, {order?.shipping_address?.city}</td>
                                <td>{order?.shipping_address?.name}</td>
                                <td>{(new Date(order.orderCreated)).toDateString()}</td>
                                <td><SKUDropdown skus={order.line_items.map(lineItem => lineItem.sku).join(' ')}/></td>
                                <td>
                                  {
                                    order.ship_status == 'awaiting-delivery' && (
                                      <UncontrolledDropdown>
                                        <DropdownToggle href="#" className="card-drop" tag="i">
                                          <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem onClick={() => { setDeleteId(order._id) }} href="#"> Delete </DropdownItem>
                                          {/* <DropdownItem onClick={() => {  }} href="#"> Edit </DropdownItem> */}
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    )
                                  }
                                </td>
                              </tr>
                            )
                          )
                        }
                      </tbody>
                    </Table>
                  </div>
                  {
                    (orders && state === 'success') ? (
                      <div>

                      </div>
                      // <TablePagination count={count} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
                    ) : (
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                          <div className="spinner-grow text-primary m-1" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>   
      <Modal isOpen={batchModal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" toggle={toggleBatchModal}>
        <Createbatch location={selectedPickupLocation.value}  refresh={refreshData}  togglemodal={toggleBatchModal} selectedIds={selectedIds}/>
      </Modal>
      <DeleteModal deleteId={deleteId} setDeleteId={setDeleteId} refresh={refreshData}/>
    </>
  );
}


const DeleteModal = ({deleteId, setDeleteId, refresh}) => {
  const [loading, setLoading] = useState(false)
  let cancelOpen = deleteId !== null
  const toggleCancelOpen = () => {
    setDeleteId(null)
  }
  const handleDelete = () => {
    setLoading(true)
    superagent.post(`${backendUrl}/deliveries/deleteDelivery`)
      .send({ 
        id: deleteId
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          toggleCancelOpen()
          refresh()
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });

  }
  return (
    <Modal isOpen={cancelOpen} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" toggle={toggleCancelOpen}>
      <ModalBody>
        <h3>CANCELLING DELIVERY</h3>
      </ModalBody>
        {
          loading ? (
            <ModalBody>
              <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                <div className="spinner-grow text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </ModalBody>
          ) : (
            <>
              <ModalBody>
                Are you sure you want to cancel this delivery? (this can't be undone).
              </ModalBody>
              <ModalFooter>
                <Button onClick={handleDelete} color="danger" className="btn-lg btn-rounded">
                  Confirm
                </Button>
              </ModalFooter>
            </>
          )
        }

    </Modal>
  )
}


const Createbatch = ({togglemodal, selectedIds, refresh, location}) => {
  const [loading, setLoading] = useState(true)
  const [downloadedCSV, setDownloadedCSV] = useState(true)
  const [orderData, setOrderData] = useState([])

  const csvHeadersHQ = [
    ["","","","","","","","","","","","","","",""  ],
    ["","Pickup Date:","07-01-21","","WEFLEET BOOKING FORM","","","","","","","","","",""  ],
    ["","","","","","","","","","","","","","",""  ],
    ["","Company Name:","July","","Email file to: bookings+WeFleet@in.cartoncloud.com.au","","","","","","","","","",""  ],
    ["","Address","69 Rupert Street","","","","","","","","","","","",""  ],
    ["","Suburb","Collingwood","","Please be aware that this form is used to import data directly into the system, be sure that information provided is accurate.","","","","","","","","","",""  ],
    ["","Postcode","3066","","","","","","","","","","","",""  ],
    ["","State","VIC","","","","","","","","","","","",""  ],
    ["","","","","","","","","","","","","","",""  ],
    ["","Contact Phone No.","(03) 8001 9009","","","","","","","","","","","",""  ],
    ["","","","","","","","","","","","","","",""  ],
    ["","Contact Name:","Richard","","","","","","","","","","","",""  ],
    ["","","","","","","","","","","","","","",""  ],
    ["","","","","","","","","","","","","","",""  ],
    ["","","","","","","","","","","","","","",""  ],
    ["","Delivery Date","Consignment Reference","Delivery Name","Street Address","Suburb","State","Postcode","Delivery Mobile Phone","Delivery Email","Item","QTY","Weight (Kg)","Cubic","Special Instructions"  ],
  ]

  const csvHeadersEWE = [
    ["","","","","","","","","","","","","","",""  ],
    ["","Pickup Date:","07-01-21","","WEFLEET BOOKING FORM","","","","","","","","","",""  ],
    ["","","","","","","","","","","","","","",""  ],
    ["","Company Name:","July","","Email file to: bookings+WeFleet@in.cartoncloud.com.au","","","","","","","","","",""  ],
    ["","Address","6 Chifley Dr","","","","","","","","","","","",""  ],
    ["","Suburb","Moorabbin Airport","","Please be aware that this form is used to import data directly into the system, be sure that information provided is accurate.","","","","","","","","","",""  ],
    ["","Postcode","3194","","","","","","","","","","","",""  ],
    ["","State","VIC","","","","","","","","","","","",""  ],
    ["","","","","","","","","","","","","","",""  ],
    ["","Contact Phone No.","(03) 8001 9009","","","","","","","","","","","",""  ],
    ["","","","","","","","","","","","","","",""  ],
    ["","Contact Name:","Richard","","","","","","","","","","","",""  ],
    ["","","","","","","","","","","","","","",""  ],
    ["","","","","","","","","","","","","","",""  ],
    ["","","","","","","","","","","","","","",""  ],
    ["","Delivery Date","Consignment Reference","Delivery Name","Street Address","Suburb","State","Postcode","Delivery Mobile Phone","Delivery Email","Item","QTY","Weight (Kg)","Cubic","Special Instructions"  ],
  ]

  const csvHeadersEWESydney = [
    ["","","","","","","","","","","","","","",""  ],
    ["","Pickup Date:","07-01-21","","WEFLEET BOOKING FORM","","","","","","","","","",""  ],
    ["","","","","","","","","","","","","","",""  ],
    ["","Company Name:","July","","Email file to: bookings+WeFleet@in.cartoncloud.com.au","","","","","","","","","",""  ],
    ["","Address","457 Waterloo Rd","","","","","","","","","","","",""  ],
    ["","Suburb","Greenacre","","Please be aware that this form is used to import data directly into the system, be sure that information provided is accurate.","","","","","","","","","",""  ],
    ["","Postcode","2190","","","","","","","","","","","",""  ],
    ["","State","NSW","","","","","","","","","","","",""  ],
    ["","","","","","","","","","","","","","",""  ],
    ["","Contact Phone No.","(03) 8001 9009","","","","","","","","","","","",""  ],
    ["","","","","","","","","","","","","","",""  ],
    ["","Contact Name:","Richard","","","","","","","","","","","",""  ],
    ["","","","","","","","","","","","","","",""  ],
    ["","","","","","","","","","","","","","",""  ],
    ["","","","","","","","","","","","","","",""  ],
    ["","Delivery Date","Consignment Reference","Delivery Name","Street Address","Suburb","State","Postcode","Delivery Mobile Phone","Delivery Email","Item","QTY","Weight (Kg)","Cubic","Special Instructions"  ],
  ]

  const CSVHeaderMap = {
    'HQ2': csvHeadersHQ,
    'EWE': csvHeadersEWE, 
    'EWE-SYD': csvHeadersEWESydney

  }
  
  const csvHeaders = CSVHeaderMap[location]
  const [csvData, setCsvData] = useState([])

  useEffect(() => {
    loadData()
    setDownloadedCSV(false)
  }, [selectedIds])
  
  const loadData = () => {
    setLoading(true)
    superagent.post(`${backendUrl}/deliveries/getBatchList`)
      .send({ 
        ids: selectedIds.map(val => val._id)
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          setOrderData(response.body)
          setCsvData(prev => {
            let data = csvHeaders

            // ["","Delivery Date","Consignment Reference","Delivery Name","Street Address","Suburb","State","Postcode","Delivery Mobile Phone","Delivery Email","Item","QTY","Weight (Kg)","Cubic","Special Instructions"  ],
            // ["","20-11-2021","6555521","Jhon Doe","Unit 1016, 35 Alber Road","Melbourne","VIC","3004","422333444","jhon@email.com","Non Luggage","1","3","","Hand Unload"  ],

            response.body.map(delivery => data.push( [
              "",
              moment(new Date(new Date().setDate(new Date().getDate() + 1))).format('DD-MM-YYYY'),
              delivery.name, // order number
              delivery.shipping_address.name, // name
              `${delivery.shipping_address.address1}${delivery.shipping_address.address2 ? ` ${delivery.shipping_address.address2}` : ''}`,// address
              delivery.shipping_address.city, // city
              delivery.shipping_address.province, // state
              delivery.shipping_address.zip, // postcode
              `${delivery.shipping_address.phone}`, // phone
              delivery.email, // email
              delivery.item, // product type
              delivery.quantity, // quantity
              delivery.weight, // weight
              delivery.cubic, // cubic
              ""
            ]))
            
            return data
          })
          setLoading(false)
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });
  }


  const save = () => {
    setLoading(true)
    superagent.post(`${backendUrl}/deliveries/createDeliveryBatch`)
      .send({ 
        ids: selectedIds.map(val => val._id)
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          togglemodal()
          refresh()
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });

  }

  
  return (
    <div>
      <div className="modal-content">
        <ModalHeader toggle={togglemodal}>
          Confirm Batch 
        </ModalHeader >
        <ModalBody>
          {
            loading ? (
              <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                <div className="spinner-grow text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                  <div className="table-responsive">
                    <Table className="table table-centered table-nowrap">
                      <thead className="thead-light">
                        <tr>
                          <th>Order ID</th>
                          <th>Shipping</th>
                          <th>Region</th>
                          <th>Shipping Name</th>
                          <th>Date</th>
                          <th>SKUs</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          orderData.map((order, key) => 
                            order.line_items.map((lineItem, key) => key === 0 ? (
                              <tr style={order.flagged ? {background: "#fde1e1"} : {}} key={"_order_" + key}>
                                <td>{order.name}</td>
                                <td>{order.shipping_type}</td>
                                <td>{order?.shipping_address?.city}</td>
                                <td>{order?.shipping_address?.name}</td>
                                <td>{(new Date(order.orderCreated)).toDateString()}</td>
                                <td>{lineItem.sku}</td>
                              </tr>
                            ): (
                              <tr style={order.flagged ? {background: "#fde1e1"} : {}} key={"_order_" + key}>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{lineItem.sku}</td>
                              </tr>
                            ))
                          )
                        }
                      </tbody>
                    </Table>
                  </div>

              </>
            )
          }
        </ModalBody>
        <ModalFooter>
          <CSVLink 
            data={csvData}
            filename={`delivery_${(new Date()).toDateString().replace(' ', '-').replace(' ', '-').replace(' ', '-')}-${location}.csv`}
            onClick={() => setDownloadedCSV(true)}
          >
            <Button color="primary"> Download </Button>
          </CSVLink>
          <Button disabled={!downloadedCSV} onClick={save} color="warning"> Confirm </Button>
        </ModalFooter>
      </div>

    </div>
  )
}


export default DeliveriesPage;


