import React from 'react';
import avatar_fallback from '../../../../assets/images/avatar-fallback.svg';


const NotificationItem = ({
  showCheckbox = true,
  url = '',
  notification,
  onCheckboxChange,
  isSelected,
  timeAgo,
}) => {
  return (
    <div className="d-flex align-items-center">
      <a href={!!url ? url : "#"} className="text-reset notification-item flex-grow-1">
        <div className="media position-relative align-items-center ">
          {showCheckbox && (
            <input
              type="checkbox"
              className="mr-2"
              checked={isSelected}
              onChange={() => onCheckboxChange(notification.id)}
            />
          )}

          <img
            src={notification.profileImage ?? avatar_fallback}
            onError={(e) => (e.target.src = avatar_fallback)}
            className="mr-3 rounded-circle avatar-xs"
            alt={`${notification.senderName}-pic`}
          />
          <div className="media-body">
            <h6 className="mt-0 mb-1 d-flex justify-content-between align-items-center">
              {notification.senderName}
              {!notification.read && (
                <span className="badge badge-warning ml-2">Unread</span>
              )}
            </h6>
            <div className="font-size-12 text-muted">
              <p className="mb-1">{notification.commentPlainText}</p>
              <p className="mb-0">
                <i className="mdi mdi-clock-outline"></i>{' '}
                {timeAgo(notification.notificationDate)}
              </p>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default NotificationItem;
