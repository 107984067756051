import React, { Component, Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CustomInput, CardTitle, Input, FormGroup, Label, Button, CardHeader, Media, Table, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useQuery from '../../../helpers/JIFI/useQuery';
import superagent from 'superagent'
import { backendUrl } from '../../../helpers/consts.js'
import ProductVariantSelector from '../helpers/ProductVariantSelector.js';

const generateID = () => {
  return `PARCEl-${Math.trunc(Math.random() * 1000 + 1)}_${Math.trunc(Math.random() * 1000 + 1)}`
}

const ParcelLine = ({ products, parcel, remove, refresh }) => {
  const [productId, setProductId] = useState(parcel.productId)
  const [description, setDescription] = useState(parcel.description)
  const [price, setPrice] = useState(parcel.price)
  const [length, setLength] = useState(parcel.length)
  const [width, setWidth] = useState(parcel.width)
  const [depth, setDepth] = useState(parcel.depth)
  const [weight, setWeight] = useState(parcel.weight)

  let modified = (
    productId !== parcel.productId ||
    description !== parcel.description ||
    price !== parcel.price ||
    length !== parcel.length ||
    width !== parcel.width ||
    depth !== parcel.depth ||
    weight !== parcel.weight
  )

  useEffect(() => {
    if (!modified) {
      setProductId(parcel.productId)
      setDescription(parcel.description)
      setPrice(parcel.price)
      setLength(parcel.length)
      setWidth(parcel.width)
      setDepth(parcel.depth)
      setWeight(parcel.weight)
    }
  }, [parcel])

  const handleSave = () => {
    if (parcel.new === true) {
      superagent.post(`${backendUrl}/orders/createParcel/`)
        .send({
          product: productId,
          parcel_description: description,
          declared_price: Number(price),
          length: Number(length),
          width: Number(width),
          depth: Number(depth),
          weight: Number(weight)
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
          console.log(response)
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
            refresh()
          }
        }).catch(err => {
          console.log(err)
        });
    } else {
      superagent.post(`${backendUrl}/orders/editParcel/${parcel._id}/`)
        .send({
          product: productId,
          parcel_description: description,
          declared_price: Number(price),
          length: Number(length),
          width: Number(width),
          depth: Number(depth),
          weight: Number(weight)
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
          console.log(response)
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
            refresh()
          }
        }).catch(err => {
          console.log(err)
        });

    }
  }

  const handleDelete = () => {
    if (parcel.new === true) {
      remove()
    } else {
      superagent.post(`${backendUrl}/orders/deleteParcel/${parcel._id}/`)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
          console.log(response)
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
            refresh()
          }
        }).catch(err => {
          console.log(err)
        });
    }
  }

  const volume = parseFloat(((length / 100 * width / 100 * depth / 100) || 0)).toFixed(4);
  return (
    <>
      <tr>
        <th scope="row">
          <ProductVariantSelector products={products} setSelectedProduct={setProductId} selectedProduct={productId} />
        </th>
        <th scope="row">
          <Input placeholder="Description" className="mb-2" type="text" value={description} onChange={(e) => { setDescription(e.target.value) }} />
        </th>
        <th scope="row">
          <InputGroup>
            <InputGroupAddon addonType="prepend">$</InputGroupAddon>
            <Input type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
          </InputGroup>
        </th>
        <th scope="row">
          <InputGroup>
            <Input type="number" value={length} onChange={(e) => setLength(e.target.value)} />
            <InputGroupAddon addonType="append">Cm</InputGroupAddon>
          </InputGroup>
        </th>
        <th scope="row">
          <InputGroup>
            <Input type="number" value={width} onChange={(e) => setWidth(e.target.value)} />
            <InputGroupAddon addonType="append">Cm</InputGroupAddon>
          </InputGroup>
        </th>
        <th scope="row">
          <InputGroup>
            <Input type="number" value={depth} onChange={(e) => setDepth(e.target.value)} />
            <InputGroupAddon addonType="append">Cm</InputGroupAddon>
          </InputGroup>
        </th>
        <th scope="row">
          <InputGroup>
            <Input value={volume} disabled/>
            <InputGroupAddon addonType="append">m³</InputGroupAddon>
          </InputGroup>
        </th>
        <th scope="row">
          <InputGroup>
            <Input type="number" value={weight} onChange={(e) => setWeight(e.target.value)} />
            <InputGroupAddon addonType="append">Kg</InputGroupAddon>
          </InputGroup>
        </th>
        <th scopr="row">
          {
            (modified || parcel.new === true) && <i style={{ fontSize: '32px', cursor: 'pointer' }} onClick={handleSave} className="bx bx-save text-success" />
          }
        </th>
        <th scopr="row">
          <i style={{ fontSize: '32px', cursor: 'pointer' }} onClick={handleDelete} className="bx bx-trash-alt text-danger" />
        </th>
      </tr>
    </>
  )
}

const ParcelsPage = () => {
  const [refresh, setRefresh] = useState(false)
  const r = () => setRefresh(prev => !prev)
  const [parcels, setParcels] = useState([])
  const [dataProduct, stateProduct] = useQuery({
    url: `/product`,
    refresh
  });
  const [dataParcel, stateParcel] = useQuery({
    url: `/orders/fetchAllParcels`,
    refresh
  });
  const products = dataProduct && dataProduct.data
  useEffect(() => {
    if (dataParcel) {
      setParcels(dataParcel.map(val => ({
        _id: val._id,
        productId: val.product._id,
        description: val.parcel_description,
        price: val.declared_price,
        length: val.length,
        width: val.width,
        depth: val.depth,
        weight: val.weight,
        sortVolumetric: val.length * val.width * val.depth
      })).sort((a, b) => a.sortVolumetric - b.sortVolumetric))
    }
  }, [dataParcel])

  const handleRemove = (removeKey) => {
    console.log(removeKey)
    setParcels(prev => {
      let updatedParcels = [...prev]
      console.log(updatedParcels.filter((value, key) => removeKey !== key))
      return updatedParcels.filter((value, key) => removeKey !== key)
    })
  }

  const loading = stateParcel === 'loading' || stateProduct === 'loading'

  const getInitParcel = () => {
    return {
      productId: products[0]._id,
      description: '',
      price: 0,
      length: 0,
      width: 0,
      depth: 0,
      weight: 0,
      new: true
    }
  }

  const createNewParcel = () => {
    setParcels(prev => {
      let updatedParcels = [...prev]
      updatedParcels.push(getInitParcel())
      return updatedParcels
    })
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Setting" breadcrumbItem="Parcels" />
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h4 className="card-title mb-4"></h4>
                    <div>
                      {
                        loading === false && (
                          <>
                            <Button color="success" onClick={createNewParcel}> Create </Button>
                          </>
                        )
                      }
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  {
                    loading === false ? (
                      <Table className="table mb-0">
                        <thead style={{ position: 'sticky', top: 123, background: 'white', }}>
                          <tr>
                            <th>Product</th>
                            <th>Description</th>
                            <th>Declared Price</th>
                            <th>Length</th>
                            <th>Width</th>
                            <th>Depth</th>
                            <th>Volume</th>
                            <th>Weight</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            parcels.map((parcel, key) => <ParcelLine refresh={() => { setParcels([]); r(); }} remove={() => handleRemove(key)} parcel={parcel} products={products} />)
                          }
                        </tbody>
                      </Table>
                    ) : (
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="spinner-border text-primary m-1" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ParcelsPage