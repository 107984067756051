import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, FormGroup, Input, Card, CardBody, CardTitle, CardSubtitle, Button, Label, Collapse } from "reactstrap";
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import useQuery from '../../../helpers/JIFI/useQuery';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import CardProduct from "./card-product";

import superagent from 'superagent'
import {backendUrl} from '../../../helpers/consts.js'
import { useHistory } from "react-router-dom";


import { toast } from "react-toastify";
import { uploadFileS3Bucket } from "../../../utilities/uploadFileS3Bucket";

const categories =  [
  'CLASSIC',
  'LIGHT',
  'TRUNK',
  'SET',
  'BAG',
  'ACCESSORIES',
  'MISC',
]

const ProductListPage  = ({
  match: {
    params
  },
}) => {
  const history = useHistory();
  const { productID } = params

  const [productName, setProductName] = useState('')
  const [productImage, setProductImage] = useState('')

  const [productCategory, setProductCategory] = useState('')

  const [productLength, setProductLength] = useState(0)
  const [productWidth, setProductWidth] = useState(0)
  const [productDepth, setProductDepth] = useState(0)

  const [productWarning, setProductWarning] = useState(5)

  const [productWeight, setProductWeight] = useState(0)
  const [isArchived, setIsArchived] = useState()

  const [productFactory, setProductFactory] = useState('')
  const [productMonthCover, setProductMonthCover] = useState(0)

  const [factoryOptions, setFactoryOptions] = useState([])

  const [refresh, setRefresh] = useState(false)
  const refreshData = () => setRefresh(prev => !prev)

  const [product, state] = useQuery({
    refresh,
    url: `/product/${productID}`
  });


  useEffect(() => {
    if (product != undefined) {
      setProductName(product.name)
      setProductImage(product.image)

      setProductLength(product.length)
      setProductDepth(product.depth)
      setProductWidth(product.width)
      setProductWeight(product.weight)
      setProductMonthCover(product.monthCover)
      setProductWarning(product.warning_amount)
      setIsArchived(product.isArchived)
      setProductCategory(product.category)

      if (factoryOptions.length > 0 && product.factory !== '' && product.factory !== undefined) {
        let fac = factoryOptions.find(val => `${val._id}` === `${product.factory}`)
        if (fac !== undefined) {
          setProductFactory({
            value: product.factory,
            label: fac.name
          })
        }
      } else {
        setProductFactory({value: product.factory})
      }
    }
  }, [product])

  useEffect(() => {
    superagent.post(`${backendUrl}/product/productFactories`)
      .send()
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
          const code = response.status
          history.replace(history.location.pathname, {
              errorStatusCode: code
          });
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
            history.replace(history.location.pathname, {
              errorStatusCode: code
            });
        } else {
          setFactoryOptions(response.body)
          setProductFactory(prev => {
            console.log(prev)
            if (prev !== '' && prev.value !== undefined){
              return ({
                value: prev.value,
                label: response.body.find(val => `${val._id}` === prev.value).name
              })
            }
            return prev
          })
          
        }
      }).catch(err => {
        console.log(err)
      });
  }, [])

  const handleIsArchived = () => {
    setIsArchived(prev => !prev)
  }

  const handleAcceptedFiles = async (files) => {
    const cancel = !files.length;
    if (cancel) return;
    let images = await Promise.all(files.map(file => uploadFileS3Bucket(file, `comment_file_${Date.now()}`)))

    superagent.put(`${backendUrl}/product/addImages/${productID}`)
      .send({ images: images })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
          const code = response.status
          history.replace(history.location.pathname, {
              errorStatusCode: code
          });
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
            history.replace(history.location.pathname, {
              errorStatusCode: code
            });
        } else {
          history.refresh()
        }
      }).catch(err => {
        console.log(err)
      });

  }

  const updateProduct = (e, image) => {
    e.preventDefault()
    if (image) {
      setProductImage(image)
    }

    if (product == undefined) {
      superagent.post(`${backendUrl}/product`)
        .send({ 
          name: productName, 
          image: image || productImage,
          length: productLength,
          width: productWidth,
          depth: productDepth,
          weight: productWeight,
          monthCover: productMonthCover,
          factory: productFactory.value,
          warning_amount: productWarning,
          isArchived: isArchived,
          category: productCategory
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
            const code = response.status
            history.replace(history.location.pathname, {
                errorStatusCode: code
            });
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
              history.replace(history.location.pathname, {
                errorStatusCode: code
              });
          } else {
            history.push(`/product/edit/${response.body._id}`)
          }
        }).catch(err => {
          console.log(err)
        });
    } else {
      superagent.put(`${backendUrl}/product/${productID}`)
        .send({ 
          name: productName, 
          image: image || productImage,
          length: productLength,
          width: productWidth,
          depth: productDepth,
          weight: productWeight,
          monthCover: productMonthCover,
          factory: productFactory.value,
          warning_amount: productWarning,
          isArchived: isArchived,
          category: productCategory
        })
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
            const code = response.status
            history.replace(history.location.pathname, {
                errorStatusCode: code
            });
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
              history.replace(history.location.pathname, {
                errorStatusCode: code
              });
          } else {
            history.refresh()
          }
        }).catch(err => {
          console.log(err)
        });
    }
  }

  const [imagesOpen, setimagesOpen] = useState(false)

  return (
    <>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Ecommerce" breadcrumbItem={productID != undefined ? 'Edit Product' : 'New Product'} />
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <CardTitle>Product Information</CardTitle>
                            <Form>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label htmlFor="productname">Product Name</Label>
                                            <Input id="productname" name="productname" type="text" className="form-control" value={productName} onChange={(e) => setProductName(e.target.value)} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor="productwarning">Warning Amount</Label>
                                            <Input id="productwarning" name="productwarning" type="number" className="form-control"  value={productWarning} onChange={(e) => setProductWarning(e.target.value)}/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor="isArchived">Archived</Label>
                                            <Input id="isArchived" name="isArchived" className="form-control" type="checkbox" onChange={handleIsArchived} checked={isArchived} style={{
                                              width: 'calc(6.5em + 1rem + 2px)', height: '7%',
                                            }}/>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                      <Row>
                                        <Col sm="4">
                                          <FormGroup>
                                              <Label htmlFor="productlength">Length (cm)</Label>
                                              <Input id="productlength" name="productlength" type="number" className="form-control"  value={productLength} onChange={(e) => setProductLength(e.target.value)}/>
                                          </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                          <FormGroup>
                                              <Label htmlFor="productwidth">Width (cm)</Label>
                                              <Input id="productwidth" name="productwidth" type="number" className="form-control"  value={productWidth} onChange={(e) => setProductWidth(e.target.value)}/>
                                          </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                          <FormGroup>
                                              <Label htmlFor="productdepth">Depth (cm)</Label>
                                              <Input id="productdepth" name="productdepth" type="number" className="form-control"  value={productDepth} onChange={(e) => setProductDepth(e.target.value)}/>
                                          </FormGroup>
                                        </Col>
                                        <Col sm="12">
                                          <FormGroup>
                                              <Label htmlFor="productdepth">Weight (kg)</Label>
                                              <Input id="productdepth" name="productdepth" type="number" className="form-control"  value={productWeight} onChange={(e) => setProductWeight(e.target.value)} />
                                          </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                          <FormGroup className="select2-container">
                                            <Label className="control-label">Factory</Label>
                                            <Select 
                                              options={factoryOptions.map(val => ({value: val._id, label: val.name}))}
                                              value={productFactory}
                                              onChange={(selected) => setProductFactory(selected)}
                                              placeholder="All" 
                                              title="Factory" 
                                              classNamePrefix="select2-selection" 
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                          <FormGroup className="select2-container">
                                            <Label className="control-label">Category</Label>
                                            <Select 
                                              options={categories.map(val => ({value: val, label: val}))}
                                              value={{label: productCategory, value: productCategory}}
                                              onChange={(selected) => setProductCategory(selected.value)}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                          <FormGroup>
                                              <Label htmlFor="productmonthcover">Months Stock Cover</Label>
                                              <Input id="productmonthcover" name="productmonthcover" type="number" step='1' className="form-control"  value={productMonthCover} onChange={(e) => setProductMonthCover(e.target.value)} />
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                        
                                    </Col>
                                </Row>
                                <Button onClick={updateProduct} type="submit" color="primary" className="mr-1 waves-effect waves-light">Save Changes</Button>
                            </Form>

                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <CardTitle>Variants</CardTitle>
                            <Link to={`/product/edit/${productID}/variant/new`}>
                              <div className="text-sm-right">
                                <Button type="button" color="success" className="btn-rounded waves-effect waves-light mb-2 mr-2"><i className="mdi mdi-plus mr-1"></i> Create new</Button>
                              </div>
                            </Link>
                            <Row>
                              {
                                product && product.variants.map((variant, key) => (
                                  <Col xl="2" sm="2" key={`_variant_${key}`}>
                                    <Link className="shadow-sm rounded border border-light w-100 h-100 flex d-flex flex-column justify-content-center align-items-center" to={`/product/edit/${productID}/variant/edit/${variant._id}`}>
                                      <img className="w-100 h-100" src={variant.image}/>
                                      <span className='text-dark mb-2'>{variant.sku}</span>
                                    </Link>
                                  </Col>
                                ))
                              }
                            </Row>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                          <CardTitle style={{cursor: 'pointer'}} onClick={() => setimagesOpen(prev => !prev)} className="mb-3">Product Images</CardTitle>
                          <Collapse isOpen={imagesOpen}>
                            <div  className="p-3 mb-3 bg-light rounded">
                              <Row>
                                {
                                  product && product.files && product.files.map((image, key) => (
                                    <Col xl="2" key={`_product_image_${key}`} onClick={(e) => { updateProduct(e, image)}}>
                                      <img className={`rounded ${image == productImage ? 'border border-success' : ''}`} style={{width: '100%', }} src={image}/>
                                    </Col>
                                  ))
                                }
                              </Row>
                            </div>
                            <Form>
                                <Dropzone
                                    onDrop={acceptedFiles =>
                                        handleAcceptedFiles(acceptedFiles)
                                    }
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                            <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <div className="dz-message needsclick">
                                                    <div className="mb-3">
                                                        <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                                    </div>
                                                    <h4>Drop files here or click to upload.</h4>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </Form>
                          </Collapse>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
      </div>
    </>
  )
}

export default ProductListPage