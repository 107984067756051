
import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { Input } from "reactstrap";
import {
	CHANGE_LOCATION
} from "../../../store/layout/actionTypes";

import useQuery from '../../../helpers/JIFI/useQuery';

const LocationSelector = ({parentSelected, parentSetSelected}) => {
  const dispatch = useDispatch()

  let selectedLocation = useSelector(state => state.Layout.location)
  let changeLocation = (value) => {
    dispatch({ type: CHANGE_LOCATION, payload: value })
  }

  let selected = selectedLocation
  let setSelected = changeLocation
  if (parentSelected !== undefined && parentSetSelected !== undefined) {
    selected = parentSelected
    setSelected = parentSetSelected
  }

  const [locations, state] = useQuery({
    url: `/inventory/getLocations`
  })

  return <>
    <Input type="select" style={{width: '100%'}}  name="location" id="location" onChange={e => setSelected(e.target.value)}>
      {
        locations ? (
          locations.map(val => <option selected={selected === val.name} value={val.name}>{val.display_name}</option>)
        ) : (
          <option selected="true" value={'HQ2'}>HQ2</option>
        )
      }
    </Input>
  </>
}

export default LocationSelector