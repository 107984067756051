import { Country, State } from 'country-state-city'; // Assuming you have a library for handling country and state information
import React, { useEffect, useState } from 'react';
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Button, ModalBody, ModalFooter, Input } from 'reactstrap'; // Assuming you are using Reactstrap for styling
import superagent from 'superagent'; // Make sure to import superagent or replace it with your preferred HTTP client
import WizardNav from './WizardNav'; // Import WizardNav component if it exists
import { backendUrl } from '../../../../helpers/consts';
import AsyncSelect from 'react-select/async';

const OrderWizardEditModalShipping = ({ togglemodal, refresh, order, orderID }) => {
    const apiKey = 'AIzaSyACNNxCG6Oc9WaFfFslSu3jv5qJApU9PpI';
    const {
        placePredictions,
        getPlacePredictions,
    } = useGoogle({
        apiKey: apiKey,
        options: {
            fields: ["address_component", "types", "geocode"],
            types: ["geocode"],
        },
    });

    useEffect(() => {
        const loaded = new CustomEvent('loadedPlaced', { 'detail': placePredictions.map(placeLine => ({ label: placeLine.description, value: placeLine })) });
        document.dispatchEvent(loaded);
    }, [placePredictions]);

    const [selected, setSelected] = useState(null);

    const getPlaceData = async (placeID) => {
        return await new Promise((res, rej) => {
            const map = new window.google.maps.Map(document.getElementById('test-map'), { zoom: 15 });
            var request = {
                placeId: placeID,
                fields: ['address_components'],
            };

            const service = new window.google.maps.places.PlacesService(map);
            service.getDetails(request, callback);

            function callback(place, status) {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    res(place);
                } else {
                    rej(place);
                }
            }
        });
    };

    const loadOptions = (
        inputValue,
        callback
    ) => {
        getPlacePredictions({ input: inputValue });
        document.addEventListener("loadedPlaced", function (e) {
            callback(e.detail);
        });
    };

    const [loading, setLoading] = useState(false);

    const [shippingType, setShippingType] = useState(order.shipping_type);
    const handleShippingTypeChange = (e) => {
        setShippingType(e.target.value);
    };

    const [name, setName] = useState(order.shipping_address.name);
    const [email, setEmail] = useState(order.email);
    const [company, setCompany] = useState(order.shipping_address.company);
    const [address1, setAddress1] = useState(order.shipping_address.address1);
    const [address2, setAddress2] = useState(order.shipping_address.address2);
    const [city, setCity] = useState(order.shipping_address.city);
    const [zip, setZip] = useState(order.shipping_address.zip);
    const [phone, setPhone] = useState(order.shipping_address.phone);

    const [selectedState, setState] = useState(State.getStatesOfCountry(Country.getAllCountries().find(val => val.name === order.shipping_address.country)?.isoCode)?.find(val => val.name === order.shipping_address.province)?.isoCode);
    const [selectedCountry, setCountry] = useState(Country.getAllCountries().find(val => val.name === order.shipping_address.country)?.isoCode);

    const handleStateChange = (e) => {
        setState(e.target.value)
    }

    const handleCountryChange = (e) => {
        setCountry(e.target.value);
        let newState;
        try {
            newState = State.getStatesOfCountry(e.target.value)[0].isoCode;
        } catch { }
        setState(newState);
    };

    useEffect(() => {
        if (selected) {
            getPlaceData(selected.value.place_id).then(res => {
                let items = res.address_components;
                let streetNumberGoogleAPI, streetAddressGoogleAPI, countryGoogleAPI,
                    stateGoogleAPI, unitNoGoogleAPI, suburnGoogleAPI, postcodeGoogleAPI;

                items.map((item) => {
                    if (item.types.includes('subpremise')) {
                        unitNoGoogleAPI = item.long_name;
                    }
                    if (item.types.includes('street_number')) {
                        streetNumberGoogleAPI = item.long_name;
                    }
                    if (item.types.includes('route')) {
                        streetAddressGoogleAPI = item.long_name;
                    }
                    if (item.types.includes('postal_code')) {
                        postcodeGoogleAPI = item.long_name;
                    }
                    if (item.types.includes('locality')) {
                        suburnGoogleAPI = item.long_name;
                    }
                    if (item.types.includes('administrative_area_level_1')) {
                        stateGoogleAPI = item.long_name;
                    }
                    if (item.types.includes('country')) {
                        countryGoogleAPI = item.long_name;
                    }
                });

                setCountry(Country.getAllCountries().find(val => val.name === countryGoogleAPI)?.isoCode);
                setState(State.getStatesOfCountry(Country.getAllCountries().find(val => val.name === countryGoogleAPI)?.isoCode)?.find(val => val.name === stateGoogleAPI)?.isoCode);
                setCity(suburnGoogleAPI === undefined ? '' : suburnGoogleAPI);
                setZip(postcodeGoogleAPI === undefined ? '' : postcodeGoogleAPI);
                setAddress2('');
                if (streetAddressGoogleAPI) {
                    if (streetNumberGoogleAPI) {
                        if (unitNoGoogleAPI) {
                            setAddress1(unitNoGoogleAPI + '/' + streetNumberGoogleAPI + ' ' + streetAddressGoogleAPI);
                        } else {
                            setAddress1(streetNumberGoogleAPI + ' ' + streetAddressGoogleAPI);
                        }
                    } else {
                        setAddress1(streetAddressGoogleAPI);
                    }
                } else {
                    setAddress1('');
                }
                setSelected('');
            });
        }
    }, [selected]);

    const handleSave = () => {
        const exportData = {
            shippingType,
            name,
            email,
            company,
            address1,
            address2,
            city,
            zip,
            phone,
            "province": State.getStateByCodeAndCountry(selectedState, selectedCountry).name,
            "country": Country.getCountryByCode(selectedCountry).name,
        };

        setLoading(true);
        superagent.post(`${backendUrl}/orders/${order._id}/editShipping/`)
            .send(exportData)
            .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
            .set('accept', 'json')
            .on('error', (response) => {
                setLoading(false);
            })
            .then(response => {
                const code = response.status;
                if (code > 400) {
                    // Handle error
                } else {
                    refresh();
                    togglemodal();
                    setLoading(false);
                }
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    };

    const makeUnique = () => {
        setLoading(true);
        superagent.post(`${backendUrl}/orders/${order._id}/makeShippingUnique/`)
            .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
            .set('accept', 'json')
            .on('error', (response) => {
                setLoading(false);
            })
            .then(response => {
                const code = response.status;
                if (code > 400) {
                    // Handle error
                } else {
                    refresh();
                    togglemodal();
                    setLoading(false);
                }
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    };

    return (
        <div>
            <WizardNav steps={['Edit Shipping Address']} activeTabProgress={1} />

            {loading === true ? (
                <div>
                    <div>
                        <ModalBody>
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-primary m-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </ModalBody>
                    </div>
                </div>
            ) : (
                <div>
                    <div style={{ display: 'none' }}><div id="test-map" /></div>
                    <div>
                        <ModalBody>
                            <div className="form-group row">
                                <label htmlFor="example-text-input" className="col-md-2 col-form-label">Shipping Type</label>
                                <div className="col-md-10">
                                    <Input onChange={handleShippingTypeChange} type="select" name="select" id="exampleSelect">
                                        <option selected={shippingType === 'standard'} value="standard"> Standard </option>
                                        <option selected={shippingType === 'express'} value="express"> Express </option>
                                        <option selected={shippingType === 'pickup'} value="pickup"> QV Pickup </option>
                                        <option selected={shippingType === 'pickup_hq'} value="pickup_hq"> HQ Pickup </option>
                                        <option selected={shippingType === 'Uber'} value="Uber"> Uber </option>
                                        <option selected={shippingType === 'Overnight'} value="overnight"> Overnight </option>
                                    </Input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-text-input" className="col-md-2 col-form-label">Name</label>
                                <div className="col-md-10">
                                    <input onChange={e => setName(e.target.value)} className="form-control" type="text" defaultValue={order.shipping_address.name} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-text-input" className="col-md-2 col-form-label">Email</label>
                                <div className="col-md-10">
                                    <input onChange={e => setEmail(e.target.value)} className="form-control" type="text" defaultValue={order.email} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-text-input" className="col-md-2 col-form-label">Phone</label>
                                <div className="col-md-10">
                                    <input onChange={e => setPhone(e.target.value)} className="form-control" type="text" defaultValue={order.shipping_address.phone} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-text-input" className="col-md-2 col-form-label">Company</label>
                                <div className="col-md-10">
                                    <input onChange={e => setCompany(e.target.value)} className="form-control" type="text" defaultValue={order.shipping_address.company} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-text-input" className="col-md-2 col-form-label">Search Address</label>
                                <div className="col-md-10">
                                    <AsyncSelect onClick={setSelected} onChange={setSelected} cacheOptions loadOptions={loadOptions} defaultOptions />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-text-input" className="col-md-2 col-form-label">Country</label>
                                <div className="col-md-10">
                                    <Input onChange={e => handleCountryChange(e)} type="select" name="select" id="exampleSelect">
                                        {
                                            Country.getAllCountries().map(country => <option value={country.isoCode} selected={selectedCountry === country.isoCode} > {country.name} </option>)
                                        }
                                    </Input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-text-input" className="col-md-2 col-form-label">State</label>
                                <div className="col-md-10">
                                    <Input onChange={e => setState(e.target.value)} type="select" name="select" id="exampleSelect">
                                        {
                                            State.getStatesOfCountry(selectedCountry).map(state => <option value={state.isoCode} selected={selectedState === state.isoCode} > {state.name} </option>)
                                        }
                                    </Input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-text-input" className="col-md-2 col-form-label">Address</label>
                                <div className="col-md-10">
                                    <input onChange={e => setAddress1(e.target.value)} className="form-control" type="text" value={address1} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-text-input" className="col-md-2 col-form-label">Suburb</label>
                                <div className="col-md-10">
                                    <input onChange={e => setCity(e.target.value)} className="form-control" type="text" value={city} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-text-input" className="col-md-2 col-form-label">Postcode</label>
                                <div className="col-md-10">
                                    <input onChange={e => setZip(e.target.value)} className="form-control" type="text" value={zip} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-text-input" className="col-md-2 col-form-label">Apartment, suite, etc. (optional)</label>
                                <div className="col-md-10">
                                    <input onChange={e => setAddress2(e.target.value)} className="form-control" type="text" value={address2} />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={makeUnique}> Make Unique </Button>
                            <Button color="success" onClick={handleSave}> Save </Button>
                        </ModalFooter>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrderWizardEditModalShipping;
