import React from 'react'

import FontFile from '../shared/font-file'

const Word = (props) => {
  return (
    <FontFile font={'serif'} multiplier={1} topOffset="0.00em" {...props} />
  )
}

export default Word
