import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import superagent from 'superagent'

import {backendUrl} from '../../helpers/consts.js'
// import {backendUrl} from '../../helpers/consts.js'

const makeid = (length) => {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const getRequestID = () => {
  return makeid(16)
}

const useQuery = ({ url, queryData, refresh }) => {
  const history = useHistory();
  const [apiData, setApiData] = useState();
  const [state, setState] = useState('loading');

  const [requestID, setRequestID] = useState()

  let param = '?';
  var paramsData = queryData && Object.entries(queryData).filter(([key, value], i) => !!value).map(([key, value], i) => {
    return value && `${[key]}=${value}&`
  });
  let params = param.concat(paramsData && paramsData.join(''));
  useEffect(() => {
    const newReqID = getRequestID()
    setRequestID(newReqID)
    setState('loading')

    let preUrl = `${backendUrl}${url}${params.length > 1 ? params : ''}`
    if (url?.includes('https://')) {
      preUrl = url
    }

    superagent.get(`${preUrl}`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status
        history.replace(history.location.pathname, {
          errorStatusCode: code
        });
      })
      .then(response => {
        setRequestID(prev => {
          if (prev === newReqID) {
            const code = response.status
            if (code > 400) {
              history.replace(history.location.pathname, {
                errorStatusCode: code
              });
            } else {
              setState('success')
              setApiData(response.body);
            }  
          } 
          return prev
        })
      }).catch(err => {
        console.log(err)
      });
  }, [url, history, params, refresh]);

  return [apiData, state];
};

export default useQuery;
