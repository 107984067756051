import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import superagent from 'superagent';
// Import images

import useQuery from '../../../helpers/JIFI/useQuery';
import avatar_fallback from '../../../assets/images/avatar-fallback.svg';

import useUserStore from '../../../zustand/useUserStore';
import { backendUrl } from '../../../helpers/consts';
import { useHistory } from 'react-router-dom';

const NotificationDropdown = () => {
  const history = useHistory();
  const [menu, setMenu] = useState(false);
  const [refresh, setRefresh] = useState(false);
  // Notification data

  const [notifications, notificationState] = useQuery({
    url: '/notifications',
    refresh: refresh,
  });

  const fetchUserList = useUserStore((state) => state.fetchUserList);

  useEffect(() => {
    fetchUserList();
  }, []);

  const timeAgo = (date) => {
    const now = new Date();
    const diffInMilliseconds = now - new Date(date);
    const diffInMinutes = Math.floor(diffInMilliseconds / 60000);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
    } else {
      return `${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
    }
  };

  const toggle = () => {
    setMenu(!menu);
  };

  const markSelectedAsRead = (id) => {
    superagent
      .post(`${backendUrl}/notifications/mark-as-read`)
      .send({ ids: [id] }) // Send the list of selected notification IDs
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        } else {
          setRefresh(!refresh);
        }
      })
      .catch((err) => {
        const errorMessage =
          err.response?.body?.message || err.message || 'Internal server error';
      });
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada"></i>
          {notifications?.length && notifications?.filter(
                  (notification) => notification.read === false
                ).length > 0 && (
            <span className="badge badge-danger badge-pill">
              {
                notifications?.filter(
                  (notification) => notification.read === false
                ).length
              }
            </span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">Notifications</h6>
              </Col>
              <div className="col-auto">
                <a href="/notifications/inbox" className="small">
                  {' '}
                  View All
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: '230px' }}>
            {notifications?.map((notification) => (
              <a
                href={notification.url ?? '#'}
                onClick={() => markSelectedAsRead(notification.id)}
                className="text-reset notification-item"
                key={notification.id}
              >
                <div className="media">
                  <img
                    onError={(e) => (e.currentTarget.src = avatar_fallback)}
                    src={notification.profileImage ?? avatar_fallback}
                    className="mr-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />

                  <div className="media-body">
                    <h6 className="mt-0 mb-1 d-flex justify-content-between align-items-center">
                      {notification.senderName}
                      {!notification.read && (
                        <span className="badge badge-warning ml-2">Unread</span>
                      )}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">{notification.commentPlainText}</p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline"></i>{' '}
                        {timeAgo(notification.notificationDate)}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </SimpleBar>

          <div className="p-2 border-top">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="/notifications/inbox"
            >
              View all
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
