import Grapheme from 'graphemer'
import React, { useEffect, useRef, useState } from 'react'

import Block from './block'

const splitter = new Grapheme()

export const newColors = {
  'Lavender': '#90759B',
  'Lilac': '#DCC5DF',
  'Ballet-Pink': '#F1C9DE',
  'Rose-Pink': '#DC75A4',
  'Grenaine': '#9E5360',
  'Blush-Pink': '#E77472',
  'Magenta': '#B12D52',
  'Maroon': '#731A29',
  'Red': '#C92327',
  'Burnt-Orange': '#DC5527',
  'Mango': '#EF7E2E',
  'Rust': '#9B4827',
  'Toffee': '#705037',
  'Taupe': '#D3A289',
  'Butter-Yellow': '#FBF3BE',
  'Yellow': '#E8B629',
  'Lime': '#C6B937',
  'Green': '#4A813C',
  'Forest-Green': '#034123',
  'Mint': '#78AB93',
  'Ice-Blue': '#BEE3E9',
  'Denim': '#75A6B3',
  'Ocean-Blue': '#3E8EA1',
  'Blue': '#1466B2',
  'Grey': '#95979E',
  'Slate-Grey': '#63727E',
  'Black': '#000000',
  White: '#FFFFFF',
}

const textTransform = (text, font) => {
  if (font === 'block') {
    return text.toUpperCase()
  }
  return text
}

const BlockLetter = ({
  mainCol,
  backCol,
  letter,
  fontSize,
  forwardKearning,
  backKearning,
  frontCurveKerning,
  small,
  size,
  letterKey,
}) => {
  const isSmall = small === true || small === 'true'
  const Letter = Block(
    newColors[mainCol],
    newColors[backCol],
    letter,
    isSmall ? fontSize * 0.68 : fontSize
  )
  const sRatio = isSmall ? 0.68 : 1
  // const Letter = Block(forwardKearning ? 'red' : 'white', backKearning ? 'red' : 'white', letter, small === 'true'? 84 * 0.68 : 84)
  const marginLeft =
    (backKearning
      ? '-0.2em'
      : forwardKearning
      ? '0.02em'
      : frontCurveKerning
      ? '0.015em'
      : '0.02em') * sRatio

  if (typeof Letter === 'object') {
    return (
      <span
        className="BlockLetter"
        style={{
          marginLeft,
        }}
      >
        {Letter}
      </span>
    )
  }

  return (
    <span
      className="BlockLetter"
      style={{
        marginLeft,
        ...(letterKey === 0 ? { marginLeft: 0 } : {}),
        fontSize: fontSize,
        height: '1em',
      }}
      dangerouslySetInnerHTML={{ __html: Letter }}
    />
  )
}

const topAngleLetters = ['Y', 'V', 'W', 'y', 'v', 'w']
const bottomAngleLetters = ['A', 'a']
const rightCurveLetters = ['O', 'S', 'J', 'G', 'C']
const leftCurveLetters = ['O', 'D', 'S']

const TopLetters = ['T', 'P', 'F']

const BlockWord = ({
  mainCol,
  backCol,
  letters,
  fontSize,
  small,
  setHeight,
  size,
  maxLetters,
  refreshToken,
  passRefesh,
  left,
}) => {
  let [applyFont, setApplyFont] = useState(
    size.height - (size.padding.top + size.padding.bottom)
  )
  let [showWord, setShowWord] = useState(false)
  let [currentLetters, setCurrentLetters] = useState(letters)
  const [refresh, setRefresh] = useState(false)

  const renderedLetters = splitter
    .splitGraphemes(textTransform(letters, 'Block'))
    .slice(0, maxLetters)
    .map((val, key) => {
      const preLetter = key > 0 ? letters[key - 1] : null

      const forwardKearning =
        (topAngleLetters.includes(preLetter) &&
          bottomAngleLetters.includes(val)) ||
        (preLetter &&
          preLetter.toUpperCase() === 'L' &&
          val.toUpperCase() === 'Y')
      const backKearning =
        (bottomAngleLetters.includes(preLetter) &&
          topAngleLetters.includes(val)) ||
        (preLetter &&
          preLetter.toUpperCase() === 'L' &&
          topAngleLetters.includes(val) &&
          val &&
          val.toUpperCase() !== 'Y') ||
        (preLetter &&
          TopLetters.includes(preLetter.toUpperCase()) &&
          bottomAngleLetters.includes(val.toUpperCase()))

      const frontCurveKerning =
        val &&
        preLetter &&
        ((rightCurveLetters.includes(val.toUpperCase()) &&
          topAngleLetters.includes(preLetter.toUpperCase())) ||
          (leftCurveLetters.includes(preLetter.toUpperCase()) &&
            bottomAngleLetters.includes(val.toUpperCase())))
      return (
        <BlockLetter
          letterKey={key}
          small={small}
          mainCol={mainCol}
          backCol={backCol}
          letter={val}
          fontSize={applyFont}
          forwardKearning={forwardKearning}
          backKearning={backKearning}
          frontCurveKerning={frontCurveKerning}
        />
      )
    })

  const containerRef = useRef(null)
  useEffect(() => {
    setShowWord(false)
    setApplyFont(
      size.height - (size.padding.top + size.padding.bottom) - size.width / 100
    )
  }, [letters])
  useEffect(() => {
    if (refresh === true) {
      setShowWord(false)
      setApplyFont(size.height - (size.padding.top + size.padding.bottom))
      setRefresh(false)
    }
  }, [refresh])
  useEffect(() => {
    setTimeout(() => {
      setRefresh(true)
    }, [200])
  }, [passRefesh, refreshToken])
  useEffect(() => {
    if (refresh === false) {
      if (
        containerRef.current.offsetWidth >
        size.width - (size.padding.left + size.padding.right)
      ) {
        setApplyFont((prev) => prev - size.width / 100)
      } else {
        setShowWord(true)
      }
    }
  }, [applyFont, letters, refresh])

  return (
    <div
      className="LetterContainer"
      style={{
        opacity: showWord ? 1 : 0,
        display: 'flex',
        justifyContent: left ? 'flex-start' : 'center',
        alignItems: 'center',
        height: size.height,
        fontSize: applyFont,
        lineHeight: `${applyFont}px`,
        width: size.width,
      }}
    >
      <div className="LetterWidth" ref={containerRef}>
        {renderedLetters}
      </div>
      {/* <div style={{position: 'absolute', top: size.padding.top, right: size.padding.right, bottom: size.padding.bottom, left: size.padding.left, border: '1px dotted black'}}/>
            <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, border: '1px solid black'}}/> */}
    </div>
  )
}

export default BlockWord
