import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Col, Card, CardBody, UncontrolledTooltip, Media, Badge } from "reactstrap";


const ProductCard = ({location, key}) => {
  return (
    <Col xl="4" sm="6" key={"_location_" + key} >
      <Link to={`/location/edit/${location._id}`}>
        <Card>
          <CardBody>
            <Media>
              <Media className="overflow-hidden" body>
                <h5 className="text-truncate font-size-15"><Link to={`/location/edit/${location._id}`} className="text-dark">{location.display_name}</Link></h5>
                <p className="text-muted mb-4">{location.description}</p>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Link>
    </Col>
  )
}


export default ProductCard;