import React from 'react'
import {skuToProduct, caseColors, newColors, colors, fonts, lineHeights, locations, skuToColor} from '../constants'
import Word from '../../../components/Personalisation/font'
import Logo from '../svgs/july_black.svg'

var QRCode = require('qrcode.react');

const displaySize = { 
    height: 96,
    width: 400,
    padding: {
      top: 8,
      bottom: 8,
      left: 0,
      right: 0
    }
  }

Date.prototype.yyyymmdd = function() {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();
  
    return [
        (dd>9 ? '' : '0') + dd,
        (mm>9 ? '' : '0') + mm,
        this.getFullYear(),
    ].join(' / ');
};

class PrintPage extends React.Component {
    render() {
        let labels = this.props.monogramRecords.map(val => {
            let items = []
            for (let i = 0; i < val.quantity; i++) {
                if(this.props.monogram == true) {
                    items.push(<PrintPageFunc monogramRecord={val} />)
                } else {
                    items.push( <PrintPageNonFunc monogramRecord={val} />)
                }
                
            }
            return items
        }).flat()
        return <div className="Labels"> {labels} </div>
    }
}

// pet
// monogram_pets
// monogram_airtable_line
// completedThumbnails


const PrintPageFunc = ({monogramRecord}) => {
    // console.log(monogramRecord)
    return (
        <div className="PrintCanvas">
            <div className="PrintLine"> {monogramRecord.monogramId} </div>
            <div className="PrintLine" style={{textTransform: 'uppercase'}}> {monogramRecord.shippingType} </div>
            <div className="PrintLineXSmall"> ORDER DATE: {(new Date(monogramRecord.created_at)).yyyymmdd()} </div>
            <div className="PrintLineXSmall"> COUNTRY: {monogramRecord.country} </div>
            <br/>
            {
                monogramRecord.letters && !monogramRecord.pet && (
                    <div style={{padding: '20px 0'}}>
                        <div className="PrintText" style={{justifyContent: 'center'}}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Word 
                                    maxLetters={20} 
                                    size={displaySize} 
                                    mainCol={monogramRecord.mainCol === 'White' ? 'Black' : monogramRecord.mainCol}
                                    backCol={monogramRecord.backCol === 'White' ? 'Black' : monogramRecord.backCol}
                                    letters={monogramRecord.letters} 
                                    fontSize={32} 
                                    font={monogramRecord.font.toLowerCase()}
                                />
                            </div>
                        </div>
                    </div>
    
                )
            }
            {
                monogramRecord.pet && monogramRecord.completedThumbnails && (
                    <div style={{padding: '20px 0'}}>
                        <div className="PrintText" style={{justifyContent: 'center'}}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                {
                                    monogramRecord.monogram_airtable_line.map(petLineId => (
                                        <img src={monogramRecord.completedThumbnails[petLineId]} style={{width: '8cm', height: '8cm'}}/>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
    
                )
            }
            <br/>
            <div className="PrintTable" >
                {
                    monogramRecord.letters && !monogramRecord.pet && (
                        <>
                            <div className="PrintTableRowFlex">
                                <div className="PrintTableRowHalfBlack"> Type </div>
                                <div className="PrintTableRowHalf PrintTableThick">
                                    { 
                                        (monogramRecord.embroided === true || monogramRecord.font.includes('all-')) ? 'EMBROIDERED' : (
                                            monogramRecord.font.includes('embos') ? 'EMBOS' : 'INKED'
                                        ) 
                                    }
                                </div>
                            </div> 
                            <div className="PrintTableRowFlex">
                                <div className="PrintTableRowHalfBlack"> Position </div>
                                <div className="PrintTableRowHalf">{ monogramRecord.location }</div>
                            </div> 
                            <div className="PrintTableRowFlex">
                                <div className="PrintTableRowHalfBlack"> Monogram </div>
                                <div style={{textTransform: 'none'}} className="PrintTableRowHalf">{ monogramRecord.letters }</div>
                            </div> 
                            <div className="PrintTableRowFlex">
                                <div className="PrintTableRowHalfBlack"> Font </div>
                                <div className="PrintTableRowHalf">{ monogramRecord.font }</div>
                            </div> 
                            <div className="PrintTableRowFlex">
                                <div className="PrintTableRowHalfBlack"> Colour </div>
                                <div className="PrintTableRowHalf">{ monogramRecord.mainCol }</div>
                            </div> 
                            {
                                monogramRecord.backCol && (
                                    <div className="PrintTableRowFlex">
                                        <div className="PrintTableRowHalfBlack"> Secondary </div>
                                        <div className="PrintTableRowHalf">{ monogramRecord.backCol }</div>
                                    </div> 
                                )
                            }

                        </>
                    )
                }
                {
                    monogramRecord.pet && monogramRecord.monogram_pets && (
                        <>
                            <div className="PrintTableRowFlex">
                                <div className="PrintTableRowHalfBlack"> Position </div>
                                <div className="PrintTableRowHalf">{ monogramRecord.location }</div>
                            </div> 
                            <div className="PrintTableRowFlex">
                                <div className="PrintTableRowHalfBlack"> Monogram </div>
                                <div style={{textTransform: 'none'}} className="PrintTableRowHalf">{ monogramRecord.monogram_pets.map(val => val.name).join(' + ') }</div>
                            </div> 
                        </>
                    )
                }
            </div>
            <br/>
            <div className="PrintTableClear" >
                <div className="PrintTableRowFlexClear">
                    <div className="PrintTableRowHalfClear"> 
                        <div className="PrintTable" >
                            <div style={{fontSize: 32, padding: '24px 32px'}} className="PrintTableRow">{ skuToProduct(monogramRecord.sku) }</div> 
                            <div style={{fontSize: 32, padding: '24px 32px'}} className="PrintTableRow">{ skuToColor(monogramRecord.sku) }</div> 
                            <div style={{fontSize: 32, padding: '24px 32px'}} className="PrintTableRow">{ monogramRecord.sku }</div> 
                        </div>
                    </div>
                    <div style={{paddingLeft: 16, fontSize: 30}} className="PrintTableRowHalfClear">
                        <div className="PrintLineXSmall">  {monogramRecord.shopifyId} </div>
                        {
                            (monogramRecord.sku.includes('COPB') || (monogramRecord.lineItems && monogramRecord.lineItems.length > 0 && monogramRecord.lineItems.map(val => val.includes('COPB')).reduce((sum, val) => sum || val))) && (
                                <div className="PrintLineXSmall">  <span style={{marginRight: 16}}> [ </span> <span style={{marginLeft: 16}}> ] </span> PB </div>    
                            )
                        }
                        {
                             monogramRecord.lineItems.map(lineItem => (
                                <div className="PrintLineXSmall">  <span style={{marginRight: 16}}> [ </span> <span style={{marginLeft: 16}}> ] </span>  {lineItem} </div>       
                            ))
                        }
                    </div>
                </div> 
            </div>
            <div style={{position: 'absolute',left: 'calc(32px + 20mm)', bottom: 0, right: 'calc(20% + 48px + 20mm)', color: 'black', fontSize: 32, fontWeight: 600}}>
                Personalised for you and shipped from <br/> the July team in Melbourne, Australia
            </div>
            <img style={{right: 'calc(32px + 20mm)', bottom: 0, position: 'absolute', width: '20%'}} src='/logo.svg'/>
        </div>
    )
}

const PrintPageNonFunc = ({monogramRecord}) => {
    return (
        <div className="PrintCanvas">
            <div className="PrintLine"> {monogramRecord.shopifyId} </div>
            <div className="PrintLine" style={{textTransform: 'uppercase'}}> {monogramRecord.shippingType} </div>
            <div className="PrintLineXSmall"> ORDER DATE: {(new Date(monogramRecord.created_at)).yyyymmdd()} </div>
            <div className="PrintLineXSmall"> COUNTRY: {monogramRecord.country} </div>
            <br/>
            <br/>
            <br/>
            <div className="PrintTable" >
                <div style={{padding: '24px 32px'}} className="PrintTableRow">{ skuToProduct(monogramRecord.sku) }</div> 
                <div style={{padding: '24px 32px'}} className="PrintTableRow">{ skuToColor(monogramRecord.sku) }</div> 
                <div style={{padding: '24px 32px'}} className="PrintTableRow">{ monogramRecord.sku }</div> 
            </div>
            <br/>
            <br/>
            <br/>
            {
                (monogramRecord.sku.includes('COPB') || (monogramRecord.lineItems && monogramRecord.lineItems.length > 0 && monogramRecord.lineItems.map(val => val.includes('COPB')).reduce((sum, val) => sum || val))) && (
                    <div className="PrintLineXSmall">  <span style={{marginRight: 16}}> [ </span> <span style={{marginLeft: 16}}> ] </span> PB </div>    
                )
            }
            {
                monogramRecord.lineItems.map(lineItem => (
                    <div className="PrintLineSmall">  <span style={{marginRight: 16}}> [ </span> <span style={{marginLeft: 16}}> ] </span> {lineItem} </div>
                ))
            }
            <div style={{position: 'absolute',left: 'calc(32px + 20mm)', bottom: 0, right: 'calc(20% + 48px + 20mm)', color: 'black', fontSize: 32, fontWeight: 600}}>
                Prepared for you and shipping from <br/> the July team in Melbourne, Australia
            </div>
            <img style={{right: 'calc(32px + 20mm)', bottom: 0, position: 'absolute', width: '20%'}} src='/logo.svg'/>
        </div>
    )
}


export default PrintPage