import React, {useEffect, useState, useRef} from 'react'
import Grapheme from 'graphemer'
import emojiUnicode from "emoji-unicode";


const replaceAll = (str, delim, replace) => {
  return str.split(delim).join(replace ? replace : '')
}

const makeid = (length) => {
  var result           = [];
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
 }
 return result.join('');
}

export const newColors = {
  'Lavender': '#90759B',
  'Lilac': '#DCC5DF',
  'Ballet-Pink': '#F1C9DE',
  'Rose-Pink': '#DC75A4',
  'Grenaine': '#9E5360',
  'Blush-Pink': '#E77472',
  'Magenta': '#B12D52',
  'Maroon': '#731A29',
  'Red': '#C92327',
  'Burnt-Orange': '#DC5527',
  'Mango': '#EF7E2E',
  'Rust': '#9B4827',
  'Toffee': '#705037',
  'Taupe': '#D3A289',
  'Butter-Yellow': '#FBF3BE',
  'Yellow': '#E8B629',
  'Lime': '#C6B937',
  'Green': '#4A813C',
  'Forest-Green': '#034123',
  'Mint': '#78AB93',
  'Ice-Blue': '#BEE3E9',
  'Denim': '#75A6B3',
  'Ocean-Blue': '#3E8EA1',
  'Blue': '#1466B2',
  'Grey': '#95979E',
  'Slate-Grey': '#63727E',
  'Black': '#000000',
  White: '#FFFFFF',

  Cream: '#d8a68b',
  Coral: '#FDC0B8',
  'Dusty-Pink': '#F5DFE2',
  Fuschia: '#DF3C7F',
  'Sky-Blue': '#BBE0EE',
  'Aqua-Blue': '#47BCEE',
  Plum: '#AC3856',
  Purple: '#64258E',
  'Dark-Purple': '#3a1247',
  'Ultramarine-Blue': '#2b3491',
  'French-Navy': '#002551',
  'Powder-Blue': '#78abba',
  'Dark-Green': '#084221',
  'Dark-Pink': '#bb2c55',
  Pink: '#f37876',
  Orange: '#e95624',
  Peach: '#f27f5b'
}

const Emoji = (key, font, fontSize, classKey) => {
  const unicode = emojiUnicode(key)
  let parsedUnicode = unicode
  parsedUnicode = replaceAll(parsedUnicode, ' ', '-')
  parsedUnicode = replaceAll(parsedUnicode, '-fe0e')
  parsedUnicode = replaceAll(parsedUnicode, '-200d')
  parsedUnicode = replaceAll(parsedUnicode, '-fe0f')
  
  const url = `https://cdn.july.com/emoji/emoji/${parsedUnicode}.svg`
  if (font === 'script') {
    return <img className={`Emoji-Image-${classKey}`} style={{
      height:`${fontSize}px`, 
      width:`${fontSize}px`, 
      maxHeight:`${fontSize}px`, 
      maxWidth:`${fontSize}px`, 
      marginTop: '-0.1em'
    }} src={url}/>
  }
  return <img className={`Emoji-Image-${classKey}`} style={{
    height:`${fontSize}px`, 
    width:`${fontSize}px`, 
    maxHeight:`${fontSize}px`, 
    maxWidth:`${fontSize}px`, 
    paddingTop: '0.05em'
  }} src={url}/>
}
const splitter = new Grapheme();

const Word = ({mainCol, letters, fontSize, font, small, print, borderColor, size, topOffset, multiplier, emojiOffset, letterSpace, maxLetters, passRefesh, overlayFont, overlayOffset, backCol}) => { 
  let isSmall = print === true ? true : small
  const maxHeight = size.height - (size.padding.top + size.padding.bottom)
  let [applyFont, setApplyFont] = useState(maxHeight * (multiplier !== undefined ? multiplier : 1)) 
  let [sizeChange, setSizeChange] = useState(0)
  let [r, setRefresh] = useState(false)
  const refresh = () => setRefresh(prev => !prev)

  let [showWord, setShowWord] = useState(false)
  const [uniqKey, setKey] = useState(makeid(10))

  const letterSpacing = letterSpace || 'normal'

  const emojiSize = applyFont * 0.7

  const Letters = (
    <>
    {
      splitter.splitGraphemes(letters).slice(0, maxLetters).reduce((acc, cur, index, array) => {
        const isLast = index == array.length - 1
        const char = cur
        const isChar = /^([,.?\-]|[-'0-9A-zÀ-ÿ\u4e00-\u9eff ]){1,20}$/i.test(char)
    
        let accArr = acc['accArr']
        let values = acc['values']
        let currentType = acc['currentType']
    
        if (acc.currentType === undefined) {
          accArr.push(char)
        } else if (isChar === currentType) {
          accArr.push(cur)
        } else if (isChar !== currentType) {
          values.push([...accArr].join(''))
          accArr = []
          accArr.push(cur)
        }
    
        if (isLast) {
          values.push([...accArr].join(''))
          accArr = []
        }
        return {
          currentType: isChar,
          accArr,
          values
        }
      }, {
        accArr: [],
        values: [],
        currentType: undefined
      })['values'].map((char, key) => {
        if (/^([,.?\-]|[-'0-9A-zÀ-ÿ\u4e00-\u9eff ]){1,20}$/i.test(char)) {
          return <span style={{height: `${applyFont}px`, lineHeight: `${applyFont}px`, letterSpacing: letterSpacing, position: 'relative'}}> 
            {char} 
            {
              overlayFont && (
                <span className={`${overlayFont}`} style={{height: `${applyFont}px`, lineHeight: `${applyFont}px`, letterSpacing: letterSpacing, position: 'absolute', ...(overlayOffset ? {inset: `${overlayOffset.map(val => `${applyFont * val}px`).join(' ')} auto auto`} : {inset: '0 0 0 0'}), color: newColors[mainCol], whiteSpace: 'nowrap'}}> {char} </span>
              )
            }
          </span>
        }

        return splitter.splitGraphemes(char).map(char => {
          const letterArray = splitter.splitGraphemes(letters)
          const prevLetter = key > 0 ? letterArray[key-1] : ''
          const nextLetter = key < letterArray.length - 1 ? letterArray[key + 1] : ''
          const prevLetterPadding = /^A-zÀ-ÿ]+$/.test(prevLetter)
          const nextLetterPadding = /^[A-zÀ-ÿ]+$/.test(nextLetter)
            return <span style={{marginTop: emojiOffset, height: `${applyFont}px`, lineHeight: `${applyFont}px`, display: 'inline-flex', justifyContent: 'center', alignItems: 'center', marginLeft: prevLetterPadding ? '0.1em': '0.04em', marginRight: nextLetterPadding ? '0.1em': '0.04em'}}> {Emoji(char, font, emojiSize, uniqKey)} </span>
        })
      })
    }  
    </>
  )

  const resizeWord = () => {
    if (containerRef.current.offsetWidth > (size.width - (size.padding.left + size.padding.right))){
      let alterFontSize = applyFont

      const ImageEls = document.getElementsByClassName(`Emoji-Image-${uniqKey}`)
      let loopCount = 0
      while(
        ((containerRef.current.offsetWidth > (size.width - (size.padding.left + size.padding.right))) || 
        (letterContainerRef.current.style.fontSize < 2)) && 
        loopCount < 20 && 
        alterFontSize > 0){
        alterFontSize -= 1
        loopCount += 1
        letterContainerRef.current.style.fontSize = `${alterFontSize}px`;
        for (const imageEl of ImageEls) {
          imageEl.style.fontSize = `${alterFontSize * 0.7}px`;
        }
      }
      setApplyFont(alterFontSize)
    } else {
      setShowWord(true)
    }
  }

  const containerRef = useRef(null)
  const letterContainerRef = useRef(null)
  useEffect(() => {
    setApplyFont(maxHeight * (multiplier !== undefined ? multiplier : 1))
  }, [letters])
  useEffect(() => {
    setShowWord(false)
    resizeWord()
  }, [applyFont, letters, r])

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowWord(false)
  //     refresh()
  //   }, 2000)
  // }, [])

  useEffect(() => {
    if (passRefesh === true) {
      setTimeout(() => {
        setShowWord(false)
        refresh()
      }, 100)
    }
  }, [passRefesh])

  let mainTextColor = mainCol===undefined && borderColor !== undefined ?'rgba(123, 109, 0, 0)' : newColors[mainCol]
  if (overlayFont) {
    mainTextColor = newColors[backCol]
  }

  let containerStyle = {
    opacity: showWord ? 1 : 0,
    transition: '0.1s opacity',
    height: size.height,
    width: size.width,
    minWidth: size.width,
    textDecation: 'none',
    ...(borderColor !== undefined ? {'-webkit-text-stroke-color': newColors[borderColor]} : {}),
    color: mainTextColor, 
    fontSize: applyFont,
    lineHeight: `${applyFont}px`
    // lineHeight: `${lineHeights[font]}px`
  }

  return (
    <>
      <div ref={letterContainerRef} className={`LetterContainer ${font}`} style={containerStyle}>
        <div className="LetterWidth" ref={containerRef} style={{marginTop: topOffset, position: 'relative'}}>
          {Letters}
        </div>
        {/* <div style={{position: 'absolute', top: size.padding.top, right: size.padding.right, bottom: size.padding.bottom, left: size.padding.left, border: '1px dotted black'}}/>
        <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, border: '1px solid black'}}/> */}
      </div>
    </>
  )
}

export default Word