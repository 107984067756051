import React, { useEffect } from 'react'
import queryString from 'query-string';
import { useHistory } from "react-router-dom";
import useQuery from '../../helpers/JIFI/useQuery';

const PrintLabels = ({
  match: {
    params
  },
  location
}) => {
  let { orderID } = queryString.parse(location.search)
  const history = useHistory();

  // console.log(orderID)

  const [order, state] = useQuery({
    url: `/orders/${orderID}`
  });

  useEffect(() => {
    if (order !== undefined) {
      const dataObject = {
        order: order, 
        monogram: true
      }

      const encodedData = Buffer.from(encodeURI(encodeURIComponent(JSON.stringify(dataObject)))).toString('base64')
      history.push(`/print-labels?orderData=${encodedData}`)
    }
  }, [order])
  // const {order, monogram} = JSON.parse(Buffer.from(orderData, 'base64'))

  return <div></div>
} 

export default PrintLabels