import React, { Component } from 'react';

import { Map, InfoWindow, Marker } from "google-maps-react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

class MapsGoogle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {}
    }
    this.onMarkerClick = this.onMarkerClick.bind(this);
  }

  onMarkerClick(props, marker, e) {
    alert("You clicked in this marker");
  }

  render() {
    return (
      <Col xl="4" sm="6">
        <Card>
          <CardBody>
            <CardTitle>Shipping Location</CardTitle>
            <div
              id="gmaps-markers"
              className="gmaps"
              style={{ position: "relative" }}
            >
              <Map
                google={this.props.google}
                style={{ width: "100%", height: "100%" }}
                zoom={14}
              >
                {/* <Marker
                  title={"The marker`s title will appear as a tooltip."}
                  name={"SOMA"}
                  position={{ lat: 37.778519, lng: -122.40564 }}
                /> */}
                <Marker
                  title={"July HQ"}
                  name={"July HQ"}
                  position={{ address: "14 Robert Street, Collingwood, 3066, Victoria, Australia" }}
                />
                {/* <Marker name={"Dolores park"} /> */}
                <InfoWindow>
                  <div>
                    <h1>{this.state.selectedPlace.name}</h1>
                  </div>
                </InfoWindow>
              </Map>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default MapsGoogle