import React, { useState,useCallback, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { FormGroup, Select, Nav, CardSubtitle, CardTitle, CardText, TabContent, TabPane, Collapse, NavLink, NavItem, Container, Input, Button, Table, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import "../datatables.scss";
import { ToastContainer, toast } from 'react-toastify';

import useQuery from '../../../helpers/JIFI/useQuery';

import { useHistory } from "react-router-dom";
import superagent from 'superagent'
import {backendUrl} from '../../../helpers/consts.js'

import LocationSelector from '../shared/LocationSelector'
import {useSelector, useDispatch} from 'react-redux'
import {
	CHANGE_LOCATION
} from "../../../store/layout/actionTypes";

import moment from 'moment';


import { CSVReader } from 'react-papaparse';
import SupplyChainNav from '../../../components/supply-chain/nav.js';
import CommentHostory from '../../Skote/Dashboard/CommentHistory.js';
import ProductVariantSelector from '../helpers/ProductVariantSelector.js';


const StockInput = ({stock, setStock, productData, purchaseOrders}) => {
  const handleAddRow = () => {
    const item = {
      product:  productData[0]._id,
      variant: productData[0]['variants'][0]._id,
      quantity: 0,  
      costPerItem: 0
    };
    setStock(prev => [...prev, item])
  };
  
  const editPurchaseOrderRow = (id, val) => {
    setStock(prev => {
      let newList = [...prev]
      newList[id]['purchaseOrder'] = val
      return newList
    })
  };

  const editVariantRow = (id, val) => {
    setStock(prev => {
      let newList = [...prev]
      newList[id]['variant'] = val
      newList[id]['costPerItem'] = 0
      newList[id]['quantity'] = 0
      return newList
    })
  };

  const editQuantityRow = (id, val) => {
    setStock(prev => {
      let newList = [...prev]

      let parsedInput = parseInt(val)
      if (parsedInput < 0) {
        parsedInput = 0
      }

      newList[id]['quantity'] = parsedInput
      
      return newList
    })
  };

  const editCostPerItem = (id, val) => {
    setStock(prev => {
      let newList = [...prev]
      let parsedInput = parseFloat(val)
      if (parsedInput < 0) {
        parsedInput = 0
      }

      newList[id]['costPerItem'] = parsedInput

      return newList
    })
  };

  const removeRow = (id) => {
    setStock(prev => {
      let newList = prev.filter((item, key) => key !== id)
      return newList
    })
  }; 

  const products = productData || []

  return <>
    <Row>
      <Col xs="4">
        Variant
      </Col>
      <Col xs="2">
        Quantity
      </Col>
      <Col xs="2">
        FOB
      </Col>
      <Col xs="2">
        Purchase Order
      </Col>
    </Row>
    <br/>
    {
      productData && stock.map((line, lineKey) => {
        
        const filteredPurchaseOrders = purchaseOrders?.filter(val => val?.lines?.some(poLine => poLine?.variant?._id === line?.variant?._id)) ?? []
        
        return (
          <Row style={{padding: '12px 0'}}>
            <Col xs="4">
              <ProductVariantSelector
                hidePersonalisationOptions
                products={products}
                setSelectedVariant={(val) => {
                  
                  editVariantRow(lineKey, val)
                }}
                selectedVariant={line.variant}
              />
            </Col>
            <Col xs="2">
              <Input style={{width: '90%', height: '100%'}} type="number" value={stock[lineKey]['quantity']} onChange={(e) => editQuantityRow(lineKey, e.target.value)}/>
            </Col>
            <Col xs="2">
              <Input style={{width: '90%', height: '100%'}} type="number" value={stock[lineKey]['costPerItem']} onChange={(e) => editCostPerItem(lineKey, e.target.value)}/>
            </Col>
            <Col xs="2">
              <Input type="select" style={{width: '100%', height: '100%'}} 
                onChange={(e) => editPurchaseOrderRow(lineKey, e.target.value)} name="products" id="products"
              >
                <option value> none </option>
                {filteredPurchaseOrders.map((purchaseOrder, key) => <option selected={line.purchaseOrder?._id === purchaseOrder?._id || line?.purchaseOrder === purchaseOrder?._id} value={purchaseOrder?._id}>{purchaseOrder.reference}</option> )}
              </Input>
            </Col>
            <Col xs="1" >
              <Button onClick={() => removeRow(lineKey)} color="danger">
                X
              </Button>{" "}
            </Col>
          </Row>
        )
      })
    }
    {
      productData && [stock.reduce((sum, line) => {
        sum.sum += line['quantity']
        sum.total += line['quantity'] * line['costPerItem']
        return sum
      }, {sum: 0, total: 0})].map(val => {
        if (val.sum === 0) {
          return <></>
        }
        return (
          <Row style={{padding: '12px 0'}}>
            <Col xs="2">
              Total
            </Col>
            <Col xs="2">
            </Col>
            <Col xs="2">
              {isNaN(val.sum) ? 0 : val.sum }
            </Col>
            <Col xs="2">
              {isNaN(val.total) ? 0 : val.total }
            </Col>
            <Col xs="2">
            </Col>
            <Col xs="1" >
            </Col>
          </Row>
        )
      })
    }
    { productData?.length > 0 &&
    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 12}}>
      <Button onClick={handleAddRow} color="primary">
        Add{" "}
      </Button>{" "}
      <span />
    </div>
}
  </>
}


const PullPage = ({
  match: {
    params
  }
}) => {
  const { containerID } = params

  const history = useHistory();
  const [refresh, setRefresh] = useState(true)
  const [stockPull, setStockPull] = useState([])
  const [modal, setModal] = useState(false)
  const [loading, setlLoading] = useState(false)
  const selectedLocation = useSelector(state => state.Layout.location)
  const [stockCount, setStockCount] = useState({})
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState()
  const dispatch = useDispatch()
  const [errors, setErrors] = useState({});
  const [formState, setFormState] = useState({
    arrivalDate: '',
    portDate: '',
    departureDate: '',
    warehouseDatePlanned: '',
    warehouseDateActual: '',
    requestedDate: '',
    bookingDate: '',
    containerState: 'draft',
    containerName: '',
    containerRef: '',
    originPort: '',
    destinationPort: '',
    shippingCarrier: '',
    containerType: '',
    freightCost: '',
    localCost: ''
  });
  const [stockCountFull, stockCountState] = useQuery({
    url: `/inventory/fetchStockCount`,
    refresh,
    queryData: {
      location: 'EWE'
    }
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: null
      }));
    }
  };

  const [data, state] = useQuery({
    url: `/product`,
    refresh
  });
  const productData = data && data.data
  const [purchaseOrdersPagination, purchaseOrderState] = useQuery({
    url: `/purchase-order/all`,
    refresh,
    queryData: {
      status: 'approved'
    }
  });

  const purchaseOrders = purchaseOrdersPagination && purchaseOrdersPagination.data
  const togglemodal = () => setModal(prev => !prev)

  useEffect(() => {
    
    let collatedStockCount = {}
    if (stockCountFull) {
      stockCountFull.map(product => {
        product.variantCounts.map(variantCount => {
          collatedStockCount[variantCount.variant.sku] = variantCount.count.on_hand
        })
      })
      setStockCount(collatedStockCount)
    }
  }, [stockCountFull])


  const validate = () => {
    const newErrors = {};
    const requiredFields = [
      'containerName', 'containerRef', 'departureDate', 'requestedDate', 'warehouseDatePlanned', 
      'arrivalDate', 'bookingDate', 'warehouseDateActual', 'portDate'
    ];

    requiredFields.forEach(field => {
      if (!formState[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim()} is required`;
      }  else if ((field === 'freightCost' || field === 'localCost') && (parseFloat(formState[field]) <= 0)) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim()} must be a positive number`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    setlLoading(true)
    const exportData = {
      containerID: containerID,
      stock: stockPull.map(item => ({
        variant: item.variant._id,
        sku: item.variant.sku,
        quantity: item.quantity,
        costPerItem: item.costPerItem,
        containerLineId: item.containerLineId,
        purchaseOrder: item.purchaseOrder?._id
      })),
      ...formState,
      name: formState.containerName,
      ref: formState.containerRef,
      status: formState.containerState,
      location: selectedLocation,
      purchaseOrder: selectedPurchaseOrder,
    }

    
    
    superagent.post(`${backendUrl}/inventory/updateContainer/${containerID}`)
      .send(exportData)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status
        history.replace(history.location.pathname, {
          errorStatusCode: code
        });
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code
          });
        } else {
          history.push(`/container/${containerID}`)
        }
      }).catch(err => {
        setlLoading(false)
        const errorMessage =
        err.response?.body?.message || err.message || 'Internal server error';
        toast.error(errorMessage, {
          autoClose: 5000,
        });
        console.log(err)
      });
  }

  const getContainer =  useCallback(() => {
    setlLoading(true)
    
    superagent.get(`${backendUrl}/inventory/fetchContainer/${containerID}`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status
        history.replace(history.location.pathname, {
          errorStatusCode: code
        });
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code
          });
        } else {
          setFormState(prevState => ({
            ...prevState,
            containerState: response.body.status,
            containerName: response.body.name,
            containerRef: response.body.ref,
            freightCost: response.body.freightCost,
            localCost: response.body.localCost,
            arrivalDate: moment(response.body.arrivalDate).format('YYYY-MM-DD'),
            portDate: moment(response.body.portDate).format('YYYY-MM-DD'),
            warehouseDatePlanned: moment(response.body.warehouseDatePlanned).format('YYYY-MM-DD'),
            warehouseDateActual: moment(response.body.warehouseDateActual).format('YYYY-MM-DD'),
            requestedDate: moment(response.body.requestedDate).format('YYYY-MM-DD'),
            bookingDate: moment(response.body.bookingDate).format('YYYY-MM-DD'),
            departureDate: moment(response.body.departureDate).format('YYYY-MM-DD'),
            originPort: response.body.originPort,
            destinationPort: response.body.destinationPort,
            shippingCarrier: response.body.shippingCarrier,
            containerType: response.body.containerType
          
          }))
        
          let items = []
          for(let containerLine of response.body.containerLines){
            const item = {
              product: containerLine.variant.product,
              variant: containerLine.variant,
              quantity: containerLine.quantity,
              costPerItem: containerLine.costPerItem,
              containerLineId: containerLine._id,
              purchaseOrder: containerLine.purchaseOrder,
            };
            items.push(item)
          }
          setStockPull(prev => items)
          if (response.body?.landingLocation?.name) {
            dispatch({ type: CHANGE_LOCATION, payload: response.body?.landingLocation?.name })
          }
          setlLoading(false)
        }
      }).catch(err => {
        console.log(err)
      });
  },[containerID, dispatch, history])
  

  useEffect(() => {
    if(containerID !== undefined){
      getContainer()
    }
  }, [containerID, getContainer])

  const handleOnDrop = (data) => {
    let items = []
    data.map((line, key) => {
      if (key > 0) {
        const count = line.data[1]
        const sku = line.data[0]

        let product = productData.find(val => (val.variants.find(variant => variant.sku === sku) != undefined))
        if (product != undefined) {
          let variant = product.variants.find(variant => variant.sku === sku)
          if (variant != undefined) {
            const item = {
              product: product._id,
              variant: variant._id,
              quantity: count,
              sku: sku
            }; 
            items.push(item)
          }
        }
      }
    })

    items = items.sort((a, b) => (a.sku < b.sku) ? -1 : (a.sku > b.sku) ? 1 : 0);
    setStockPull(prev => [...prev, ...items])
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    // console.log(data);
  };


  const getInputClass = (field) => errors[field] ? 'is-invalid' : '';

  let purchaseOrderOptions = purchaseOrders?.map(val => ({ value: val._id, label: val.reference })) || []
  let purchasOrder = purchaseOrderOptions?.find(val => val.value === selectedPurchaseOrder)

  return <SupplyChainNav
    commentComponent={<CommentHostory title="Comments" initCommentType={'Container'} initOrderName={formState.containerName} />}
  >
    <Container fluid>
      <div>
      <Row className="mb-2">
        <Col xs="12" m="8" lg="8" xl="8" >
          <Card>
            <CardBody>
            <Row>
                <Col xs="6">
                  <FormGroup className="select2-container">
                    <Label className="control-label">Supplier Invoice Reference</Label>
                    <Input  
                    className={getInputClass('containerName')}
                    name="containerName" value={formState.containerName} onChange={handleInputChange} placeholder="Name" />
                    {errors.containerName && <div className="invalid-feedback">{errors.containerName}</div>}
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup className="select2-container">
                    <Label className="control-label">Container Reference</Label>
                    <Input 
                    className={getInputClass('containerRef')}
                    name="containerRef" value={formState.containerRef} onChange={handleInputChange} placeholder="Name" />
                  {errors.containerRef && <div className="invalid-feedback">{errors.containerRef}</div>}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <FormGroup className="select2-container">
                    <Label className="control-label">Destination Port</Label>
                    <Input 
                    name="destinationPort"
                    onChange={handleInputChange} value={formState.destinationPort} type="select"  id="exampleSelect">
                      <option value> none </option>
                      <option value='draft'> Port 1 </option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup className="select2-container">
                    <Label className="control-label">Origin Port</Label>
                    <Input  onChange={handleInputChange} value={formState.originPort} type="select" 
                    name="originPort"
                     id="exampleSelect">
                      <option value> none </option>
                      <option value='draft'> Port 1 </option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <FormGroup className="select2-container">
                    <Label className="control-label">Shipping Carrier</Label>
                    <Input
                    name="shippingCarrier"
                    onChange={handleInputChange} value={formState.shippingCarrier} type="select"  id="exampleSelect">
                      <option value> none </option>
                      <option value='draft'> Carrier 1 </option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup className="select2-container">
                    <Label className="control-label">Container Type</Label>
                    <Input name="containerType"
                     onChange={handleInputChange} value={formState.containerType} type="select"  id="exampleSelect">
                      <option value> none </option>
                      <option value='draft'> 40ft </option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className="select2-container">
                <Label className="control-label">Container State</Label>
                <Input 
                name="containerState"
                onChange={handleInputChange} value={formState.containerState} type="select"  id="exampleSelect">
                  <option value='draft'> Draft </option>
                  <option value='packing'> Packing </option>
                  <option value='in-transit'> In Transit </option>
                  <option value='at-port'> At Port </option>
                  <option value='ready'> Ready to unload </option>
                  <option value='completed'> Completed </option>
                </Input>
              </FormGroup>
              <FormGroup className="select2-container">
                <Label className="control-label">Landing Location</Label>
                <LocationSelector />
              </FormGroup>
             
              <Row>
                <Col xs="4">
                  <FormGroup className="select2-container">
                    <Label className="control-label">Departure Date</Label>
                    <Input
                    className={`form-control ${getInputClass('departureDate')}`}
                    name="departureDate"
                     type="date" value={formState.departureDate} onChange={handleInputChange} defaultValue="2019-08-19" id="example-date-input" />
                   {errors.departureDate && <div className="invalid-feedback">{errors.departureDate}</div>}
                  </FormGroup>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Arrival Date</Label>
                    <input 
                    className={`form-control ${getInputClass('arrivalDate')}`}
                    name="arrivalDate" type="date" value={formState.arrivalDate} onChange={handleInputChange} defaultValue="2019-08-19" id="example-date-input" />
                    {errors.arrivalDate && <div className="invalid-feedback">{errors.arrivalDate}</div>}
                  </FormGroup>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Port Date</Label>
                    <input 
                    className={`form-control ${getInputClass('portDate')}`}
                    name="portDate" type="date" value={formState.portDate} onChange={handleInputChange} defaultValue="2019-08-19" id="example-date-input" />
                    {errors.portDate && <div className="invalid-feedback">{errors.portDate}</div>}
                  </FormGroup>
                </Col>
                <Col xs="4">
                  <FormGroup className="select2-container">
                    <Label className="control-label">Requested Date</Label>
                    <input 
                      className={`form-control ${getInputClass('requestedDate')}`}
                        name="requestedDate" type="date" value={formState.requestedDate} onChange={handleInputChange} defaultValue="2019-08-19" id="example-date-input" />
                    {errors.requestedDate && <div className="invalid-feedback">{errors.requestedDate}</div>}
                  </FormGroup>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Booking Date</Label>
                    <input 
                    className={`form-control ${getInputClass('bookingDate')}`}
                    name="bookingDate" type="date" value={formState.bookingDate} onChange={handleInputChange} defaultValue="2019-08-19" id="example-date-input" />
                    {errors.bookingDate && <div className="invalid-feedback">{errors.bookingDate}</div>}
                  </FormGroup>
                </Col>
                <Col xs="4">
                  <FormGroup className="select2-container">
                    <Label className="control-label">Warehouse Date Planned</Label>
                    <input className={`form-control ${getInputClass('warehouseDatePlanned')}`} name="warehouseDatePlanned" type="date" value={formState.warehouseDatePlanned} onChange={handleInputChange} defaultValue="2019-08-19" id="example-date-input" />
                    {errors.warehouseDatePlanned && <div className="invalid-feedback">{errors.warehouseDatePlanned}</div>}
                  </FormGroup>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Warehouse Date Actual</Label>
                    <input className={`form-control ${getInputClass('warehouseDateActual')}`} name="warehouseDateActual" type="date" value={formState.warehouseDateActual} onChange={handleInputChange} defaultValue="2019-08-19" id="example-date-input" />
                    {errors.warehouseDateActual && <div className="invalid-feedback">{errors.warehouseDateActual}</div>}
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className="select2-container">
                <Label className="control-label">Freight Cost - $USD</Label>
                <Input
                      className={`form-control ${getInputClass('freightCost')}`}
                      name="freightCost"
                      type="number"
                      value={formState.freightCost}
                      onChange={handleInputChange}
                    />
                
                {errors.freightCost && <div className="invalid-feedback">{errors.freightCost}</div>}
              </FormGroup>
              <FormGroup className="select2-container">
                <Label className="control-label">Local Cost - $USD</Label>
                <Input 
                    className={`form-control ${getInputClass('localCost')}`}
                 name="localCost" type="number" value={formState.localCost} onChange={handleInputChange} />
                {errors.localCost && <div className="invalid-feedback">{errors.localCost}</div>}
              </FormGroup>
              <br/>
              <div>
                <div className="mb-2">
                  <a href="https://jifi-storage-staging.s3-ap-southeast-2.amazonaws.com/comment_file_1637273421528.csv"> CSV Template </a>
                </div>
                <CSVReader
                  onDrop={handleOnDrop}
                  onError={handleOnError}
                  onRemoveFile={handleOnRemoveFile}
                >
                  <span>Drop CSV file here or click to upload.</span>
                </CSVReader>
              </div>
              <br/>
              <div id="accordion">
                <Card className="mb-1">
                  <CardHeader className="p-3" id="headingOne">
                    <h6 className="m-0 font-14">
                      <span style={{ cursor: "pointer" }} className="text-dark">
                        Book Stock 
                      </span>
                    </h6>
                  </CardHeader>
                  <Card>
                    <CardBody>
                      <StockInput stock={stockPull} setStock={setStockPull} productData={productData} purchaseOrders={purchaseOrders}/>
                    </CardBody>
                  </Card>
                </Card>
              </div>

              <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 12}}>
                <span />
                {((productData && (stockPull.length > 0))) && (
                  <Button onClick={
                    () => {
                      if(!validate()) return
                      togglemodal()
                    }
                    } color="primary" className="btn-lg btn-rounded">
                    Update Container
                  </Button>
                ) }
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" m="4" lg="4" xl="4">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">CONTAINER SUMMARY</h4>
              <br />
              {
                productData && stockPull.length > 0 && 'Count:'
              }
              {
                productData && stockPull.map((line, lineKey) => {
                  return <div>  <span className="text-success">+{line['quantity']}</span> x {line['variant']?.sku} </div>
                })
              }
            </CardBody>
          </Card> 
        </Col>
      </Row>
      </div>
    </Container>
    <Modal isOpen={modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" toggle={togglemodal}>
      <CardBody>
        <h4 className="card-title mb-4">CONTAINER SUMMARY</h4>
        <br />
        {
          productData && stockPull.length > 0 && 'Count:'
        }
        {
          productData && stockPull.map((line, lineKey) => {
            return <div>  <span className="text-success">+{line['quantity']}</span> x {line['variant']?.sku} </div>
          })
        }
      </CardBody>
      <ModalFooter>
        {
          loading ? (
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <Button onClick={handleSubmit} color="primary" className="btn-lg btn-rounded">
              Confirm
            </Button>
          )
        }
      </ModalFooter>
    </Modal>
  </SupplyChainNav>
}

export default PullPage
