import React, { Component, Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CustomInput, CardTitle, Input, FormGroup, Label, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { uploadFileS3Bucket } from "../../../utilities/uploadFileS3Bucket";
import superagent from 'superagent'
import {backendUrl} from '../../../helpers/consts.js'

const UserProfile = ({ props }) => {
    const history = useHistory();
    const initialState = {
        userName: "",
        email: "",
        newPassword: "",
        confirmPassword: "",
        profileImage: null,
        isProfileImageUploaded: false
    }

    const [state, setState] = useState(initialState);

    useEffect(() => {
        let userData = localStorage.getItem("authUser");
        if (userData) {
            userData = JSON.parse(userData);
            let userId = userData._id;
            getUserProfile();
        }
    }, []);

    const getUserProfile = () => {
        superagent.get(`${backendUrl}/users/profile/`)
            .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
            .set('accept', 'json')
            .on('error', (response) => {
                const code = response.status
                history.replace(history.location.pathname, {
                    errorStatusCode: code
                });
            })
            .then(response => {
                const code = response.status
                if (code > 400) {
                    history.replace(history.location.pathname, {
                        errorStatusCode: code
                    });
                } else {
                    const { userName, email, profile_image } = response.body.data;
                    setState((prevState => {
                        return {
                            ...prevState,
                            userName: userName, email: email, profileImage: profile_image ? profile_image.split('/').pop() : null
                        }
                    }))
                }
            }).catch(err => {
                console.log(err)
            });
    }

    const handleInputChange = (name, value) => {
        setState((prevState => {
            return {
                ...prevState,
                [name]: value
            }
        }))
    };

    const handleFileChange = ({ target: { files } }) => {
        const cancel = !files.length;
        if (cancel) return;
        setState((prevState => {
            return {
                ...prevState,
                profileImage: files[0],
                isProfileImageUploaded: true
            }
        }))
    }

    const handleUpdateUserProfile = async (e) => {
        e.preventDefault();
        const { userName, email, newPassword, profileImage, isProfileImageUploaded } = state;

        let updateFields = {
            profile_image: profileImage,
            userName: userName,
            email: email,
        };

        if (email === "") {
            toast.error("Email address cannot be empty");
            return null;
        }

        if (newPassword !== "") {
            if (newPassword.length < 6) {
                toast.error("New Password Must Have Atleast 6-15 Characters");
                return null;
            }

            if (confirmPassword === "" || confirmPassword.length < 6) {
                toast.error("Confirm Password Must Have Atleast 6-15 Characters and Not Empty");
                return null;
            }

            if (newPassword !== confirmPassword) {
                toast.error("Please add same New Password and confirm Password");
                return null;
            }
            updateFields = { ...updateFields, password: newPassword }
        }

        let [userProfilePhoto] = await Promise.all([
            isProfileImageUploaded ? uploadFileS3Bucket(profileImage, `profile_photo_${Date.now()}`) : profileImage
        ])
        updateFields.profile_image = userProfilePhoto;
        superagent.put(`${backendUrl}/users/updateProfile/`)
            .send(updateFields)
            .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
            .set('accept', 'json')
            .on('error', (response) => {
                const code = response.status
                history.replace(history.location.pathname, {
                    errorStatusCode: code
                });
            })
            .then(response => {
                const code = response.status
                if (code > 400) {
                    history.replace(history.location.pathname, {
                        errorStatusCode: code
                    });
                } else {
                    const oldUser = JSON.parse(localStorage.getItem("authUser"))
                    localStorage.setItem("authUser", JSON.stringify({
                        ...oldUser,
                        ...response.data,
                    }))
                    toast.success("Profile updated successfully")
                }
            }).catch(err => {
                console.log(err)
            });
    };

    toast.configure({ autoClose: 8000, hideProgressBar: true })
    const { email, userName, newPassword, confirmPassword, profileImage } = state;
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Setting" breadcrumbItem="User Profile" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <form className="outer-repeater">
                                        <div data-repeater-list="outer-group" className="outer">
                                            <div data-repeater-item className="outer">
                                                <FormGroup className="mb-4" row>
                                                    <Label htmlFor="taskname" className="col-form-label col-lg-2">Profile Picture</Label>
                                                    <Col lg="10">
                                                        <CustomInput
                                                            accept="image/*"
                                                            className="form-control"
                                                            type="file"
                                                            id="exampleCustomFileBrowser"
                                                            name="customFile"
                                                            label={profileImage || 'choose an image file'}
                                                            onChange={(e) => handleFileChange(e)}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className="mb-4" row>
                                                    <Label htmlFor="userName" className="col-form-label col-lg-2">User Name</Label>
                                                    <Col lg="10">
                                                        <Input name="userName" type="text" className="form-control" placeholder="Enter User Name..." value={userName}
                                                            onChange={(e) => handleInputChange(e.target.name, e.target.value)} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className="mb-4" row>
                                                    <Label htmlFor="email" className="col-form-label col-lg-2">Email</Label>
                                                    <Col lg="10">
                                                        <Input name="email" type="text" className="form-control" placeholder="Enter User Email..." value={email}
                                                            onChange={(e) => handleInputChange(e.target.name, e.target.value)} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className="mb-4" row>
                                                    <label htmlFor="newPassword" className="col-form-label col-lg-2">New password</label>
                                                    <div className="col-lg-10">
                                                        <Input name="newPassword" type="password" placeholder="*************" className="form-control" value={newPassword}
                                                            onChange={(e) => handleInputChange(e.target.name, e.target.value)} />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="mb-4" row>
                                                    <label htmlFor="confirmPassword" className="col-form-label col-lg-2">Confirm password</label>
                                                    <div className="col-lg-10">
                                                        <Input name="confirmPassword" type="password" placeholder="*************" className="form-control" value={confirmPassword}
                                                            onChange={(e) => handleInputChange(e.target.name, e.target.value)} />
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </form>
                                    <Row className="justify-content-end">
                                        <Col lg="10">
                                            <Button onClick={(e) => handleUpdateUserProfile(e)} type="submit" color="primary">Update Profile</Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default UserProfile;