import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Col, Card, CardBody, UncontrolledTooltip, Media, Badge } from "reactstrap";



const FlagTypeCard = ({flagtype, key}) => {
  console.log(flagtype)

  return (
    <Col xl="4" sm="6" key={"_flagtype_" + key} >
      <Link to={`/flagtype/edit/${flagtype._id}`}>
        <Card>
          <CardBody>
            <Media>
              <Media className="overflow-hidden" body>
                <h5 className="text-truncate font-size-15"><Link to={`/flagtype/edit/${flagtype._id}`} className="text-dark">{flagtype.name}</Link></h5>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Link>
    </Col>
  )
}


export default FlagTypeCard;