import React from 'react'

import FontFile from '../shared/font-file'

const Word = (props) => {
  return (
    <FontFile
      font={'signature'}
      topOffset={'0.05em'}
      multiplier={1.25}
      {...props}
    />
  )
}

export default Word
