import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import CommentHistory from './CommentHistory'

const WelcomeComp = ({ stateData, dashboard, monogramData }) => {
    const { newOrders, corp } = stateData;
    const user = JSON.parse(localStorage.getItem("authUser"));
    return (
        <React.Fragment>
            <Card className="overflow-hidden">
                <div style={{background: 'rgba(31, 62, 60, 1)'}}>
                    <Row>
                        <Col xs="12">
                            <div className="text-primary p-3 mb-0">
                                <h5 className="text-white text-center">Welcome Back {user.userName}</h5>
                            </div>
                        </Col>
                    </Row>
                </div>
                <CardBody className="pt-0">
                    <Row style={{borderBottom: '1px solid #eff2f7'}}>
                        <Col sm="2">
                            <div className="avatar-md profile-user-wid mb-4">
                                <img  style={{objectFit: 'cover', width: 64, height: 64}} src={user.profile_image?.replace('.com//', '.com/')} alt="" className="img-thumbnail rounded-circle" />
                            </div>
                        </Col>
                        <Col sm="10">
                            <div className="pt-4 pb-4" >
                                <Row>
                                    <Col xs="4" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <Link to="/orders">
                                            <h5 className={`font-size-15 text-center ${dashboard ? '' : 'placeholder'}`}>{dashboard ? newOrders : ' '}</h5>
                                            <p className="text-muted mb-0">New Orders</p>
                                        </Link>
                                    </Col>
                                    <Col xs="6" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <Link to="/corp-orders">
                                            <h5 className={`font-size-15 text-center ${dashboard ? '' : 'placeholder'}`}>{dashboard ? corp : ' '}</h5>
                                            <p className="text-muted mb-0">Corporate Orders</p>
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{borderBottom: '1px solid #eff2f7'}}>
                        <Col xs="12">
                            <div className="text-primary p-3 mb-0">
                                <h5 className="text-black text-center">Uncompleted Monograms</h5>
                            </div>
                        </Col>
                        <Col sm="12">
                            <div className="py-2" >
                                <Row>
                                    <Col xs="4">
                                        <h5 className={`font-size-15 text-center ${monogramData ? '' : 'placeholder'}`}>{monogramData ? monogramData.stand.printJobs + monogramData.corp.printJobs : ' '}</h5>
                                        <p className="text-muted mb-0 text-center">Print</p>
                                    </Col>
                                    <Col xs="4" >
                                        <h5 className={`font-size-15 text-center ${monogramData ? '' : 'placeholder'}`}>{monogramData ? monogramData.stand.embroideryJobs + monogramData.corp.embroideryJobs  : ' '}</h5>
                                        <p className="text-muted mb-0 text-center">Embroidery</p>
                                    </Col>
                                    <Col xs="4" >
                                        <h5 className={`font-size-15 text-center ${monogramData ? '' : 'placeholder'}`}>{monogramData ? monogramData.stand.awaitingJobs + monogramData.corp.awaitingJobs  : ' '}</h5>
                                        <p className="text-muted mb-0 text-center">Awaiting Stock</p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{borderBottom: '1px solid #eff2f7'}}>
                        <Col xs="12">
                            <div className="text-primary p-3 mb-0">
                                <h5 className="text-black text-center">Completed Monograms</h5>
                            </div>
                        </Col>
                        <Col sm="12">
                            <div className="py-2" >
                                <Row>
                                    <Col xs="4">
                                        <h5 className={`font-size-15 text-center ${monogramData ? '' : 'placeholder'}`}>{monogramData ? monogramData.completed.greenMachine : ' '}</h5>
                                        <p className="text-muted mb-0 text-center">Green Machine</p>
                                    </Col>
                                    <Col xs="4" >
                                        <h5 className={`font-size-15 text-center ${monogramData ? '' : 'placeholder'}`}>{monogramData ? monogramData.completed.ironMan  : ' '}</h5>
                                        <p className="text-muted mb-0 text-center">Iron Man</p>
                                    </Col>
                                    <Col xs="4" >
                                        <h5 className={`font-size-15 text-center ${monogramData ? '' : 'placeholder'}`}>{monogramData ? monogramData.completed.julie  : ' '}</h5>
                                        <p className="text-muted mb-0 text-center">Julie</p>
                                    </Col>
                                    <Col xs="6" >
                                        <h5 style={{marginTop: '10px'}} className={`font-size-15 text-center ${monogramData ? '' : 'placeholder'}`}>{monogramData ? (monogramData.completed.greyMachine ? monogramData.completed.greyMachine : 0) : ' '}</h5>
                                        <p className="text-muted mb-0 text-center">Grey Machine</p>
                                    </Col>
                                    <Col xs="6" >
                                        <h5 style={{marginTop: '10px'}} className={`font-size-15 text-center ${monogramData ? '' : 'placeholder'}`}>{monogramData ? (monogramData.completed.whiteMachine ? monogramData.completed.whiteMachine : 0) : ' '}</h5>
                                        <p className="text-muted mb-0 text-center">White Machine</p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="12">
                            <CommentHistory title="Message Board" initCommentType={'Dashboard'} />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    )

}

export default WelcomeComp;
