import React, { useState, useEffect, useRef } from "react";
import { FormGroup, Row, Col, Card, CardBody, Container, Input, Button, Label, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Link } from "react-router-dom";
import useQuery from '../../../helpers/JIFI/useQuery';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import CardProduct from "./card-product";
import Select from 'react-select';

const perPageOptions = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },

]

const paginate = function (array, index) {
  return [
    array[index - 2],
    array[index - 1],
    array[index],
    array[index + 1],
    array[index + 2]
  ].filter(function (el) {
    return el != null;
  });
}


const TablePagination = ({ count, page, setPage, perPage, setPerPage }) => {
  const pageCount = Math.ceil(count / perPage)
  const pages = paginate(Array.from({ length: pageCount }, (v, i) => i + 1), page)
  const handleNumberClicked = (key) => {
    setPage(key)
  }
  return (
    <Pagination className="pagination pagination-rounded justify-content-end mb-2">
      <div style={{ height: '100%', width: 140 }}>
        <Select
          options={perPageOptions}
          onChange={newPerPage => setPerPage(newPerPage.value)}
          value={perPageOptions.find(val => val.value === perPage)}
        />
      </div>
      <PaginationItem disabled>
        <PaginationLink previous />
      </PaginationItem>
      {
        pages.map(val => (
          <PaginationItem onClick={() => handleNumberClicked(val)} active={val === page}>
            <PaginationLink>
              {val}
            </PaginationLink>
          </PaginationItem>
        ))
      }
      <PaginationItem>
        <PaginationLink next />
      </PaginationItem>
    </Pagination>
  )
}

const ProductListPage = ({ }) => {

  const handleTermChange = (e) => {
    const newTerm = e.currentTarget.value
    setStateData((prevState => {
      return {
        ...prevState,
        searchTerm: newTerm
      }
    }))
  }

  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
    setRefresh(prev => !prev)
  }
  const initialState = {
    searchTerm: '',
  }

  const [stateData, setStateData] = useState(initialState);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const [data] = useQuery({ refresh, url: `/product/findAll`, queryData: {  perPage: perPage, page: page, searchTerm: stateData.searchTerm} });

  const { searchTerm } = stateData;
  const count = data && data.count;

  const products = data && data.data

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Products" breadcrumbItem="All Products" />
        <Card>        
          <CardBody>
          <Row className="mb-2">
            <Col sm="4">
              <FormGroup className="select2-container">
                <Label className="control-label">FILTER</Label>
                <div className="search-box mb-2 d-block">
                  <div className="position-relative">
                    <Input type="text" className="form-control" placeholder="Search..." value={searchTerm} onChange={handleTermChange} />
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </FormGroup>
            </Col>
            <Col sm="6">
            </Col>
            <Col sm="2">
              <Link  to="/product/variant-bulk-editor" className="dropdown-item text-danger text-right">Bulk Edit Variants</Link>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col sm="10" />
            <Col sm="2">
              <Link to='/product/new'>
                <div className="text-sm-right">
                  <Button type="button" color="success" className="btn-rounded waves-effect waves-light mb-2 mr-2"><i className="mdi mdi-plus mr-1"></i> Create new</Button>
                </div>
              </Link>
            </Col>
          </Row>
          <Row>
            {
              products ? products.map((val, key) => <CardProduct product={val} key={key} />) : (
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                  <div className="spinner-grow text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )
            }
          </Row>
          <TablePagination count={count} page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} />
        </CardBody>
      </Card>
      </Container>
    </div>
  )
}

export default ProductListPage