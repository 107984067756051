import React from 'react';
import S3 from 'react-aws-s3';
import superagent from 'superagent'
import { backendUrl } from '../helpers/consts.js'

const uploadFileS3Bucket = async (file, fileName) => {
    const config = await superagent.get(`${backendUrl}/inventory/getConfig/`)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')


    console.log(config.body)
    const ReactS3Client = new S3(config.body);

    const res = await ReactS3Client.uploadFile(file, fileName)
    return res.location
};

export { uploadFileS3Bucket };