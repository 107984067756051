import React, { Component, useState, useEffect } from "react";
import { Table, Row, Col, Card, CardBody, CardTitle, CardSubtitle, UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from "reactstrap";
import { Link } from "react-router-dom";

import { CSVLink, CSVDownload } from "react-csv";

import {AddProduct, AddVariant} from './AddProduct'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import useQuery from '../../helpers/JIFI/useQuery';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import {useSelector} from 'react-redux'

import LocationSelector from './shared/LocationSelector'


const BasicTable = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [modal, setModal] = useState()
    const [createVariant, setCreateVariant] = useState()

    const [refresh, setRefresh] = useState(true)
    const refreshData = () => {
        setRefresh(prev => !prev)
    }

    const togglemodal = () => {
        setModal(prev => !prev)
    }
    const toggleVariant = () => {
        setCreateVariant(prev => undefined)
    }
    const handleChange = date => {
        setStartDate(date)
    };

    const selectedLocation = useSelector(state => state.Layout.location)
    useEffect(() => {
        refreshData()
    }, [selectedLocation])

    const [data, state] = useQuery({
        url: `/inventory/fetchStockCount`,
        refresh,
        queryData: {
            location: selectedLocation
        }
    });

    const [pendingData, pendingState] = useQuery({
        url: `/inventory/pendingStock`,
        refresh,
    });
    
    const products = data && data
    const count = data && data.count

    const productDataArrays = products ? 
        products.map(product => product.variantCounts.map(variantCounts => ([variantCounts.variant.sku, variantCounts.count?.on_hand, variantCounts.count?.display, variantCounts.count?.dead]))) 
    : [[]]
    const merged = [].concat.apply([], productDataArrays);

    const dateString = (new Date()).toDateString().replace(' ', '-').replace(' ', '-').replace(' ', '-')
    const csvData = [
        ['SKU', 'ON_HAND', 'DISPLAY', 'DEAD'],
        ...merged
    ]

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Inventory" breadcrumbItem="Products" />
                    <Row className="mb-2">
                        <Col sm="8">
                            <CSVLink 
                                data={csvData}
                                filename={`STOCK_COUNT_${dateString}.csv`}
                            >
                                <Button type="button" color="secondary" className="btn-lg btn-rounded">
                                CSV
                                </Button>
                            </CSVLink>
                        </Col>
                        <Col sm="2">
                            {/* <div className="text-sm-right">
                                <Button onClick={togglemodal} type="button" color="success" className="btn-rounded waves-effect waves-light mb-2 mr-2"><i className="mdi mdi-plus mr-1"></i> Create new</Button>
                            </div> */}
                        </Col>
                        <Col sm="2">
                            <LocationSelector />
                        </Col>
                    </Row>
                    {
                        state === 'success' && products && products.map(product => (
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            {product.productName}
                                            <Link onClick={() => {setCreateVariant(product['_id']);}} className="mr-3 text-primary float-right">
                                                <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip"></i>
                                                <UncontrolledTooltip placement="top" target="edittooltip" >
                                                    Create
                                                </UncontrolledTooltip >
                                            </Link>
                                        </CardTitle>
                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>SKU</th>
                                                        <th>Variant Name</th>
                                                        <th>Pending</th>
                                                        <th>Stock On Hand</th>
                                                        <th>Display Stock</th>
                                                        <th>Dead Stock</th>
                                                        <th>Monogramming</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        product.variantCounts.sort(function(a, b){
                                                            if(a.variant.sku < b.variant.sku) { return -1; }
                                                            if(a.variant.sku > b.variant.sku) { return 1; }
                                                            return 0;
                                                          }).map(variantCounts => {
                                                            return (
                                                                <tr>
                                                                    <th scope="row">{variantCounts.variant.sku}</th>
                                                                    <td>{variantCounts.variant.name}</td>
                                                                    <td>{pendingData && pendingData[variantCounts.variant.sku] || 0}</td>
                                                                    <td>{variantCounts.count?.on_hand}</td>
                                                                    <td>{variantCounts.count?.display}</td>
                                                                    <td>{variantCounts.count?.dead}</td>
                                                                    <td>{variantCounts.count?.monogram}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>                
                        </Row>
                        ))
                    }
                </div>
            </div>
            <Modal isOpen={modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" toggle={togglemodal}>
                <div className="modal-content">
                    <ModalHeader toggle={togglemodal}>
                        Create Product
                    </ModalHeader >
                    <ModalBody>
                        <AddProduct refreshData={refreshData} toggle={toggleVariant} />
                    </ModalBody>
                </div>
            </Modal>
            <Modal isOpen={createVariant !== undefined} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" toggle={toggleVariant}>
                <div className="modal-content">
                    <ModalHeader >
                        Create Variant
                        </ModalHeader >
                    <ModalBody>
                        <AddVariant id={createVariant} refreshData={refreshData} toggle={toggleVariant} />
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default BasicTable;
