import React from 'react'
import { Container, Row, Col, Button  } from "reactstrap";
import { Link } from "react-router-dom";
import useQuery from '../../../helpers/JIFI/useQuery';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import CardLocation from "./card-location";

const ProductListPage = ({}) => {
  const [data, state] = useQuery({
    url: `/inventory/getLocations`
  });
  const locations = data
  return (
    <>
      <div className="page-content">
        <Container fluid>
            <Breadcrumbs title="Locations" breadcrumbItem="All Locations" />
            <Row className="mb-2">
              <Col sm="10" />
              <Col sm="2">
                <Link to='/location/new'>
                  <div className="text-sm-right">
                    <Button type="button" color="success" className="btn-rounded waves-effect waves-light mb-2 mr-2"><i className="mdi mdi-plus mr-1"></i> Create new</Button>
                  </div>
                </Link>
              </Col>
            </Row>
            <Row>
                {
                  locations ? locations.map((val, key) => <CardLocation location={val} key={key} />) : (
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: 48 }}>
                      <div className="spinner-grow text-primary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )
                }
            </Row>
        </Container>
      </div>
    </>
  )
}

export default ProductListPage