import React, { useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Breadcrumb,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import useQuery from '../../helpers/JIFI/useQuery';
import { CSVLink } from "react-csv";

const regions = [
  { name: "AU", url: "https://july.com/stock/AU.json" },
  { name: "US", url: "https://july.com/stock/US.json" },
  { name: "NZ", url: "https://july.com/stock/NZ.json" },
  { name: "UK", url: "https://july.com/stock/UK.json" },
];

const BasicTable = () => {
  const [refresh, setRefresh] = useState(true);
  const refreshData = () => {
    setRefresh((prev) => !prev);
  };

  const [selectedRegion, setSelectedRegion] = useState("AU");
  const [productData, productState] = useQuery({
    url: `/product/findAll`,
    refresh,
  });

  const selectedRegionObject = regions.find((val) => val.name === selectedRegion);
  const [stockData, setStockData] = useState();
  const [stockState, setStockState] = useState("loading");

  useEffect(() => {
    setStockState("loading");
    setStockData(null);
    fetch(selectedRegionObject.url)
      .then((val) => val.json())
      .then((val) => {
        setStockData(val);
        setStockState("success");
      })
      .catch((e) => console.log(e));
  }, [selectedRegionObject.url, refresh]);

  const generateCSVData = () => {
    if (!productData || !stockData) return [];

    let csvData = [["Product Name", "Variant Name", "SKU", "Status"]];
    productData.data.forEach((product) => {
      product.variants.forEach((variant) => {
        const sku = variant.sku;
        const oosData = stockData[sku];

        let countType = "Available";

        if (oosData.avaliable === true && oosData.on_site === true && oosData.preorder === true) {
          countType = "Preorder";
        }

        if (oosData.avaliable === false && oosData.on_site === true) {
          countType = "Out of Stock";
        }

        if (oosData.on_site === false) {
          countType = "Discontinued";
        }

        csvData.push([product.name, variant.name, variant.sku, countType]);
      });
    });
    return csvData;
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb title="Inventory" breadcrumbItem="All Regions" />
        <Row className="mb-2">
          <Col sm="8"></Col>
          <Col sm="2">
            <CSVLink
              data={generateCSVData()}
              filename={`inventory_${selectedRegion}.csv`}
              className="btn btn-primary"
            >
              Export CSV
            </CSVLink>
          </Col>
          <Col sm="2">
            <Select
              value={{ value: selectedRegion, label: selectedRegion }}
              options={regions.map((val) => ({ value: val.name, label: val.name }))}
              onChange={(val) => setSelectedRegion(val.value)}
            />
          </Col>
        </Row>
        <Row>
          {productData && productState === "success" && stockData && stockState === "success" ? (
            <>
              {productData.data
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((product) => (
                  <Col md={4} key={product.name}>
                    <Card>
                      <CardBody>
                        <CardTitle>{product.name}</CardTitle>
                        <div className="table-responsive">
                          <Table className="table mb-0">
                            <tbody>
                              {product.variants.map((variant) => {
                                const sku = variant.sku;
                                const oosData = stockData[sku];

                                let countType = "Available";

                                if (oosData.avaliable === true && oosData.on_site === true && oosData.preorder === true) {
                                  countType = "Preorder";
                                }

                                if (oosData.avaliable === false && oosData.on_site === true) {
                                  countType = "Out of Stock";
                                }

                                if (oosData.on_site === false) {
                                  countType = "Discontinued";
                                }

                                const typeColor = {
                                  Preorder: "bg-warning bg-soft",
                                  Available: "bg-success bg-soft",
                                  "Out of Stock": "bg-danger bg-soft",
                                  Discontinued: "bg-secondary bg-soft",
                                };

                                return (
                                  <tr className={typeColor[countType]} key={variant.sku}>
                                    <td scope="row">{variant.name}</td>
                                    <td scope="row">{variant.sku}</td>
                                    <td scope="row">{countType}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
            </>
          ) : (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Spinner type="border" color="primary" />
            </div>
          )}
        </Row>
      </div>
    </div>
  );
};

export default BasicTable;
