import React, { useState } from 'react';
import { Table, Col, Card, CardBody, Button, Container, Row, Label, Input } from 'reactstrap';
// Editable
import { Link } from 'react-router-dom';

import { CSVLink } from 'react-csv';
import { TablePagination } from '../../../components/supply-chain/TablePagination/index.js';
//Import Breadcrumb

import useQuery from '../../../helpers/JIFI/useQuery';

import SupplyChainNav from '../../../components/supply-chain/nav.js';

const FactoryList = () => {
  const dateString = new Date()
    .toDateString()
    .replace(' ', '-')
    .replace(' ', '-')
    .replace(' ', '-');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [factories, factoriesState] = useQuery({
    url: `/factory/all`,
    queryData: {
      page,
      perPage,
      searchTerm
    },
  });


  const handleKeyDown = (e) => {
    
    if (e.key === 'Enter' || e.keyCode === 13) {
      setSearchTerm(e.target.value);
    }
  }

  const factoryData = factories?.data;
  const count = factories?.totalCount;

  const csvData = [
    ['Code', 'Name', 'Total Stock Product'],
    ...(factoryData?.length > 0
      ? factoryData?.map((val) => [
          val.code,
          val.name,
          val?.variantLines?.length ?? 0,
        ])
      : []),
  ];
  return (
    <>
      <SupplyChainNav>
        <Container>
          <Col xs="12" m="12">
            <Card>
              <CardBody>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Link to="/factories/create">
                    <div className="text-sm-right">
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded waves-effect waves-light mb-2 mr-2"
                      >
                        <i className="mdi mdi-plus mr-1"></i> Create new{' '}
                      </Button>
                    </div>
                  </Link>
                  <CSVLink
                    style={{ float: 'right', marginBottom: '1.25rem' }}
                    data={csvData}
                    filename={`FACTORY-${dateString}.csv`}
                  >
                    <Button
                      type="button"
                      color="secondary"
                      className="btn-md btn-rounded"
                    >
                      CSV
                    </Button>
                  </CSVLink>
                </div>
                <Row>
                <Col>
                    <Label className="control-label">FILTER</Label>
                    <Row>
                      <Col xs="3">
                    
                        <div className="search-box mb-2 d-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                              onKeyDown={handleKeyDown}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                    
                      </Col>
                      
                    </Row>

                  </Col>
                </Row>
                {factoriesState === 'success' ? (
                  <div className="table-responsive">
                    <Table className="table table-centered table-nowrap">
                      <tr>
                        <th>Code</th>
                        <th>Name</th>
                        <th className="text-center">Total Stock Product</th>
                      </tr>
                      <tbody>
                        {factoryData && factoryData.length > 0
                          ? factoryData.map((val, key) => {
                              return (
                                <tr>
                                  <td>
                                    <Link
                                      className="text-body font-weight-bold"
                                      to={`/factories/${val._id}`}
                                    >
                                      {val.code}
                                    </Link>
                                  </td>
                                  <td>{val.name}</td>
                                  <td className="text-center">
                                    {val?.variantLines?.length}
                                  </td>
                                </tr>
                              );
                            })
                          : []}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      className="spinner-border text-primary m-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
                <TablePagination
                  count={count}
                  page={page}
                  setPage={setPage}
                  perPage={perPage}
                  setPerPage={setPerPage}
                />
              </CardBody>
            </Card>
          </Col>
        </Container>
      </SupplyChainNav>
    </>
  );
};

export default FactoryList;
