import React, { useState, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import { Row, Col, Card, CardBody } from "reactstrap";
import { FormGroup, Container, Input, Button,  Label,  Modal,  ModalFooter } from "reactstrap";

import "../datatables.scss";

import useQuery from '../../../helpers/JIFI/useQuery';

import { useHistory } from "react-router-dom";
import superagent from 'superagent'
import {backendUrl} from '../../../helpers/consts.js'

import LocationSelector from '../shared/LocationSelector'
import { useSelector } from 'react-redux';


import { CSVReader } from 'react-papaparse';
import SupplyChainNav from '../../../components/supply-chain/nav.js';
import ProductVariantSelector from "../helpers/ProductVariantSelector.js";



const StockInput = ({ stock, setStock, productData, purchaseOrders }) => {
  

  const handleAddRow = () => {
    const item = {
      product: productData[0]._id,
      variant: productData[0].variants[0]._id,
      quantity: 0,
      costPerItem: 0,
      purchaseOrder: ''
    };
    setStock(prev => [...prev, item]);
  };

 

  const editRow = (id, field, value) => {
    setStock(prev => {
      const newList = [...prev];
      newList[id][field] = value;
      return newList;
    });
    
  };

  const removeRow = (id) => {
    setStock(prev => prev.filter((_, key) => key !== id));
   
  };

  const products = productData || [];
  

  return (
    <>
      <Row>
        <Col xs="4">Variant</Col>
        <Col xs="2">Quantity</Col>
        <Col xs="2">FOB</Col>
        <Col xs="2">Purchase Order</Col>
      </Row>
      <br />
      {productData &&
        stock.map((line, lineKey) => {
          const filteredPurchaseOrders = purchaseOrders?.filter(val =>
            val?.lines?.some(poLine => poLine?.variant?._id === line?.variant?._id)
          );
          return (
            <Row key={lineKey} style={{ padding: '12px 0' }}>
              <Col xs="4">
                <ProductVariantSelector
                  hidePersonalisationOptions
                  products={products}
                  setSelectedVariant={(val) => editRow(lineKey, 'variant', val)}
                  selectedVariant={line.variant}
                />
              
              </Col>
              <Col xs="2">
                <Input
                  style={{ width: '90%', height: '100%' }}
                  type="number"
                  value={line.quantity}
                  onChange={(e) => editRow(lineKey, 'quantity', e.target.value)}
                />
               
              </Col>
              <Col xs="2">
                <Input
                  style={{ width: '90%', height: '100%' }}
                  type="number"
                  value={line.costPerItem}
                  onChange={(e) => editRow(lineKey, 'costPerItem', e.target.value)}
                />
               
              </Col>
              <Col xs="2">
                <Input
                  type="select"
                  style={{ width: '100%', height: '100%' }}
                  onChange={(e) => editRow(lineKey, 'purchaseOrder', e.target.value)}
                  value={line.purchaseOrder}
                >
                  <option value="">none</option>
                  {filteredPurchaseOrders.map((purchaseOrder, key) => (
                    <option key={key} value={purchaseOrder?._id}>
                      {purchaseOrder.reference}
                    </option>
                  ))}
                </Input>
                
              </Col>
              <Col xs="1">
                <Button onClick={() => removeRow(lineKey)} color="danger">
                  X
                </Button>
              </Col>
            </Row>
          );
        })}
      {productData &&
        [stock.reduce(
          (sum, line) => {
            sum.sum += parseInt(line.quantity);
            sum.total += line.quantity * line.costPerItem;
            return sum;
          },
          { sum: 0, total: 0 }
        )].map(val => {
          if (val.sum === 0) {
            return <></>;
          }
          return (
            <Row key="total" style={{ padding: '12px 0' }}>
              <Col xs="2">Total</Col>
              <Col xs="2"></Col>
              <Col xs="2">{isNaN(val.sum) ? 0 : val.sum}</Col>
              <Col xs="2">{isNaN(val.total) ? 0 : val.total}</Col>
              <Col xs="2"></Col>
              <Col xs="1"></Col>
            </Row>
          );
        })}
        {productData?.length > 0  &&
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 12 }}>
        <Button onClick={handleAddRow} color="primary">
          Add
        </Button>
        <span />
      </div>
}
    </>
  );
};



const PullPage = () => {
  const history = useHistory();
  const [stockPull, setStockPull] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const selectedLocation = useSelector(state => state.Layout.location);
  const [formState, setFormState] = useState({
    arrivalDate: '',
    portDate: '',
    departureDate: '',
    warehouseDatePlanned: '',
    warehouseDateActual: '',
    requestedDate: '',
    bookingDate: '',
    containerState: 'draft',
    containerName: '',
    containerRef: '',
    originPort: '',
    destinationPort: '',
    shippingCarrier: '',
    containerType: '',
    freightCost: '',
    localCost: ''
  });
  const [stockCount, setStockCount] = useState({})

  const [stockCountFull, stockCountState] = useQuery({
    url: `/inventory/fetchStockCount`,
    refresh: true,
    queryData: {
      location: 'EWE'
    }
  });

  const [data, state] = useQuery({
    url: `/product`,
    refresh: true
  });
  
  const productData = data && data.data;
  const [purchaseOrdersPagination, purchaseOrderState] = useQuery({
    url: `/purchase-order/all`,
    refresh: true,
    queryData: {
      status: 'approved'
    }
  });


  const purchaseOrders = purchaseOrdersPagination && purchaseOrdersPagination.data
  useEffect(() => {
    let collatedStockCount = {};
    if (stockCountFull) {
      stockCountFull.forEach(product => {
        product.variantCounts.forEach(variantCount => {
          collatedStockCount[variantCount.variant.sku] = variantCount.count.on_hand;
        });
      });
      setStockCount(collatedStockCount);
    }
  }, [stockCountFull]);

  const toggleModal = () => setModal(prev => !prev);

  const validate = () => {
    const newErrors = {};
    const requiredFields = [
      'containerName', 'containerRef', 'departureDate', 'requestedDate', 'warehouseDatePlanned', 
      'arrivalDate', 'bookingDate', 'warehouseDateActual', 'portDate', 'freightCost', 'localCost'
    ];

    requiredFields.forEach(field => {
      if (!formState[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim()} is required`;
      } else if ((field === 'freightCost' || field === 'localCost') && (parseFloat(formState[field]) <= 0)) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim()} must be a positive number`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: null
      }));
    }
  };

  const handleSubmit = () => {
    if (!validate()) {
      return;
    }

    setLoading(true);
    const exportData = {
      stock: stockPull,
      ...formState,
      name: formState.containerName,
      ref: formState.containerRef,
      status: formState.containerState,
      location: selectedLocation
    };

    superagent
      .post(`${backendUrl}/inventory/createContainer`)
      .send(exportData)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code
        });
      })
      .then(response => {
        const code = response.status;
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code
          });
        } else {
          history.go(0);
        }
      })
      .catch(err => {
        setLoading(false);
        const errorMessage = err.response?.body?.message || err.message || 'Internal server error';
        toast.error(errorMessage, {
          autoClose: 5000,
        });
      });
  };

  const handleOnDrop = (data) => {
    let items = [];
    data.forEach((line, key) => {
      if (key > 0) {
        const count = line.data[1];
        const sku = line.data[0];
        let product = productData.find(val => val.variants.find(variant => variant.sku === sku));
        if (product && sku !== "") {
          let variant = product.variants.find(variant => variant.sku === sku);
          if (variant) {
            const item = {
              product: product._id,
              variant: variant,
              quantity: count,
              sku: sku
            };
            items.push(item);
          }
        }
      }
    });

    items = items.sort((a, b) => (a.sku < b.sku ? -1 : a.sku > b.sku ? 1 : 0));
    setStockPull(prev => [...prev, ...items]);
  };

  const handleOnError = (err) => {
    console.log(err);
  };

  const handleOnRemoveFile = () => {
    // Handle file removal
  };

  const getInputClass = (field) => errors[field] ? 'is-invalid' : '';

  return (
    <SupplyChainNav>
      <ToastContainer />
      <Container fluid>
        <div>
          <Row className="mb-2">
            <Col xs="12" m="8" lg="8" xl="8">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="6">
                      <FormGroup className="select2-container">
                        <Label className="control-label">Supplier Invoice Reference</Label>
                        <Input
                          className={getInputClass('containerName')}
                          name="containerName"
                          value={formState.containerName}
                          onChange={handleInputChange}
                          placeholder="Name"
                        />
                        {errors.containerName && <div className="invalid-feedback">{errors.containerName}</div>}
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup className="select2-container">
                        <Label className="control-label">Container Reference</Label>
                        <Input
                          className={getInputClass('containerRef')}
                          name="containerRef"
                          value={formState.containerRef}
                          onChange={handleInputChange}
                          placeholder="Name"
                        />
                        {errors.containerRef && <div className="invalid-feedback">{errors.containerRef}</div>}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <FormGroup className="select2-container">
                        <Label className="control-label">Destination Port</Label>
                        <Input
                          name="destinationPort"
                          value={formState.destinationPort}
                          onChange={handleInputChange}
                          type="select"
                          id="exampleSelect"
                        >
                          <option value="">none</option>
                          <option value="draft">Port 1</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup className="select2-container">
                        <Label className="control-label">Origin Port</Label>
                        <Input
                          name="originPort"
                          value={formState.originPort}
                          onChange={handleInputChange}
                          type="select"
                          id="exampleSelect"
                        >
                          <option value="">none</option>
                          <option value="draft">Port 1</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <FormGroup className="select2-container">
                        <Label className="control-label">Shipping Carrier</Label>
                        <Input
                          name="shippingCarrier"
                          value={formState.shippingCarrier}
                          onChange={handleInputChange}
                          type="select"
                          id="exampleSelect"
                        >
                          <option value="">none</option>
                          <option value="draft">Carrier 1</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup className="select2-container">
                        <Label className="control-label">Container Type</Label>
                        <Input
                          name="containerType"
                          value={formState.containerType}
                          onChange={handleInputChange}
                          type="select"
                          id="exampleSelect"
                        >
                          <option value="">none</option>
                          <option value="draft">40ft</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Container State</Label>
                    <Input
                      name="containerState"
                      value={formState.containerState}
                      onChange={handleInputChange}
                      type="select"
                      id="exampleSelect"
                    >
                      <option value="draft">Draft</option>
                      <option value="packing">Packing</option>
                      <option value="in-transit">In Transit</option>
                      <option value="at-port">At Port</option>
                      <option value="ready">Ready to unload</option>
                      <option value="completed">Completed</option>
                    </Input>
                  </FormGroup>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Landing Location</Label>
                    <LocationSelector />
                  </FormGroup>
                  <Row>
                    <Col xs="4">
                      <FormGroup className="select2-container">
                        <Label className="control-label">Departure Date</Label>
                        <Input
                          className={`form-control ${getInputClass('departureDate')}`}
                          name="departureDate"
                          type="date"
                          value={formState.departureDate}
                          onChange={handleInputChange}
                          id="example-date-input"
                        />
                        {errors.departureDate && <div className="invalid-feedback">{errors.departureDate}</div>}
                      </FormGroup>
                      <FormGroup className="select2-container">
                        <Label className="control-label">Arrival Date</Label>
                        <Input
                          className={`form-control ${getInputClass('arrivalDate')}`}
                          name="arrivalDate"
                          type="date"
                          value={formState.arrivalDate}
                          onChange={handleInputChange}
                          id="example-date-input"
                        />
                        {errors.arrivalDate && <div className="invalid-feedback">{errors.arrivalDate}</div>}
                      </FormGroup>
                      <FormGroup className="select2-container">
                        <Label className="control-label">Port Date</Label>
                        <Input
                          className={`form-control ${getInputClass('portDate')}`}
                          name="portDate"
                          type="date"
                          value={formState.portDate}
                          onChange={handleInputChange}
                          id="example-date-input"
                        />
                        {errors.portDate && <div className="invalid-feedback">{errors.portDate}</div>}
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup className="select2-container">
                        <Label className="control-label">Requested Date</Label>
                        <Input
                          className={`form-control ${getInputClass('requestedDate')}`}
                          name="requestedDate"
                          type="date"
                          value={formState.requestedDate}
                          onChange={handleInputChange}
                          id="example-date-input"
                        />
                        {errors.requestedDate && <div className="invalid-feedback">{errors.requestedDate}</div>}
                      </FormGroup>
                      <FormGroup className="select2-container">
                        <Label className="control-label">Booking Date</Label>
                        <Input
                          className={`form-control ${getInputClass('bookingDate')}`}
                          name="bookingDate"
                          type="date"
                          value={formState.bookingDate}
                          onChange={handleInputChange}
                          id="example-date-input"
                        />
                        {errors.bookingDate && <div className="invalid-feedback">{errors.bookingDate}</div>}
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup className="select2-container">
                        <Label className="control-label">Warehouse Date Planned</Label>
                        <Input
                          className={`form-control ${getInputClass('warehouseDatePlanned')}`}
                          name="warehouseDatePlanned"
                          type="date"
                          value={formState.warehouseDatePlanned}
                          onChange={handleInputChange}
                          id="example-date-input"
                        />
                        {errors.warehouseDatePlanned && <div className="invalid-feedback">{errors.warehouseDatePlanned}</div>}
                      </FormGroup>
                      <FormGroup className="select2-container">
                        <Label className="control-label">Warehouse Date Actual</Label>
                        <Input
                          className={`form-control ${getInputClass('warehouseDateActual')}`}
                          name="warehouseDateActual"
                          type="date"
                          value={formState.warehouseDateActual}
                          onChange={handleInputChange}
                          id="example-date-input"
                        />
                        {errors.warehouseDateActual && <div className="invalid-feedback">{errors.warehouseDateActual}</div>}
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Freight Cost - $USD</Label>
                    <Input
                      className={`form-control ${getInputClass('freightCost')}`}
                      name="freightCost"
                      type="number"
                      value={formState.freightCost}
                      onChange={handleInputChange}
                    />
                    {errors.freightCost && <div className="invalid-feedback">{errors.freightCost}</div>}
                  </FormGroup>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Local Cost - $USD</Label>
                    <Input
                      className={`form-control ${getInputClass('localCost')}`}
                      name="localCost"
                      type="number"
                      value={formState.localCost}
                      onChange={handleInputChange}
                    />
                    {errors.localCost && <div className="invalid-feedback">{errors.localCost}</div>}
                  </FormGroup>
                  <br />
                  <div>
                    <div className="mb-2">
                      <a href="https://jifi-storage-staging.s3-ap-southeast-2.amazonaws.com/comment_file_1637273421528.csv">
                        CSV Template
                      </a>
                    </div>
                    <CSVReader
                      onDrop={handleOnDrop}
                      onError={handleOnError}
                      onRemoveFile={handleOnRemoveFile}
                    >
                      <span>Drop CSV file here or click to upload.</span>
                    </CSVReader>
                  </div>
                  <br />
                  <div id="accordion">
                    <Card className="mb-1">
                      <CardBody>
                        <StockInput
                          stock={stockPull}
                          setStock={setStockPull}
                          productData={productData}
                          purchaseOrders={purchaseOrders}
                        />
                      </CardBody>
                    </Card>
                  
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 12 }}>
                    <span />
                    {productData && stockPull.length > 0 && (
                      <Button onClick={() => {
                        if (!validate()) {
                          return;
                        }

                        toggleModal()
                      }} color="primary" className="btn-lg btn-rounded">
                        Create Container
                      </Button>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" m="4" lg="4" xl="4">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">CONTAINER SUMMARY</h4>
                  <br />
                  {productData && stockPull.length > 0 && 'Count:'}
                  {productData &&
                    stockPull.map((line, lineKey) => (
                      <div key={lineKey}>
                        <span className="text-success">+{line.quantity}</span> x {line.variant?.sku}
                      </div>
                    ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        size="lg"
        toggle={toggleModal}
      >
        <CardBody>
          <h4 className="card-title mb-4">CONTAINER SUMMARY</h4>
          <br />
          {productData && stockPull.length > 0 && 'Count:'}
          {productData &&
            stockPull.map((line, lineKey) => (
              <div key={lineKey}>
                <span className="text-success">+{line.quantity}</span> x {line.variant?.sku}
              </div>
            ))}
        </CardBody>
        <ModalFooter>
          {loading ? (
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <Button onClick={handleSubmit} color="primary" className="btn-lg btn-rounded">
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </SupplyChainNav>
  );
};

export default PullPage;
